import { useTranslation } from "react-i18next";
import { BaseSyntheticEvent, useState } from "react";
import { Control, useController } from "react-hook-form";

// Constnats
import { emojiRegex } from "@/_constants/text";

// Helpers
import ruleset from "@/_helpers/ruleset";

// Styles
import IconButton from "./icon_button";

// Components
import { TextField, InputAdornment, Box } from "@mui/material";
import { WarningFilled, CircleDash, CheckmarkFilled, View, ViewOff } from '@carbon/icons-react';



interface PasswordInputProps {
    name: string;
    control: Control<any>;
    size?: 'small' | 'medium' | 'large';
    label?: string;
    placeHolder?: string;
    tabIndex?: number;
    showRequirements?: boolean;
}

const PasswordInput: React.FC<PasswordInputProps>  = ({ 
  name,
  control,
  size = 'small',
  label,
  placeHolder,
  tabIndex = -1,
  showRequirements = false
}) => {

    const { t } = useTranslation();
    const { field, fieldState } = useController({name, control});
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const lengthRule = ruleset.passwordLength.safeParse(field.value).success;
    const uppercaseRule = ruleset.passwordUppercase.safeParse(field.value).success;
    const numberRule = ruleset.passwordNumber.safeParse(field.value).success;
    const specialRule = ruleset.passwordSpecial.safeParse(field.value).success;

    const classes = {
      'TextInput': true,
      'TextInput-small': size == 'small',
      'TextInput-medium': size == 'medium',
      'TextInput-large': size == 'large',
    }
  
    const validClasses = Object.entries(classes)
        .filter(([,v]) => !!v)
        .map(([k,]) => k)
        .join(' ')

    const handleBeforeInput = (event: BaseSyntheticEvent) => {
      const nativeEvent = event.nativeEvent as InputEvent;
      const inputValue = nativeEvent.data;

      if (inputValue && emojiRegex.test(inputValue)) {
        event.preventDefault();
      }
    };

    return (
      // Width set to 100% to always fill what ever container it is in
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: '12px', width: '100%'}}>
          <span className="label-text-02 text-secondary" style={{marginBottom: '0.5em'}}>{label ?? t('inputs.password')}</span>
          <TextField
            className={validClasses}
            inputProps={{...field, maxLength: 100}}
            onBeforeInput={handleBeforeInput}
            placeholder={placeHolder ?? t('placeholders.password')}
            tabIndex={tabIndex}
            autoComplete="true"
            type={showPassword ? 'text' : 'password'}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            InputProps={{
              endAdornment: (
              <InputAdornment position="end">
                {fieldState.invalid && (<WarningFilled fill="var(--support-error)" />)}
                <IconButton
                  kind="ghost"
                  size={size}
                  icon={showPassword ? <View fill="var(--icon-primary)" /> : <ViewOff fill="var(--icon-primary)" />}
                  tabIndex={-1}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  />
              </InputAdornment>
              )
            }}
            >
          </TextField>
          {showRequirements && 
            <>
              <Box height={12} />
              <RequirementRow success={lengthRule} label={t('inputs.info.passwordRequirements.length')} />
              <Box height={2} />
              <RequirementRow success={uppercaseRule} label={t('inputs.info.passwordRequirements.uppercase')} />
              <Box height={2} />
              <RequirementRow success={numberRule} label={t('inputs.info.passwordRequirements.number')} />
              <Box height={2} />
              <RequirementRow success={specialRule} label={t('inputs.info.passwordRequirements.special', {symbols: '!@£$%^&*",.?_'})} />
            </>
          }
      </div>
    );
};

interface IRequirementRow {
  success: boolean;
  label: string;
}

const RequirementRow: React.FC<IRequirementRow> = ({success, label}) => {
  return (
    <Box display="flex" alignItems="center">
      {success ? <CheckmarkFilled fill="var(--support-success)" /> : <CircleDash />}
      <Box width={8} />
      <span className="helper-text-02" style={{textAlign: 'start'}}>{label}</span>
    </Box>
  );
}

export default PasswordInput;