import { t } from "i18next";
import { IExerciseMetrics } from "@/interfaces/exercise/exercise";
import { ISelectOption } from "@/interfaces/components/select_option";

export const exerciseTypeOptions: ISelectOption<string>[] = [
    { id: 1, label: 'Cardio', value: 'cardio' },
    { id: 2, label: 'Resistance', value: 'resistance' },
    { id: 3, label: 'Functional', value: 'functional' },
];

export const exerciseMetricOptions: ISelectOption<IExerciseMetrics>[] = [
    { id: 1, label: 'Weight & Reps', value: {weight: true, reps: true, duration: false, distance: false, calories: false} },
    { id: 2, label: 'Weight & Time', value: {weight: true, reps: false, duration: true, distance: false, calories: false} },
    { id: 3, label: 'Time', value: {weight: false, reps: false, duration: true, distance: false, calories: false} },
    { id: 4, label: 'Distance', value: {weight: false, reps: false, duration: false, distance: true, calories: false} },
    { id: 5, label: 'Calories', value: {weight: false, reps: false, duration: false, distance: false, calories: true} },
];

export const exerciseCardioMetricOptions: ISelectOption<IExerciseMetrics>[] = [
    { id: 1, label: 'Time', value: {weight: false, reps: false, duration: true, distance: false, calories: false} },
    { id: 2, label: 'Distance', value: {weight: false, reps: false, duration: false, distance: true, calories: false} },
    { id: 3, label: 'Calories', value: {weight: false, reps: false, duration: false, distance: false, calories: true} },
];

export const exerciseBodypartOptions: ISelectOption<string>[] = [
    { label: 'Back', value: 'back' },
    { label: 'Biceps', value: 'biceps' },
    { label: 'Cardio', value: 'cardio' },
    { label: 'Chest', value: 'chest' },
    { label: 'Core', value: 'core' },
    { label: 'Flexibility', value: 'flexibility' },
    { label: 'Full Body', value: 'full_body' },
    { label: 'Legs', value: 'legs' },
    { label: 'Shoulders', value: 'shoulders' },
    { label: 'Triceps', value: 'triceps' },
];

export const exerciseCategoryOptions: ISelectOption<string>[] = [
    { label: 'Barbell', value: 'barbell' },
    { label: 'Bodyweight', value: 'bodyweight' },
    { label: 'Dumbbells', value: 'dumbbells' },
    { label: 'EZ Bar', value: 'ez_bar' },
    { label: 'Kettlebell', value: 'kettlebell' },
    { label: 'Machine', value: 'machine' },
    { label: 'Other', value: 'other' },
    { label: 'Plyometrics', value: 'plyometrics' },
    { label: 'Resistance Bands', value: 'resistance_bands' },
    { label: 'Stretching' , value: 'stretching' },
    { label: 'TRX', value: 'trx' },
];

export const exerciseMachineTypeOptions: ISelectOption<string>[] = [
    { label: 'Pin Loaded', value: 'pin_loaded' },
    { label: 'Plate Loaded', value: 'plate_loaded' },
    { label: 'Cables', value: 'cables' },
    { label: 'Other', value: 'other' },
];

export const exerciseMachineBrandOptions: ISelectOption<string>[] = [
    { label: 'Arsenal Strength', value: 'Arsenal Strength' },
    { label: 'Atlantis', value: 'Atlantis' },
    { label: 'Cybex', value: 'Cybex' },
    { label: 'Generic', value: 'Generic' },
    { label: 'Gym80', value: 'Gym80' },
    { label: 'Gymleco', value: 'Gymleco' },
    { label: 'Hammer Strength', value: 'Hammer Strength' },
    { label: 'Life Fitness', value: 'Life Fitness' },
    { label: 'Matrix', value: 'Matrix' },
    { label: 'Nautilus', value: 'Nautilus' },
    { label: 'Panatta', value: 'Panatta' },
    { label: 'Prime', value: 'Prime' },
    { label: 'Primal Strength', value: 'Primal Strength' },
    { label: 'Rogers', value: 'Reogers' },
    { label: 'Strive', value: 'Strive' },
    { label: 'Technogym', value: 'Technogym' },
    { label: 'Watson', value: 'Watson' }
];

export const exerciseLibraryTypeOptions: ISelectOption<string>[] = [
    { label: 'Custom', value: 'custom' },
    { label: 'Default', value: 'default' },
    { label: 'Team', value: 'team' },
];

export const filterColumns = [
    { label: t('components.dataTable.headers.type'), field: 'type' },
    { label: t('components.dataTable.headers.primaryFocus'), field: 'bodypart' },
    { label: t('components.dataTable.headers.category'), field: 'category' },
    { label: t('components.dataTable.headers.machineType'), field: 'machine_type', defaultHidden: true },
    { label: t('components.dataTable.headers.machineBrand'), field: 'machine_brand', defaultHidden: true },
    { label: t('components.dataTable.headers.library'), field: 'library' }
]