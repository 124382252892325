import { FC } from "react";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IFolder } from "@/interfaces/folder/folder";
import { folderFormSchema, IFolderForm } from "@/interfaces/folder/folder_form";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";
import TextInput from "@/components/text_input";
import { useAddFolderMutation, useUpdateFolderMutation } from "@/repositories/folder";


interface _FolderModalProps {
    open: boolean;
    onClose: () => void;
    folder?: IFolder;
}

const FolderModal: FC<_FolderModalProps> = ({
    open,
    onClose,
    folder,
}) => {

    const { t } = useTranslation();
    const [addFolder, { isLoading: isAdding }] = useAddFolderMutation();
    const [updateFolder, { isLoading: isUpdating }] = useUpdateFolderMutation();


    const formMethods = useForm<IFolderForm>({
        resolver: zodResolver(folderFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: folder?.name ?? '',
            description: folder?.description ?? '',
        }
    });

    const handleAdd = (data: IFolderForm) => {
        addFolder(data).unwrap().then((folder) => {
            showToast({
                type: 'success', 
                title: t('notifications.folder.created.title'), 
                message: t('notifications.folder.created.message', {name: folder.name})
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleUpdate = (data: IFolderForm) => {
        if (!folder) return;
        updateFolder({id: folder.id, data: data}).unwrap().then((folder) => {
            showToast({
                type: 'success', 
                title: t('notifications.folder.updated.title'), 
                message: t('notifications.folder.updated.message', {name: folder.name})
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }
    
    return (
        <Modal
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : onClose}
            title={t(folder ? 'modals.folder.title_edit' : 'modals.folder.title_add')}
            children={
                <FormProvider {...formMethods}>
                    <FolderForm />
                </FormProvider>}
            action1={{
                label: t('components.buttons.save'),
                loading: isAdding || isUpdating,
                disabled: !formMethods.formState.isDirty,
                onClick: folder ? formMethods.handleSubmit(handleUpdate) : formMethods.handleSubmit(handleAdd)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isAdding || isUpdating,
                onClick: onClose
            }}
            />
    )
}

export default FolderModal


const FolderForm: FC = () => {

    const { t } = useTranslation();
    const { control } = useFormContext<IFolderForm>();

    return (
        <Box display="flex" flexDirection="column" rowGap="16px" minWidth="640px">
            
            <TextInput
                control={control}
                name="name"
                label={t('inputs.titles.folderName')}
                maxLength={40}
                placeHolder={t('inputs.placeholders.folderName')}
                />

            <TextInput
                control={control}
                name="description"
                size="textarea"
                label={t('inputs.titles.folderDescription')}
                maxLength={1000}
                placeHolder={t('inputs.placeholders.folderDescription')}
                minRows={4}
                />

        </Box>
    )
}