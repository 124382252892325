import { FC } from "react"

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@/components/icon_button";
import { Checkbox, CheckboxCheckedFilled, Download } from "@carbon/icons-react";

interface ThumbnailProps {
    selected?: boolean;
    isLoading?: boolean;
    onSelect?: () => void;
    onDownload?: () => void;
    onClick?: () => void;
    url: string;
}

const Thumbnail: FC<ThumbnailProps> = ({
    selected = false,
    isLoading = false,
    onSelect,
    onDownload,
    onClick,
    url
}) => {
    
    return (
        <Box onClick={onClick} className={`Thumbnail${selected?' Thumbnail--selected':''}`} sx={{
            height: 200,
            aspectRatio: 0.69,
            borderRadius: '6px',
            background: `url(${url})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            position: 'relative',
            // border: '1px solid black',
            cursor: !!onClick ? 'pointer' : undefined,
            '::after': {
                content: `" "`,
                position: 'absolute',
                top: 0, left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '6px',
                background: '#8D8D8D',
                opacity: 0,
                transition: '.3s all'
            },
            '&:hover::after, &.Thumbnail--selected::after' : {
                opacity: 0.5,
            },
            // '&.Thumbnail--selected:not(:hover) .Thumbnail__Select' : {
            //     opacity: 0.5,
            // },
            '.Thumbnail__Button' : {
                opacity: 0,
                zIndex: 100,
                transition: '.3s all'
            },
            '&:hover .Thumbnail__Button, &.Thumbnail--selected .Thumbnail__Select' : {
                opacity: 1,
            },
        }}>
            {isLoading && <Skeleton 
                variant='rectangular' 
                height={200} 
                sx={{borderRadius: '6px'}}
            />}
            {(onSelect || selected) && <IconButton 
                size='small'
                className='Button__primary-icon Thumbnail__Button Thumbnail__Select'
                sx={{position: 'absolute', left: 8, top: 8}}
                onClick={onSelect}
                icon={selected ? <CheckboxCheckedFilled /> : <Checkbox />}
            />}
            {onDownload && <IconButton 
                size='small' 
                className='Button__primary-icon Thumbnail__Button'
                sx={{position: 'absolute', right: 8, bottom: 8}}
                onClick={onDownload}
                icon={<Download />}
            />}
        </Box>
    )
}

export default Thumbnail