import { FC, ReactNode } from "react";

// Interfaces
import { NavItem } from "@/interfaces/components/nav_item";

// Styles
import { ArrowLeft } from "@carbon/icons-react";

// Components
import { Box, Skeleton } from "@mui/material";
import IconButton from "@/components/icon_button";
import NavButton from "@/components/navigation/nav_button";

interface _IMenu {
    kind?: 'primary' | 'ghost';
    label?: string;
    adornment?: ReactNode;
    avatar?: ReactNode;
    items: Array<NavItem>;
    onBack?: () => void;
    children?: ReactNode | ReactNode[]
    isLoading?: boolean;
}

const NavSideMenu: FC<_IMenu> = ({ 
    kind = 'primary',
    label,
    avatar,
    items,
    onBack,
    children,
    adornment,
    isLoading = false
}) => {

    return (
        <Box className={kind === 'primary' ? 'NavSideMenu__primary' : 'NavSideMenu__ghost'}>

            <Box display="flex" alignItems="center">
                {!!onBack && 
                    <IconButton 
                        kind="ghost"
                        size="small"
                        icon={<ArrowLeft />} 
                        onClick={onBack} 
                        sx={{mr: 0.5}}
                    />
                }
                {avatar}
                {isLoading ?
                    <Skeleton variant="rectangular" height="28px" width="150px" /> : 
                    <span className="body-02">{label}</span>
                }
            </Box>
            
            {adornment}

            {/* Spacer */}
            <Box height="16px" />

            {items.map((item, index) => (
                <NavButton 
                    key={index} 
                    kind={kind} 
                    label={item.label} 
                    path={item.path}
                    tabsRoot={item.tabsRoot}
                    marginBottom="4px"
                />
            ))}

            <Box flexGrow={1} />
            {children && <Box width='100%'>{children}</Box>}
        </Box>
    )
}

export default NavSideMenu;