import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from 'react-hook-form';

// Helpers
import showToast from '@/_lib/toast';

// Services and interfaces
import { selectSettings } from '@/store/settings';
import { ApiError } from '@/interfaces/api/error';
import { useUpdateSettingsMutation } from '@/repositories/coach';
import { ISettingsForm, settingsSchema } from "@/interfaces/coach/settings_form";

// Components
import { Box, Container } from '@mui/material';
import SettingsForm from '@/pages/common/settings_form';


const MetricsPage = () => {

    const { t } = useTranslation();
    const settings = useSelector(selectSettings);
    const [updateSettings, { isLoading }] = useUpdateSettingsMutation();

    const formMethods = useForm<ISettingsForm>({
        resolver: zodResolver(settingsSchema),
        mode: 'onBlur',
        defaultValues: {
            physique: settings.physique,
            training: settings.training,
            supplementation: settings.supplementation,
            activity: settings.activity,
            recovery: settings.recovery,
            health: settings.health,
            units: settings.units
        }
    });

    const submitForm = async (data: ISettingsForm) => {
        const payload = {
            site_settings: data.units,
            data_metrics: {
                physique: data.physique,
                training: data.training,
                supplementation: data.supplementation,
                activity: data.activity,
                recovery: data.recovery,
                health: data.health
            }
        }
        await updateSettings(payload).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.settings.metrics.title'), message: t('notifications.settings.metrics.message')});
            formMethods.reset(data);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        })
    }

    return (
        <Container>
            <Box display="flex" flexDirection="column" justifyContent="flex-start">

                {/* Flexible space */}
                <Box sx={{height: 'clamp(30px, 10vh, 70px)'}} />
                
                <FormProvider {...formMethods}>
                    <SettingsForm disableOnNotDirty isLoading={isLoading} submitForm={formMethods.handleSubmit(submitForm)} />
                </FormProvider>

            </Box>
        </Container>
    )
}

export default MetricsPage;
