import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { BaseSyntheticEvent, FC, useEffect, useState } from "react";

// Styles
import { WarningFilled } from "@carbon/icons-react";

// Services and interfaces
import { IActivityBuilder } from '@/interfaces/activity_plan/activity_builder';

// Components
import { Box, Skeleton } from "@mui/material";


interface _ActivityMenuProps {
    section: string;
    onSelectPlan: (section: 'steps' | 'cardio') => void;
    isLoading?: boolean;
    disableSteps?: boolean;
    disableCardio?: boolean;
}

const ActivityMenu: FC<_ActivityMenuProps> = ({
    section,
    onSelectPlan,
    isLoading = false,
    disableSteps = false,
    disableCardio = false
}) => {

    const { t } = useTranslation();
    const sectionIndex = section === 'steps' ? 0 : 1;
    const [selectedSection, setSelectedSection] = useState<'steps' | 'cardio'>('steps');

    const handleSelectSection = (e: BaseSyntheticEvent, section: 'steps' | 'cardio') => {
        if (e.target.tagName !== 'DIV' && e.target.tagName !== 'SPAN') return;
        setSelectedSection(section);
    }

    useEffect(() => {
        onSelectPlan(selectedSection);
    }, [selectedSection, onSelectPlan]);

    return (
        <Box className="Builder__menu">

            <Box className="Builder__menu-header">
                <span className="heading-06-compact">
                    {t('components.activityBuilder.menu.title')}
                </span>
            </Box>
                    
                <Box className="Builder__menu-sections">
                    {isLoading ? 
                        <Skeleton variant="rounded" width="100%" height={48} /> :
                        <>
                            <_MenuSection
                                section="steps"
                                selectedSection={section}
                                disabled={disableSteps}
                                handleSelect={(e) => handleSelectSection(e, 'steps')}
                                />

                            <_MenuSection
                                section="cardio"
                                selectedSection={section}
                                disabled={disableCardio}
                                handleSelect={(e) => handleSelectSection(e, 'cardio')}
                                />
                            </>}
                    {!isLoading && <Box className="Builder__menu-indicator" style={{ top: `${sectionIndex * 48 + 16}px` }} />}
                </Box>

        </Box>
    )
}

export default ActivityMenu;

interface _MenuPlanProps {
    section: 'cardio' | 'steps';
    selectedSection: string;
    isLoading?: boolean;
    disabled?: boolean;
    handleSelect: (e: BaseSyntheticEvent) => void;
}

const _MenuSection: FC<_MenuPlanProps> = ({
    section,
    selectedSection,
    isLoading = false,
    disabled = false,
    handleSelect,
}) => {

    const { t } = useTranslation();
    const { formState: { errors } } = useFormContext<IActivityBuilder>();

    return (
        <Box 
            className={`Builder__menu-section ${selectedSection === section ? 'Builder__menu-section--selected' : ''}`} 
            onClick={handleSelect}>

            <Box width="8px" />

            {/* Title */}
            {isLoading ? <Skeleton variant="text" width={200} height={28} /> :
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                
                    <Box display="flex" alignItems="center">
                        {errors[section] && <WarningFilled style={{color: 'var(--support-error)', marginRight: '8px'}} />}
                        <span className="body-02-compact TwoLine--ellipsis" style={{textAlign: 'left', color: disabled ? 'var(--text-disabled)' : 'var(--text-primary)'}}>
                            {t(`components.activityBuilder.menu.${section}`)}
                        </span>
                    </Box>
                    {disabled && <span className="label-text-02" style={{color: 'var(--text-disabled)', textAlign: 'left'}}>
                        {t('components.activityBuilder.menu.disabled')}
                    </span>}
                    {errors[section] && <span className="label-text-02" style={{color: 'var(--support-error)'}}>
                        {t('components.activityBuilder.menu.sectionErrors')}
                    </span>}
                </Box>}

    </Box>
    )
}