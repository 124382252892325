import dayjs from "dayjs";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

// Services and interfaces
import { IPhaseNote } from "@/interfaces/phase_note/phase_note";

// Components
import PhaseNote from "./note";
import { Box } from "@mui/material";
import Card from "@/components/card";


interface NotesGroup {
    [key: string]: IPhaseNote[] 
}

interface _NotesProps {
    phase_id: string,
    phase_start: string,
    notes?: IPhaseNote[];
}

const Notes: FC<_NotesProps> = ({
    phase_id,
    phase_start,
    notes
}) => {

    const { t } = useTranslation();

    const mappedNotes: NotesGroup = useMemo(() => {
        return notes?.reduce<NotesGroup>((prev, curr) => {
            const w = dayjs.utc(curr.date).diff(phase_start, 'week');
            const week = (w + 1).toString()
            prev[week] = prev[week] ?? [];
            prev[week].push(curr);
            return prev;
        }, {}) ?? {};
    }, [notes, phase_start])
    
    const hasNotes = notes && notes?.length > 0;

    return (
        <>
            {/* No Notes */}
            {!hasNotes && (
                <Card sx={{mb: 0.5}}>
                    <span className='body-02'>{t('components.phases.noNoteData')}</span>
                </Card>
            )}

            {hasNotes && Object.entries(mappedNotes).map(([week, note]) => (
                <Box key={week} mb={2}>
                    
                    <span className="heading-07">{`${t('timeDate.week')} ${week}`}</span> 
                    {note.map(n => (
                        <Card key={n.id} sx={{mb: 1, mt: '4px'}}>
                            <PhaseNote showMenu note={n} phase_id={phase_id} />
                        </Card>
                    ))}

                </Box>
            ))}
        </>
    )
}

export default Notes;