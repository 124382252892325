import { useTranslation } from "react-i18next";

// Interfaces
import { ISupplementPlan } from "@/interfaces/supplement_plan/supplement_plan";

// Components
import PlanMenu from "./supplement_menu";
import TableToggle from "./toggle_active";
import { ColDef } from "@/components/datatable";


const useSupplementPlanTableHeaders = (): ColDef<ISupplementPlan>[] => {

    const { t } = useTranslation();

    const headers: ColDef<ISupplementPlan>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '20%',
            sortable: true
        },
        {
            field: 'focus',
            headerName: t('components.dataTable.headers.focus'),
            width: '25%',
            render: (row: ISupplementPlan) => row.focus ?? '-'
        },
        {
            field: 'start',
            headerName: t('components.dataTable.headers.startDate'),
            width: '15%',
            render: (row: ISupplementPlan) => row.start ? new Date(row.start).toLocaleDateString() : '-'
        },
        {
            field: 'end',
            headerName: t('components.dataTable.headers.endDate'),
            width: '15%',
            render: (row: ISupplementPlan) => row.end ? new Date(row.end).toLocaleDateString() : '-'
        },
        {
            field: 'draft',
            headerName: t('components.dataTable.headers.status'),
            width: '15%',
            render: (row: ISupplementPlan) => row.draft ? t('general.draft') : t('general.ready')
        },
        {
            field: 'active',
            headerName: t('components.dataTable.headers.active'),
            width: '15%',
            render: (row: ISupplementPlan) => <TableToggle row={row} />
        },
        {
            field: 'actions',
            headerName: '',
            width: '10%',
            render: (row: ISupplementPlan) => <PlanMenu row={row} />
        }
    ];

    return headers;
}

export default useSupplementPlanTableHeaders;