import { z } from 'zod';
import ruleset from '@/_helpers/ruleset';

export interface ILoginCredentials {
    email: string;
    password: string;
}

export const schema = z.object({
    email: ruleset.email,
    password: ruleset.passwordRequired,
});