import { FC } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

// Interfaces
import { IActivityBuilder } from "@/interfaces/activity_plan/activity_builder"

// Styles
import { Add } from "@carbon/icons-react";

// Components
import CardioDay from "./cardio_day";
import { Box } from "@mui/material";
import Button from "@/components/button";
import useSort from "@/components/sortable/sort_hooks";
import SortableItem from "@/components/sortable/sortable_item";


interface _CardioProps {
    readonly?: boolean;
}

const Cardio: FC<_CardioProps> = ({
    readonly
}) => {

    const { t } = useTranslation();
    const { sensors, restrictToVerticalAxis } = useSort();

    const { control } = useFormContext<IActivityBuilder>()
    const sessions = useWatch({ control, name: 'cardio' });
    const { fields, append, move, remove } = useFieldArray({
        control,
        name: 'cardio'
    })

    const newDay = {
        uuid: uuidv4(),
        name: '',
        exercise_id: '',
        exercise_name: '',
        type: '',
        duration: '',
        distance: '',
        calories: '',
        hr: '',
        notes: ''
    }

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (over !== null && active.id !== over.id) {
            const oldIndex = fields.findIndex(item => item.id === active.id);
            const newIndex = fields.findIndex(item => item.id === over.id);
            move(oldIndex, newIndex);
        }
    };

    const handleAddDay = () => {
        append(newDay);
    }

    const handleCopy = (index: number) => {
        const copy = {...sessions[index], name: `${sessions[index].name} - ${t('general.copy')}`};
        append(copy);
    }

    return (
        <Box overflow="hidden">

            <Box display="flex" width="100%" justifyContent="flex-end" height="48px" alignItems="center">

                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addSession')}
                    endIcon={<Add />}
                    minWidth={false}
                    disabled={readonly}
                    onClick={handleAddDay}
                    />
                    
            </Box>

            <Box height="calc(100% - 40px)" sx={{overflowY: 'auto', scrollbarWidth: 'none'}}>

                {fields.length === 0 && <_NoSessions />}
                
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
                    <SortableContext items={fields.map(session => session.id)} strategy={verticalListSortingStrategy}>

                        {fields.map((session, i) => (
                            <SortableItem key={session.id} id={session.id}>
                                <CardioDay
                                    id={session.id}
                                    dayIndex={i}
                                    readonly={readonly}
                                    onCopy={handleCopy}
                                    onRemove={() => remove(i)}
                                    />
                            </SortableItem>
                        ))}

                    </SortableContext>
                </DndContext>
            </Box>

        </Box>
    )
}

export default Cardio;

const _NoSessions: FC = () => {

    const { t } = useTranslation();

    return (
        <Box 
            display="flex" 
            alignItems="center" 
            justifyContent="center" 
            flexGrow={1}
            padding="16px"
            minHeight="48px"
            borderRadius="6px" 
            border="solid 1px var(--border-subtle-01)"
            sx={{bgcolor: 'var(--layer-01)'}}
            >
            <span className="body-02">{t('components.activityBuilder.section.noSessions')}</span>
        </Box>
    )
}