import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Toolbar } from '@mui/material';

// Helpers
import ResetScroll from "@/_lib/reset_scroll";

// Context
import SetupContext from "@/contexts/setup_context";

// Components
import SetupLayoutMenu from "@/components/setup/menu";
import SetupLayoutHeader from "@/components/setup/header";
import NavToolbar from "@/components/navigation/nav_toolbar";


export default function SetupLayout() {
                    
    const [profile, setProfile] = useState(false);
    const [settings, setSettings] = useState(false);

    return (
        <>
            <NavToolbar />
            <Box component='main' minHeight='calc(100vh - 48px - 24px)' padding='48px 24px 24px 24px'>
                <Toolbar variant='dense' />

                <SetupLayoutHeader />
                
                <Box display='flex'>

                    <SetupContext.Provider value={{profile, setProfile, settings, setSettings}}>

                        <SetupLayoutMenu />

                        <Box display='flex' width="100%">
                            <ResetScroll />
                            <Outlet /> 
                        </Box>
                        
                    </SetupContext.Provider>

                </Box >

            </Box>
        </>
    )
}