import { FC } from 'react';

// Components
import Box from "@mui/material/Box";
import { Skeleton } from "@mui/material";

import Card from "../card";
import OverflowMenu from "../overflow_menu";

const PhaseSkeleton: FC = () => (
    <Card sx={{p:1.5}}>
        <Box alignItems='center' display='flex' width='100%'>

            {/* titles */}
            <Box minWidth={300} mx={5}>
                <Skeleton />
                <Skeleton />
            </Box>
            
            <Box flexGrow={1} />

            {/* Duration */}
            <Skeleton sx={{mx: 2, minWidth: 150}}  />

            <OverflowMenu
                options={[]}
                disabled={true}
            />
        </Box>
    </Card>
);

export default PhaseSkeleton;