import { t } from "i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FC, useEffect, useState } from "react";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { setCompletedSetup } from "@/store/coach";
import { useCompleteSetupMutation } from "@/repositories/coach";

// Components
import { Backdrop, Box, CircularProgress } from "@mui/material";


interface _CompleteSetupOverlayProps {
    open: boolean;
}

const CompleteSetupOverlay: FC<_CompleteSetupOverlayProps> = ({
    open,
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    const [completeSetup] = useCompleteSetupMutation();

    useEffect(() => {
        const loaderTimeout = setTimeout(() => {

            completeSetup().unwrap().then(() => {
                setLoading(false);
              
                const textTimeout = setTimeout(() => {
                    dispatch(setCompletedSetup());
                }, 3000);

                return () => clearTimeout(textTimeout);
    
    
            }).catch((error: ApiError) => {
                showToast({type: 'error', apiError: error.type})
                navigate('/clients/active');
            });
        }, 1500);
    
        return () => clearTimeout(loaderTimeout);
      }, [navigate, dispatch, completeSetup]);

    return (
        <Backdrop
            open={open}
            sx={{
                bgcolor: 'var(--nav-background)',
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            >
           {loading && <>
                <CircularProgress size={16} thickness={5} sx={{color: 'var(--text-overlay)'}} />
                <Box width={8} />
                <span className="helper-text-02" style={{color: 'var(--text-overlay)'}}>{t('pages.setup.complete.loadingText')}</span>
            </>}
            {!loading &&
                <Box display="flex" flexDirection="column" alignItems="center" sx={{opacity: loading ? 0 : 1, transition: 'opacity 1s ease-in-out'}}>
                    <span className="heading-05" style={{color: 'var(--text-overlay)'}}>{t('pages.setup.complete.title')}</span>
                    <span className="body-02-compact" style={{color: 'var(--text-overlay)'}}>{t('pages.setup.complete.subtitle')}</span>
                </Box>}

        </Backdrop>
    );
}

export default CompleteSetupOverlay;