import { z } from "zod";
import ruleset from "@/_helpers/ruleset";

export interface IProspectForm {
    first_name: string;
    last_name: string;
    email: string;
    mobile?: string|undefined;
    notes?: string|undefined;
    value?: number|string|undefined;
    close_percentage?: number;
    stage?: string;
}

export const prospectFormSchema = z.object({
    first_name: ruleset.required,
    last_name: ruleset.required,
    email: ruleset.email,
    mobile: ruleset.phoneNumber,
    notes: ruleset.stringOptional,
    value: ruleset.numberOptional,
    stage: ruleset.selectOptional,
    close_percentage: ruleset.selectNumberOptional,
});