import { z } from "zod";
import ruleset from "@/_helpers/ruleset";
import { ISection, ISupplementBuilder, supplementSectionsSchema } from "./supplement_builder";

export interface ISupplementPlan extends ISupplementBuilder {
    id: string;
    name: string;
    focus: string;
    sections: ISection[];
    draft: boolean;
    active: boolean;
    start: string;
    end: string;
    readonly: boolean;
}

export const supplementPlanFormSchema = z.object({
    name: ruleset.required,
    focus: ruleset.stringOptional,
    notes: ruleset.stringOptional,
    draft: ruleset.boolean,
    sections: supplementSectionsSchema,
});