import { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Services and interfaces
import { selectShowReferralModal, selectHasCompletedSetup } from "@/store/coach";

// Styles
import { CurrencyDollar } from "@carbon/icons-react";

// Components
import NavHeaderAction from "./nav_header_action";
import ReferralIntroModal from "@/components/referrals/referral_intro_modal";


const NavReferral: FC = () => {

    const navigate = useNavigate();
    const completedSetup = useSelector(selectHasCompletedSetup);
    const showReferralModal = useSelector(selectShowReferralModal);

    const handleGoToReferrals = () => {
        navigate('/settings/referrals');
    }

    return (
        <>
            <NavHeaderAction icon={<CurrencyDollar size={20} />} onClick={handleGoToReferrals} />
            {completedSetup && showReferralModal && <ReferralIntroModal
                open={completedSetup && showReferralModal}
                onClose={() => {}}
                />}
        </>
    )
}

export default NavReferral;