import { FC } from "react";
import { AvatarProps, Avatar as MuiAvatar, Skeleton } from "@mui/material";


interface _AvatarProps {
    size?: 'small' | 'medium' | 'large' | 'extra-large';
    src: string;
    alt?: string;
    name: string;
    index?: number;
    isLoading?: boolean;
    sx?: AvatarProps['sx'];
}

const Avatar: FC<_AvatarProps> = ({
    size = 'medium',
    src,
    // alt,
    name,
    index = 1,
    isLoading = false,
    sx
}) => {

    const classes = {
        'Avatar-small': size === 'small',
        'Avatar-medium': size === 'medium',
        'Avatar-large': size === 'large',
        'Avatar-extra-large': size === 'extra-large',
    }
    
    const validClasses = Object.entries(classes)
        .filter(([,v]) => !!v)
        .map(([k,]) => k)
        .join(' ')

    // Based on the index, return the color for the avatar (10 colors available)
    const colors = [
        'var(--profile-01)',
        'var(--profile-02)',
        'var(--profile-03)',
        'var(--profile-04)',
        'var(--profile-05)',
        'var(--profile-06)',
        'var(--profile-07)',
        'var(--profile-08)',
        'var(--profile-09)',
    ];

    const loaderSize = size === 'small' ? 24 : size === 'medium' ? 32 : size === 'large' ? 48 : 64;

    const color = colors[index % colors.length];

    if (isLoading) return (
        <Skeleton variant="circular" height={loaderSize} width={loaderSize} sx={{marginRight: '16px'}} />
    )
    
    return (
        <MuiAvatar   
            className={validClasses}
            sx={{...sx, bgcolor: color}}
            src={src as string}
            // alt={name}
            >
            <span className="Avatar Avatar__text">{name.split(' ').map(w => w[0]?.toUpperCase()).join('').substring(0, 2)}</span>
        </MuiAvatar>
    );
}

export default Avatar;