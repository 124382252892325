import { t } from "i18next";
import { ISelectOption } from "@/interfaces/components/select_option";


export const setTypeOptions: ISelectOption<string>[] = [
    { id: 1, label: t('components.select.options.setType.warmup'), value: 'warm_up' },
    { id: 2, label: t('components.select.options.setType.dropset'), value: 'drop_set' },
    { id: 3, label: t('components.select.options.setType.superset'), value: 'super_set' },
    { id: 4, label: t('components.select.options.setType.giantSet'), value: 'giant_set' },
    { id: 5, label: t('components.select.options.setType.restPause'), value: 'rest_pause' },
    { id: 6, label: t('components.select.options.setType.failure'), value: 'failure' },
    { id: 7, label: t('components.select.options.setType.widowmaker'), value: 'widow_maker' },
    { id: 8, label: t('components.select.options.setType.amrap'), value: 'amrap' },
    { id: 9, label: t('components.select.options.setType.topSet'), value: 'top_set' },
    { id: 10, label: t('components.select.options.setType.backOffSet'), value: 'back_off_set' },
];