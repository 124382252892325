import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { useGetActivityTemplatesQuery } from "@/repositories/activity_template";
import { IActivityTemplate } from "@/interfaces/activity_template/activity_template";

// Compnents
import { Container, Box } from "@mui/material";
import DataTable from "@/components/datatable";
import useTeamActivityTemplateHeaders from "./table_headers_team";


const TeamTab: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const headers = useTeamActivityTemplateHeaders();
    const hasTeam = useSelector(selectHasTeam);

    const { data, isLoading } = useGetActivityTemplatesQuery();
    const list = data?.filter((t) => t.type === 'team');

    useEffect(() => {
        if (!hasTeam) navigate('/templates/activity/personal');
    }, [hasTeam, navigate])

    const handleOpenTemplate = (template: IActivityTemplate) => {
        navigate(`/templates/activity/${template.id}`);
    }

    return (
        <Box height='100%' overflow='auto'>
        <Container sx={{padding: '24px 0'}}>

            <DataTable
                data={list}
                dataKey="id"
                columns={headers} 
                hideHeader
                localPagination
                filterKeys={["name"]}
                noDataMessage={t('components.dataTable.noData.noTeamTemplates')}
                isLoading={isLoading}
                rowClick={handleOpenTemplate}
                />

        </Container>
        </Box>
    );
};

export default TeamTab;