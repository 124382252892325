import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import { BaseSyntheticEvent, FC, useEffect, useState } from "react";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

// Styles
import { Add } from "@carbon/icons-react";

// Services and interfaces
import { IProgrammeBuilder } from "@/interfaces/programme/programme_builder";

// Components
import Button from "@/components/button";
import MenuSection from './menu_section';
import { Box, Skeleton } from "@mui/material";
import useSort from '@/components/sortable/sort_hooks';


interface _WorkoutMenuProps {
    selectedIndex: number;
    onSelectWorkout: (sectionIndex: number) => void;
    isLoading?: boolean;
}

const WorkoutMenu: FC<_WorkoutMenuProps> = ({
    selectedIndex,
    onSelectWorkout,
    isLoading = false
}) => {

    const { t } = useTranslation();
    const { control } = useFormContext<IProgrammeBuilder>();
    const { fields, append, remove, move } = useFieldArray({
        control,
        name: 'plan'
    });

    const [selectedSection, setSelectedSection] = useState<string>(fields[0]?.id);
    const { sensors, restrictToVerticalAxis } = useSort();

    const handleSelectWorkout = (e: BaseSyntheticEvent, id: string) => {
        if (e.target.tagName !== 'DIV' && e.target.tagName !== 'SPAN') return;
        setSelectedSection(id);
        onSelectWorkout(fields.findIndex(item => item.id === id));
    }
    
    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
    
        if (over !== null && active.id !== over.id) {
            const oldIndex = fields.findIndex(item => item.id === active.id);
            const newIndex = fields.findIndex(item => item.id === over.id);

            const selectedSectionIndex = fields.findIndex(item => item.id === selectedSection);
    
            let selectedSectionIndexAfter = selectedSectionIndex;
    
            // Check whether the currently selected section has moved
            if (selectedSectionIndex === oldIndex) {
                selectedSectionIndexAfter = newIndex;
            } else if (selectedSectionIndex > oldIndex && selectedSectionIndex <= newIndex) {
                selectedSectionIndexAfter = selectedSectionIndex - 1;
            } else if (selectedSectionIndex < oldIndex && selectedSectionIndex >= newIndex) {
                selectedSectionIndexAfter = selectedSectionIndex + 1;
            }
    
            move(oldIndex, newIndex);
            onSelectWorkout(selectedSectionIndexAfter);
        }
    };

    const handleAddWorkout = () => {
        append({
            uuid: uuidv4(),
            name: '',
            exercises: [],
        });
    };

    const handleRemoveWorkout = (workoutIndex: number) => {
        if (selectedIndex === workoutIndex && selectedIndex > 0) {
            onSelectWorkout(selectedIndex - 1);
        } else {
            onSelectWorkout(0);
        }
        remove(workoutIndex);
    }

    useEffect(() => {
        const i = fields.findIndex(item => item.id === selectedSection);
        // If the selected section has been removed, select the first section
        if (i == -1) {
            setSelectedSection(fields[0].id);
        }
    }, [fields, selectedSection])

    return (
        <Box className="Builder__menu">

            <Box className="Builder__menu-header">
                <span className="heading-06-compact">{t('components.programmeBuilder.menu.title')}</span>
                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addWorkout')}
                    endIcon={<Add />}
                    minWidth={false}
                    disabled={isLoading}
                    onClick={handleAddWorkout}
                    />
            </Box>


            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
                <SortableContext items={fields.map(field => field.id)} strategy={verticalListSortingStrategy}>
                    
                    <Box className="Builder__menu-sections">
                        {isLoading ? <Skeleton variant="rounded" width="100%" height={48} /> :
                            fields.map((workout, i) => (
                                <MenuSection
                                    key={workout.id}
                                    id={workout.id}
                                    workoutIndex={i}
                                    selectedWorkout={selectedIndex}
                                    handleSelect={(e) => handleSelectWorkout(e, workout.id)}
                                    onCopy={(workout) => append(workout)}
                                    onRemove={handleRemoveWorkout}
                                    />
                            ))}
                        {!isLoading && <Box className="Builder__menu-indicator" style={{ top: `${selectedIndex * 48 + 16}px` }} />}
                    </Box>
                    
                </SortableContext>
            </DndContext>

        </Box>
    )
}

export default WorkoutMenu;