import { FC, useState } from "react";
import { useParams } from "react-router-dom";

// Services and interfaces
import { useGetWorkoutsQuery } from "@/repositories/workout";

// Components
import DataTable from "@/components/datatable";
import useTrainingDataHeaders from "./data_headers";
import WorkoutDetailsPanel from "./workout_details_panel";

const TrainingDataTab: FC = () => {

    const { id } = useParams();
    const headers = useTrainingDataHeaders();
    const { data, isLoading } = useGetWorkoutsQuery(id!);

    const [selectedWorkout, setSelectedWorkout] = useState<string|undefined>(undefined)

    // Use set timeout to reset the selectedWorkout once the panel has closed
    const handleClose = () => {
        setTimeout(() => setSelectedWorkout(undefined), 500);
    }

    return (
        <>
            <DataTable
                data={data}
                columns={headers}
                hideHeader
                hideFilter
                rowClick={(r) => setSelectedWorkout(r.id)}
                isLoading={isLoading}
                />

            {selectedWorkout && <WorkoutDetailsPanel 
                open={Boolean(selectedWorkout)}
                onClose={handleClose}
                workoutId={selectedWorkout}
                />}
        </>
    )
}

export default TrainingDataTab;