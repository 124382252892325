import { FC } from "react";
import { t } from "i18next";

// Interfaces
import IconAction from "@/interfaces/components/icon_action";
import ButtonAction from "@/interfaces/components/button_action";

// Components
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@/components/button";
import IconButton from "./icon_button";

interface ToolbarProps {
    title?: string;
    onCancel: (()=>void);
    action1?: ButtonAction;
    action2?: ButtonAction;
    iconActions?: IconAction[];
}

const SelectionToolbar: FC<ToolbarProps> = ({
    title = '',
    onCancel,
    action1,
    action2,
    iconActions
}) => {

    return (
    <Toolbar className='SecondaryToolbar__inverse'>
            <Container sx={{display: 'flex', height: '100%', alignItems: 'center', padding: 0}}>
                
                <span className='body-02-compact text-on-color'>{ title }</span>
                <Box flexGrow={1} />

                {/* Icon Actions */}
                {iconActions?.map((action, index) => (
                    <IconButton
                        key={index}
                        kind="tertiary"
                        size="small"
                        icon={action.icon}
                        onClick={action.onClick}
                    />
                ))}
                
                {/* Actions */}
                {action2 && <Button
                    kind="tertiary"
                    size="small"
                    label={action2?.label}
                    endIcon={action2?.icon}
                    disabled={action2?.disabled}
                    loading={action2?.loading}
                    onClick={action2?.onClick}
                    sx={{...action2?.sx, mr: 1}}
                />}

                {action1 && <Button
                    size="small"
                    label={action1?.label}
                    endIcon={action1?.icon}
                    disabled={action1?.disabled}
                    loading={action1?.loading}
                    onClick={action1?.onClick}
                    sx={{minWidth: 'unset', ...action1?.sx}}
                />}

                {/* Spacer */}
                <Box height="45%" borderLeft='1px solid white'/>
                
                <Button
                    size="small"
                    sx={{minWidth: 'unset'}}
                    label={t('components.buttons.cancel')}
                    onClick={onCancel}
                />

            </Container>
        </Toolbar>
    );
}

export default SelectionToolbar;