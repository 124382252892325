import baseApi from '@/repositories/base_api';
import { supplementPlanApi } from './supplement_plan';
import { IAPIResponse } from '@/interfaces/api/response';
import { ISupplementPlan } from '@/interfaces/supplement_plan/supplement_plan';
import { ISupplementTemplate } from '@/interfaces/supplement_template/supplement_template';
import { ITemplateAssignForm, ITemplateAssignManyForm } from '@/interfaces/template/assign_form';
import { ISupplementTemplateForm } from '@/interfaces/supplement_template/supplement_template_form';


export const supplementTemplateApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getSupplementTemplates: build.query<ISupplementTemplate[], void>({
            query: () => ({ url: '/templates/supplements', method: 'get' }),
            transformResponse: (response: IAPIResponse<ISupplementTemplate[]>) => response.data.sort((a, b) => a.name.localeCompare(b.name)),
            providesTags: ['Templates.supplement.list'],
            keepUnusedDataFor: 1800
        }),
        getSupplementTemplate: build.query<ISupplementTemplate, string>({
            query: (id) => ({ url: `/templates/supplements/${id}`, method: 'get' }),
            transformResponse: (response: IAPIResponse<ISupplementTemplate>) => response.data,
        }),
        addSupplementTemplate: build.mutation<ISupplementTemplate, ISupplementTemplateForm>({
            query: (data) => ({ url: '/templates/supplements', method: 'post', data }),
            transformResponse: (response: IAPIResponse<ISupplementTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(supplementTemplateApi.util.updateQueryData('getSupplementTemplates' as never, undefined as never, (templates: ISupplementTemplate[]) => {
                    templates.push(response.data)
                    templates.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
        updateSupplementTemplate: build.mutation<ISupplementTemplate, {id: string, data: ISupplementTemplateForm}>({
            query: ({id, data}) => ({ url: `/templates/supplements/${id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<ISupplementTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(supplementTemplateApi.util.updateQueryData('getSupplementTemplates' as never, undefined as never, (templates: ISupplementTemplate[]) => {
                    const index = templates.findIndex((item) => item.id === response.data.id);
                    templates.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteSupplementTemplate: build.mutation<void, string>({
            query: (id) => ({ url: `/templates/supplements/${id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(supplementTemplateApi.util.updateQueryData('getSupplementTemplates' as never, undefined as never, (templates: ISupplementTemplate[]) => {
                    return templates.filter((item) => item.id !== id)
                }))).catch(() => {});
            },
        }),
        deleteSupplementTemplates: build.mutation<void, string[]>({
            query: (ids) => ({ url: `/templates/supplements/bulk-delete`, method: 'post', data: {ids: ids} }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(ids, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(supplementTemplateApi.util.updateQueryData('getSupplementTemplates', undefined, templates => {
                    return templates.filter((item) => !ids.includes(item.id))
                }))).catch(() => {});
            },
        }),
        copySupplementTemplate: build.mutation<ISupplementTemplate, string>({
            query: (id) => ({ url: `/templates/supplements/${id}/copy`, method: 'post' }),
            transformResponse: (response: IAPIResponse<ISupplementTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(supplementTemplateApi.util.updateQueryData('getSupplementTemplates' as never, undefined as never, (templates: ISupplementTemplate[]) => {
                    templates.push(response.data);
                    templates.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
        assignSupplementTemplate: build.mutation<ISupplementPlan, ITemplateAssignForm>({
            query: (data) => ({ url: `/templates/supplements/${data.template_id}/assign`, method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<ISupplementPlan>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(supplementPlanApi.util.updateQueryData('getSupplementPlans', _.client_id, plans => {
                    plans.push(response.data);
                    plans.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
        assignSupplementTemplates: build.mutation<ISupplementTemplate, {id: string, data: ITemplateAssignManyForm}>({
            query: ({id, data}) => ({ url: `/templates/supplements/${id}/bulk-assign`, method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<ISupplementTemplate>) => response.data,
            invalidatesTags: [{ type: 'Plans.supplement.list', id: 'ALL' }]
        }),
        shareSupplementTemplate: build.mutation<ISupplementTemplate, string>({
            query: (id) => ({ url: `/templates/supplements/${id}/share`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<ISupplementTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(supplementTemplateApi.util.updateQueryData('getSupplementTemplates', undefined, templates => {
                    const index = templates.findIndex((item) => item.id === response.data.id);
                    if (index == -1) return templates;
                    const t = [...templates];
                    t.splice(index, 1, response.data);
                    return t;
                }))).catch(() => {});
            },
        }),
    }),
})

export const { 
    useGetSupplementTemplatesQuery,
    useGetSupplementTemplateQuery,
    useLazyGetSupplementTemplateQuery,
    useAddSupplementTemplateMutation,
    useUpdateSupplementTemplateMutation,
    useDeleteSupplementTemplateMutation,
    useDeleteSupplementTemplatesMutation,
    useCopySupplementTemplateMutation,
    useAssignSupplementTemplateMutation,
    useAssignSupplementTemplatesMutation,
    useShareSupplementTemplateMutation
} = supplementTemplateApi;

