import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { BaseSyntheticEvent, FC, useRef, useState } from "react";
import { FieldErrors, useFieldArray, useFormContext, useWatch } from "react-hook-form";

// Interfaces
import {IProgrammeBuilder, IExercise, ISet } from "@/interfaces/programme/programme_builder";
import { IProgrammeWorkoutBuilder } from '@/interfaces/programme_workout/programme_workout_builder';

// Styles
import { Add, ChevronDown, WarningFilled } from "@carbon/icons-react";

// Components
import AddModal from './add_modal';
import Button from "@/components/button";
import useSetHeaders from "./set_headers";
import OverflowMenu from '@/components/overflow_menu';
import BuilderTable from "@/components/builder/table";
import DeleteModal from "@/components/builder/delete_modal";
import { Accordion, AccordionDetails, AccordionSummary, accordionSummaryClasses, Box, Card } from "@mui/material";


interface _ExerciseProps {
    basePath: `plan.${number}.exercises`|'exercises';
    exerciseIndex: number;
    expanded: boolean;
    onExpand: (uuid: string) => void;
    onReplace?: (exercise: IExercise, exerciseIndex: number) => void;
    onRemove: (index: number) => void;
}

const Exercise: FC<_ExerciseProps> = ({
    basePath,
    exerciseIndex,
    expanded,
    onExpand,
    onReplace,
    onRemove
}) => {
    const { t } = useTranslation();
    const exerciseRef = useRef(null);
    const [open, setOpen] = useState<string|null>(null);

    const { control, formState } = useFormContext<IProgrammeBuilder|IProgrammeWorkoutBuilder>();
    const exercise = useWatch({ control, name: `${basePath}.${exerciseIndex}` });
    const { fields: sets, append: appendSet, remove: removeSet } = useFieldArray({
        control,
        name: `${basePath}.${exerciseIndex}.sets`
    });

    const headers = useSetHeaders({ basePath, exerciseIndex, onRemoveSet: removeSet, onRemoveExercise: onRemove });
    const programmeErrors = formState.errors as FieldErrors<IProgrammeBuilder>;
    const programmeWorkoutErrors = formState.errors as FieldErrors<IProgrammeWorkoutBuilder>;

    const hasErrors = () => {
        if (basePath === 'exercises') {
            return programmeWorkoutErrors.exercises?.[exerciseIndex];
        }
        // Get the workoutIndex from the basePath
        const workoutIndex = basePath.split('.')[1];
        return programmeErrors.plan?.[Number(workoutIndex)]?.exercises?.[exerciseIndex];
    }

    const handleChange = (e: BaseSyntheticEvent) => {
        const classList = e.target.classList;
        if (!classList.contains('_Expandable') || classList.contains('MuiAccordionSummary-content')) return;
        e.stopPropagation();
        onExpand(exercise.uuid);
    }

    const handleAddSet = () => {
        if (!sets.length) {
            const newSet: ISet = {
                id: uuidv4(),
                rep_min: '',
                rep_max: '',
                distance_min: '',
                duration_min: '',
                calories_min: '',
                target_rpe: '',
                target_rir: '',
                target_rest_time: '',
                tempo_con: '',
                tempo_ecc: '',
                tempo_len: '',
                tempo_sho: ''
            }
            appendSet(newSet);
        } else {
            const lastSet = exercise.sets[exercise.sets.length - 1];
            lastSet.id = uuidv4();
            lastSet.notes = '';
            appendSet(lastSet);
        }
        if (!expanded) onExpand(exercise.uuid);
    }

    const handleReplace = (exercise: IExercise) => {
        if (!onReplace) return;
        onReplace(exercise, exerciseIndex);
        setOpen(null);
    }

    return (
        <Card elevation={0} sx={{borderRadius: '6px', border: 'solid 1px var(--border-subtle-01)'}}>

            <Accordion 
                ref={exerciseRef}
                expanded={expanded}
                onChange={handleChange}
                slotProps={{ transition: { unmountOnExit: true } }} sx={{
                    m: 0, 
                    width: '100%', 
                    boxShadow: '0', 
                }}>

                <AccordionSummary
                    expandIcon={<ChevronDown className="_Expandable" />}
                    tabIndex={-1}
                    sx={{
                        ml: 3, p: 0,
                        flexDirection: 'row-reverse',
                        [`& .${accordionSummaryClasses.content}`]: {
                            m: 0, p: 2,
                            [`&.${accordionSummaryClasses.expanded}`]: {
                                m: 0
                            }
                        }
                    }}>
                    <Box className="_Expandable" display="flex" width="100%" alignItems="center">

                        <Box className="_Expandable" display='flex' flexDirection="column" width="100%" alignItems="flex-start" marginLeft="16px">
                            <Box display="flex" alignItems="center">
                                <span className="body-02 _Expandable">{exercise?.name}</span>
                                {hasErrors() && 
                                    <WarningFilled className="_Expandable" style={{marginLeft: '8px', color: 'var(--support-error)'}} />}
                            </Box>
                            <span className="label-text-02 _Expandable">{t('components.programmeBuilder.exercise.sets', {count: sets.length})}</span>
                        </Box>

                        <Box display="flex" flexGrow={1} width="100%" />

                        {/* Add */}
                        <Button
                            kind="ghost"
                            size="small"
                            label={t('components.buttons.addSet')}
                            endIcon={<Add />}
                            minWidth={false}
                            onClick={handleAddSet}
                            sx={{marginRight: '8px'}}
                            />

                        <OverflowMenu
                            options={[
                                {name: t('components.menuItems.replace'), action: () => setOpen('replace')}
                            ]}
                            onDelete={() => setOpen('delete')}
                            />
                            {open === 'replace' && <AddModal
                                open={open === 'replace'}
                                onClose={() => setOpen(null)}
                                onReplace={handleReplace}
                                />}
                            {open === 'delete' && <DeleteModal
                                open={open === 'delete'}
                                onClose={() => setOpen(null)}
                                title={t('modals.deleteWorkoutExercise.title')}
                                text={t('modals.deleteWorkoutExercise.text')}
                                onDelete={() => onRemove(exerciseIndex)}
                                />}
                        
                    </Box>
                </AccordionSummary>

                <AccordionDetails sx={{p:0, textAlign: 'left'}}>
                    <Box padding="0px 24px 24px 64px">
                        <BuilderTable
                            data={sets}
                            columns={headers}
                            noDataMessage={t('components.programmeBuilder.table.noSets')}
                            />
                    </Box>
                </AccordionDetails>

            </Accordion>
        </Card>
    )
}

export default Exercise;