import { useTranslation } from "react-i18next";

// Helpers
import { capitalize, capitalizeWords } from "@/_helpers/text_functions";

// Interfaces
import { IProgrammeTemplate } from "@/interfaces/programme_template/programme_template";

// Components
import TemplateMenu from "./programme_menu";
import { ColDef } from "@/components/datatable";


const useTeamProgrammeTemplateTableHeaders = (): ColDef<IProgrammeTemplate>[] => {

    const { t } = useTranslation();

    const headers: ColDef<IProgrammeTemplate>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '30%',
            sortable: true
        },
        {
            field: 'created_at',
            headerName: t('components.dataTable.headers.dateAdded'),
            width: '10%',
            render: (row: IProgrammeTemplate) => new Date(row.created_at).toLocaleDateString()
        },
        {
            field: 'focus',
            headerName: t('components.dataTable.headers.focus'),
            width: '20%',
            render: (row: IProgrammeTemplate) => capitalize(row.focus ?? '-')
        },
        {
            field: 'shared_by',
            headerName: t('components.dataTable.headers.sharedBy'),
            width: '15%',
            render: (row: IProgrammeTemplate) => capitalizeWords(row.shared_by ?? '-')
        },
        {
            field: 'actions',
            headerName: '',
            width: '10%',
            render: (row: IProgrammeTemplate) => <TemplateMenu row={row} />
        }
    ];

    return headers;
}

export default useTeamProgrammeTemplateTableHeaders;