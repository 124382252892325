import { z } from "zod";
import ruleset from "@/_helpers/ruleset";
import { daySchema, IDay, INutritionBuilder } from "./nutrition_builder";

export interface INutritionPlan extends INutritionBuilder {
    id: string;
    name: string;
    duration: number;
    start: string;
    end: string;
    plan: IDay[];
    tags: string[];
    draft: boolean;
    active: boolean;
    readonly: boolean;
}

export const nutritionPlanSchema = z.object({
    name: ruleset.required,
    focus: ruleset.stringOptional,
    notes: ruleset.stringOptional,
    draft: ruleset.boolean,
    plan: daySchema,
})
