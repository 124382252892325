import { z } from "zod";
import { t } from "i18next";
import ruleset, { addIssue } from "@/_helpers/ruleset";
import { IProgrammeBuilder, ISettings, IWorkout, programmeSettingsSchema, workoutSchema } from "./programme_builder";

export interface IProgrammePlan extends IProgrammeBuilder {
    id: string;
    name: string;
    duration: number;
    focus: string;
    notes?: string|undefined;
    tags: string[];
    plan: IWorkout[];
    settings: ISettings;
    start: string;
    end: string;
    draft: boolean;
    active: boolean;
    readonly: boolean;
}

export const programmePlanFormSchema = z.object({
    name: ruleset.required,
    focus: ruleset.stringOptional,
    notes: ruleset.stringOptional,
    draft: ruleset.boolean,
    plan: workoutSchema,
    settings: programmeSettingsSchema
}).superRefine((data, ctx) => {
    data.plan.forEach((workout, workoutIndex) => {
        const settings = data.settings;
        const path = ['plan', workoutIndex];
        if (!workout.name || workout.name.trim() === '') addIssue(ctx, [...path, 'name'], t('components.programmeBuilder.errors.noWorkoutTitle'));
        if (!workout.exercises || workout.exercises.length == 0) addIssue(ctx, [...path, 'exercises'], t('components.programmeBuilder.errors.noExercises'));
        workout.exercises.forEach((exercise, exerciseIndex) => {
            exercise.sets.forEach((set, setIndex) => {
                const metrics = exercise.metrics;
                const path = ['plan', workoutIndex, 'exercises', exerciseIndex, 'sets', setIndex];
                if (metrics.reps && set.rep_min === null) addIssue(ctx, [...path, 'rep_min']);
                if (metrics.duration && set.duration_min === null) addIssue(ctx, [...path, 'duration_min']);
                if (metrics.distance && set.distance_min === null) addIssue(ctx, [...path, 'distance_min']);
                if (metrics.calories && set.calories_min === null) addIssue(ctx, [...path, 'calories_min']);
                if (set.notes && set.notes.length > 1000) addIssue(ctx, [...path, 'notes']);
                if (!metrics.reps) return;
                if (settings.tempo && set.tempo_con === null) addIssue(ctx, [...path, 'tempo_con']);
                if (settings.tempo && set.tempo_ecc === null) addIssue(ctx, [...path, 'tempo_ecc']);
                if (settings.tempo && set.tempo_len === null) addIssue(ctx, [...path, 'tempo_len']);
                if (settings.tempo && set.tempo_sho === null) addIssue(ctx, [...path, 'tempo_sho']);
            });
        });
    });
});