import { v4 as uuidv4 } from 'uuid';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from "react-i18next";
import { BaseSyntheticEvent, FC, useEffect, useState } from "react";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import { FieldErrors, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";

// Styles
import { Add, DragVertical, WarningFilled } from "@carbon/icons-react";

// Services and interfaces
import { ISupplementBuilder, ISection } from "@/interfaces/supplement_plan/supplement_builder";

// Components
import Button from "@/components/button";
import { Box, Skeleton } from "@mui/material";
import OverflowMenu from '@/components/overflow_menu';
import useSort from '@/components/sortable/sort_hooks';
import DeleteModal from '@/components/builder/delete_modal';
import RenameModal from '@/components/builder/rename_modal';


interface _SectionMenuProps {
    selectedIndex: number;
    onSelectSection: (sectionIndex: number) => void;
    isLoading?: boolean;
}

const SectionMenu: FC<_SectionMenuProps> = ({
    selectedIndex,
    onSelectSection,
    isLoading = false
}) => {

    const { t } = useTranslation();
    const { control } = useFormContext<ISupplementBuilder>();
    const { fields: sections, append, remove, move } = useFieldArray({
        control,
        name: 'sections'
    });

    const [selectedSection, setSelectedSection] = useState<string>(sections[0]?.id);
    const { sensors, restrictToVerticalAxis } = useSort();

    const newSection: ISection = {
        uuid: uuidv4(),
        name: '',
        supplements: []
    }

    const handleSelectSection = (e: BaseSyntheticEvent, id: string) => {
        if (e.target.tagName !== 'DIV' && e.target.tagName !== 'SPAN') return;
        setSelectedSection(id);
        onSelectSection(sections.findIndex(item => item.id === id));
    }
    
    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
    
        if (over !== null && active.id !== over.id) {
            const oldIndex = sections.findIndex(item => item.id === active.id);
            const newIndex = sections.findIndex(item => item.id === over.id);

            const selectedSectionIndex = sections.findIndex(item => item.id === selectedSection);
    
            let selectedSectionIndexAfter = selectedSectionIndex;
    
            // Check whether the currently selected section has moved
            if (selectedSectionIndex === oldIndex) {
                selectedSectionIndexAfter = newIndex;
            } else if (selectedSectionIndex > oldIndex && selectedSectionIndex <= newIndex) {
                selectedSectionIndexAfter = selectedSectionIndex - 1;
            } else if (selectedSectionIndex < oldIndex && selectedSectionIndex >= newIndex) {
                selectedSectionIndexAfter = selectedSectionIndex + 1;
            }
    
            move(oldIndex, newIndex);
            onSelectSection(selectedSectionIndexAfter);
        }
    };

    const handleRemoveSection = (sectionIndex: number) => {
        if (selectedIndex === sectionIndex && selectedIndex > 0) {
            onSelectSection(selectedIndex - 1);
        } else {
            onSelectSection(0);
        }
        remove(sectionIndex);
    }

    useEffect(() => {
        const i = sections.findIndex(item => item.id === selectedSection);
        // If the selected section has been removed, select the first section
        if (i == -1) {
            setSelectedSection(sections[0].id);
        }
    }, [sections, selectedSection]);

    return (
        <Box className="Builder__menu">

            <Box className="Builder__menu-header">
                <span className="heading-06-compact">{t('components.supplementBuilder.menu.title')}</span>
                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addFrequency')}
                    endIcon={<Add />}
                    minWidth={false}
                    disabled={isLoading}
                    onClick={() => append(newSection)}
                    />
            </Box>


            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
                <SortableContext items={sections.map(section => section.id)} strategy={verticalListSortingStrategy}>
                    
                    <Box className="Builder__menu-sections">
                        {isLoading ? <Skeleton variant="rounded" width="100%" height={48} /> :
                            sections.map((section, i) => (
                                <_MenuSection
                                    key={section.id}
                                    id={section.id}
                                    sectionIndex={i}
                                    selectedSection={selectedIndex}
                                    canDelete={sections.length > 1}
                                    handleSelect={(e) => handleSelectSection(e, section.id)}
                                    onCopy={(section) => append(section)}
                                    onRemove={handleRemoveSection}
                                    />
                            ))}
                        {!isLoading && <Box className="Builder__menu-indicator" style={{ top: `${selectedIndex * 48 + 16}px` }} />}
                    </Box>
                    
                </SortableContext>
            </DndContext>

        </Box>
    )
}

export default SectionMenu;

interface _MenuSectionProps {
    id: string
    sectionIndex: number;
    selectedSection: number;
    canDelete?: boolean;
    handleSelect: (e: BaseSyntheticEvent) => void;
    onCopy: (section: ISection) => void;
    onRemove: (index: number) => void;
    isLoading?: boolean;
}

const _MenuSection: FC<_MenuSectionProps> = ({
    id,
    sectionIndex,
    selectedSection,
    canDelete = true,
    handleSelect,
    onCopy,
    onRemove,
    isLoading = false
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<string|null>(null);
    const { control, setValue, formState: { errors } } = useFormContext<ISupplementBuilder>();

    const sections = useWatch({ control, name: 'sections' });
    const disabled = sections.length === 1;
    const section = useWatch({ control, name: `sections.${sectionIndex}` });
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const dragStyle = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const setErrorMessage = (errors: FieldErrors<ISupplementBuilder>) => {
        const formErrors = errors.sections?.[sectionIndex];
        if (formErrors?.name) return formErrors.name.message;
        if (formErrors?.supplements?.message) return formErrors.supplements.message;
        return t('components.supplementBuilder.menu.sectionErrors');
    }

    const handleCopySection = () => {
        const trimCount = section.name.length + 7 - 40;
        const regex = new RegExp(`.{${trimCount}}$`);
        const name = `${section.name.length > 33 ? section.name.replace(regex, '') : section.name} - ${t('general.copy')}`
        const newSection = {
            ...section,
            uuid: uuidv4(),
            name: name
        }
        onCopy(newSection);
    }

    if (!section) return null;

    return (
        <Box 
            key={sectionIndex}
            ref={setNodeRef}
            style={dragStyle}
            className={`Builder__menu-section ${selectedSection === sectionIndex ? 'Builder__menu-section--selected' : ''}`} 
            onClick={handleSelect}
            >

            <Box width="8px" />

            {/* Title */}
            {isLoading ? <Skeleton variant="text" width={200} height={28} /> :
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                
                    <Box display="flex" alignItems="center" maxWidth="200px">
                        {errors.sections?.[sectionIndex] && <WarningFilled style={{color: 'var(--support-error)', marginRight: '8px'}} />}
                        <span className="body-02-compact" style={{textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            {section.name ? section.name : t('components.supplementBuilder.menu.sectionPlaceholder')}</span>
                    </Box>
                    {errors.sections?.[sectionIndex] && <span className="label-text-02" style={{color: 'var(--support-error)', textAlign: 'left'}}>
                        {setErrorMessage(errors)}</span>}
                </Box>}

            {/* Spacer */}
            <Box flexGrow={1} padding="0 8px" />

            <Box className="Builder__menu-section-actions">
                {!disabled && <Box {...attributes} {...listeners} display="flex" alignItems="center" width="32px" justifyContent="center" sx={{cursor: 'grab'}} >
                    <DragVertical onClick={(e) => e.stopPropagation()} />
                </Box>}

                <OverflowMenu
                    options={[
                        {name: t('components.menuItems.rename'),action: () => setOpen('rename')},
                        {name: t('components.menuItems.copy'), action: handleCopySection},
                    ]}
                    disableDelete={!canDelete}
                    onDelete={() => setOpen('delete')}
                    />
                {open === 'rename' && 
                    <RenameModal 
                        open={open === 'rename'} 
                        onClose={() => setOpen(null)}
                        onSubmit={(d) => setValue(`sections.${sectionIndex}.name`, d.name, { shouldDirty: true, shouldValidate: true })}
                        title={t('modals.renameSection')}
                        name={section.name}
                        placeholder={t('components.supplementBuilder.menu.sectionPlaceholder')}
                        />}
                {open === 'delete' && 
                    <DeleteModal 
                        open={open === 'delete'} 
                        onClose={() => setOpen(null)}
                        title={t('modals.deleteFrequency.title')}
                        text={t('modals.deleteFrequency.text')}
                        onDelete={() => onRemove(sectionIndex)}
                        />}
            </Box>

    </Box>
    )
}