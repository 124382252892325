import baseApi from '@/repositories/base_api';

import { IAPIResponse } from '@/interfaces/api/response';
import { INotification } from '@/interfaces/notifications/notification';

export const notificationApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        notifications: build.query<INotification[], void>({
            query: () => ({ url: '/notifications'}),
            transformResponse: (response: IAPIResponse<INotification[]>) => response.data,
        }),
        readNotification: build.mutation<INotification, string>({
            query: (id) => ({ url: `/notifications/${id}/read`, method: 'post' }),
            transformResponse: (response: IAPIResponse<INotification>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                    dispatch(notificationApi.util.updateQueryData('notifications', undefined, (notifs) => {
                        const i = notifs.findIndex(n => n.id === id)
                        notifs.splice(i, 1)
                    }))).catch(() => {});
            },
        }),
        readAllNotifications: build.mutation<void, void>({
            query: () => ({ url: `/notifications/read-all`, method: 'post' }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                    dispatch(notificationApi.util.upsertQueryData('notifications', undefined, []))
                ).catch(() => {});
            },
        })
    }),
})

export const { 
    useNotificationsQuery,
    useLazyNotificationsQuery,
    useReadNotificationMutation,
    useReadAllNotificationsMutation
} = notificationApi;