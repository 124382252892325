//TODO Come back to this and refactor to strongly type each array (split out)
export const units = {
    currency: [
        { label: 'GBP', value: '£' },
        { label: 'USD', value: '$' },
        { label: 'EUR', value: '€' },
        { label: 'AED', value: 'د.إ' },
    ],
    weight: [
        { label: 'Kilograms', value: 'kg' },
        { label: 'Pounds', value: 'lbs' }
    ],
    measurements: [
        { label: 'Centimeters', value: 'cm' },
        { label: 'Inches', value: 'in' }
    ]
}