
import { FC, useEffect, useState } from "react";

// Components
import FormResponse from "./form_response";
import RightPanel from "@/components/right_panel";


interface _LeadFormResponsePanelProps {
    open: boolean;
    onClose: ()=>void;
    leadformReponseId: string,
    name: string
}

const LeadFormResponsePanel: FC<_LeadFormResponsePanelProps> = ({
    open,
    onClose,
    leadformReponseId,
    name
}) => {

    const [panelOpen, setPanelOpen] = useState(false);

    const handleClose = () => {
        setPanelOpen(false);
        onClose();
    }

    useEffect(() => {
        if (open) setPanelOpen(true);
    }, [open])

    return (
        <RightPanel 
            title={name} 
            open={panelOpen} 
            onClose={handleClose}
            children={<FormResponse id={leadformReponseId} />}
            />
    )
}

export default LeadFormResponsePanel