import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { ChangeEvent, FC, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";

// Helpers
import showToast from '@/_lib/toast';

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IFolder } from "@/interfaces/folder/folder";
import { useAddFileMutation } from "@/repositories/file";

// Styles
import { Upload } from "@carbon/icons-react";

// Components
import FileItem from "./file_item";
import Box from "@mui/material/Box";
import Button from "@/components/button";
import Modal from "@/components/modal/modal";


interface _FileForm {
    files: {
        uuid: string;
        file: File;
    }[]
}

interface _UploadProgress {
    uuid: string;
    progress: number;
    status: "uploading" | "success" | "error"; 
    error?: string;
}

interface _FileModalProps {
    open: boolean;
    onClose: () => void;
    folder: IFolder;
}

const FileModal: FC<_FileModalProps> = ({
    open,
    onClose,
    folder
}) => {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState<_UploadProgress[]>([]);
    const [addFile] = useAddFileMutation();

    const formMethods = useForm<_FileForm>({
        mode: 'onBlur',
        defaultValues: {
            files: []
        }
    });

    const updateFileProgress = (uuid: string, progress: number, status: _UploadProgress['status'] = 'uploading', error?: string) => {
        setProgress((prev) =>
            prev.map((file) => (file.uuid === uuid ? { ...file, progress, status, error } : file))
        );
    };

    const files = formMethods.watch('files');
    const hasFiles = files.length > 0;

    const handleAddFiles = async (data: _FileForm) => {
        setLoading(true);

        // Create an array of promises for each file upload
        const uploadPromises = data.files.map((f) => {
            if (progress.find(p => p.uuid === f.uuid)?.status === 'success') return; // Skip if file is already uploaded
            return new Promise<void>((resolve, reject) => {
                // Add progress tracking for each file
                setProgress((prev) => [...prev, { uuid: f.uuid, progress: 0, status: 'uploading' }]);

                const form = new FormData();
                form.append("file", f.file);

                addFile({
                    id: String(folder.id),
                    data: form,
                    onProgress: (progress) => updateFileProgress(f.uuid, progress),
                }).unwrap().then(() => {
                    updateFileProgress(f.uuid, 100, 'success');
                    resolve();
                }).catch((error: ApiError) => {
                    updateFileProgress(f.uuid, 100, 'error', error.type);
                    reject();
                });
            });
        });

        try {
            await Promise.allSettled(uploadPromises);

            const results = await Promise.allSettled(uploadPromises);

            const allSucceeded = results.every(result => result.status === 'fulfilled');

            if (allSucceeded) {
                showToast({
                    type: 'success', 
                    title: t('notifications.files.uploaded.title', {count: data.files.length})
                });
                onClose();
            }
        } catch (error) {
            console.error("Error occurred during upload process:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveFile = (uuid: string) => {
        setProgress((prev) => prev.filter((file) => file.uuid !== uuid));
    }

    const handleClose = () => {
        formMethods.reset();
        onClose();
    }
    
    return (
        <Modal
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : handleClose}
            title={t('modals.addFiles', {name: folder.name})}
            children={
                <FormProvider {...formMethods}>
                    <FileForm 
                        progress={progress} 
                        loading={loading} 
                        onRemove={handleRemoveFile}
                        />
                </FormProvider>
            }
            action1={{
                label: t('components.buttons.upload'),
                icon: <Upload />,
                disabled: loading || !hasFiles,
                onClick: formMethods.handleSubmit(handleAddFiles)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: loading,
                onClick: handleClose
            }}
        />
    )
}

export default FileModal


interface _FileFormProps {
    progress: _UploadProgress[];
    loading: boolean;
    onRemove: (uuid: string) => void;
}

const FileForm: FC<_FileFormProps> = ({
    progress,
    loading,
    onRemove
}) => {

    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement|null>(null);

    const { control } = useFormContext<_FileForm>();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "files"
    });

    const onFilesAdded = (e: ChangeEvent<HTMLInputElement>) => {
        append([...e.target.files!].map(file => ({
            file: file,
            uuid: uuidv4(),
        })))
    }

    const handleRemoveFile = (index: number, uuid: string) => {
        remove(index);
        onRemove(uuid);
    }

    return (
        <Box minWidth="640px">
            <Box display="flex" flexDirection="column" rowGap={1} mb={1}>
                <div className="text-primary heading-07-compact">
                    {t('components.vault.selectFiles')}
                </div>
                <div className="text-secondary body-02-compact">
                    {t('components.vault.maxSize')}
                </div>
            </Box>

            {/* Upload */}
            <Button
                size='small'
                label={t('components.buttons.browseFiles')}
                onClick={()=>inputRef.current?.click()}
            />  
            <input
                type="file"
                ref={inputRef}
                hidden multiple
                accept=".jpg,.jpeg,.png,.pdf,.mp4,.mov,.avi,.mkv,.mpeg,.mpeg4"
                onChange={onFilesAdded}
            />

            {/* Added files */}
            <Box display="flex" flexDirection="column" rowGap={1} my={2}>
                {fields.map((f, i) => (
                    <FileItem 
                        key={f.id} 
                        file={f.file}
                        isLoading={loading}
                        uploadProgress={progress.find(p => p.uuid === f.uuid)?.progress ?? 0}
                        error={progress.find(p => p.uuid === f.uuid)?.error}
                        onRemove={() => handleRemoveFile(i, f.uuid)}
                    />
                ))}
            </Box>
        </Box>
        
    )
}