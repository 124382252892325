import { t } from "i18next";
import { Control, useController } from "react-hook-form";

// Styles
import { Close, Search } from "@carbon/icons-react";

// Components
import IconButton from "@/components/icon_button";
import { TextField, InputAdornment } from "@mui/material";


interface SearchInputProps {
  name: string;
  control: Control<any>;
  //REVIEW will this work?
  // control: Control<FieldValues>;
  size?: 'small' | 'medium' | 'large';
  placeHolder?: string;
  tabIndex?: number;
  disabled?: boolean;
}

const SearchInput: React.FC<SearchInputProps>  = ({
  name,
  control,
  size = 'small',
  placeHolder = t('components.searchInput.placeholder'),
  tabIndex = -1,
  disabled = false
}) => {

  const { field, fieldState } = useController({name, control});

  const classes = {
    'SearchInput': true,
    'SearchInput-small': size == 'small',
    'SearchInput-medium': size == 'medium',
    'SearchInput-large': size == 'large',
    'SearchInput-clear': field.value.length >= 1
  }

  const validClasses = Object.entries(classes)
      .filter(([,v]) => !!v)
      .map(([k,]) => k)
      .join(' ')

  const searchStyles = {
    maxWidth: '358px',
    width: '100%',
  }

  return (
    <TextField
      className={validClasses}
      inputProps={{...field}}
      placeholder={placeHolder}
      tabIndex={tabIndex}
      autoComplete="true"
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      disabled={disabled}
      sx={searchStyles}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{mr: 0, paddingRight: '12px'}}>
            <Search />
          </InputAdornment>
        ),
        endAdornment: field.value.length >= 1 && (
          <InputAdornment position="end">
            <IconButton 
              kind="ghost"
              size={size}
              icon={<Close />}
              onClick={() => field.onChange('')}
              />
          </InputAdornment>
        )
      }}
    />
  );
};

export default SearchInput;