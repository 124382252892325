import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";

// Helper
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useChangePasswordMutation } from "@/repositories/user";
import { IChangePasswordForm, passwordSchema } from "@/interfaces/auth/change_password";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Button from "@/components/button";
import { Box, Container } from "@mui/material";
import PasswordInput from "@/components/password_input";
import Link from "@/components/link";


const PrivacyPage = () => {

    const { t } = useTranslation();
    const [changePassword, { isLoading }] = useChangePasswordMutation();

    const { control, handleSubmit, reset, formState: { isDirty, isValid } } = useForm<IChangePasswordForm>({
        resolver: zodResolver(passwordSchema),
        mode: 'onBlur',
        defaultValues: {
            password: '',
            password_confirmation: ''
        }
    });

    const submitForm = async (data: IChangePasswordForm) => {
        await changePassword(data).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.settings.password.title'), message: t('notifications.settings.password.message')});
            reset();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        });
    }

    return (
        <Container>
            <Box display="flex" flexDirection="column" justifyContent="flex-start">

                {/* Flexible space */}
                <Box sx={{height: 'clamp(30px, 10vh, 70px)'}} />

                {/* Title */}
                <span className="heading-07-compact" style={{textAlign: 'left'}}>{t('pages.settings.privacy.changePassword')}</span>

                {/* Spacer */}
                <Box height="24px" />

                <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '24px', rowGap: '32px', alignItems: 'start', padding: '0px !important'}}>

                    <Box display="flex" flexDirection="column" flexGrow={1} maxWidth="540px" alignItems="center" justifyContent="center">

                        <PasswordInput
                            name='password'
                            control={control}
                            label={t('inputs.titles.password')} 
                            tabIndex={1}
                            placeHolder={t('inputs.placeholders.passwordNew')}
                            showRequirements
                            />

                    </Box>

                    <Box display="flex" flexDirection="column" flexGrow={1} maxWidth="540px" alignItems="center" justifyContent="center">
                        <PasswordInput
                            name='password_confirmation'
                            control={control}
                            label={t('inputs.titles.passwordConfirmation')}
                            placeHolder={t('inputs.placeholders.passwordNew')}
                            tabIndex={2}
                            />
                    </Box>
                    
                    <Box />

                    {/* Actions */}
                    <Box display="flex" flexGrow={1} height="24px" width="100%" maxWidth="540px" justifyContent="end">
                        <Button
                            label={t('components.buttons.setNewPassword')}
                            loading={isLoading}
                            disabled={!isValid || !isDirty}
                            endIcon={<ArrowRight />}
                            onClick={handleSubmit(submitForm)}
                        />
                    </Box>

                </Box>

                {/* privacy policy */}
                <Box mt={8}>
                    <Link
                        newWindow
                        label={t('pages.settings.privacy.privacyPolicy')}
                        to='https://www.1fit.com/privacy/'
                    />
                </Box>
            </Box>
        </Container>
    )
}

export default PrivacyPage;


