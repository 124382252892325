import dayjs from "dayjs";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FC, useEffect, useState } from "react";

// Helpers
import { formatDuration } from "@/_helpers/time_functions";
import { convertToPounds, roundNumber } from "@/_helpers/number_functions";

// Services and interfaces
import { IExercise } from "@/interfaces/workout/workout";
import { selectSiteSettingsWeight } from "@/store/settings";
import { useGetWorkoutQuery } from "@/repositories/workout";

// Components
import Card from "@/components/card";
import { Skeleton } from "@mui/material";
import useSetHeaders from "./set_headers";
import Box, { BoxProps } from "@mui/material/Box";
import RightPanel from "@/components/right_panel";
import BuilderTable from "@/components/builder/table";


interface WorkoutDetailsPanelProps {
    open: boolean;
    onClose: ()=>void;
    workoutId?: string
}

const WorkoutDetailsPanel: FC<WorkoutDetailsPanelProps> = ({
    open,
    onClose,
    workoutId
}) => {

    const { id } = useParams();
    const [panelOpen, setPanelOpen] = useState(false);
    const { data: workout, isLoading } = useGetWorkoutQuery({client_id: id!, workout_id: workoutId ?? ''}, {skip: !workoutId});

    const unit = useSelector(selectSiteSettingsWeight);
    const isKg = unit?.value === 'kg';

    const boxProps: BoxProps = {
        className: 'body-02',
        display: 'flex', pb: 1, mb: 1,
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--border-subtle-01)',
        color: 'var(--text-secondary)'
    }

    const handleClose = () => {
        setPanelOpen(false);
        onClose();
    }

    useEffect(() => {
        if (open) setPanelOpen(true);
    }, [open])

    return (
        <RightPanel title={workout?.name ?? ''} open={panelOpen} onClose={handleClose}>
            {/* Date */}
            <Box {...boxProps}>
                <span>{t('components.workouts.date')}</span> 
                {isLoading ? 
                    <Skeleton variant="rounded" width="80px" height="20px" /> :
                    <span>{dayjs(workout?.started).format('DD/MM/YYYY')}</span>}
            </Box>
            {/* Duration */}
            <Box {...boxProps}>
                <span>{t('components.workouts.duration')}</span> 
                {isLoading ? 
                    <Skeleton variant="rounded" width="80px" height="20px" /> :
                    <span>{formatDuration(dayjs(workout?.completed).diff(workout?.started, 'seconds'), true)}</span>}
            </Box>
            {/* Performance */}
            <Box {...boxProps}>
                <span>{t('components.workouts.sessionPerformance')}</span>
                {isLoading ? 
                    <Skeleton variant="rounded" width="50px" height="20px" /> :
                    <span>{t('metrics.rating', { value: workout?.rating })}</span>}
            </Box>
            {/* Volume */}
            <Box {...boxProps} sx={{borderBottom: 'none'}}>
                <span>{t('components.workouts.totalVolume')}</span>
                {isLoading ? 
                    <Skeleton variant="rounded" width="50px" height="20px" /> :
                    <span>{`${isKg ? roundNumber(workout?.total_volume ?? 0, 0) : convertToPounds(Number(workout?.total_volume))} ${unit?.value}`}</span>}
            </Box>
            <Box display="flex" flexDirection="column" className='text-primary' mt={2}>   
                <span className='heading-07-compact'>{t('components.workouts.notes')}</span>
                {isLoading ? 
                    <Skeleton variant="rounded" width="50px" height="20px" /> :
                    <span className='body-02'>{workout?.notes ?? t('components.workouts.noNotes')}</span>}
            </Box>
            {isLoading && Array(3).fill({}).map((_, i) => (
                <Card key={i} sx={{my: 2, borderRadius: '6px'}}>
                    <Box display="flex" justifyContent='space-between' mb={2}>
                        <Skeleton variant="text" width="100px" height="20px" />
                        <Skeleton variant="text" width="100px" height="20px" />
                    </Box>
                    <Box className='body-02' mb={2}>
                        <Skeleton variant="text" width="100%" height="20px" />
                    </Box>
                    <BuilderTable
                        data={[]}
                        columns={Array(3).fill({}).map((_, i) => ({
                            field: `field_${i}`,
                            headerName: ''
                        }))}
                        
                        isLoading={isLoading}
                        />
                </Card>
            ))}
            {!isLoading && workout?.exercises.map(e => (
                <Card key={e.id} sx={{my: 2, borderRadius: '6px'}}>
                    <Box display="flex" justifyContent='space-between' mb={2}>
                        <span className="body-01">{e.name}</span>
                        <span className="label-text-01" style={{color: 'var(--text-secondary)'}}>
                            {t('components.workouts.sets', {count: e.sets.length})}
                        </span>
                    </Box>
                    <Box className='body-02' mb={2}>
                        {e.notes ?? t('components.workouts.noNotes')}
                    </Box>
                    <_ExerciseTable ex={e} lastExercise={workout.history.find((h) => h.exercise_id == e.exercise_id)} />
                </Card>
            ))}
        </RightPanel>
    )
}

export default WorkoutDetailsPanel


interface _ExerciseTableProps {
    ex: IExercise,
    lastExercise?: IExercise
}

const _ExerciseTable: FC<_ExerciseTableProps> = ({
    ex,
    lastExercise
}) => {

    const headers = useSetHeaders({metrics: ex.metrics, historicSets: lastExercise?.sets});
    
    return (
        <Box sx={{ 'td, th': {pb: 2} }}>
            <BuilderTable
                data={ex.sets}
                columns={headers}
            />
        </Box>
    )
}