import { useTranslation } from "react-i18next";
import { useSortable } from "@dnd-kit/sortable";
import { useFormContext, useWatch } from "react-hook-form";
import { BaseSyntheticEvent, FC, useRef, useState } from "react";

// Constants and helpers
import { cardioTypeOptions } from "@/_constants/activity";
import { formatDateTime, formatDuration } from "@/_helpers/time_functions";

// Services and interfaces
import { useGetExercisesQuery } from "@/repositories/exercise";
import { IActivityBuilder } from "@/interfaces/activity_plan/activity_builder";

// Styles
import { ChevronDown, Copy, DragVertical, TrashCan } from "@carbon/icons-react";

// Components
import TextInput from "@/components/text_input";
import IconButton from "@/components/icon_button";
import NumberInput from "@/components/number_input";
import SelectInput from "@/components/select_input";
import DurationInput from "@/components/duration_input";
import DragHandle from "@/components/sortable/drag_handle";
import { Accordion, AccordionDetails, AccordionSummary, accordionSummaryClasses, Box, Card } from "@mui/material";


interface CardioDayProps {
    id: string;
    dayIndex: number;
    readonly?: boolean;
    onCopy: (index: number) => void;
    onRemove: (index: number) => void;
}

const CardioDay: FC<CardioDayProps> = ({
    id,
    dayIndex,
    readonly = false,
    onCopy,
    onRemove
}) => {

    const { t } = useTranslation();
    const sessionRef = useRef(null);
    const { setNodeRef } = useSortable({ id });
    const [sessionExpanded, setSessionExpanded] = useState<boolean>(false);

    const { data, isLoading } = useGetExercisesQuery();
    const exercises = data?.filter((e) => e.type === 'cardio');

    const { control, setValue } = useFormContext<IActivityBuilder>()
    const cardioPlan = useWatch({ control, name: 'cardio' });
    const name = useWatch({ control, name: `cardio.${dayIndex}.name` });
    const duration = useWatch({ control, name: `cardio.${dayIndex}.duration` });
    const updated_at = useWatch({ control, name: `cardio.${dayIndex}.updated_at` });

    const formattedDuration = formatDuration(duration as number);

    const handleChange = (e: BaseSyntheticEvent) => {
        const classList = e.target.classList;
        if (!classList.contains('_Expandable') || classList.contains('MuiAccordionSummary-content')) return;
        e.stopPropagation();
        setSessionExpanded(!sessionExpanded);
    }

    const handleOnSelectExercise = (e: string) => {
        const exercise = exercises?.find((ex) => ex.id === e);
        if (exercise) {
            setValue(`cardio.${dayIndex}.exercise_name`, exercise.name);
        }
    }
    
    return (
        <Card ref={setNodeRef} key={id} elevation={0} sx={{borderRadius: '6px', border: 'solid 1px var(--border-subtle-01)', marginBottom: '24px'}}>
            <Accordion 
                ref={sessionRef}
                expanded={sessionExpanded}
                onChange={handleChange}
                slotProps={{ transition: { unmountOnExit: true } }} sx={{
                    m: 0, 
                    width: '100%', 
                    boxShadow: '0', 
                }}>

                <AccordionSummary
                    expandIcon={<ChevronDown className="_Expandable" />}
                    sx={{
                        ml: 3, p: 0,
                        flexDirection: 'row-reverse',
                        [`& .${accordionSummaryClasses.content}`]: {
                            m: 0, p: 2,
                            [`&.${accordionSummaryClasses.expanded}`]: {
                                m: 0
                            }
                        }
                    }}>
                    <Box className="_Expandable" display="flex" width="100%" alignItems="center">

                        <Box className="_Expandable" display='flex' flexDirection="column" alignItems="flex-start" marginLeft="6px">
                            <span className="body-01 _Expandable">{name ? name : t('components.activityBuilder.section.untitledSession')}</span>
                            <Box className="_Expandable" display="flex" alignItems="center">
                                <span className="label-text-02 _Expandable">{formattedDuration}</span>
                                {updated_at && <span className="label-text-02 _Expandable" style={{marginLeft: '8px', color: 'var(--text-place-holder)'}}>{`(${t('timeDate.textOutputs.lastUpdated', {datetime: formatDateTime(updated_at)})})`}</span>}
                            </Box>
                        </Box>
                        
                        <Box display="flex" flexGrow={1} width="100%" />

                        {/* Sort */}
                        {cardioPlan.length == 1 || readonly ?
                            <Box display="flex" alignItems="center" minWidth="32px" justifyContent="center" marginRight="8px" sx={{color: 'var(--icon-disabled)' }}>
                                <DragVertical onClick={(e) => e.stopPropagation()} />
                            </Box> :
                            <DragHandle id={id}>
                                <Box display="flex" alignItems="center" width="32px" justifyContent="center" sx={{ cursor: 'grab' }}>
                                    <DragVertical onClick={(e) => e.stopPropagation()} />
                                </Box>
                            </DragHandle>}

                        <IconButton
                            kind="ghost"
                            size="small"
                            icon={<Copy />}
                            disabled={!name || readonly}
                            onClick={() => onCopy(dayIndex)}
                            sx={{marginLeft: '8px'}}
                            />

                        <IconButton
                            kind="danger-ghost"
                            size="small"
                            icon={<TrashCan />}
                            disabled={cardioPlan.length === 1 || readonly}
                            onClick={() => onRemove(dayIndex)}
                            sx={{marginLeft: '8px'}}
                            />

                    </Box>
                        
                </AccordionSummary>
                
                <AccordionDetails className="_Expandable" sx={{p:0, textAlign: 'left'}}>
                    <Box padding="0px 24px 16px 64px">

                        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" columnGap="24px">

                            <TextInput
                                name={`cardio.${dayIndex}.name`}
                                control={control}
                                label={t('inputs.titles.sessionName')}
                                placeHolder={t('inputs.placeholders.sessionName')}
                                maxLength={40}
                                disabled={readonly}
                                />

                            <SelectInput 
                                name={`cardio.${dayIndex}.exercise_id`}
                                control={control}
                                label={t('inputs.titles.exercise')}
                                items={exercises ?? []}
                                itemKey='id'
                                itemLabel='name'
                                itemValue='id'
                                itemsLoading={isLoading}
                                onChange={(e) => handleOnSelectExercise(e)}
                                disabled={readonly}
                                />
                            
                            <SelectInput 
                                name={`cardio.${dayIndex}.type`}
                                control={control}
                                label={t('inputs.titles.cardioType')}
                                items={cardioTypeOptions}
                                itemsLoading={isLoading}
                                disabled={readonly}
                                />

                        </Box>

                        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" columnGap="24px">

                            <DurationInput
                                name={`cardio.${dayIndex}.duration`}
                                control={control}
                                label={t('inputs.titles.duration')}
                                placeHolder={t('inputs.placeholders.duration')}
                                format="hh:mm:ss"
                                disabled={readonly}
                                />

                            <NumberInput 
                                name={`cardio.${dayIndex}.distance`}
                                control={control}
                                label={t('inputs.titles.distance')}
                                placeHolder={t('inputs.placeholders.distance')}
                                maxLength={5}
                                disabled={readonly}
                                />
                            <NumberInput 
                                name={`cardio.${dayIndex}.calories`}
                                control={control}
                                label={t('inputs.titles.calories')}
                                placeHolder={t('inputs.placeholders.calories')}
                                maxLength={4}
                                disabled={readonly}
                                />

                            <NumberInput 
                                name={`cardio.${dayIndex}.hr`}
                                control={control}
                                label={t('inputs.titles.hr')}
                                placeHolder={t('inputs.placeholders.hr')}
                                maxLength={3}
                                disabled={readonly}
                                />

                        </Box>

                        <TextInput
                            control={control}
                            name={`cardio.${dayIndex}.notes`}
                            size='textarea'
                            label={t('inputs.titles.notes')}
                            placeHolder={t('inputs.placeholders.cardioNotes')}
                            minRows={4}
                            maxLength={2000}
                            disabled={readonly}
                            />
                        
                    </Box>
                </AccordionDetails>
            </Accordion>

        </Card>
    )
}

export default CardioDay;