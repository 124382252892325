import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AddressMode } from "@stripe/stripe-js";
import { AddressElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectHasTrialCode } from "@/store/coach";
import { useCreateSubscriptionMutation } from "@/repositories/subscription";

// Styles
import { ShoppingCartPlus } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material"
import Button from '@/components/button';
import FullScreenLoader from "@/components/overlays/fullscreen_loader";


const SubscriptionForm = () => {

    const { t } = useTranslation();
    const elements = useElements();
    const stripe = useStripe();

    const isTrial = useSelector(selectHasTrialCode);
    const [loading, setLoading] = useState(false);
    const trialEndDate = dayjs().add(7, 'day').format('D MMMM YYYY');
    const [createSubscription, { isLoading }] = useCreateSubscriptionMutation();

    const [addressComplete, setAddressComplete] = useState(false);
    const [paymentComplete, setPaymentComplete] = useState(false);

    const addressOptions = {
        mode: 'billing' as AddressMode
    }

    const submit = async () => {
        setLoading(true);
        if (!elements || !stripe) return;

        const address = elements.getElement(AddressElement);
        const payment = elements.getElement(PaymentElement);

        if (!address || !payment) return;
        
        await elements.submit();

        const { paymentMethod, error } = await stripe.createPaymentMethod({elements: elements});
        if (error) {
            setLoading(false);
            return showToast({type: 'info', title: t('notifications.billing.paymentFailed'), message: error.message});
        }
        
        await createSubscription(paymentMethod.id).unwrap().catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })

        setLoading(false);
            
    }

    return (
        <>
        <FullScreenLoader show={loading || isLoading} loadingText={t(`billing.${isTrial ? 'processingTrial' : 'processingPayment'}`)} />
        <Box display="grid" gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }} columnGap="24px" rowGap="32px" maxWidth="1536px" justifyContent="space-between">

            {/* Address element */}
            <Box display="flex" flexDirection="column" alignItems="flex-start" maxWidth="540px">
                <span className="heading-06" style={{color: 'var(--text-secondary)'}}>{t('pages.setup.subscription.form.billingAddress')}</span>
                <Box height="24px" />
                <AddressElement options={addressOptions} onChange={(e) => setAddressComplete(e.complete)} />
            </Box>

            {/* Payment element */}
            <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start" maxWidth="540px">
                <span className="heading-06" style={{color: 'var(--text-secondary)'}}>{t('pages.setup.subscription.form.paymentDetails')}</span>
                <Box height="24px" />
                <PaymentElement
                    options={{
                        terms: {
                            card: 'never'
                        }
                    }}
                    onChange={(e) => setPaymentComplete(e.complete)} 
                    />
                <Box height="30px" />
                {isTrial ? 
                    <span className="body-02" style={{color: 'var(--text-secondary)', whiteSpace: 'pre-wrap', textAlign: 'left'}}>{t('pages.setup.subscription.form.trialTerms', {date: trialEndDate, duration: '7 day'})}</span> :
                    <span className="body-02" style={{color: 'var(--text-secondary)', whiteSpace: 'pre-wrap', textAlign: 'left'}}>{t('pages.setup.subscription.form.terms')}</span>}
            </Box>
            
            {/* Spacer */}
            <Box />

            {/* Actions */}
            <Box display="flex" flexGrow={1} height="24px" maxWidth="540px" justifyContent="end" paddingBottom="24px">
                <Button
                    disabled={!addressComplete || !paymentComplete || loading || isLoading}
                    loading={null}
                    endIcon={<ShoppingCartPlus />}
                    onClick={submit}
                    sx={{'@media (max-width: 768px)': {width: '100%'}}}
                    >
                    {t('components.buttons.subscribe')}
                </Button>
            </Box>
                
        </Box>

        </>
    )
}

export default SubscriptionForm;