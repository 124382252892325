import { FC } from "react";
import { useTranslation } from "react-i18next";

// Styles
import { Save } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";


interface _ClientStatusModalProps {
    open: boolean;
    onClose: () => void;
    isActive: boolean;
    isLoading: boolean;
    onUpdate: () => void;
}

const ClientStatusModal: FC<_ClientStatusModalProps> = ({
    open,
    onClose,
    isActive,
    isLoading,
    onUpdate
}) => {

    const { t } = useTranslation();

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={isActive ? t('modals.clientStatus.title_inactive') : t('modals.clientStatus.title_active')}
            text={isActive ? t('modals.clientStatus.text_inactive') : t('modals.clientStatus.title_active')}
            action1={{
                kind: 'primary',
                label: isActive ? t('components.buttons.setInactive') : t('components.buttons.setActive'),
                icon: <Save />,
                loading: isLoading,
                onClick: onUpdate
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px'}}}
            />
    )
}

export default ClientStatusModal;