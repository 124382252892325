const typography = {
    fontFamily: [
        '"IBM Plex Sans"',
        'sans-serif',
      ].join(','),
    // Headings
    heading01: {
        fontSize: '54px',
        fontWeight: 300,
        lineHeight: '64px'
    },
    heading02: {
        fontSize: '42px',
        fontWeight: 300,
        lineHeight: '50px'
    },
    heading03: {
        fontSize: '32px',
        fontWeight: 300,
        lineHeight: '40px'
    },
    heading04: {
        fontSize: '28px',
        fontWeight: 400,
        lineHeight: '36px'
    },
    heading05: {
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '38px'
    },
    heading06: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px'
    },
    heading07: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0.16px'
    },
    heading06Compact: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '22px'
    },
    heading07Compact: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '18px',
        letterSpacing: '0.16px'
    },
    // Body
    body01: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px'
    },
    body02: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.16px'
    },
    body01Compact: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px'
    },
    body02Compact: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0.16px'
    },
    // Labels
    labelText01: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0.16px'
    },
    labelText02: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0.32px'
    },
    // Helper Text
    helperText01: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0.16px'       
    },
    helpeText02: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0.32px'
    },
};

export default typography;