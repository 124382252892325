import { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Services and interfaces
import { selectHasTeam } from "@/store/team";

// Components
import { Box } from "@mui/material";    
import Modal from "@/components/modal/modal";
import ProgressIndicator, { ProgressIndicatorStepProps } from "@/components/progress_indicator";


interface _ReferralModalProps {
    open: boolean;
    onClose: () => void;
    status: string;
}

const ReferralModal: FC<_ReferralModalProps> = ({
    open,
    onClose,
    status
}) => {

    const { t } = useTranslation();
    const hasTeam = useSelector(selectHasTeam);

    const steps: ProgressIndicatorStepProps[] = [
        {
            active: status === 'bronze',
            complete: ['silver', 'gold'].includes(status),
            title: t('components.progressIndicator.titles.referralBronze'),
            subtitle: t('components.progressIndicator.subtitles.referralBronze'),
            link: ''
        },
        {
            active: status === 'silver',
            complete: status === 'gold',
            disabled: status !== 'silver',
            title: t('components.progressIndicator.titles.referralSilver', {context: `${hasTeam}`}),
            subtitle: t('components.progressIndicator.subtitles.referralSilver'),
            link: ''
        },
        {
            active: status === 'gold',
            complete: false,
            disabled: status !== 'gold',
            title: t('components.progressIndicator.titles.referralGold', {context: `${hasTeam}`}),
            subtitle: t('components.progressIndicator.subtitles.referralGold'),
            link: ''
        }
    ]

    return (
        <Modal 
            open={open}
            onClose={onClose}
            showClose
            title={t('modals.referralBenefits.title')}
            text={t('modals.referralBenefits.text')}
            children={
                <Box paddingBottom="24px">
                    <ProgressIndicator steps={steps} fullwidth />
                </Box>
            }
            sx={{'& .MuiPaper-root': {maxWidth: '470px'}}}
            />
    )
}

export default ReferralModal;