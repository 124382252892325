import { t } from "i18next";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { INutritionBuilder } from "@/interfaces/nutrition/nutrition_builder";
import { useAddNutritionTemplateMutation } from "@/repositories/nutrition_template";
import { INutritionTemplateForm } from "@/interfaces/nutrition_template/nutrition_template_form";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import NutritionModal from "@/components/nutrition_builder/nutrition_modal";


interface _NoTemplatesProps {
    message?: string;
    disabled?: boolean;
}

const NoTemplates: FC<_NoTemplatesProps> = ({
    message = t('components.dataTable.noData.noTemplatesFound'),
    disabled = false
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null);
    const [addNutrition, { isLoading: isSaving }] = useAddNutritionTemplateMutation();

    const handleAdd = (data: INutritionBuilder) => {
        addNutrition(data as INutritionTemplateForm).unwrap().then((template) => {
            showToast({
                type: 'success', 
                title: t('notifications.template.created.title'), 
                message: t('notifications.template.created.message', {name: template.name})
            });
            setOpen(null);
            navigate(`/templates/nutrition/${template.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" paddingBottom="24px">

            <Box height="32px" />
            <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{message}</span>
            <Box height="12px" />

            <Box display="flex"justifyContent="center" alignItems="center" columnGap="8px">
                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addTemplate')}
                    endIcon={<Add />}
                    minWidth={false}
                    disabled={disabled}
                    onClick={() => setOpen('add')}
                    />
                    {open === 'add' && <NutritionModal 
                        open={open === 'add'}
                        onClose={() => setOpen(null)}
                        isLoading={isSaving}
                        onSave={handleAdd}
                        />}
            </Box>

        </Box>
    )
}

export default NoTemplates;