import { FC } from "react";
import { FormProvider, useForm,} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";

// Services and interfaces
import { ITransferManyForm, prospectTransferManySchema } from "@/interfaces/transfer/transfer_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import TransferForm from "./transfer_form";
import Modal from "@/components/modal/modal";


interface _TransferManyModalProps {
    open: boolean;
    onClose: () => void;
    rowIds: string[];
    isLoading: boolean;
    onSubmit: (data: ITransferManyForm) => void;
}

const TransferManyModal: FC<_TransferManyModalProps> = ({
    open,
    onClose,
    rowIds,
    isLoading,
    onSubmit
}) => {
    const { t } = useTranslation();
    
    const formMethods = useForm<ITransferManyForm>({
        resolver: zodResolver(prospectTransferManySchema),
        mode: 'onBlur',
        defaultValues: {
            ids: rowIds,
            coach: null
        }
    });

    const handleClose = () => {
        formMethods.reset();
        onClose();
    }

    return (
        <Modal 
            open={open}
            onClose={formMethods.formState.isDirty ? () => {} : handleClose}
            title={t('modals.transfer.title', {model: t('modals.transfer.prospect'), count: rowIds.length} )}
            text={t('modals.transfer.text', {model: t('modals.transfer.prospect'), count: rowIds.length} )}
            children={
                <FormProvider {...formMethods}>
                    <TransferForm isMultiselect={true} />
                </FormProvider>}
            action1={{
                kind: 'primary',
                label: t('components.buttons.transfer'),
                icon: <ArrowRight />,
                loading: isLoading,
                onClick: formMethods.handleSubmit(onSubmit)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: handleClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px'}}}
            />
    )
}

export default TransferManyModal;