import axios from "@/_lib/axios";

function download(url: string, filename: string) {
  axios(url, {
    withCredentials: false,
    responseType: 'blob'
  })
    .then(res => {
      let blobUrl = window.URL.createObjectURL(res.data);
      let a = document.createElement('a');
      a.download = filename;
      a.href = blobUrl;
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
}

export const downloadQuery = (url: string) => {
  const filename = url.replace(/^.*[\\\/]/, '').replace(/(.*?\..*?)\?(.*)/gm, '$1');
  download(url, filename)
  // download(`media/${uuid}/download`)
}

export const downloadMultipleQuery = (uuids: string[]) => { 
  const API_URL = import.meta.env.VITE_API_URL;
  download(`${API_URL}media/download?uuid=${uuids.join(",")}`, 'user.zip');
}
