import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { Options } from "@/components/menu";
import { selectHasTeam, selectIsMember } from "@/store/team";
import { ApiError } from "@/interfaces/api/error";
import { IProspect } from "@/interfaces/prospect/prospect";
import { ITransferForm } from "@/interfaces/transfer/transfer_form";
import { IProspectConvertForm } from "@/interfaces/prospect/prospect_convert_form";
import { useConvertProspectMutation, useDeleteProspectMutation, useTransferProspectMutation } from "@/repositories/prospects";

// Components
import { Box } from "@mui/material";
import ConvertModal from "./convert_modal";
import TransferModal from "./transfer_modal";
import AddProspectModal from "./prospect_modal";
import DeleteProspectModal from "./delete_modal";
import OverflowMenu from "@/components/overflow_menu";
import useBulkActions from "@/components/datatable/use_bulk_actions";
import LeadFormResponsePanel from "@/pages/common/lead_form_response_panel";


interface _ProspectMenuProps {
    row: IProspect;
}

const ProspectMenu: FC<_ProspectMenuProps> = ({
    row,
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const hasTeam = useSelector(selectHasTeam);
    const isMember = useSelector(selectIsMember);
    const { showBulkActions } = useBulkActions();
    const [open, setOpen] = useState<string|null>(null);
    const disableMenu = showBulkActions || row.readonly;
    const disableConvert = !row.first_name || !row.last_name || !row.email || !row.mobile;
    const [transferProspect, { isLoading: isTransferring }] = useTransferProspectMutation();
    const [convertProspect, { isLoading: isConverting }] = useConvertProspectMutation();
    const [deleteProspect, { isLoading: isDeleting }] = useDeleteProspectMutation();

    const options: Options[] = [
        {name: t('components.menuItems.edit'), disabled: false, action: () => setOpen('edit')},
        {name: t('components.menuItems.viewResponse'), disabled: !row.response_id, action: () => setOpen('response')},
        {name: t('components.menuItems.convert'), disabled: disableMenu || disableConvert, action: () => setOpen('convert')},
    ]

    if (hasTeam && !isMember && !options.some(option => option.name === t('components.menuItems.transferProspect'))) {
        // Add to index 2 in the array
        options.splice(2, 0, {name: t('components.menuItems.transferProspect'), disabled: false, action: () => setOpen('transfer')})
    }

    const handleTransfer = (data: ITransferForm) => {
        transferProspect(data).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.prospects.transferred.title', {count: 1})});
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleConvert = (data: IProspectConvertForm) => {
        convertProspect({id: row.id, data: data}).unwrap().then((client) => {
            showToast({
                type: 'success', 
                title: t('notifications.prospects.converted.title'),
                message: t('notifications.prospects.converted.message', {name: client.full_name})
            });
            setOpen(null);
            navigate(`/clients/${client.id}/overview`);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleDelete = () => {
        deleteProspect(row.id).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.prospects.deleted.title', {count: 1}), 
                message: <Trans 
                    i18nKey="notifications.prospects.deleted.message" 
                    values={{name: `${row.first_name} ${row.last_name}`}}
                    components={{bold: <strong />}}
            />
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Box display="flex" justifyContent="flex-end">
            <OverflowMenu 
                position={{horizontal: 'right', vertical: 'bottom'}}
                options={options}
                deleteLabel={t('components.menuItems.delete')}
                disableDelete={disableMenu}
                disabled={showBulkActions}
                onDelete={() => setOpen('delete')}
                />
            {open === 'edit' && 
                <AddProspectModal 
                    open={open === 'edit'} 
                    onClose={() => setOpen(null)}
                    prospect={row}
                    />}
            {row.response_id && open === 'response' && <LeadFormResponsePanel
                open={open === 'response'}
                onClose={() => setOpen(null)}
                leadformReponseId={row.response_id}
                name={`${row.first_name} ${row.last_name}`}
                />}
            {open === 'transfer' && <TransferModal
                open={open === 'transfer'}
                onClose={() => setOpen(null)}
                rowId={row.id}
                isLoading={isTransferring}
                onSubmit={handleTransfer}
                />}
            {open === 'convert' && <ConvertModal
                open={open === 'convert'}
                onClose={() => setOpen(null)}
                name={`${row.first_name} ${row.last_name}`}
                isLoading={isConverting}
                onSubmit={handleConvert}
                />}
            {open === 'delete' && <DeleteProspectModal
                open={open === 'delete'}
                onClose={() => setOpen(null)}
                name={`${row.first_name} ${row.last_name}`}
                isLoading={isDeleting}
                onDelete={handleDelete}
                />}
            
        </Box>
    )
}

export default ProspectMenu;