import { FC } from "react";
// import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helpers
// import { roundNumber } from "@/_helpers/number_functions";

// Services and interfaces

// Components
import TotalsCard from "./totals_card";
import { Box, Container } from "@mui/material";
import DataTable from "@/components/datatable";
import useMeasurementTableHeaders from "./measurement_headers";
import ComingSoonOverlay from "@/components/overlays/coming_soon";


// NOTE Page commented out as left as placeholder for future development
const MeasurementsTab: FC = () => {

    // const { id } = useParams();
    const { t } = useTranslation();
    const headers = useMeasurementTableHeaders();

    // const calculateChange = (bodyweight: number, index: number): number => {
    //     if (!data || !bodyweight) return 0;
    //     const next = data?.slice(index + 1).find((next) => next.bodyweight)?.bodyweight
    //     if (!next) return 0;
    //     return roundNumber(bodyweight - next, 2);
    // }

    // const weightData = data?.map((item, i): IBodyweightData => {
    //     return {
    //         id: item.id,
    //         date: item.date,
    //         bodyweight: item.bodyweight,
    //         change: calculateChange(item.bodyweight, i)
    //     }
    // }) ?? [];

    // const now = dayjs();
    // const sevenDaysAgo = now.subtract(7, 'day');
    // const forteenDaysAgo = now.subtract(14, 'day');

    // const thisWeek = weightData.filter((item) => item.bodyweight && dayjs(item.date).isAfter(sevenDaysAgo));
    // const lastWeek = weightData.filter((item) => item.bodyweight && dayjs(item.date).isAfter(forteenDaysAgo) && dayjs(item.date).isBefore(sevenDaysAgo));

    // const averageTotals = (): TotalValues => {
    //     if (!weightData || !thisWeek.length) return {value: 0, change: 0, daysLogged: 0};

    //     const average = roundNumber(thisWeek.reduce((acc, item) => {
    //         return acc + item.bodyweight;
    //     }, 0) / thisWeek.length, 2);
    //     const lastWeekAverage = roundNumber(lastWeek.reduce((acc, item) => {
    //         return acc + item.bodyweight;
    //     }, 0) / lastWeek.length, 2);

    //     const change = average && lastWeekAverage ? roundNumber(average - lastWeekAverage, 2) : 0;
    //     return {value: average, change: change, daysLogged: thisWeek.length};
    // }

    // const highTotals = (): TotalValues => {
    //     if (!weightData || !thisWeek.length) return {value: 0, change: 0, daysLogged: 0};

    //     const high = roundNumber(thisWeek.reduce((max, item) => {
    //         return item.bodyweight > max ? item.bodyweight : max;
    //     }, 0), 2);
    //     const lastWeekHigh = roundNumber(lastWeek.reduce((max, item) => {
    //         return item.bodyweight > max ? item.bodyweight : max;
    //     }, 0), 2);

    //     const change = high && lastWeekHigh ? roundNumber(high - lastWeekHigh, 2) : 0;
    //     return {value: high, change: change, daysLogged: thisWeek.length};
    // }

    // const lowTotals = (): TotalValues => {
    //     if (!weightData || !thisWeek.length) return {value: 0, change: 0, daysLogged: 0};

    //     const low = roundNumber(thisWeek.reduce((min, item) => {
    //         return item.bodyweight < min ? item.bodyweight : min;
    //     }, 1000), 2);
    //     const lastWeekLow = roundNumber(lastWeek.reduce((min, item) => {
    //         return item.bodyweight < min ? item.bodyweight : min;
    //     }, 1000), 2);

    //     const change = low && lastWeekLow ? roundNumber(low - lastWeekLow, 2) : 0;
    //     return {value: low, change: change, daysLogged: thisWeek.length};
    // }

    return (
        <Container sx={{height: '100%', pt: '24px', position: 'relative'}}>

            <Box display="flex" columnGap="16px" sx={{ filter: 'blur(8px)' }}>

                <TotalsCard metric="average" totals={{value: 0, change: 0, daysLogged: 0}} isLoading={true} />
                <TotalsCard metric="high" totals={{value: 0, change: 0, daysLogged: 0}} isLoading={true}  />
                <TotalsCard metric="low" totals={{value: 0, change: 0, daysLogged: 0}} isLoading={true}  />

            </Box>

            <Box sx={{ filter: 'blur(8px)' }}>
            <DataTable
                data={[]}
                dataKey="id"
                columns={headers} 
                hideHeader
                hideFilter
                localPagination
                noDataMessage={t('components.dataTable.noData.noLoggedData')}
                isLoading={true}
                />
            </Box>

            <ComingSoonOverlay isBlurred={false} hasBackground={false} />

        </Container>
    )
}

export default MeasurementsTab;