import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

// Components
import Modal from "@/components/modal/modal";


interface _DefaulteModalProps {
    open: boolean;
    onClose: () => void;
    name: string;
    isLoading: boolean;
    onSubmit: () => void;
}

const DefaultModal: FC<_DefaulteModalProps> = ({
    open,
    onClose,
    name,
    isLoading,
    onSubmit
}) => {

    const { t } = useTranslation();

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.makeDefault.title')}
            text={<Trans i18nKey="modals.makeDefault.text" values={{name: name}} components={{ strong: <strong /> }} />}
            action1={{
                kind: 'primary',
                label: t('components.buttons.makeDefault'),
                loading: isLoading,
                onClick: onSubmit
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px'}}}
            />
    )
}

export default DefaultModal;