import { z } from "zod";
import ruleset from "@/_helpers/ruleset";
import { cardioSchema, IActivityBuilder, stepsSchema } from "@/interfaces/activity_plan/activity_builder";


export interface IActivityTemplateForm extends IActivityBuilder {
    id: string;
    draft: boolean;
}

export const activityTemplateFormSchema = z.object({
    name: ruleset.required,
    steps_notes: ruleset.stringOptional,
    cardio_notes: ruleset.stringOptional,
    steps: stepsSchema(true),
    cardio: cardioSchema(true),
    draft: ruleset.boolean
})
.superRefine((val, ctx) => {
    if (!!val.steps && val.steps.length === 0) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['steps']
        })
    }

    if (!!val.cardio && val.cardio.length === 0) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['cardio']
        })
    }
});