import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useCompleteReferralsMutation } from "@/repositories/coach";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";    
import Modal from "@/components/modal/modal";
import ReferralIntroPreview from "./referral_intro_preview";


interface _ReferralIntroModalProps {
    open: boolean;
    onClose: () => void;
}

const ReferralIntroModal: FC<_ReferralIntroModalProps> = ({
    open,
    onClose,
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [completeReferrals, { isLoading }] = useCompleteReferralsMutation();

    const handleAction = () => {
        completeReferrals().unwrap().then(() => {
            navigate('/settings/referrals');
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Modal 
            open={open}
            onClose={() => onClose()}
            title={t('modals.referralIntroduction.title')}
            text={t('modals.referralIntroduction.text')}
            children={
                <Box paddingBottom="24px">
                    <ReferralIntroPreview />
                </Box>
            }
            action1={{
                label: t('components.buttons.goToReferrals'),
                icon: <ArrowRight />,
                loading: isLoading,
                onClick: handleAction,
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '1168px'}}}
            />
    )
}

export default ReferralIntroModal;