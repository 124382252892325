import { useTranslation } from "react-i18next";

// Helpers
import { capitalizeWords } from "@/_helpers/text_functions";
import { formatFileSize } from "@/_helpers/number_functions";

// Services and interfaces
import { IFolderItem } from "@/interfaces/folder/folder";

// Styles
import { Pdf, Video, Image, Unknown, Link, Music } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import { ColDef } from "@/components/datatable";
import ItemMenu from "@/pages/vault/files/item_menu";


const useItemTableHeaders = (): ColDef<IFolderItem>[] => {

    const { t } = useTranslation();

    const getFileIcon = (type: string, subtype?: string) => {
        if (type == 'link')
            return <Link size={16} />;
        else if (type == 'video')
            return <Video size={16} />
        else if (type == 'audio')
            return <Music size={16} />;
        else if (type == 'image')
            return <Image size={16} />;
        else if (type == 'application' && subtype == 'pdf')
            return <Pdf size={16} />;
        else 
            return <Unknown />;
    };

    const headers: ColDef<IFolderItem>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '30%',
            sortable: true,
            render: (row: IFolderItem) => 
                <Box display="flex" alignItems="center">
                    {getFileIcon(row.type, row.subtype)}
                    <span className="OneLine--ellipsis-breakWord" style={{marginLeft: '8px'}}>{capitalizeWords(row.name)}</span>
                </Box>
        },
        {
            field: 'created_at',
            headerName: t('components.dataTable.headers.dateAdded'),
            width: '15%',
            render: (row: IFolderItem) => new Date(row.created_at).toLocaleDateString()
        },
        {
            field: 'media_type',
            headerName: t('components.dataTable.headers.mediaType'),
            width: '15%',
            render: (row: IFolderItem) => row.type == 'link' ? t(`enums.fileSubtype.${row.subtype ?? 'other'}`) : row.subtype?.toUpperCase() ?? '-'
        },
        {
            field: 'size',
            headerName: t('components.dataTable.headers.size'),
            width: '20%',
            render: (row: IFolderItem) => row.size ? formatFileSize(row.size) : '-'
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (row: IFolderItem) => <ItemMenu row={row} />
        }
    ];

    return headers;
}

export default useItemTableHeaders;