// Base API
import baseApi from '@/repositories/base_api';

// Interfaces
import { IReferral, IReferralStats } from '@/interfaces/referral/referral';
import { IAPIResponse } from '@/interfaces/api/response';


export const referralApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getReferrals: build.query<IReferral[], void>({
      query: () => ({ url: '/referrals', method: 'get' }),
      transformResponse: (response: IAPIResponse<IReferral[]>) => response.data.sort((a, b) => new Date(b.redeemed_at).getTime() - new Date(a.redeemed_at).getTime()),
      providesTags: [
        { type: 'Referral', id: 'ALL' }
      ],
    }),
    getReferralStats: build.query<IReferralStats, void>({
      query: () => ({ url: '/referrals/stats', method: 'get' }),
      transformResponse: (response: IAPIResponse<IReferralStats>) => response.data,
      providesTags: [
        { type: 'Referral', id: 'STATS' }
      ],
    }),
  }),
})

export const { 
  useGetReferralsQuery,
  useGetReferralStatsQuery
} = referralApi;
