
import { useTranslation } from "react-i18next";
import { BaseSyntheticEvent, FC, useRef, useState } from "react";
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { FieldArrayWithId, FieldErrors, useFormContext } from "react-hook-form";
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

// Interfaces
import { IExercise } from "@/interfaces/programme/programme_builder";
import { IProgrammeBuilder } from "@/interfaces/programme/programme_builder";
import { IProgrammeWorkoutBuilder } from "@/interfaces/programme_workout/programme_workout_builder";

// Styles
import { ChevronDown, DotMark, Link, WarningFilled } from "@carbon/icons-react";

// Components
import SupersetExercise from "./superset_exercise";
import useSort from "@/components/sortable/sort_hooks";
import SortableItem from '@/components/sortable/sortable_item';
import { Accordion, AccordionDetails, AccordionSummary, accordionSummaryClasses, Box, Card } from "@mui/material";


interface _SupersetProps {
    basePath: `plan.${number}.exercises`|'exercises';
    exercises: FieldArrayWithId<IProgrammeBuilder, `plan.${number}.exercises`, "id">[];
    supersetExercises: FieldArrayWithId<IProgrammeBuilder, `plan.${number}.exercises`, "id">[];
    onReplace?: (exercise: IExercise, exerciseIndex: number) => void;
    onUnlinkExercise: (index: number) => void;
    onMove: (oldIndex: number, newIndex: number) => void;
    onRemove: (index: number) => void;
}

const Superset: FC<_SupersetProps> = ({
    basePath,
    exercises,
    supersetExercises,
    onReplace,
    onUnlinkExercise,
    onMove,
    onRemove
}) => {

    const { t } = useTranslation();
    const exerciseRef = useRef<HTMLDivElement>(null);
    const { sensors, restrictToVerticalAxis } = useSort();
    const [exerciseExpanded, setExerciseExpanded] = useState<boolean>(true);
    const { formState } = useFormContext<IProgrammeBuilder|IProgrammeWorkoutBuilder>();

    const programmeErrors = formState.errors as FieldErrors<IProgrammeBuilder>;
    const programmeWorkoutErrors = formState.errors as FieldErrors<IProgrammeWorkoutBuilder>;

    const hasErrors = () => {
        const superset_id = supersetExercises[0].superset_id;
        if (basePath === 'exercises') {
            const hasError = supersetExercises.some((exercise, index) => {
                if (exercise.superset_id == superset_id && programmeWorkoutErrors.exercises?.[index]) return true;
                return false;
            })
            return hasError;
        }
        // Get the workoutIndex from the basePath
        const workoutIndex = basePath.split('.')[1];
        const hasError = exercises.some((exercise, index) => {
            if (exercise.superset_id == superset_id && programmeErrors.plan?.[Number(workoutIndex)]?.exercises?.[index]) {
                return true;
            }
            return false;
        });
        return hasError;
    }

    const handleChange = (e: BaseSyntheticEvent, expanded: boolean) => {
        const classList = e.target.classList;
        if (!classList.contains('_Expandable') || classList.contains('MuiAccordionSummary-content')) return;
        e.stopPropagation();
        setExerciseExpanded(expanded);
    }

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (over !== null && active.id !== over.id) {
            const oldIndex = exercises.findIndex(item => item.id === active.id);
            const newIndex = exercises.findIndex(item => item.id === over.id);
            onMove(oldIndex, newIndex);
        }
    };

    const handleRemoveExercise = (index: number) => {
        if (supersetExercises.length === 2) {
            const superset_id = supersetExercises[index].superset_id;
            const s = exercises.find(e => e.superset_id === superset_id && e.id !== supersetExercises[index].id);
            const i = exercises.findIndex(e => e.id === s?.id);
            if (i == -1) return
            onUnlinkExercise(i);
        }
        onRemove(index);
    }

    return (
        <Card elevation={0} sx={{borderRadius: '6px', border: 'solid 1px var(--border-subtle-01)'}}>
            <Accordion 
                ref={exerciseRef}
                expanded={exerciseExpanded}
                onChange={handleChange}
                slotProps={{ transition: { unmountOnExit: true } }} sx={{
                    m: 0, 
                    width: '100%', 
                    boxShadow: '0', 
                }}>

            <AccordionSummary
                expandIcon={<ChevronDown className="_Expandable" />}
                sx={{
                    ml: 3, p: 0,
                    flexDirection: 'row-reverse',
                    [`& .${accordionSummaryClasses.content}`]: {
                        m: 0, p: 2,
                        [`&.${accordionSummaryClasses.expanded}`]: {
                            m: 0
                        }
                    }
                }}>
                <Box className="_Expandable" display="flex" width="100%" alignItems="center">

                    <Box className="_Expandable" display="flex" minWidth="40px" minHeight="40px" borderRadius="4px" alignItems="center" justifyContent="center" sx={{backgroundColor: 'var(--button-secondary)'}}>
                        <Link style={{color: 'var(--icon-on-color)'}} />
                    </Box>

                    <Box className="_Expandable" display='flex' flexDirection="column" alignItems="flex-start" marginLeft="16px">
                        <Box display="flex" alignItems="center">
                            {supersetExercises.length == 2 ? 
                                <span className="heading-06-compact _Expandable">{t('components.programmeBuilder.exercise.superset')}</span> :
                                <span className="heading-06-compact _Expandable">{t('components.programmeBuilder.exercise.giantSet')}</span>}
                            {hasErrors() && <WarningFilled style={{color: 'var(--support-error)', marginLeft: '8px'}} />}
                        </Box>
                        <Box display="flex">
                            {supersetExercises.map((exercise, i) => (
                                <Box key={exercise.id} display="flex" alignItems="center">
                                    <span className="label-text-02 _Expandable" style={{color: 'var(--text-secondary)'}}>{exercise.name}</span>
                                    {i !== supersetExercises.length - 1 && 
                                        <DotMark size="6" style={{color: 'var(--text-secondary)', marginLeft: '4px', marginRight: '4px'}} />}
                                </Box>
                            ))}
                        </Box>
                    </Box>

                    <Box display="flex" flexGrow={1} />
                    
                </Box>
            </AccordionSummary>

            <AccordionDetails sx={{p:0, textAlign: 'left'}}>
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
                    <SortableContext items={supersetExercises.map(exercise => exercise.id)} strategy={verticalListSortingStrategy}>
                        {supersetExercises.map((exercise, index) => (
                            <SortableItem key={exercise.id} id={exercise.id}>
                                <SupersetExercise
                                    key={exercise.id}
                                    id={exercise.id}
                                    basePath={basePath}
                                    exerciseIndex={exercises.findIndex(e => e.id === exercise.id)}
                                    supersetIndex={index}
                                    onReplace={onReplace}
                                    onRemoveExercise={handleRemoveExercise}
                                    onUnlinkExercise={onUnlinkExercise}
                                    />
                            </SortableItem>
                        ))}
                    </SortableContext>
                </DndContext>
            </AccordionDetails>

        </Accordion>

        </Card>
)
}

export default Superset;