
import { createSlice } from "@reduxjs/toolkit";

interface DataTableState {
    selected: string[];
    showBulkActions: boolean;
    filters: Record<string, string[]>;
    hiddenColumns: string[];
}

const initialState: DataTableState = {
    selected: [],
    showBulkActions: false,
    filters: {},
    hiddenColumns: []
};

export const datatableSlice = createSlice({
  name: "datatable",
  initialState,
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload;
    },
    setShowBulkActions: (state, { payload }) => {
      state.showBulkActions = payload;
    },
    setFilters: (state, { payload }) => {
      state.filters = payload;
    },
    setHiddenColumns: (state, { payload }) => {
      state.hiddenColumns = payload;
    }
  },
  selectors: {
    selectSelected: (state): string[] => state.selected,
    selectShowBulkActions: (state): boolean => state.showBulkActions,
    selectFilters: (state): Record<string, string[]> => state.filters,
    selectHiddenColumns: (state): string[] => state.hiddenColumns
  }
});

export const { 
  selectSelected, 
  selectShowBulkActions,
  selectFilters,
  selectHiddenColumns
} = datatableSlice.selectors;

export const { 
  setSelected, 
  setShowBulkActions,
  setFilters,
  setHiddenColumns
} = datatableSlice.actions;

export default datatableSlice.reducer;