import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";

// Constants
import { foodUnitOptions } from "@/_constants/food";

// Services and interfaces
import { IFoodForm } from "@/interfaces/food/food_form";

// Styles
import { Error } from '@mui/icons-material';
import { Add, TrashCan } from "@carbon/icons-react";

// Components
import Link from "@/components/link";
import { Box, Card } from "@mui/material";
import TextInput from "@/components/text_input";
import IconButton from "@/components/icon_button";
import NumberInput from "@/components/number_input";
import MultiselectInput from "@/components/multiselect_input";


interface ExerciseFormProps {
    type?: string;
}

const ExerciseForm: FC<ExerciseFormProps> = ({
    type = 'custom',
}) => {

    const { t } = useTranslation();
    const { control, formState: { errors } } = useFormContext<IFoodForm>();
    const macros = useWatch({control, name: 'macros'});
    const calories = useWatch({control, name: 'macros.calories'});
    const canAddServing = Object.values(macros).every(v => v !== undefined && v !== '') && type == 'custom';

    const { append, remove, fields } = useFieldArray({
        control,
        name: "custom_units",
    });

    const addServing = () => {
        append({ label: '', weight: 0, custom: true });
    }

    return (
        <Box display="flex" flexDirection="column" rowGap="12px" width="680px">

            <Box display="grid" gridTemplateColumns="2fr 1fr" columnGap="16px">

                <TextInput 
                    name="name"
                    control={control}
                    label={t('inputs.titles.foodName')}
                    placeHolder={t('inputs.placeholders.foodName')}
                    maxLength={40}
                    disabled={type !== 'custom'}
                    />

                <MultiselectInput 
                    name="units"
                    control={control}
                    label={t('inputs.titles.unit')}
                    items={foodUnitOptions}
                    itemKey="label"
                    itemValue="weight"
                    itemDisabled={(item) => item.label === 'Gram'}
                    disableClear
                    returnObject
                    disabled={type !== 'custom'}
                    />

            </Box>

            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr 1fr" columnGap="16px">

                <NumberInput 
                    name="macros.protein"
                    control={control}
                    label={t('inputs.titles.protein')}
                    decimalPlaces={2}
                    maxLength={3}
                    placeHolder="0"
                    helperText={t('inputs.helperText.per100g')}
                    endIcon={<span className="body-02-compact">g</span>}
                    disabled={type !== 'custom'}
                    /> 

                <NumberInput 
                    name="macros.carbs"
                    control={control}
                    label={t('inputs.titles.carbs')}
                    decimalPlaces={2}
                    maxLength={3}
                    placeHolder="0"
                    helperText={t('inputs.helperText.per100g')}
                    endIcon={<span className="body-02-compact">g</span>}
                    disabled={type !== 'custom'}
                    />  

                <NumberInput 
                    name="macros.fat"
                    control={control}
                    label={t('inputs.titles.fat')}
                    decimalPlaces={2}
                    maxLength={3}
                    placeHolder="0"
                    helperText={t('inputs.helperText.per100g')}
                    endIcon={<span className="body-02-compact">g</span>}
                    disabled={type !== 'custom'}
                    />  

                <NumberInput 
                    name="macros.fibre"
                    control={control}
                    label={t('inputs.titles.fibre')}
                    decimalPlaces={2}
                    maxLength={3}
                    placeHolder="0"
                    helperText={t('inputs.helperText.per100g')}
                    endIcon={<span className="body-02-compact">g</span>}
                    disabled={type !== 'custom'}
                    />
                
                {/* Calories */}
                <Box display="flex" flexDirection="column">
                    <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('inputs.titles.calories')}</span>
                    <Box height="14px" />
                    <Box display="flex" alignItems="center">
                        <span className="body-02-compact">{`${calories ?? 0} kcal`}</span>
                        {errors.macros?.calories && <Error color="error" style={{height: '16px'}} />}
                    </Box>
                    <Box height="14px" />
                    {errors.macros?.calories && <span className="label-text-02" style={{color: 'var(--support-error)'}}>{t('inputs.errors.caloriesNotZero')}</span>}
                </Box>

            </Box>

            {fields.map((s, i: number) => (
                (s.custom && <ServingCard 
                    key={s.id} 
                    index={i} 
                    disabled={type !== 'custom'}
                    removeServing={remove}
                    />)
            ))}
            <Link
                size="small"
                label={t('components.buttons.addServing')}
                icon={<Add />}
                to=''
                onClick={canAddServing ? addServing : undefined}
                />

        </Box>
    )
}

export default ExerciseForm;

interface _ServingCardProps {
    index: number;
    disabled?: boolean;
    removeServing: (index: number) => void;
}

const ServingCard: FC<_ServingCardProps> = ({
    index,
    disabled = false,
    removeServing,
}) => {

    const { t } = useTranslation();
    const { control } = useFormContext<IFoodForm>();
    const macros = useWatch({control, name: 'macros'});
    const units = useWatch({control, name: 'custom_units'});

    const protein = ((macros.protein as number || 0)/100) * (units[index].weight as number || 0);
    const carbs = ((macros.carbs as number || 0)/100) * (units[index].weight as number || 0);
    const fat = ((macros.fat as number || 0)/100) * (units[index].weight as number || 0);
    const fibre = ((macros.fibre as number || 0)/100) * (units[index].weight as number || 0);
    const calories = (((protein * 4) + (carbs * 4) + (fat * 9))).toLocaleString(undefined, {maximumFractionDigits: 1});

    const cardStyles = {
        backgroundColor: 'var(--layer-01) !important',
        border: 'solid 1px var(--border-subtle-01)',
        borderRadius: '6px',
        padding: '16px'
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between" alignItems="center">

                <span className="body-02-compact">{t('pages.library.foods.serving') + ` ${index+1}`}</span>

                <IconButton
                    kind="ghost"
                    size="small"
                    icon={<TrashCan style={{color: disabled ? 'var(--icon-disabled)' : 'var(--support-error)'}} />}
                    disabled={disabled}
                    onClick={() => removeServing(index)}
                    />
                
            </Box>
            <Card elevation={0} sx={cardStyles}>
                <Box display="grid" gridTemplateColumns="2fr 1fr" columnGap="16px">

                    <TextInput 
                        name={`custom_units.${index}.label`}
                        control={control}
                        label={t('inputs.titles.servingName')}
                        placeHolder={t('inputs.placeholders.foodServing')}
                        maxLength={25}
                        disabled={disabled}
                        />

                    <NumberInput 
                        name={`custom_units.${index}.weight`}
                        control={control}
                        label={t('inputs.titles.servingSize')}
                        decimalPlaces={1}
                        maxLength={4}
                        placeHolder="0"
                        endIcon={<span className="body-02-compact">g</span>}
                        disabled={disabled}
                        />  

                </Box>

                <Box height="16px" />

                <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr 1fr" columnGap="16px">

                    {/* Protein */}
                    <Box display="flex" flexDirection="column">
                        <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('inputs.titles.protein')}</span>
                        <Box height="14px" />
                        <span className="body-02-compact">{`${roundNumber(protein, 2)} g`}</span>
                    </Box>

                    {/* Carbs */}
                    <Box display="flex" flexDirection="column">
                        <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('inputs.titles.carbs')}</span>
                        <Box height="14px" />
                        <span className="body-02-compact">{`${roundNumber(carbs, 2)} g`}</span>
                    </Box>

                    {/* Fat */}
                    <Box display="flex" flexDirection="column">
                        <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('inputs.titles.fat')}</span>
                        <Box height="14px" />
                        <span className="body-02-compact">{`${roundNumber(fat, 2)} g`}</span>
                    </Box>

                    {/* Fibre */}
                    <Box display="flex" flexDirection="column">
                        <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('inputs.titles.fibre')}</span>
                        <Box height="14px" />
                        <span className="body-02-compact">{`${roundNumber(fibre, 2)} g`}</span>
                    </Box>

                    {/* Calories */}
                    <Box display="flex" flexDirection="column">
                        <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('inputs.titles.calories')}</span>
                        <Box height="14px" />
                        <span className="body-02-compact">{`${calories} kcal`}</span>
                    </Box>

                </Box>

            </Card>
        </Box>
    )
}