import { z } from "zod";
import ruleset from "@/_helpers/ruleset";
import { daySchema, IDay, INutritionBuilder } from "@/interfaces/nutrition/nutrition_builder";

export interface INutritionTemplateForm extends INutritionBuilder {
    id: string;
    name: string;
    notes: string;
    type: string;
    plan: IDay[];
    created_at: string;
    updated_at: string;
}

export const nutritionTemplateFormSchema = z.object({
    name: ruleset.required,
    focus: ruleset.stringOptional,
    notes: ruleset.stringOptional,
    draft: ruleset.boolean,
    plan: daySchema,
});