import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

// Interfaces
import { useGetClientQuery } from "@/repositories/client";
import { IClientData } from "@/interfaces/client/client_data";

// Components
import { ColDef } from "@/components/datatable";


const useRecoveryTableHeaders = (): ColDef<IClientData>[] => {

    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { data: client } = useGetClientQuery(id!);
    const showSleepTime = client?.coach_settings.recovery.sleep_time;
    const showSleepQuality = client?.coach_settings.recovery.sleep_quality;
    const showEnergyLevel = client?.coach_settings.recovery.energy_level;
    const showMuscleSoreness = client?.coach_settings.recovery.muscle_soreness;

    useEffect(() => {
        if (Object.values(client?.coach_settings.recovery ?? {}).every(value => value === false)) navigate(`/clients/${id}/overview`);
    }, [id, client?.coach_settings.recovery, navigate]);

    const headers: ColDef<IClientData>[] = [
        {
            field: 'date',
            headerName: t('components.dataTable.headers.date'),
            width: '20%',
            render: (row: IClientData) => new Date(row.date).toLocaleDateString()
        },
        ...showSleepTime ? [{
            field: 'sleep_time',
            headerName: t('components.dataTable.headers.sleepTime'),
            width: '15%',
            render: (row: IClientData) => row.sleep_time ? t('timeDate.hour', { count: row.sleep_time }) : '-'
        }] : [],
        ...showSleepQuality ? [{
            field: 'sleep_quality',
            headerName: t('components.dataTable.headers.sleepQuality'),
            width: '15%',
            render: (row: IClientData) => <Rating rating={row.sleep_quality} />
        }] : [],
        ...showMuscleSoreness ? [{
            field: 'muscle_soreness',
            headerName: t('components.dataTable.headers.muscleSoreness'),
            width: '15%',
            render: (row: IClientData) => <Rating rating={row.muscle_soreness} isInverted />
        }] : [],
        ...showEnergyLevel ? [{
            field: 'energy_level',
            headerName: t('components.dataTable.headers.energyLevel'),
            width: '15%',
            render: (row: IClientData) => <Rating rating={row.energy_level} />
        }] : [],
    ];

    return headers;
}

export default useRecoveryTableHeaders;

interface _RatingProps {
    rating: number;
    isInverted?: boolean;
}

const Rating: FC<_RatingProps> = ({
    rating,
    isInverted = false
}) => {

    const isError = isInverted ? rating >= 4 : rating <= 2

    if (!rating) return <span>{'-'}</span>;

    return (
        <>
            <span style={{color: isError ? 'var(--support-error)' : 'unset'}}>{rating}</span>
            <span style={{color:  isError ? 'var(--support-error)' : 'var(--text-placeholder)'}}>{' / 5'}</span>
        </>
    );
}