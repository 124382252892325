import { useTranslation } from "react-i18next";

// Helpers
import { capitalize } from "@/_helpers/text_functions";

// Interfaces
import { INutritionTemplate } from "@/interfaces/nutrition_template/nutrition_template";

// Components
import TemplateMenu from "./nutrition_menu";
import { ColDef } from "@/components/datatable";


const useTeamNutritionTemplateTableHeaders = (): ColDef<INutritionTemplate>[] => {

    const { t } = useTranslation();

    const headers: ColDef<INutritionTemplate>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '30%',
            sortable: true
        },
        {
            field: 'created_at',
            headerName: t('components.dataTable.headers.dateAdded'),
            width: '15%',
            render: (row: INutritionTemplate) => new Date(row.created_at).toLocaleDateString()
        },
        {
            field: 'shared_by',
            headerName: t('components.dataTable.headers.sharedBy'),
            width: '15%',
            render: (row: INutritionTemplate) => capitalize(row.shared_by ?? '')
        },
        {
            field: 'actions',
            headerName: '',
            width: '10%',
            render: (row: INutritionTemplate) => <TemplateMenu row={row} />
        }
    ];

    return headers;
}

export default useTeamNutritionTemplateTableHeaders;