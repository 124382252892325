import { FC } from "react";
import { useTranslation } from "react-i18next";

// Components
import Button from "@/components/button";
import { Box, Skeleton } from "@mui/material";


interface _ChartCardProps {
    metric: string;
    totals?: TotalValues;
    lastMenstruation?: string;
    isLoading?: boolean;
    onShowChart?: () => void;
}

export interface TotalValues {
    total: string|number;
    daysLogged: number;
}

const ChartCard: FC<_ChartCardProps> = ({ 
    metric,
    totals,
    lastMenstruation,
    isLoading = false,
    onShowChart
}) => {

    const { t } = useTranslation();
    const hideShowChartButton = metric === 'menstruation';
    const averageText = () => {
        switch (metric) {
            case 'steps': return totals?.total ? t('metrics.steps', { count: totals?.total as number }) : '-';
            case 'cardio': return totals?.total ? t('timeDate.minute', { count: totals?.total as number }) : '-';
            case 'sleepTime': return totals?.total ? t('timeDate.hour', { count: Number(totals?.total) }) : '-';
            case 'sleepQuality': return totals?.total ? t('metrics.rating', { value: totals?.total }) : '-';
            case 'muscleSoreness': return totals?.total ? t('metrics.rating', { value: totals?.total }) : '-';
            case 'energyLevel': return totals?.total ? t('metrics.rating', { value: totals?.total }) : '-';
            case 'rhr': return totals?.total ? t('metrics.bpmValue', { value: totals?.total ?? '-' }) : '-';
            case 'hrv': return totals?.total ? t('metrics.msValue', { value: totals?.total ?? '-' }) : '-';
            case 'bloodGlucose': return totals?.total ? t('metrics.mmolValue', { value: totals?.total ?? '-' }) : '-';
            case 'bloodPressure': return totals?.total ? t('metrics.mmhgValue', { value: totals?.total ?? '-' }) : '-';
            case 'menstruation': return lastMenstruation ? t('timeDate.day_ago', { count: Number(lastMenstruation) }) : '-';
            default: return '';
        }
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start" height="114px" width="100%" padding="10px 16px 16px 16px" boxSizing="border-box" borderRadius="6px" border="solid 1px var(--border-subtle-01)" marginBottom="16px" sx={{bgcolor: 'var(--layer-01)'}}>
                        
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" marginBottom="8px">
                {isLoading ?
                    <Skeleton variant="rounded" width="100px" height="18px" sx={{marginBottom: '20px'}} /> :
                    <span className="body-02-compact" style={{textAlign: 'left', padding: '7px 0'}}>{t(`metrics.${metric}`)}</span>}
                    {!hideShowChartButton && <Button 
                        kind="ghost"
                        size="small"
                        label={t('components.buttons.showChart')}
                        minWidth={false}
                        disabled={!onShowChart}
                        onClick={() => onShowChart!()} />}
            </Box>
            
            {isLoading ?
                <Skeleton variant="rounded" height="28px" width="100px" sx={{marginBottom: '4px'}} /> :
                <span className="heading-05" style={{marginBottom: '4px', whiteSpace: 'nowrap'}}>{averageText()}</span>}

            {totals && isLoading ?
                <Skeleton variant="rounded" width="100px" height="16px" /> :
                (totals && <span className="helper-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.chartCard.activity.daysLogged', {count: totals.daysLogged})}</span>)}

        </Box>
    )
}

export default ChartCard;