import { z } from 'zod';
import ruleset from '@/_helpers/ruleset';


export interface IActivePhaseForm {
    start: string,
}

export const activePhaseSchema = z.object({
    start: ruleset.required,
});