import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { Options } from "@/components/menu";
import { ApiError } from "@/interfaces/api/error";
import { ITemplateAssignManyForm } from "@/interfaces/template/assign_form";
import { ICheckinTemplate } from "@/interfaces/checkin_template/checkin_template";
import { useAssignCheckinTemplatesMutation, useCopyCheckinTemplateMutation, useDeleteCheckinTemplateMutation, useSetDefaultCheckinTemplateMutation } from "@/repositories/checkin_template";

// Components
import { Box } from "@mui/material";
import DefaultModal from "./default_modal";
import OverflowMenu from "@/components/overflow_menu";
import useBulkActions from "@/components/datatable/use_bulk_actions";
import CopyTemplateModal from "@/pages/templates/components/copy_modal";
import AssignTemplateModal from "@/pages/templates/components/assign_modal";
import DeleteTemplateModal from "@/pages/templates/components/delete_modal";


interface _CheckinMenuProps {
    row: ICheckinTemplate;
}

const CheckinMenu: FC<_CheckinMenuProps> = ({
    row
}) => {

    const { t } = useTranslation();
    const { showBulkActions } = useBulkActions();
    const [open, setOpen] = useState<string|null>(null);
    const [copyTemplate, { isLoading: isCopying }] = useCopyCheckinTemplateMutation();
    const [assignTemplates, { isLoading: isAssigning }] = useAssignCheckinTemplatesMutation();
    const [makeDefault, { isLoading: isMakingDefault }] = useSetDefaultCheckinTemplateMutation();
    const [deleteTemplate, { isLoading: isDeleting }] = useDeleteCheckinTemplateMutation();

    const isTeam: boolean = row.type === 'team';
    const disableMenu: boolean = showBulkActions;
    const disableMakeDefault = row.default == true || row.status === 'in_draft' || isTeam;
    const disableDelete: boolean = row.default == true || row.shared || row.status === 'in_use';
    const options: Options[] = [
        {name: t('components.menuItems.assignTemplate'), disabled: disableMenu || row.draft || isTeam, action: () => setOpen('assign')},
        {name: t('components.menuItems.copyTemplate'), disabled: disableMenu, action: () => setOpen('copy')},
        {name: t('components.menuItems.makeDefault'), disabled: disableMakeDefault, action: () => setOpen('default')}
    ]

    const handleCopy = () => {
        copyTemplate(row.id).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.template.copied.title'), message: t('notifications.template.copied.message', {name: row.name})});
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleAssignMany = (data: ITemplateAssignManyForm) => {
        assignTemplates({id: row.id, data: data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.template.assigned.title', {count: data.clients.length}), 
                message: <Trans 
                    i18nKey="notifications.template.assigned.message" 
                    values={{name: row.name, count: data.clients.length}}
                    components={{bold: <strong />}}
                />});
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleMakeDefault = () => {
        makeDefault(row.id).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.template.madeDefault.title'), 
                message: <Trans 
                    i18nKey="notifications.template.madeDefault.message" 
                    values={{name: row.name}}
                    components={{bold: <strong />}}
                />});
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleDelete = () => {
        deleteTemplate(row.id).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.template.deleted.title', {count: 1}), 
                message: <Trans 
                    i18nKey="notifications.template.deleted.message" 
                    values={{name: row.name, count: 1}}
                    components={{bold: <strong />}}
            />
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Box display="flex" justifyContent="flex-end">
            <OverflowMenu 
                position={{horizontal: 'right', vertical: 'bottom'}}
                options={options}
                deleteLabel={t('components.menuItems.delete')}
                disableDelete={disableMenu || disableDelete}
                disabled={disableMenu}
                onDelete={isTeam ? undefined : () => setOpen('delete')}
                />
            {open === 'copy' && <CopyTemplateModal
                open={open === 'copy'}
                onClose={() => setOpen(null)}
                name={row.name}
                isLoading={isCopying}
                onCopy={handleCopy}
                />}
            {open === 'assign' && <AssignTemplateModal
                open={open === 'assign'}
                onClose={() => setOpen(null)}
                id={row.id}
                name={row.name}
                isLoading={isAssigning}
                onAssign={handleAssignMany}
                />}
            {open === 'default' && <DefaultModal
                open={open === 'default'}
                onClose={() => setOpen(null)}
                name={row.name}
                isLoading={isMakingDefault}
                onSubmit={handleMakeDefault}
                />}
            {open === 'delete' && <DeleteTemplateModal
                open={open === 'delete'}
                onClose={() => setOpen(null)}
                name={row.name}
                isLoading={isDeleting}
                onDelete={handleDelete}
                />}
            
        </Box>
    )
}

export default CheckinMenu;