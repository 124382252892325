import { BaseSyntheticEvent, useEffect, useState } from "react";

// Styles
import { Checkmark, ChevronDown } from "@carbon/icons-react";

// Components
import Tag from "@/components/tag";
import { Select, FormControl, MenuItem, ListItemIcon, SelectChangeEvent, Box, FormControlOwnProps } from "@mui/material";

export interface ISelectItem {
  [key: string]: any;
}

interface FilterSelectProps {
  // Label for the select
  label: string;
  // Tab index for accessibility
  tabIndex?: number;
  // If set, the select will be disabled
  disabled?: boolean;
  // Value of the selected items
  selectedItems: string[];
  // Items to be shown in the dropdown
  items: ISelectItem[];
  // If set, the key of the item to be used as the value, e.g. "id"
  itemKey?: string;
  // If set, the value will be shown in the dropdown, e.g. "label (value)"
  itemLabel?: string;
  // If set, the value will be returned, e.g. "value"
  itemValue?: string;
  // Optional loader for items if they are derived from an API
  renderLabel?: (item: ISelectItem) => string;
  // Optional on change event
  onChange: (value: any) => void;
  sx?: FormControlOwnProps['sx'];
}

const FilterSelect: React.FC<FilterSelectProps>  = ({
  label,
  tabIndex = -1,
  disabled = false,
  selectedItems,
  items,
  itemKey = 'id',
  itemLabel = 'label',
  itemValue = 'value',
  renderLabel,
  onChange,
  sx,
}) => {

  const [value, setValue] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const valueInRange = value.every(v => items.some(i => i[itemKey] === v));

  const styles = {
    width: 'auto',
    minWidth: 'fit-content',
    textAlign: 'left',
    '& .MuiSelect-select': {
      paddingRight: '16px !important'
    },
  }

  const CustomArrowIcon: React.FC = (props) => (
    <ChevronDown {...props} />
  );

  const handleOpen = (e: BaseSyntheticEvent) => {
    if (e.target.tagName !== 'DIV' && e.target.tagName !== 'SPAN') return;
    setOpen(true);
  };

  const handleChange = (e: SelectChangeEvent<string[]>) => {
    const newValue = e.target.value as string[];
    setValue(newValue);

    const selectedItems = newValue.map(value => items.find(item => item[itemKey] === value)).filter(item => item !== undefined);
    const itemValues = selectedItems.map(item => item?.[itemValue]);
    onChange(itemValues);
  }

  useEffect(() => {
    setValue(selectedItems);
  }, [selectedItems]);

  return (
    <FormControl sx={{...sx}}>
        
      {/* Select */}
      <Select
        className="FilterInput"
        sx={styles}
        multiple
        // Checks if the value used exists in the items arrray (useful when parent can change items list)
        value={valueInRange ? value : []}
        onChange={handleChange}
        open={open}
        onOpen={(e) => handleOpen(e)}
        onClose={() => setOpen(false)}
        displayEmpty
        tabIndex={tabIndex}
        autoComplete="true"
        disabled={disabled}
        IconComponent={CustomArrowIcon}
        renderValue={(selected) => {
          const item = items.find(item => item[itemKey] === selected[0]);
          return <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', position: 'relative' }}>
            {item ? 
            <Tag
              label={`${selected.length}`}
              sx={{
                backgroundColor: 'var(--tag-high-contrast-background)', 
                color: 'var(--tag-high-contrast-color)',
                mx: '4px'
              }}
              /> : <Box width="0" />}
            <span className="body-02-compact">{label}</span>
          </Box>
        }}
        MenuProps={{
          sx: {
            transform: `translateY(4px) !important`,
            '& .MuiMenu-list': {
              minWidth: 'unset',
              maxHeight: '320px',
              borderRadius: '6px',
              border: 'solid 1px var(--focus)',
            }
          }
        }}
        >
        {items.map((item) => (
          <MenuItem key={item[itemKey]} value={item[itemKey]}>
            <span className="body-02">{renderLabel?.(item) ?? item[itemLabel]}</span>
            <Box sx={{flexGrow: 1}} />
            <ListItemIcon sx={{minWidth: 'unset !important'}}>
              {value.includes(item[itemKey]) && <Checkmark />}
            </ListItemIcon>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterSelect;