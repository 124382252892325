import { useTranslation } from "react-i18next";
import { Control, useController } from "react-hook-form";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";

// Styles
import { Close } from "@carbon/icons-react";

// Components
import Button from "@/components/button";
import IconButton from "@/components/icon_button";
import { Box, InputAdornment, TextField } from "@mui/material";


interface IFileUploaderProps {
    size?: 'small' | 'medium' | 'large';
    name: string;
    control: Control<any>;
    label?: string;
    tabIndex?: number;
    disabled?: boolean;
}

const FileUploader: FC<IFileUploaderProps> = ({
    size = 'small',
    name,
    control,
    label,
    tabIndex = -1,
    disabled = true,
}) => {

    const { t } = useTranslation();
    const { field, fieldState } = useController({name, control});

    const [fileSrc, setFileSrc] = useState('');
    const [fileName, setFileName] = useState('');
    const inputRef = useRef<HTMLInputElement|null>(null);

    const classes = {
        'FileUploader': true,
        'FileUploader-small': size == 'small',
        'FileUploader-medium': size == 'medium',
        'FileUploader-large': size == 'large',
        'FileUploader-clear': field.value?.length >= 1
      }
    
    const validClasses = Object.entries(classes)
        .filter(([,v]) => !!v)
        .map(([k,]) => k)
        .join(' ')

    useEffect(() => {
        if (!field.value || fileSrc) return;
        setFileName(extractFileName());
    }, [field.value])

    const onSelectFile = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];

            // If the file is not a video, reject it
            // if (!file.type.includes('video')) {
            //     return;
            // }

            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setFileSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(file);

            const result = await new Promise((resolve) => {
                if (!file) return;
                resolve(URL.createObjectURL(file))
            });
            
            setFileName(file.name);
            setFileSrc(result as string);
            field.onChange(result)
            field.onBlur()
        }
    }

    const deleteFile = () => {
        setFileSrc('');
        field.onChange('')
    }

    const extractFileName = (): string => {
        if (!field.value) return '';
        try {
          const urlObj = new URL(field.value);
          const pathname = urlObj.pathname;
          const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
          return fileName;
        } catch (error) {
          return '';
        }
      }

    if (!field.value) return (
        <>
            <Button
                kind="tertiary"
                size="small"
                label={t('components.buttons.uploadVideo')}
                disabled={disabled}
                onClick={() => inputRef.current?.click()}
                sx={{marginTop: '22px'}}
                />
            <input 
                ref={inputRef}
                name={name} 
                onChange={onSelectFile}
                multiple={false} 
                accept="video/*" 
                type='file' 
                hidden 
                onClick={(e) => {e.currentTarget.value = ''}} 
            />
        </>
    )


    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%'}}>
            {label && <span className="label-text-02" style={{marginBottom: '0.5em', color: 'var(--text-secondary)'}}>{label}</span>}

            {/* // NOTE: errors not showing for some reason, but the error is being thrown */}
            <TextField
                className={validClasses}
                value={fileName}
                error={fieldState.invalid}
                tabIndex={tabIndex}
                autoComplete="true"
                disabled={true}
                helperText={fieldState.error?.message}
                InputProps={{
                    endAdornment: fileName.length >= 1 && (
                    <InputAdornment position="end">
                        <IconButton 
                            kind="ghost"
                            size={size}
                            icon={<Close />}
                            disabled={disabled}
                            onClick={deleteFile}
                        />
                    </InputAdornment>
                    )
                }}
            />
        </Box>
    );
}

export default FileUploader;