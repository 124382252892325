import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

// Constants
import { checkinDayOptions, checkinFrequencyOptions, paymentScheduleOptions } from "@/_constants/client";

// Services and interfaces
import { selectCurrency } from "@/store/settings";
import { useGetCheckinTemplatesQuery } from "@/repositories/checkin_template";

// Components
import { Box } from "@mui/material";
import NumberInput from "@/components/number_input";
import SelectInput from "@/components/select_input";
import MultiselectInput from "@/components/multiselect_input";




const ConvertForm: FC = () => {

    const { t } = useTranslation();
    const currency = useSelector(selectCurrency);
    const { control, watch, getValues, setValue } = useFormContext();

    const { data: checkinTemplates } = useGetCheckinTemplatesQuery();
    const defaultTemplate = checkinTemplates?.find(c => c.default);

    const checkinFrequency = watch('checkin_frequency');
    const checkinDisabled = checkinFrequency === 'none';

    useEffect(() => {
        if (getValues('checkin_template_id') === '' && defaultTemplate) {
            setValue('checkin_template_id', defaultTemplate.id);
        }
    }, [defaultTemplate, getValues, setValue]);

    return (
        <Box display="flex" flexDirection="column" rowGap="16px">
                <NumberInput
                    name='payment_amount'
                    control={control}
                    label={t('inputs.titles.paymentAmount')}
                    placeHolder="100"
                    helperText={currency ? `${currency.label} (${currency.value})` : undefined}
                    decimalPlaces={2}
                />
                <SelectInput
                    name='payment_schedule'
                    control={control}
                    label={t('inputs.titles.paymentSchedule')}
                    items={paymentScheduleOptions}
                    allowEmpty
                />
                <SelectInput
                    name='checkin_frequency'
                    control={control}
                    label={t('inputs.titles.checkinFrequency')}
                    items={checkinFrequencyOptions}
                    onChange={() => setValue('checkin_days', [])}
                    />
                {checkinFrequency === 'weekly' ? 
                    <MultiselectInput
                        name='checkin_days'
                        control={control}
                        label={t('inputs.titles.checkinDay')}
                        items={checkinDayOptions}
                        disabled={checkinDisabled}
                        /> :
                    <SelectInput
                        name='checkin_days'
                        control={control}
                        label={t('inputs.titles.checkinDay')}
                        items={checkinDayOptions}
                        disabled={checkinDisabled}
                        />}
                <SelectInput
                    name='checkin_template_id'
                    control={control}
                    label={t('inputs.titles.checkinTemplate')}
                    items={checkinTemplates?.filter((c) => !c.draft) ?? []}
                    itemLabel="name"
                    itemValue="id"
                    disabled={checkinDisabled}
                    />
        </Box>
    );
}

export default ConvertForm;