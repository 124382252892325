import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

// Components
import { Box } from "@mui/material";
import Checkbox from "@/components/checkbox";
import { IDataMetrics } from "@/interfaces/settings/data_metrics";

const DataMetrics: FC = () => {

    const { t } = useTranslation();
    const { control } = useFormContext<IDataMetrics>();

    return (
        <Box display="flex" flexGrow={1} alignItems="start">

            {/* Settings column 1 */}
            <Box display="flex" flexDirection="column" flexGrow={1} maxWidth="270px" alignItems="start" justifyContent="start">

                {/* Physique Data */}
                <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start">
                    <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.metricsCard.titles.physique')}</span>
                    <Box height="8px" />

                    <Checkbox name="physique.bodyweight" control={control} label={t('components.checkbox.bodyweight')} disabled />
                    <Checkbox name="physique.photos" control={control} label={t('components.checkbox.photos')} disabled />
                </Box>

                {/* Spacer */}
                <Box height="40px" />

                {/* Training Data */}
                <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start">
                    <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.metricsCard.titles.training')}</span>
                    <Box height="8px" />

                    {/* <Checkbox name="training.weight" control={control} label={t('components.checkbox.weight')} disabled />
                    <Checkbox name="training.reps" control={control} label={t('components.checkbox.reps')} disabled /> */}
                    <Checkbox name="training.rir" control={control} label={t('components.checkbox.rir')} />
                    <Checkbox name="training.rpe" control={control} label={t('components.checkbox.rpe')} />
                    <Checkbox name="training.tempo" control={control} label={t('components.checkbox.tempo')} />
                    <Checkbox name="training.set_type" control={control} label={t('components.checkbox.setType')} />
                    <Checkbox name="training.rest_time" control={control} label={t('components.checkbox.restTime')} />
                    {/* <Checkbox name="training.session_performance" control={control} label={t('components.checkbox.sessionPerformance')} /> */}
                </Box>

                {/* Spacer */}
                <Box height="40px" />

                {/* Supplementation */}
                <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start">
                    <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.metricsCard.titles.supplementation')}</span>
                    <Box height="8px" />

                    <Checkbox name="supplementation.supplements" control={control} label={t('components.checkbox.supplements')} />
                </Box>

            </Box>

            {/* Spacer between columns */}
            <Box width="24px" />

            {/* Settings column 2 */}
            <Box display="flex" flexDirection="column" flexGrow={1} maxWidth="540px" alignItems="start" justifyContent="space-between">

                {/* Activity Data */}
                <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start">
                    <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.metricsCard.titles.activity')}</span>
                    <Box height="8px" />

                    <Checkbox name="activity.steps" control={control} label={t('components.checkbox.steps')} />
                    <Checkbox name="activity.cardio" control={control} label={t('components.checkbox.cardio')} />
                </Box>

                {/* Spacer */}
                <Box height="40px" />

                {/* Recovery Data */}
                <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start">
                    <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.metricsCard.titles.recovery')}</span>
                    <Box height="8px" />

                    <Checkbox name="recovery.sleep_time" control={control} label={t('components.checkbox.sleepTime')} />
                    <Checkbox name="recovery.sleep_quality" control={control} label={t('components.checkbox.sleepQuality')} />
                    <Checkbox name="recovery.muscle_soreness" control={control} label={t('components.checkbox.muscleSoreness')} />
                    <Checkbox name="recovery.energy_level" control={control} label={t('components.checkbox.energyLevels')} />
                </Box>

                {/* Spacer */}
                <Box height="40px" />

                {/* Health Data */}
                <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start">
                    <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.metricsCard.titles.health')}</span>
                    <Box height="8px" />

                    <Checkbox name="health.hrv" control={control} label={t('components.checkbox.hrv')} />
                    <Checkbox name="health.rhr" control={control} label={t('components.checkbox.rhr')} />
                    <Checkbox name="health.blood_glucose" control={control} label={t('components.checkbox.bloodGlucose')} />
                    <Checkbox name="health.blood_pressure" control={control} label={t('components.checkbox.bloodPressure')} />
                    <Checkbox name="health.menstrual_cycle" control={control} label={t('components.checkbox.menstrualCycle')} />
                </Box>

            </Box>

        </Box>
    )
}

export default DataMetrics;