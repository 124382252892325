import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

// Constants
import { filterColumns } from "@/_constants/exercise";

// Services and interfaces
import { IExercise } from "@/interfaces/exercise/exercise";
import { useGetExercisesQuery } from "@/repositories/exercise";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box, Container } from "@mui/material";
import DataTable from "@/components/datatable";
import ExerciseModal from "./exercises/exercise_modal";
import useExerciseFilterItems from "./exercises/filter_items";
import SecondaryToolbar from "@/components/secondary_toolbar";
import useExerciseTableHeaders from "./exercises/table_headers";
import NoExercisesFound from "@/components/programme_builder/no_exercises";


const ExerciseListPage: FC = () => {

    const { t } = useTranslation();
    const filters = useExerciseFilterItems();
    const [open, setOpen] = useState<boolean>(false);
    const [exercise, selectExercise] = useState<IExercise|undefined>(undefined)

    const headers = useExerciseTableHeaders();
    const { data: list, isLoading } = useGetExercisesQuery();

    const handleCloseExercise = () => {
        setOpen(false);
        selectExercise(undefined);
    }

    const handleOpenExercise = (exercise: IExercise) => {
        selectExercise(exercise);
        setOpen(true);
    }

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar 
                title={t('pages.library.exercises.title')}
                action1={{
                    label: t('components.buttons.addExercise'),
                    icon: <Add />,
                    onClick: () => setOpen(true)
                
                }}
                />
            {open && <ExerciseModal exercise={exercise} open={open} onClose={handleCloseExercise} />}
            
            <Box height='100%' overflow='auto'>
                <Container sx={{padding: '24px 0'}}>

                    <DataTable
                        data={list}
                        dataKey="id"
                        columns={headers} 
                        hideHeader
                        localPagination
                        filter={{
                            filterBar: {
                                filterItems: filters,
                                columns: filterColumns,
                            }
                        }}
                        filterKeys={["name"]}
                        isLoading={isLoading}
                        noDataMessage={<NoExercisesFound message={t('components.dataTable.noData.noExercises')} />}
                        noDataFoundMessage={<NoExercisesFound />}
                        rowClick={handleOpenExercise}
                    />

                </Container>
            </Box>
            
        </Box>
    )
}

export default ExerciseListPage