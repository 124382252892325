import { FC } from "react";

// Components
import BillingTable from "./billing/table";
import BillingHeader from "./billing/header";
import ActionBar from "./billing/action_bar";
import { Box, Container } from "@mui/material";
import PaymentMethods from "./billing/payment_methods";
import ErrorNotification from "./billing/error_notification";


const BillingPage: FC = () => {

    return (
        <Container>
            <Box display="flex" flexDirection="column" justifyContent="flex-start" textAlign="left">

                {/* Flexible space */}
                <Box sx={{height: 'clamp(30px, 10vh, 70px)'}} />

                <ErrorNotification />
                
                <BillingHeader />

                {/* Spacer */}
                <Box height="40px" />

                <PaymentMethods />

                {/* Spacer */}
                <Box height="4px" />

                <ActionBar />

                {/* Spacer */}
                <Box height="40px" />

                <BillingTable />

                {/* Spacer */}
                <Box height="40px" />

            </Box>
        </Container>
    )
}

export default BillingPage;