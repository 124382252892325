import dayjs from "dayjs";
import { FC, useState } from "react"
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helpers
import { snakeToCamel } from "@/_helpers/text_functions";
import { roundNumber } from "@/_helpers/number_functions";

// Services and interfaces
import { useGetClientDataQuery } from "@/repositories/client_data";

// Components
import DataTable from "@/components/datatable";
import { Box, Container } from "@mui/material";
import Chart from "@/pages/client/components/chart";
import useRecoveryTableHeaders from "./table_headers";
import SecondaryToolbar from "@/components/secondary_toolbar";
import ChartCard, { TotalValues } from "@/pages/client/components/chart_card";


const RecoveryPage: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [chartMetric, setChartMetric] = useState<string>('');
    const headers = useRecoveryTableHeaders();

    const { data: list, isLoading } = useGetClientDataQuery(id!);
    const hasSleepTimeData = list?.some(item => item.sleep_time);
    const hasSleepQualityData = list?.some(item => item.sleep_quality);
    const hasMuscleSorenessData = list?.some(item => item.muscle_soreness);
    const hasEnergyLevelData = list?.some(item => item.energy_level);
    const isRating = chartMetric == 'sleep_quality' || chartMetric == 'muscle_soreness' || chartMetric == 'energy_level'

    const sevenDayAverage = (metric: string, decimalPlaces: number): TotalValues => {
        const now = dayjs();
        const sevenDaysAgo = now.subtract(7, 'day');

        const filteredData = list?.filter(item => {
            const itemDate = dayjs(item.date);
            return itemDate.isAfter(sevenDaysAgo) && item[metric];
          });

        if (!filteredData || filteredData.length == 0) return { total: 0, daysLogged: 0 };

        const total = roundNumber(filteredData?.reduce((acc, item) => acc + item[metric], 0) / filteredData.length, decimalPlaces).toString();
        return { total, daysLogged: filteredData.length };
    }

    const handleOpenChart = (metric: string) => {
        setChartMetric(metric);
        setOpen(true);
    }

    return (
        <Box width='100%' height='100%'>

            <SecondaryToolbar
                kind="primary"
                title={t('pages.client.recovery')}
            />

            <Box sx={{overflowY: 'auto', height: 'calc(100% - 48px)'}}>
            <Container sx={{py: '24px'}}>

                <Box display="flex" columnGap="16px">

                    {headers.find(item => item.field === 'sleep_time') && 
                        <ChartCard 
                            metric="sleepTime" 
                            totals={sevenDayAverage('sleep_time', 0)} 
                            onShowChart={hasSleepTimeData ? () => handleOpenChart('sleep_time') : undefined}
                            />}
                    {headers.find(item => item.field === 'sleep_quality') && 
                    <ChartCard
                        metric="sleepQuality" 
                        totals={sevenDayAverage('sleep_quality', 0)} 
                        onShowChart={hasSleepQualityData ? () => handleOpenChart('sleep_quality') : undefined} 
                        />}
                    {headers.find(item => item.field === 'muscle_soreness') && 
                    <ChartCard 
                        metric="muscleSoreness"
                        totals={sevenDayAverage('muscle_soreness', 1)} 
                        onShowChart={hasEnergyLevelData ? () => handleOpenChart('muscle_soreness') : undefined} 
                        />}
                    {headers.find(item => item.field === 'energy_level') && 
                    <ChartCard 
                        metric="energyLevel" 
                        totals={sevenDayAverage('energy_level', 0)} 
                        onShowChart={hasMuscleSorenessData ? () => handleOpenChart('energy_level') : undefined} 
                        />}
                    {open == true && 
                        <Chart
                            open={open}
                            onClose={() => setOpen(false)}
                            kind={isRating ? 'bar' : 'line'}
                            title={t(`metrics.${snakeToCamel(chartMetric)}`)}
                            data={list ?? []}
                            metric={chartMetric}
                            isRating={chartMetric == 'sleep_quality' || chartMetric == 'muscle_soreness' || chartMetric == 'energy_level'}
                            yLabel={t(`metrics.${snakeToCamel(chartMetric)}`)}
                        />}

                </Box>

                <DataTable
                    data={list}
                    dataKey="id"
                    columns={headers} 
                    hideHeader
                    hideFilter
                    localPagination
                    noDataMessage={t('components.dataTable.noData.noLoggedData')}
                    isLoading={isLoading}
                    />

            </Container>
            </Box>

        </Box>
    )
}

export default RecoveryPage;