import { t } from "i18next";
import { useState, FC, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

// Config
import useClientDetailMenuItems from "@/_configs/client_detail";

// Helpers
import showToast from "@/_lib/toast";

// Services
import { ApiError } from "@/interfaces/api/error";
import { useGetClientQuery } from "@/repositories/client";

// Styles
import { Settings } from "@carbon/icons-react";

// Components
import Tag from "@/components/tag";
import Box from "@mui/material/Box";
import Avatar from "@/components/avatar";
import Tooltip from "@/components/tooltip";
import { Options } from "@/components/menu";
import MenuButton from "@/components/menu_button";
import CheckinPopup from "./checkins/checkin_popup";
import ProfileModal from "./components/client_modal";
import SettingsModal from "./components/settings_modal";
import useCheckinPopup from "./checkins/use_checkin_popup";
import NavSideMenu from "@/components/navigation/side_menu";
import OnboardingNotice from "./components/onboarding_notice";
import LeadFormResponsePanel from "@/pages/common/lead_form_response_panel";

type OpenProps = 'profile'|'settings'|'onboarding'|'prospect'|null

const View: FC = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const [open, setOpen] = useState<OpenProps>(null)
    const menuItems = useClientDetailMenuItems(id!);

    const { data: client, isLoading, isError, error } = useGetClientQuery(id!)
    const { open: checkinOpen, handleSetOpen } = useCheckinPopup();
    
    const back = () => navigate('/clients/active')

    const settingsMenuItems: Options[] = [
        { name: t('components.menuItems.clientProfile'), action: () => setOpen('profile') },
        { name: t('components.menuItems.customiseSettings'), disabled: client?.readonly, action: () => setOpen('settings') },
        { name: t('components.menuItems.viewOnboardingForm'), disabled: true, action: () => setOpen('onboarding') },
        { name: t('components.menuItems.viewLeadGenerationForm'), disabled: !client?.lead_form_response, action: () => setOpen('prospect') }
    ];

    useEffect(() => {
        return () => handleSetOpen(false)
    }, [])

    // if coach doesnt have perm to view, go back to clients list
    useEffect(() => {
        if (!isError) return;
        back()
        showToast({
            type: 'error',
            apiError: (error as ApiError).type
        });
    }, [isError])

    return (
        <Box height='calc(100vh - 40px)' display='flex' overflow='hidden'>
            <NavSideMenu 
                kind='ghost'
                onBack={back}
                avatar={
                    <Avatar 
                        size="small"
                        name={`${client?.first_name} ${client?.last_name}`}
                        src={client?.avatar ?? ''}
                        sx={{mr: 1}}
                    /> 
                }
                adornment={!isLoading && !client?.active ? (
                    <Box ml={8} mt='-8px'>
                        <Tooltip kind='nav' placement='right' title={t('components.client.inactiveText')}>
                            <Tag label={t('components.client.inactive')} colour='grey' />
                        </Tooltip>
                    </Box>
                ) : undefined}
                label={`${client?.first_name} ${client?.last_name}`}
                items={menuItems}
                isLoading={isLoading}
            >
                <Box display='flex' sx={{ '& > *': {
                    flexGrow: 1,
                    color: 'var(--text-secondary) !important'
                } }}>
                    <MenuButton
                        kind='ghost'
                        size='large'
                        label={t('modals.clientSettings.title')}
                        startIcon={<Settings />}
                        options={settingsMenuItems}
                        position={{ horizontal: 'center', vertical: 'top' }}
                        menuWidth="224px"
                    />
                </Box>
            </NavSideMenu>
     
            {/* Main content */}
            <Box display='flex' flexDirection='column' width='100%' height='100%'>
                <Box flexGrow={1} overflow='hidden'>
                    <Outlet />
                </Box>
                {/* Onboarding banner */}
                <OnboardingNotice />
            </Box>

            {checkinOpen && <CheckinPopup />}

            {client && open === 'profile' && <ProfileModal 
                open={open === 'profile'}
                onClose={()=>setOpen(null)}
                client={client}
            />}
            {client && open === 'settings' && <SettingsModal 
                open={open === 'settings'}
                onClose={() => setOpen(null)}
                settings={client.coach_settings}
            />}
            {client?.lead_form_response && open === 'prospect' && <LeadFormResponsePanel
                open={open === 'prospect'}
                onClose={() => setOpen(null)}
                leadformReponseId={client.lead_form_response}
                name={client.full_name}
                />}
        </Box>
    );
}

export default View;