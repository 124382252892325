import { t } from "i18next";
import { IFoodUnit } from "@/interfaces/food/food";
import { ISelectOption } from "@/interfaces/components/select_option";


export const foodUnitOptions: IFoodUnit[] = [
    { label: t('components.select.options.foodServings.gram'), weight: 1 },
    { label: t('components.select.options.foodServings.ounce'), weight: 28.3495 },
    { label: t('components.select.options.foodServings.millilitre'), weight: 1 },
    { label: t('components.select.options.foodServings.fluidOunce'), weight: 29.57352956255 },
];

export const foodLibraryTypeOptions: ISelectOption<string>[] = [
    { label: 'Custom', value: 'custom' },
    // { label: 'Default', value: 'default' },
    { label: 'Team', value: 'team' },
];

export const filterColumns = [
    { label: t('components.dataTable.headers.library'), field: 'library' }
]