import { t } from "i18next";
import { BaseSyntheticEvent, FC, ReactNode, useState } from "react";

// Styles
import { Checkmark, ChevronDown, ChevronUp } from "@carbon/icons-react";

// Components
import MenuItem from "@mui/material/MenuItem";
import { MenuProps as MuiMenuProps, Menu as MuiMenu, Box } from "@mui/material";

interface _Option {
    name?: string;
    options?: _Option[];
    disabled?: boolean;
    selected?: boolean;
    render?: (() => ReactNode);
    action: ((e?: BaseSyntheticEvent) => void);
}

interface _FilterMenuProps {
    size?: 'extra-small'|'small'|'medium'|'large';
    position?: { horizontal: 'center'|'left'|'right'|number, vertical: 'bottom'|'center'|'top'|number };
    options: _Option[];
    deleteLabel?: string;
    divider?: boolean;
    closeOnClick?: boolean;
    sx?: MuiMenuProps['sx'];
}

const FilterMenu: FC<_FilterMenuProps> = ({
    size = 'small',
    position = { horizontal: 'right', vertical: 'bottom' },
    options,
    divider,
    closeOnClick = true,
    sx,
}) => {

    const menuItemClasses = {
        'Menu__item-extra-small': size === 'extra-small',
        'Menu__item-small': size === 'small',
        'Menu__item-medium': size === 'medium',
        'Menu__item-large': size === 'large',
    }

    const validMenuItemClasses = Object.entries(menuItemClasses)
        .filter(([,v]) => !!v)
        .map(([k,]) => k)
        .join(' ')

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedMenu, setSelectedMenu] = useState<string | null>(null);
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [subMenuPosition, setSubMenuPosition] = useState<{ top: number; left: number, right: number, bottom: number, width: number, height: number } | null>(null);
    const subMenuOptions = options.find(opt => opt.name === selectedMenu)?.options || [];

    const handleFilterMenuOpen = (event: BaseSyntheticEvent|React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSubMenuOpen = (event: BaseSyntheticEvent, filter: string) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setSubMenuAnchorEl(event.currentTarget);
        setSelectedMenu(filter);
        setSubMenuPosition(rect);
    };
    
    const handleSubMenuClose = () => {
        setSubMenuAnchorEl(null);
        setSelectedMenu(null);
    };

    const handleClick = (event: BaseSyntheticEvent, option: _Option): void => {
        option.action(event);
        option.options && handleSubMenuOpen(event, option.name!);
        closeOnClick && setAnchorEl(null);
    }

    const handleClose = (e: BaseSyntheticEvent) => {
        e.stopPropagation();
        setAnchorEl(null);
    }

    return (
        <> 
            <Box display="flex" alignItems="center" onClick={(e) => handleFilterMenuOpen(e!)} sx={{mr: '6px', ml: '4px', cursor: 'pointer'}}>
                <span className="body-02-compact" style={{marginRight: '8px'}}>{t('general.other')}</span>
                {anchorEl ? <ChevronUp color="rgba(0, 0, 0, 0.54)" style={{paddingTop: '2px'}} /> : <ChevronDown color="rgba(0, 0, 0, 0.54)" style={{paddingTop: '2px'}} />}
            </Box>
            <MuiMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableAutoFocusItem
                anchorOrigin={position}
                transformOrigin={{horizontal: position.horizontal, vertical: position.vertical == 'top' ? 'bottom' : 'top'}}
                slotProps={{
                    paper: {
                        sx: {
                            zIndex: 20000,
                            boxShadow: `0px ${position.vertical === 'bottom' ? '2px' : '0px'} 6px 0px rgba(0, 0, 0, 0.30)`,
                            minWidth: 180,
                            bgcolor: 'var(--layer-01)',
                            borderRadius: '6px',
                            transform: `translateY(${position.vertical === 'bottom' ? '6px' : '-2px'}) !important`,
                            '& .MuiMenu-list': {
                                borderRadius: '6px',
                                border: 'solid 1px var(--focus)'
                            },
                            ...sx
                        }
                    }
                }}
                >
                {options.map((opt, i) => (
                    <MenuItem
                        className={validMenuItemClasses + ` Menu__item ${opt.name == selectedMenu ? 'Menu__item-active' : ''}`}
                        key={opt.name}
                        onClick={(event) => handleClick(event, opt)}
                        divider={divider && i !== options.length - 1}
                        disabled={opt.disabled}
                        >
                        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                            { opt.render ? <opt.render/> : <span className="body-02-compact">{opt.name}</span>}
                            { opt.selected && <Checkmark />}
                        </Box>
                    </MenuItem>
                ))}
            </MuiMenu>

            {/* Submenu */}
            {selectedMenu &&
                <MuiMenu
                    anchorEl={subMenuAnchorEl}
                    open={Boolean(subMenuAnchorEl)}
                    onClose={handleSubMenuClose}
                    anchorOrigin={position}
                    transformOrigin={{horizontal: position.horizontal, vertical: position.vertical == 'top' ? 'bottom' : 'top'}}
                    slotProps={{
                        paper: {
                            sx: {
                                zIndex: 20000,
                                boxShadow: `0px 0px 6px 0px rgba(0, 0, 0, 0.30)`,
                                minWidth: 180,
                                bgcolor: 'var(--layer-01)',
                                borderRadius: '6px',
                                top: `calc(${subMenuPosition?.top}px - 9px) !important`,
                                left: `calc(${subMenuPosition?.left}px - ${subMenuPosition?.width}px - 3px) !important`,
                                '& .MuiMenu-list': {
                                borderRadius: '6px',
                                border: 'solid 1px var(--focus)'}
                            }
                        }
                    }}
                    >
                    {subMenuOptions.map((opt, i) => (
                        <MenuItem
                            className={validMenuItemClasses + ` Menu__item ${opt.name == selectedMenu ? 'Menu__item-active' : ''}`}
                            key={opt.name}
                            onClick={(event) => handleClick(event, opt)}
                            divider={divider && i !== options.length - 1}
                            disabled={opt.disabled}
                            >
                            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                { opt.render ? <opt.render/> : <span className="body-02-compact">{opt.name}</span>}
                                { opt.selected && <Checkmark />}
                            </Box>
                        </MenuItem>
                    ))}
                </MuiMenu>
                }
        </>
    );
};

export default FilterMenu;