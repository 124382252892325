import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import { IProgrammeWorkout } from '@/interfaces/programme_workout/programme_workout';
import { IProgrammeWorkoutForm } from '@/interfaces/programme_workout/programme_workout_form';


export const programmeWorkoutApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getProgrammeWorkouts: build.query<IProgrammeWorkout[], void>({
            query: () => ({ url: '/catalogues/workouts', method: 'get' }),
            transformResponse: (response: IAPIResponse<IProgrammeWorkout[]>) => response.data.sort((a, b) => a.name.localeCompare(b.name)),
            providesTags: ['Catalogues.workout.list'],
            keepUnusedDataFor: 1800
        }),
        getProgrammeWorkout: build.query<IProgrammeWorkout, string>({
            query: (id) => ({ url: `/catalogues/workouts/${id}`, method: 'get' }),
            transformResponse: (response: IAPIResponse<IProgrammeWorkout>) => response.data,
        }),
        addProgrammeWorkout: build.mutation<IProgrammeWorkout, Partial<IProgrammeWorkoutForm>>({
            query: (data) => ({ url: '/catalogues/workouts', method: 'post', data }),
            transformResponse: (response: IAPIResponse<IProgrammeWorkout>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(programmeWorkoutApi.util.updateQueryData('getProgrammeWorkouts', undefined, catalogue => {
                    catalogue.push(response.data)
                    catalogue.sort((a, b) => a.name.localeCompare(b.name))
                }))).catch(() => {});
            },
        }),
        updateProgrammeWorkout: build.mutation<IProgrammeWorkout, {id: string, data: IProgrammeWorkoutForm}>({
            query: ({id, data}) => ({ url: `/catalogues/workouts/${id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<IProgrammeWorkout>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(programmeWorkoutApi.util.updateQueryData('getProgrammeWorkouts', undefined, catalogue => {
                    const index = catalogue.findIndex((item) => item.id === response.data.id);
                    catalogue.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteProgrammeWorkout: build.mutation<void, string>({
            query: (id) => ({ url: `/catalogues/workouts/${id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(programmeWorkoutApi.util.updateQueryData('getProgrammeWorkouts', undefined, catalogue=> {
                    return catalogue.filter((item) => item.id !== id)
                }))).catch(() => {});
            },
        }),
        deleteProgrammeWorkouts: build.mutation<void, string[]>({
            query: (ids) => ({ url: `/catalogues/workouts/bulk-delete`, method: 'post', data: {ids: ids} }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(ids, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(programmeWorkoutApi.util.updateQueryData('getProgrammeWorkouts', undefined, catalogue => {
                    return catalogue.filter((item) => !ids.includes(item.id))
                }))).catch(() => {});
            },
        }),
        copyProgrammeWorkout: build.mutation<IProgrammeWorkout, string>({
            query: (id) => ({ url: `/catalogues/workouts/${id}/copy`, method: 'post' }),
            transformResponse: (response: IAPIResponse<IProgrammeWorkout>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(programmeWorkoutApi.util.updateQueryData('getProgrammeWorkouts', undefined, catalogue => {
                    catalogue.push(response.data)
                    catalogue.sort((a, b) => a.name.localeCompare(b.name))
                }))).catch(() => {});
            },
        }),
    }),
})

export const { 
    useGetProgrammeWorkoutsQuery,
    useGetProgrammeWorkoutQuery,
    useLazyGetProgrammeWorkoutQuery,
    useAddProgrammeWorkoutMutation,
    useDeleteProgrammeWorkoutMutation,
    useDeleteProgrammeWorkoutsMutation,
    useUpdateProgrammeWorkoutMutation,
    useCopyProgrammeWorkoutMutation,
} = programmeWorkoutApi;

