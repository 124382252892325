import { FC } from "react"
import { useTranslation } from "react-i18next";

// Context
import { usePhaseContext } from "@/contexts/phase_context";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import Notes from "./notes";
import RightPanel from "@/components/panel/right_panel";


const NotesPanel: FC = () => {
    
    const { t } = useTranslation()
    const { phase, open, setOpen } = usePhaseContext();

    return (
        <RightPanel
            open={open === 'note_panel'}
            onClose={() => setOpen(null)}
            title={t('rightPanel.phase.notes')}
            showClose
            action1={{
                onClick: () => setOpen('note_modal'), 
                label: t('components.buttons.addNote'), 
                icon: <Add />,
                disabled: phase?.readonly
            }}
            children={<Notes
                phase_id={phase!.id}
                phase_start={phase!.start ?? phase?.expected_start}
                notes={phase!.notes}
            />}
            />
    )
}

export default NotesPanel;