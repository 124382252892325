import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSortable } from "@dnd-kit/sortable";
import { useFormContext, useWatch } from "react-hook-form";

// Interfaces
import { questionTypeOptions } from "@/_constants/question_type";
import { IFormBuilder } from "@/interfaces/form_builder/form_builder";

// Styles
import { Checkbox, CircleOutline, DragVertical, Email, Phone, Star, TextLongParagraph, TrashCan } from "@carbon/icons-react";

// Components
import Toggle from "@/components/toggle";
import Button from "@/components/button";
import { Box, Skeleton } from "@mui/material";
import TextInput from "@/components/text_input";
import IconButton from "@/components/icon_button";
import SelectInput from "@/components/select_input";

interface _FormQuestionProps {
    id: string;
    sectionIndex: number;
    questionIndex: number;
    remove: (index: number) => void;
    isLoading?: boolean;
}

const FormQuestion: FC<_FormQuestionProps> = ({
    id,
    sectionIndex,
    questionIndex,
    remove,
    isLoading = false
}) => {

    const { t } = useTranslation();
    const { attributes, listeners, setNodeRef } = useSortable({ id });
    const { control, formState: { errors }, getValues, setValue } = useFormContext<IFormBuilder>();

    const form = useWatch({ control, name: 'form' });
    const questions = useWatch({ control, name: `form.${sectionIndex}.questions` });
    const question = useWatch({ control, name: `form.${sectionIndex}.questions.${questionIndex}` });
    const disabled = form[sectionIndex].required;

    useEffect(() => {
        if (['select', 'multiselect'].includes(question.type) && !question.options?.items) {
            setValue(`form.${sectionIndex}.questions.${questionIndex}.options`, { items: [''], multiple: question.type === 'multiselect' });
        } else if (!['select', 'multiselect'].includes(question.type) && question.options) {
            setValue(`form.${sectionIndex}.questions.${questionIndex}.options`, undefined);
        }
    }, [question.type, question.options, question])

    const addOption = () => {
        const items = [...getValues(`form.${sectionIndex}.questions.${questionIndex}.options.items`), ''];
        setValue(`form.${sectionIndex}.questions.${questionIndex}.options.items`, items);
    };

    const removeOption = (index: number) => {
        const options = [...getValues(`form.${sectionIndex}.questions.${questionIndex}.options.items`)];
        options.splice(index, 1);
        setValue(`form.${sectionIndex}.questions.${questionIndex}.options.items`, options);
    };

    return (
        <Box ref={setNodeRef} className="Builder__content-question">

            {/* Question header */}
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="4px">

                {/* Question index */}
                {isLoading ? <Skeleton variant="rounded" width={200} height={18} /> :
                <span className="heading-07-compact">{t('components.formBuilder.section.question', { number: questionIndex + 1 })}</span>}

                {isLoading ? <Box height="32px" /> : <Box display="flex" alignItems="center">
                    <SelectInput
                        name={`form.${sectionIndex}.questions.${questionIndex}.type`}
                        control={control}
                        style="inline"
                        items={questionTypeOptions.filter(option => option.value !== 'email' && option.value !== 'phone')}
                        itemKey="value"
                        disabled={disabled}
                    />

                    <Box width="16px" />

                    <Toggle
                        name={`form.${sectionIndex}.questions.${questionIndex}.required`}
                        control={control}
                        size="small"
                        label={t('components.toggle.required')}
                        disabled={disabled}
                        />

                    <Box width="24px" />

                    {(disabled || form[sectionIndex].questions.length === 1) ?
                        <Box display="flex" alignItems="center">
                            <DragVertical onClick={(e) => e.stopPropagation()} style={{color: 'var(--icon-disabled)', marginRight: '4px'}} />
                        </Box> :
                        <Box {...attributes} {...listeners} display="flex" alignItems="center" sx={{mr: '4px', cursor: 'grab'}}>
                            <DragVertical onClick={(e) => e.stopPropagation()} />
                        </Box>}
                    
                    <IconButton
                        kind="danger-ghost"
                        size="small"
                        icon={<TrashCan />}
                        disabled={questions.length === 1 || disabled}
                        onClick={() => remove(questionIndex)}
                        />

                </Box>}
            </Box>

            
            <TextInput
                name={`form.${sectionIndex}.questions.${questionIndex}.title`}
                control={control}
                placeHolder={t('inputs.placeholders.question')}
                gutter="0"
                isLoading={isLoading}
                disabled={disabled}
                />

            {isLoading ? <Box display="flex" padding="16px 0 16px 16px" marginLeft="16px" borderLeft="solid 1px var(--border-subtle-01)">
                    <Skeleton variant="rounded" width={200} height={18} />
                </Box> :

                <Box display="flex" padding="16px 0 16px 16px" marginLeft="16px" borderLeft="solid 1px var(--border-subtle-01)">

                    {question.type === 'text' && <>
                        <TextLongParagraph style={{marginRight: '8px'}}/>
                        <span className="body-02-compact">{t('components.formBuilder.section.shortAnswer')}</span>
                        </>}
                    {question.type === 'long_text' && <>
                        <TextLongParagraph style={{marginRight: '8px'}}/>
                        <span className="body-02-compact" >{t('components.formBuilder.section.longAnswer')}</span>
                        </>}

                    {question.type === 'email' && <>
                        <Email style={{marginRight: '8px', marginTop: '1px'}}/>
                        <span className="body-02-compact">{t('components.formBuilder.section.email')}</span>
                        </>}
                    {question.type === 'phone' && <>
                        <Phone style={{marginRight: '8px', marginTop: '1px'}}/>
                        <span className="body-02-compact" >{t('components.formBuilder.section.phone')}</span>
                        </>}

                    {['select', 'multiselect'].includes(question.type) && <Box display="flex" flexGrow={1} flexDirection="column" rowGap="16px">
                    
                        {question.options?.items?.map((_, i) => (
                            <Box key={i} display="flex" alignItems="center">
                                {/* Icon in box due to oddity in svg sizing without */}
                                <Box display="flex" alignItems="center" marginRight="8px">
                                    {question.type == 'select' ? 
                                        <CircleOutline /> :
                                        <Checkbox />
                                        }
                                </Box>
                                <TextInput
                                    name={`form.${sectionIndex}.questions.${questionIndex}.options.items.${i}`}
                                    control={control}
                                    placeHolder="Type your question"
                                    gutter="0"
                                    />
                                <IconButton
                                    kind="ghost"
                                    size="small"
                                    icon={<TrashCan />}
                                    disabled={question.options?.items.length === 1}
                                    onClick={() => removeOption(i)}
                                />
                            </Box>))}

                            {/* Add option */}
                            <Box display="flex" alignItems="center">
                            {question.type == 'select' ? 
                                <CircleOutline style={{marginRight: '8px'}} /> :
                                <Checkbox style={{marginRight: '8px'}} />}
                                <Button
                                    kind="ghost"
                                    size="small"
                                    label={t('components.buttons.addOption')}
                                    onClick={addOption}
                                    />
                            </Box>
                            {errors.form?.[sectionIndex]?.questions?.[questionIndex]?.options?.multiple && 
                                    <span 
                                        className="label-text-02" 
                                        style={{color: 'var(--support-error)', textAlign: 'left'}}
                                        >{errors.form?.[sectionIndex]?.questions?.[questionIndex]?.options?.multiple?.message}</span>}
                        </Box>}

                    {question.type === 'rating' && <Box display="flex" columnGap="8px">
                        <Star />
                        <span className="body-02-compact">{t('components.formBuilder.question.rating')}</span>
                        <span className="body-02-compact">{t('components.formBuilder.question.ratingDescription')}</span>
                    </Box>}
                    
                </Box>}

        </Box>
    );
};

export default FormQuestion;
