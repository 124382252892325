import { z } from "zod";
import { t } from "i18next";
import ruleset, { addIssue, decimalRule } from "@/_helpers/ruleset";


export interface ISupplementBuilder {
    id: string;
    team_id: number;
    type: string;
    name: string;
    duration: number;
    focus: string;
    notes: string;
    tags: string[];
    sections: ISection[];
    draft: boolean;
    status: string;
    shared: boolean;
    created_at: string;
}

export interface ISection {
    uuid: string;
    name: string;
    supplements: ISupplement[];
}

export interface ISupplement {
    uuid: string;
    _id: string;
    name: string;
    brand: string;
    amount: number|string;
    unit: string;
    category: string[];
    priority: string;
    link?: string;
    discount_code?: string;
    notes?: string;
}

export const supplementSchema = z.object({
    uuid: ruleset.required,
    _id: ruleset.required,
    name: ruleset.required,
    brand: ruleset.required,
    //REVIEW change when realm changed to decimal
    amount: decimalRule(0),
    unit: ruleset.select,
    priority: ruleset.select,
    category: ruleset.multiselect,
    link: ruleset.linkOptional,
    discount_code: ruleset.stringOptional,
    notes: ruleset.stringOptional,
});

export const supplementsSchema = z.array(supplementSchema).nonempty(t('components.supplementBuilder.section.noSupplementsError'));

export const supplementSectionSchema = z.object({
    name: ruleset.stringOptional,
    uuid: ruleset.required,
    supplements: supplementsSchema
}).superRefine((data, ctx) => {
    if (!data.name) addIssue(ctx, ['name'], t('components.supplementBuilder.errors.noSectionTitle'));
    if (data.name && data.name.length > 40) addIssue(ctx, ['name'], t('inputs.errors.titleTooLong', { length: 40 }));
});

export const supplementSectionsSchema = z.array(supplementSectionSchema)

export const supplementFormSchema = z.object({
    name: ruleset.required,
    focus: ruleset.stringOptional,
    notes: ruleset.stringOptional,
});