import { Navigate } from "react-router-dom";
import ClientList from "@/pages/client/list";
import ActiveTab from "@/pages/client/tabs/active_tab";
import InactiveTab from "@/pages/client/tabs/inactive_tab";


export const clientRoutes = {
  path: "clients",
  element: <ClientList />,
  children: [
    {
      index: true,
      element: <Navigate to="active" replace />,
    },
    {
      path: "active",
      element: <ActiveTab />
    },
    {
      path: "inactive",
      element: <InactiveTab />
    },
  ]
}