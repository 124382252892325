import { FC, useEffect, useState, SyntheticEvent } from "react";
import { Link, useLocation } from 'react-router-dom';

// Components
import { Tabs as MuiTabs, Tab, TabOwnProps } from "@mui/material";


export interface TabsProps {
    tabs: TabProps[];
    initialTab?: string;
}

export interface TabProps {
    label: string;
    link: string;
    sx?: TabOwnProps['sx']
}

const Tabs: FC<TabsProps> = ({
    tabs,
    initialTab,
    // ...props
}) => {

    const location = useLocation();
    const [value, setValue] = useState(initialTab || tabs[0].link);

    useEffect(() => {
        if (location.pathname !== value) setValue(location.pathname);
    }, [location.pathname, value])

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <MuiTabs
            className="Tabs"
            value={value}
            onChange={handleChange}
        >
            {tabs?.map((t) => (
                <Tab
                    value={t.link}
                    className="Tabs__tab"
                    key={t.link}
                    label={t.label}
                    to={t.link} 
                    component={Link}
                    disableRipple
                    sx={{...t.sx}}
                />
            ))}
        </MuiTabs>
    );
}

export default Tabs;