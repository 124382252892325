import baseApi from '@/repositories/base_api';

// Interfaces
import { ITeamForm } from '@/interfaces/team/team_form';
import { IAPIResponse } from '@/interfaces/api/response';
import { ITeamResponse } from '@/interfaces/team/team_response'


export const teamApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getTeam: build.query<ITeamResponse, void>({
            query: () => ({ url: '/team', method: 'get' }),
            transformResponse: (response: IAPIResponse<ITeamResponse>) => response.data,
            providesTags: ['Team'],
        }),
        createTeam: build.mutation<ITeamResponse, ITeamForm>({
            query: (data) => ({ url: '/team', method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<ITeamResponse>) => response.data,
        }),
        updateTeam: build.mutation<ITeamResponse, ITeamForm>({
            query: (data) => ({ url: '/team', method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<ITeamResponse>) => response.data,
        }),
        deleteTeam: build.mutation<void, void>({
            query: () => ({ url: '/team', method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
        }),
        requestTeam: build.mutation<void, void>({
            query: (data) => ({ url: '/team/request', method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
        })
    }),
})

export const { 
    useGetTeamQuery, 
    useCreateTeamMutation, 
    useUpdateTeamMutation, 
    useDeleteTeamMutation,
    useRequestTeamMutation
} = teamApi;

