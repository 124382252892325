import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

// Interfaces
import { ITeamForm } from "@/interfaces/team/team_form";

// Components
import { Box } from "@mui/material";
import TextInput from "@/components/text_input";


const TeamForm: FC = () => {

    const { t } = useTranslation();
    const { control } = useFormContext<ITeamForm>();
    
    return (
        <Box display="flex" flexDirection="column" gap="16px">
            <TextInput 
                name="team_name" 
                control={control} 
                label={t('inputs.titles.teamName')}
                maxLength={50}
                />
        </Box>
    )
}

export default TeamForm;