import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { IExercise } from "@/interfaces/exercise/exercise";
import { useDeleteExerciseMutation } from "@/repositories/exercise";

// Components
import Modal from "@/components/modal/modal";
import { TrashCan } from "@carbon/icons-react";
import IconButton from "@/components/icon_button";
import { ApiError } from "@/interfaces/api/error";


interface DeleteExerciseProps {
    row: IExercise;
}

const DeleteExercise: FC<DeleteExerciseProps> = ({
    row
}) => {

    const [open, setOpen] = useState<boolean>(false);
    const disableMenu: boolean = ['default', 'team'].includes(row.library);

    return (
        <>
            <IconButton
                kind="ghost"
                size="small"
                icon={<TrashCan style={{color: !disableMenu ? 'var(--support-error)' : 'var(--icon-disabled)'}} />}
                disabled={disableMenu}
                onClick={() => setOpen(true)}
                />
            {open && <DeleteExerciseModal
                open={open}
                onClose={() => setOpen(false)}
                exercise={row}
                />}
            
        </>
    )
}

export default DeleteExercise;

interface DeleteExerciseModalProps {
    open: boolean;
    onClose: () => void;
    exercise: IExercise;
}

const DeleteExerciseModal: FC<DeleteExerciseModalProps> = ({
    open,
    onClose,
    exercise
}) => {

    const { t } = useTranslation();
    const [deleteExercise, { isLoading }] = useDeleteExerciseMutation();

    const handleDelete = () => {
        deleteExercise(exercise.id).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.exercise.deleted.title'), message: t('notifications.exercise.deleted.message', {name: exercise.name})});
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.deleteExercise.title', {name: exercise?.name})}
            text={<Trans i18nKey="modals.deleteExercise.text" values={{ name: exercise.name }} />}
            action1={{
                kind: 'danger',
                label: t('components.buttons.delete'),
                loading: isLoading,
                onClick: handleDelete,
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            />
    )
}