
// Pages
import ErrorPage from "@/pages/error";
import FoodListPage from "@/pages/library/food_list";
import ExerciseListPage from "@/pages/library/exercise_list";
import SupplementListPage from "@/pages/library/supplement_list";


export const libraryRoutes = {
  path: "library",
  errorElement: <ErrorPage />,
  children: [
    {
      path: "exercises",
      element: <ExerciseListPage />
    },
    {
      path: "supplements",
      element: <SupplementListPage />
    },
    {
      path: "foods",
      element: <FoodListPage />
    }
  ],
}
