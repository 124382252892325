import { FC } from "react"
import { useTranslation } from "react-i18next";

// Services and interfaces
import IPaymentMethod from "@/interfaces/payment_methods/payment_method";
import { useGetPaymentMethodsQuery } from "@/repositories/payment_methods";

// Components
import { Box } from "@mui/material"
import PaymentMethod from "./payment_method";


const PaymentMethods: FC = () => {

    const { t } = useTranslation();
    const { data: list, isLoading } = useGetPaymentMethodsQuery(undefined);
    const loadingPM = {
        id: 1,
        stripe_id: 'loading',
        brand: 'loading',
        last_four: '****',
        exp_month: '12',
        exp_year: '2099',
        default: true
    };

    return (
        <>
            {/* Title */}
            <span className="heading-07-compact">{t('pages.settings.billing.paymentMethods')}</span>
            <Box height={8} />

            {/* Payment method list */}
            {isLoading ? 
                <PaymentMethod paymentMethod={loadingPM} dataLoading /> :
                list?.map((item: IPaymentMethod) => (
                    <PaymentMethod key={item.id} paymentMethod={item} />
                ))
            }
        </>
    )
}

export default PaymentMethods;