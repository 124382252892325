import { t } from "i18next";
import { Backdrop, Box, CircularProgress } from "@mui/material";

interface FullScreenLoaderProps {
    show: boolean;
    loadingText: string|undefined;
}


const FullScreenLoader = ({show, loadingText}: FullScreenLoaderProps) => {
    return (
        <Backdrop
            sx={{zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={show}
            >
            <CircularProgress size={16} thickness={5} sx={{color: 'var(--text-overlay)'}} />
            <Box width={8} />
            <span className="helper-text-02" style={{color: 'var(--text-overlay)'}}>{loadingText ?? t('general.loading')}</span>
        </Backdrop>
    );
}

export default FullScreenLoader;