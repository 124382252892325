import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Helpers
// import { roundNumber } from "@/_helpers/number_functions";

// Interfaces
import { selectSiteSettingsWeight } from "@/store/settings";
import { IClientData } from "@/interfaces/client/client_data";

// Styles
// import { CaretDown, CaretUp } from "@carbon/icons-react";

// Components
// import { Box } from "@mui/material";
import { ColDef } from "@/components/datatable";


const useMeasurementTableHeaders = (): ColDef<IClientData>[] => {

    const { t } = useTranslation();
    const weightUnit = useSelector(selectSiteSettingsWeight) ?? { value: 'kg' };

    const headers: ColDef<IClientData>[] = [
        {
            field: 'date',
            headerName: t('components.dataTable.headers.name'),
            width: '20%',
            render: (row) => new Date(row.date).toLocaleDateString()
        },
        {
            field: 'bodyweight',
            headerName: t('components.dataTable.headers.weight'),
            width: '20%',
        },
        //TODO To into setting
        {
            field: 'change',
            headerName: t('components.dataTable.headers.change', { unit: weightUnit.value ?? 'kg'}),
            width: '20%'
        }
    ]

    return headers;
}

export default useMeasurementTableHeaders;


// interface _ChangeProps {
//     change: number;
//     weightUnit: string;
// }

// const Change: FC<_ChangeProps> = ({ change, weightUnit }) => {
//     return (
//         <span>{change ? roundNumber(change, 2) : '-'} {weightUnit}</span>
//     )
// }