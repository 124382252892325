import { FC } from "react";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IProspect } from "@/interfaces/prospect/prospect";
import { IProspectForm, prospectFormSchema } from "@/interfaces/prospect/prospect_form";
import { useAddProspectMutation, useUpdateProspectMutation } from "@/repositories/prospects";

// Components
import { FormProvider, useForm } from "react-hook-form";
import ProspectForm from "./prospect_form";
import Modal from "@/components/modal/modal";


interface _ProspectModalProps {
    open: boolean;
    onClose: () => void;
    prospect?: IProspect;
}

const ProspectModal: FC<_ProspectModalProps> = ({
    open,
    onClose,
    prospect,
}) => {

    const { t } = useTranslation();
    const [addProspect, { isLoading: isAddingProspect }] = useAddProspectMutation();
    const [updateProspect, { isLoading: isUpdatingProspect }] = useUpdateProspectMutation();

    const formMethods = useForm<IProspectForm>({
        resolver: zodResolver(prospectFormSchema),
        mode: 'onBlur',
        defaultValues: {
            first_name: prospect?.first_name ?? '',
            last_name: prospect?.last_name ?? '',
            email: prospect?.email ?? '',
            mobile: prospect?.mobile ?? '',
            notes: prospect?.notes ?? '',
            value: prospect?.value ?? '',
            stage: prospect?.stage ?? undefined,
            close_percentage: prospect?.close_percentage ?? undefined
        }
    });
    
    const handleClose = () => {
        formMethods.reset();
        onClose();
    }

    const handleAddProspect = (data: IProspectForm) => {
        addProspect(data).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.prospects.created.title'), message: t('notifications.prospects.created.message', {name: `${data.first_name} ${data.last_name}`})});
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleUpdateProspect = (data: IProspectForm) => {
        if (!prospect) return;
        updateProspect({id: prospect.id, data: data}).unwrap().then((prospect) => {
            showToast({
                type: 'success',
                title: t('notifications.prospects.updated.title'), 
                message: t('notifications.prospects.updated.message', {name: `${prospect.first_name} ${prospect.last_name}`})});
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Modal 
            open={open}
            onClose={formMethods.formState.isDirty ? () => {} : handleClose}
            title={prospect ? t('modals.editProspect') : t('modals.addProspect')}
            children={
                <FormProvider {...formMethods}>
                    <ProspectForm readonly={prospect?.readonly} />
                </FormProvider>}
            action1={{
                label: t('components.buttons.save'),
                loading: isAddingProspect || isUpdatingProspect,
                disabled: !formMethods.formState.isDirty,
                onClick: formMethods.handleSubmit(prospect ? handleUpdateProspect : handleAddProspect)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isAddingProspect || isUpdatingProspect,
                onClick: handleClose
            }}
            />
    )
}

export default ProspectModal;