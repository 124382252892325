import { Navigate } from "react-router-dom";

// Pages
import ErrorPage from "@/pages/error";
import WorkoutListPage from "@/pages/catalogues/workout_list";


export const catalogueRoutes = {
  path: "catalogues",
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Navigate to="/catalogues/workouts" replace />,
    },
    {
      path: "workouts",
      element: <WorkoutListPage />,
    },
  ],
}
