import { FC } from "react"
import { useNavigate } from "react-router-dom";

// Interfaces
import { ICheckinSubmission } from "@/interfaces/checkin_submission/checkin_submission";

// Components
import { Box } from "@mui/material";
import NoCheckins from "./no_checkins";
import CheckinCard from "./checkin_card";
import Tag, { TagProps } from "@/components/tag";


export interface BoardColumn {
    status: 'overdue' | 'due_today' | 'to_do' | 'in_progress' | 'done';
    checkins: ICheckinSubmission[];
    label: string;
    color: TagProps['colour'];
    outlined?: boolean;
}

interface _CheckinColumnProps {
    column: BoardColumn;
    index: number;
    isLoading: boolean;
}

const CheckinColumn: FC<_CheckinColumnProps> = ({
    column,
    index,
    isLoading
}) => {

    const navigate = useNavigate();
    const loadingData = {client: {id: 0, name: '', avatar: ''}, status: 'to_do', created_at: ''};

    const handleClick = (id: number) => {
        navigate(`/clients/${id}/overview`);
    }

    return (
        <Box key={index} display="flex" flex='1 1 0px' width='0' flexDirection="column" maxWidth="284px" rowGap="8px" alignItems="flex-start">

            <Box display="flex" width="100%" height="24px" marginBottom="12px" alignItems="center" justifyContent="space-between">
                <Tag label={column.label} colour={column.color} outlined={column.outlined} />
                {column.checkins.length > 0 && <span className="label-text-01">{column.checkins.length}</span>}
            </Box>

            {isLoading && Array(3).fill({}).map((_, index) => (
                <CheckinCard key={index} checkin={loadingData as ICheckinSubmission} isLoading={isLoading} />
            ))}

            {!isLoading && column.checkins?.length === 0 && <NoCheckins status={column.status} />}

            {column.checkins && column.checkins.map((checkin) => (
                <CheckinCard
                    key={checkin.id}
                    checkin={checkin}
                    isLoading={isLoading}
                    onClick={() => handleClick(checkin.client.id)}
                />
            ))}

        </Box>
    )
}

export default CheckinColumn;