
import { FC, useEffect, useState } from "react";
import { useFormContext, useWatch } from 'react-hook-form';

// Components
import { Box, Container } from "@mui/material";
import FormMenu from '@/components/form_builder/form_menu';
import FormSection from '@/components/form_builder/form_section';


interface _FormProps {
    isLoading?: boolean;
}

const Form: FC<_FormProps> = ({ isLoading = false }) => {

    const { control } = useFormContext();
    const form = useWatch({ control, name: 'form' });
    const [selectedSection, setSelectedSection] = useState<number>(0)

    const handleSelect = (sectionIndex: number) => {
        setSelectedSection(sectionIndex);
    }

    useEffect(() => {
        if (selectedSection === null && form.length > 0) {
            setSelectedSection(0)
        }
    }, [form, selectedSection])

    return (
        <Container sx={{padding: '0px !important', overflow: 'hidden', height: '100%'}}>
            
            <Box className="Builder">

                <FormMenu selectedIndex={selectedSection} onSelectSection={handleSelect} isLoading={isLoading} />

                {form.length > 0 && <FormSection
                    key={form[selectedSection].uuid}
                    section={form[selectedSection]}
                    sectionIndex={selectedSection}
                    isLoading={isLoading}
                    />}

            </Box>
            
        </Container>
    )
}

export default Form;