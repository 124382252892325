import { FC } from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Constants
import { supplementUnitOptions, supplementCategoryOptions, supplementPriorityOptions } from "@/_constants/supplement";

// Services and interfaces
import { ISupplementForm } from "@/interfaces/supplement/supplement_form";

// Components
import { Box } from "@mui/material";
import TextInput from "@/components/text_input";
import SelectInput from "@/components/select_input";
import MultiselectInput from "@/components/multiselect_input";


interface ExerciseFormProps {
    control: Control<ISupplementForm, any>;
    type?: string;
}

const ExerciseForm: FC<ExerciseFormProps> = ({
    control,
    type = 'custom'
}) => {

    const { t } = useTranslation();

    return (
        <Box display="flex" flexDirection="column" rowGap="12px" width="670px">

            <TextInput 
                name="name"
                control={control}
                label={t('inputs.titles.supplementName')}
                placeHolder={t('inputs.placeholders.supplementName')}
                maxLength={50}
                disabled={type !== 'custom'}
                />

            <Box display="flex" columnGap="16px">

                <TextInput 
                    name="brand"
                    control={control}
                    label={t('inputs.titles.brand')}
                    placeHolder={t('inputs.placeholders.supplementBrand')}
                    maxLength={40}
                    disabled={type !== 'custom'}
                    />

                <SelectInput 
                    name="unit"
                    control={control}
                    label={t('inputs.titles.unit')}
                    items={supplementUnitOptions}
                    itemKey="value"
                    disabled={type !== 'custom'}
                    />

            </Box>

            <Box display="flex" columnGap="16px">

                <MultiselectInput 
                    name="category"
                    control={control}
                    label={t('inputs.titles.category')}
                    items={supplementCategoryOptions}
                    itemKey="value"
                    disabled={type !== 'custom'}
                    />

                <SelectInput 
                    name="priority"
                    control={control}
                    label={t('inputs.titles.priority')}
                    items={supplementPriorityOptions}
                    itemKey="value"
                    disabled={type !== 'custom'}
                    />

            </Box>

            <TextInput
                name="link"
                control={control}
                label={t('inputs.titles.link')}
                placeHolder={t('inputs.placeholders.supplementLink')}
                minRows={4}
                disabled={type !== 'custom'}
                />


            <TextInput
                name="notes"
                control={control}
                size="textarea"
                label={t('inputs.titles.notes')}
                placeHolder={t('inputs.placeholders.supplementNotes')}
                minRows={4}
                maxLength={1000}
                disabled={type !== 'custom'}
                />


        </Box>
    )
}

export default ExerciseForm;