import baseApi from '@/repositories/base_api';

// Interfaces
import { IAPIResponse } from '@/interfaces/api/response';
import { ITeamResponse } from '@/interfaces/team/team_response'
import { ITeamMemberForm } from '@/interfaces/team/member_form';


export const teamMemberApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        updateTeamMember: build.mutation<ITeamResponse, {id: number, data: ITeamMemberForm}>({
            query: ({id, data}) => ({ url: `/team/member/${id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<ITeamResponse>) => response.data,
        }),
        deleteTeamMember: build.mutation<ITeamResponse, {id: number, retain: boolean}>({
            query: ({id, retain}) => ({ url: `/team/member/${id}?retain=${retain}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<ITeamResponse>) => response.data,
            invalidatesTags: ['Clients.list'],
        }),
    }),
})

export const { 
    useUpdateTeamMemberMutation, 
    useDeleteTeamMemberMutation,
} = teamMemberApi;

