import { t } from 'i18next';
import { ReactNode } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Components
import { NotificationBody } from '@/components/notification/notification_body';

interface IToastProps {
    type: 'error' | 'success' | 'info' | 'warning';
    apiError?: string | null;
    title?: string | undefined;
    message?: string | ReactNode | undefined;
    action?: {
        label: string;
        onClick: () => void;
    };
}

const showToast = ({ type, apiError = null, title = t('notifications.toast.title'), message, action }: IToastProps) => {
    switch (type) {
        case 'info':
            _infoToast(apiError, title, message, action);
            break;
        case 'success':
            _successToast(apiError, title, message, action);
            break;
        case 'warning':
            _warningToast(apiError, title, message, action);
            break;
        case 'error':
            _errorToast(apiError, title, message, action);
            break;
        default:
            break;
    }
};

function _infoToast(apiError: string|null, title: string, message: string|ReactNode|undefined, action: any) {
    toast.info(
        <NotificationBody apiError={apiError} title={title} message={message} action={action} />,
        {
            autoClose: action ? false : 5000,
            hideProgressBar: action ? true : false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
        }
    );
}

function _successToast(apiError: string|null, title: string, message: string|ReactNode|undefined, action: any) {
    toast.success(
        <NotificationBody apiError={apiError} title={title} message={message} action={action} />,
        {
            autoClose: action ? false : 5000,
            hideProgressBar: action ? true : false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
        }
    );
}

function _warningToast(apiError: string|null, title: string, message: string|ReactNode|undefined, action: any) {
    toast.warning(
        <NotificationBody apiError={apiError} title={title} message={message} action={action} />,
        {
            autoClose: action ? false : 5000,
            hideProgressBar: action ? true : false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
        }
    );
}

function _errorToast(apiError: string|null, title: string, message: string|ReactNode|undefined, action: any) {
    toast.error(
        <NotificationBody apiError={apiError} title={title} message={message} action={action} />,
        {
            autoClose: action ? false : 10000,
            hideProgressBar: action ? true : false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
        }
    );
}

export default showToast;