
import { FC } from "react";
import { useTranslation } from "react-i18next";

// Services and interfaces
import { useLazyGetProgrammeWorkoutQuery } from "@/repositories/programme_workout";
import { IProgrammeWorkout } from "@/interfaces/programme_workout/programme_workout";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import WorkoutTable from "./workout_table";
import Modal from "@/components/modal/modal";
import useBulkActions from "@/components/datatable/use_bulk_actions";


interface _AddWorkoutModalProps {
    open: boolean;
    onClose: () => void;
    onAdd: (exercises: IProgrammeWorkout) => void;
}

const AddWorkoutModal: FC<_AddWorkoutModalProps> = ({
    open,
    onClose,
    onAdd,
}) => {

    const { t } = useTranslation();
    const { selected } = useBulkActions();
    const [getProgrammeWorkout] = useLazyGetProgrammeWorkoutQuery();

    const handleAdd = async () => {
        if (selected.length == 0) return;
        const workout = await getProgrammeWorkout(selected[0]).unwrap();
        if (!workout) return;
        onAdd(workout);
    }

    return (
        <Modal 
            open={open}
            onClose={selected.length ? undefined : onClose} 
            title={t(`modals.programmeWorkout.title_add`)}
            children={<WorkoutTable />}
            action1={{
                kind: 'primary',
                label: t('components.buttons.add'),
                icon: <Add />,
                disabled: !selected.length,
                onClick: handleAdd
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: onClose
            }}
            sx={{'.MuiPaper-root': {width: '100%' ,maxWidth: '1400px'}}}
            />
    )
}

export default AddWorkoutModal;