import { FC } from "react";
import { t } from "i18next";
import { useSelector } from "react-redux";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { selectReferralCode } from "@/store/coach";

// Styles
import { Copy } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import { Trans } from "react-i18next";
import Button from "@/components/button";


const ReferralHeader: FC = () => {

    const url = import.meta.env.VITE_BASE_URL;
    const hasTeam = useSelector(selectHasTeam);
    const code = useSelector(selectReferralCode);

    const handleCopy = () => {
        navigator.clipboard.writeText(`${url}register/details?referral=${code}`);
        showToast({
            type: 'info',
            title: t('notifications.settings.referral.copied'),
        });
    }

    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            justifyContent="center" 
            alignItems="center" 
            borderRadius="6px" 
            border="solid 1px var(--border-subtle-01)"
            padding="32px"
            rowGap="16px"
            sx={{bgcolor: 'var(--layer-01)'}}
            >

            <span className="heading-05">{
                <Trans
                    i18nKey={hasTeam ? 'pages.settings.referral.header.teamReferralText' : 'pages.settings.referral.header.referralText'}
                    components={{ strong: <strong /> }}
                />}</span>

            <Box display="flex" alignItems="center">

                <span className="body-02-compact" style={{color: 'var(--text-secondary)', marginRight: '8px'}}>{t('pages.settings.referral.header.referralLink')}</span>
                <span className="body-02-compact">{`${url}register/details?referral=${code}`}</span>
                
                <Box width="12px" />
                
                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.copy')}
                    endIcon={<Copy />}
                    minWidth={false}
                    onClick={handleCopy}
                    />
            </Box>
        
        </Box>
    )
}

export default ReferralHeader