import { createContext, FC, ReactNode, useContext, useState } from "react";

type View = 'collection'|'grid'

interface IGalleryContext {
    selected: string[];
    setSelected: (value: string[]) => void;
    toggleSelected: (value: string) => void;
    lightboxState: string|[string, string]|null;
    setLightboxState: (value: string|[string, string]|null) => void;
    viewMode: View;
    setViewmode: (value: View) => void;
}

interface ProviderProps {
    children?: ReactNode|ReactNode[]
}

const GalleryContext = createContext<IGalleryContext|undefined>(undefined);

const useGalleryContext = () => {
    const context = useContext(GalleryContext);
    if (!context) throw Error('gallery context not initialized!')
    return context;
};

const GalleryProvider: FC<ProviderProps> = ({ children }) => {

    // selection
    const [selected, setSelected] = useState<string[]>([]);
    const toggleSelected = (i: string) => {
        const newArr = [...selected]
        const idx = newArr.indexOf(i)
        idx === -1 ? newArr.push(i) : newArr.splice(idx, 1)
        setSelected(newArr)
    }

    // lightbox
    const [lightboxState, setLightboxState] = useState<string|[string, string]|null>(null);

    // view mode
    const [viewMode, setViewmode] = useState<View>('collection');

    return (
        <GalleryContext.Provider value={{ 
            selected, 
            setSelected, 
            toggleSelected,
            lightboxState,
            setLightboxState,
            viewMode,
            setViewmode
        }}>
            {children}
        </GalleryContext.Provider>
    );
};
  
export { GalleryProvider, useGalleryContext };
export default GalleryContext;