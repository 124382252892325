import { z } from "zod";
import ruleset from "@/_helpers/ruleset";
import { IProgrammeWorkout } from "./programme_workout";
import { ISettings, programmeSettingsSchema } from "@/interfaces/programme/programme_builder";


export interface IProgrammeWorkoutForm extends IProgrammeWorkout {
    name: string;
    settings: ISettings;
}

export const programmeWorkoutForm = z.object({
    name: ruleset.required,
    settings: programmeSettingsSchema
});