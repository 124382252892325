import { BaseSyntheticEvent, ReactNode } from "react";
import { Control, useController } from "react-hook-form";

// Constants
import { emojiRegex } from "@/_constants/text";

// Components
import { Error } from '@mui/icons-material';
import { TextField, InputAdornment, Box, TextFieldProps, Skeleton } from "@mui/material";

interface TextInputProps {
  name: string;
  control: Control<any>;
  //REVIEW will this work?
  size?: 'small' | 'medium' | 'large' | 'textarea';
  label?: string;
  placeHolder?: string;
  helperText?: string;
  tabIndex?: number;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  maxLength?: number;
  minRows?: number;
  gutter?: string;
  isLoading?: boolean;
  hideErrorMessage?: boolean;
  allowEmoji?: boolean;
  disabled?: boolean;
  sx?: TextFieldProps['sx'];
}

const TextInput: React.FC<TextInputProps>  = ({
  name,
  control,
  size = 'small',
  label,
  placeHolder,
  helperText,
  tabIndex = -1,
  startIcon,
  endIcon,
  maxLength,
  minRows,
  gutter = '12px',
  isLoading = false,
  hideErrorMessage = false,
  allowEmoji = false,
  disabled = false,
  sx
}) => {

  const { field, fieldState } = useController({name, control});
  // const [value, setValue] = useState<string>('');

  const skeletonClasses = {
    'Skeleton__field-small': size == 'small',
    'Skeleton__field-medium': size == 'medium',
    'Skeleton__field-large': size == 'large',
    'Skeleton__field-textarea': size == 'textarea',
  }

  const classes = {
    'TextInput': true,
    'TextInput-small': size == 'small',
    'TextInput-medium': size == 'medium',
    'TextInput-large': size == 'large',
    'TextInput-textarea': size == 'textarea',
  };

  const validSkeletonClasses = Object.entries(skeletonClasses)
      .filter(([,v]) => !!v)
      .map(([k,]) => k)
      .join(' ')

  const validClasses = Object.entries(classes)
      .filter(([,v]) => !!v)
      .map(([k,]) => k)
      .join(' ')

  const handleBeforeInput = (event: BaseSyntheticEvent) => {
    const nativeEvent = event.nativeEvent as InputEvent;
    const inputValue = nativeEvent.data;

    if (!allowEmoji && inputValue && emojiRegex.test(inputValue)) {
      event.preventDefault();
    }
  };

  // useEffect(() => {
  //   if (field.value == null) {
  //     setValue('');
  //     return;
  //   } else {
  //     setValue(field.value);
  //   }
  // },[field.value]);

  return (
    // Width set to 100% to always fill what ever container it is in
    <Box className='input__container' sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: gutter, width: '100%'}}>
      
      <Box display="flex" width="100%">
        {label && (isLoading ? <Skeleton variant="text" width="100" /> : 
          <span className="label-text-02" style={{marginBottom: '0.5em', color: 'var(--text-secondary)'}}>{label}</span>)}
        {maxLength && field.value?.length <= maxLength && <span className="label-text-02" style={{marginBottom: '0.5em', color: 'var(--text-secondary)', marginLeft: 'auto'}}>{field.value?.length}/{maxLength}</span>}
        {maxLength && field.value?.length > maxLength && 
          <span className="label-text-02" style={{marginBottom: '0.5em', color: 'var(--support-error)', marginLeft: 'auto'}}>{field.value?.length}/{maxLength}</span>}

      </Box>

      {isLoading ? <Skeleton className={validSkeletonClasses} variant="rounded" width="100%" /> :
        <TextField
          ref={field.ref}
          className={validClasses}
          value={field.value || ''}
          onChange={(e) => field.onChange(e.target.value)}
          onBlur={field.onBlur}
          onBeforeInput={handleBeforeInput}
          inputProps={{maxLength: maxLength}}
          placeholder={placeHolder}
          tabIndex={tabIndex}
          autoComplete="true"
          multiline={size == 'textarea'}
          rows={size == 'textarea' && minRows ? minRows : 1}
          error={fieldState.invalid}
          helperText={helperText && !fieldState.error?.message ? helperText : hideErrorMessage ? null : fieldState.error?.message}
          disabled={disabled}
          sx={sx}
          InputProps={{
            startAdornment: startIcon && (
              <InputAdornment position="start" sx={{mr: 0, paddingRight: '12px'}}>
                {startIcon}
              </InputAdornment>
            ),
            endAdornment: (endIcon || fieldState.invalid) && (
              <InputAdornment position="end" sx={{ml: 0}}>
                {fieldState.invalid && size != 'textarea' && (<Error color="error" sx={{fontSize: '16px', marginRight: endIcon ? '4px' : '0'}} />)}
                {endIcon}
              </InputAdornment>
            ),
            // When using a textarea, we need to set the min height to match the minRows
            sx: {
              minHeight: size == 'textarea' ? `calc(18px * ${minRows} + 22px)` : 'unset'
            }
          }}
        />}
    </Box>
  );
};

export default TextInput;