import { FC, useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// Services and interfaces
import { selectCoachId } from "@/store/coach";
import { selectHasTeam, selectIsMember, selectMembers, selectSelectedMember, setSelectedMember } from "@/store/team";

// Components
import { Box } from "@mui/material";
import MenuButton from "@/components/menu_button";
import SecondaryToolbar from "@/components/secondary_toolbar";


const ProspectsPage: FC = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const hasTeam = useSelector(selectHasTeam);
    const coachId = useSelector(selectCoachId);
    const members = useSelector(selectMembers);
    const isMember = useSelector(selectIsMember);
    const selectedMember = useSelector(selectSelectedMember);
    const isProspectsList = location.pathname.includes('list');

    const options = members.map(member => ({
        name: member.name,
        action: () => dispatch(setSelectedMember(member.id))
    }));

    const tabs = [
        {
            label: t('tabs.prospects'),
            link: '/prospects/list',
            sx: {width:'120px'}
        },
        {
            label: t('tabs.leadForms'),
            link: '/prospects/lead-forms',
            sx: {width:'120px'}
        }
    ]

    useEffect(() => {
        if (coachId && hasTeam && !selectedMember) {
            dispatch(setSelectedMember(coachId));
        }
    }, [coachId, hasTeam, selectedMember, dispatch]);

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar 
                title={t('pages.prospects.title')}
                tabs={tabs}
                slot={
                    hasTeam && !isMember && isProspectsList && <MenuButton
                        kind="tertiary"
                        size="small"
                        label={selectedMember?.name}
                        options={options}
                        activeOption={selectedMember?.name}
                        />
                }
                />

            <Outlet />
            
        </Box>
    )
}

export default ProspectsPage;