import baseApi from '@/repositories/base_api';
import { programmePlanApi } from './programme_plan';
import { IAPIResponse } from '@/interfaces/api/response';
import { IProgrammePlan } from '@/interfaces/programme/programme_plan';
import { IProgrammeTemplate } from '@/interfaces/programme_template/programme_template';
import { ITemplateAssignForm, ITemplateAssignManyForm } from '@/interfaces/template/assign_form';
import { IProgrammeTemplateForm } from '@/interfaces/programme_template/programme_template_form';


export const programmeTemplateApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getProgrammeTemplates: build.query<IProgrammeTemplate[], void>({
            query: () => ({ url: '/templates/programmes', method: 'get' }),
            transformResponse: (response: IAPIResponse<IProgrammeTemplate[]>) => response.data.sort((a, b) => a.name.localeCompare(b.name)),
            providesTags: ['Templates.programme.list'],
            keepUnusedDataFor: 1800
        }),
        getProgrammeTemplate: build.query<IProgrammeTemplate, string>({
            query: (id) => ({ url: `/templates/programmes/${id}`, method: 'get' }),
            transformResponse: (response: IAPIResponse<IProgrammeTemplate>) => response.data,
        }),
        addProgrammeTemplate: build.mutation<IProgrammeTemplate, IProgrammeTemplateForm>({
            query: (data) => ({ url: '/templates/programmes', method: 'post', data }),
            transformResponse: (response: IAPIResponse<IProgrammeTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(programmeTemplateApi.util.updateQueryData('getProgrammeTemplates', undefined, templates => {
                    templates.push(response.data)
                    templates.sort((a, b) => a.name.localeCompare(b.name))
                }))).catch(() => {});
            },
        }),
        updateProgrammeTemplate: build.mutation<IProgrammeTemplate, {id: string, data: IProgrammeTemplateForm}>({
            query: ({id, data}) => ({ url: `/templates/programmes/${id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<IProgrammeTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(programmeTemplateApi.util.updateQueryData('getProgrammeTemplates', undefined, templates => {
                    const index = templates.findIndex((item) => item.id === response.data.id);
                    templates.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteProgrammeTemplate: build.mutation<void, string>({
            query: (id) => ({ url: `/templates/programmes/${id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(programmeTemplateApi.util.updateQueryData('getProgrammeTemplates', undefined, templates=> {
                    return templates.filter((item) => item.id !== id)
                }))).catch(() => {});
            },
        }),
        deleteProgrammeTemplates: build.mutation<void, string[]>({
            query: (ids) => ({ url: `/templates/programmes/bulk-delete`, method: 'post', data: {ids: ids} }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(ids, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(programmeTemplateApi.util.updateQueryData('getProgrammeTemplates', undefined, templates => {
                    return templates.filter((item) => !ids.includes(item.id))
                }))).catch(() => {});
            },
        }),
        copyProgrammeTemplate: build.mutation<IProgrammeTemplate, string>({
            query: (id) => ({ url: `/templates/programmes/${id}/copy`, method: 'post' }),
            transformResponse: (response: IAPIResponse<IProgrammeTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(programmeTemplateApi.util.updateQueryData('getProgrammeTemplates', undefined, templates => {
                    templates.push(response.data)
                    templates.sort((a, b) => a.name.localeCompare(b.name))
                }))).catch(() => {});
            },
        }),
        assignProgrammeTemplate: build.mutation<IProgrammePlan, ITemplateAssignForm>({
            query: (data) => ({ url: `/templates/programmes/${data.template_id}/assign`, method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<IProgrammePlan>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(programmePlanApi.util.updateQueryData('getProgrammePlans', _.client_id, plans => {
                    plans.push(response.data);
                    plans.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
        assignProgrammeTemplates: build.mutation<IProgrammeTemplate, {id: string, data: ITemplateAssignManyForm}>({
            query: ({id, data}) => ({ url: `/templates/programmes/${id}/bulk-assign`, method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<IProgrammeTemplate>) => response.data,
            invalidatesTags: [{ type: 'Plans.programme.list', id: 'ALL' }]
        }),
        shareProgrammeTemplate: build.mutation<IProgrammeTemplate, string>({
            query: (id) => ({ url: `/templates/programmes/${id}/share`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<IProgrammeTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(programmeTemplateApi.util.updateQueryData('getProgrammeTemplates', undefined, templates => {
                    const index = templates.findIndex((item) => item.id === response.data.id);
                    if (index == -1) return templates;
                    const t = [...templates];
                    t.splice(index, 1, response.data);
                    return t;
                }))).catch(() => {});
            },
        }),
    }),
})

export const { 
    useGetProgrammeTemplatesQuery,
    useGetProgrammeTemplateQuery,
    useLazyGetProgrammeTemplateQuery,
    useAddProgrammeTemplateMutation,
    useUpdateProgrammeTemplateMutation,
    useDeleteProgrammeTemplateMutation,
    useDeleteProgrammeTemplatesMutation,
    useCopyProgrammeTemplateMutation,
    useAssignProgrammeTemplateMutation,
    useAssignProgrammeTemplatesMutation,
    useShareProgrammeTemplateMutation
} = programmeTemplateApi;

