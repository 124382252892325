import { z } from 'zod';
import ruleset, { transformEmptyStringToNull } from '@/_helpers/ruleset';

export interface IProfileForm {
    [key: string]: string | URL | undefined;
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    business_name: string;
    avatar: string|URL|undefined;
    business_logo?: string|URL|undefined;
}

export const profileSchema = z.object({
    first_name: ruleset.name,
    last_name: ruleset.name,
    phone_number: ruleset.phoneNumber,
    email: ruleset.email,
    business_name: ruleset.name,
    avatar: transformEmptyStringToNull(ruleset.blobUrl.nullable().optional()),
    business_logo: transformEmptyStringToNull(ruleset.blobUrl.nullable().optional())
});