import baseApi from '@/repositories/base_api';
import { IFolder } from '@/interfaces/folder/folder';
import { IAPIResponse } from '@/interfaces/api/response';
import { IFolderForm } from '@/interfaces/folder/folder_form';


export const folderApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getFolders: build.query<IFolder[], void>({
            query: () => ({ url: '/folders', method: 'get' }),
            transformResponse: (response: IAPIResponse<IFolder[]>) => response.data.sort((a, b) => a.name.localeCompare(b.name)),
            providesTags: ['Folder.list'],
            keepUnusedDataFor: 1800
        }),
        getFolder: build.query<IFolder, string>({
            query: (id) => ({ url: `/folders/${id}`, method: 'get' }),
            transformResponse: (response: IAPIResponse<IFolder>) => response.data,
        }),
        addFolder: build.mutation<IFolder, IFolderForm>({
            query: (data) => ({ url: '/folders', method: 'post', data }),
            transformResponse: (response: IAPIResponse<IFolder>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(folderApi.util.updateQueryData('getFolders', undefined, folders => {
                    folders.push(response.data)
                    folders.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
        updateFolder: build.mutation<IFolder, {id: string, data: IFolderForm}>({
            query: ({id, data}) => ({ url: `/folders/${id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<IFolder>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(folderApi.util.updateQueryData('getFolders', undefined, folders => {
                    const index = folders.findIndex((item) => item.id === response.data.id);
                    folders.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteFolder: build.mutation<void, string>({
            query: (id) => ({ url: `/folders/${id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(folderApi.util.updateQueryData('getFolders', undefined, folders => {
                    return folders.filter((item) => item.id !== id)
                }))).catch(() => {});
            },
        }),
        deleteFolders: build.mutation<void, string[]>({
            query: (ids) => ({ url: `/folders/bulk-delete`, method: 'post', data: {ids: ids} }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(ids, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(folderApi.util.updateQueryData('getFolders', undefined, folders => {
                    return folders.filter((item) => !ids.includes(item.id))
                }))).catch(() => {});
            },
        }),
        addFolderFile: build.mutation<void, {id: string, file_id: string}>({
            query: ({id, file_id}) => ({ url: `/folders/${id}/files/${file_id}`, method: 'post' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
        }),
    }),
})

export const { 
    useGetFoldersQuery,
    useGetFolderQuery,
    useAddFolderMutation, 
    useUpdateFolderMutation, 
    useDeleteFolderMutation,
    useDeleteFoldersMutation
} = folderApi;

