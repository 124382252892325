import { createSlice } from "@reduxjs/toolkit";

// Apis
import { authApi } from "@/repositories/auth";
import { coachApi } from "@/repositories/coach";

// Interfaces
import { ICoach } from "@/interfaces/coach/coach.tsx";

const initialState: ICoach = {
  id: null,
  team_role: undefined,
  avatar: undefined,
  business_name: undefined,
  business_logo: undefined,
  referral_code: undefined,
  code_used: undefined,
  completed_setup: false,
  show_referral_modal: false,
};

export const coachSlice = createSlice({
  name: "coach",
  initialState,
  reducers: {
    setCompletedSetup: (state) => {
      state.completed_setup = true;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.getUser.matchFulfilled, (state, { payload }) => {
      state.id = payload.coach.id;
      state.team_role = payload.coach.team_role;
      state.avatar = payload.coach.avatar;
      state.business_name = payload.coach.business_name;
      state.business_logo = payload.coach.business_logo;
      state.referral_code = payload.coach.referral_code;
      state.code_used = payload.coach.code_used;
      state.completed_setup = payload.coach.completed_setup;
      state.show_referral_modal = payload.coach.show_referral_modal;
    }).addMatcher(coachApi.endpoints.updateCoach.matchFulfilled, (state, { payload }) => {
      state.avatar = payload.avatar;
      state.business_name = payload.business_name;
      state.business_logo = payload.business_logo;
    }).addMatcher(coachApi.endpoints.deleteAvatar.matchFulfilled, (state) => {
      state.avatar = undefined;
    }).addMatcher(coachApi.endpoints.deleteLogo.matchFulfilled, (state) => {
      state.business_logo = undefined;
    }).addMatcher(coachApi.endpoints.completeReferrals.matchFulfilled, (state, { payload }) => {
      state.show_referral_modal = payload.show_referral_modal;
    }).addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      resetState(state);
    });
  },
  selectors: {
    selectCoach: (state): ICoach => state,
    selectCoachId: (state) => state.id,
    selectReferralCode: (state) => state.referral_code,
    selectHasTrialCode: (state) => state.code_used?.type === 'trial',
    selectHasCompletedSetup: (state) => state.completed_setup,
    selectShowReferralModal: (state) => state.show_referral_modal,
  },
});

const resetState = (state: ICoach) => {
  state.id = null;
  state.team_role = undefined;
  state.avatar = undefined;
  state.business_name = undefined;
  state.business_logo = undefined;
  state.referral_code = undefined;
  state.code_used = undefined;
  state.completed_setup = false;
  state.show_referral_modal = false;
};

export const { 
  selectCoach,
  selectCoachId,
  selectReferralCode,
  selectHasTrialCode,
  selectHasCompletedSetup,
  selectShowReferralModal,
} = coachSlice.selectors;

export const { setCompletedSetup } = coachSlice.actions;

export default coachSlice.reducer;