import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldErrors, useFormContext } from "react-hook-form";

// Services and interfaces
import { IProgrammeBuilder } from "@/interfaces/programme/programme_builder";
import { IProgrammeWorkoutBuilder } from "@/interfaces/programme_workout/programme_workout_builder";

// Styles
import { TextLongParagraph } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import TextInput from "@/components/text_input";
import IconButton from "@/components/icon_button";
import ClickAwayListener from "@mui/material/ClickAwayListener";


interface _SetNoteProps {
    basePath: `plan.${number}.exercises.${number}.sets.${number}`|`exercises.${number}.sets.${number}`;
    exerciseIndex: number;
    setIndex: number;
}

const SetNote: FC<_SetNoteProps> = ({
    basePath,
    exerciseIndex,
    setIndex
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const { control, formState } = useFormContext<IProgrammeBuilder|IProgrammeWorkoutBuilder>();

    const programmeErrors = formState.errors as FieldErrors<IProgrammeBuilder>;
    const programmeWorkoutErrors = formState.errors as FieldErrors<IProgrammeWorkoutBuilder>;

    const hasErrors = () => {
        if (basePath.split('.')[0] == 'exercises') {
            return programmeWorkoutErrors.exercises?.[exerciseIndex]?.sets?.[setIndex]?.notes;
        }
        const workoutIndex = basePath.split('.')[1];
        return programmeErrors.plan?.[Number(workoutIndex)]?.exercises?.[exerciseIndex]?.sets?.[setIndex]?.notes;
    }
    
    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Box>
                <Tooltip 
                    arrow 
                    placement="bottom-start"
                    title={
                        <Box display='flex' flexDirection='column' gap={2} padding="8px 8px 0 8px" >
                            {/* <span className="heading-07-compact">{t('components.programmeBuilder.exercise.setNotes')}</span> */}
                            <TextInput
                                name={`${basePath}.notes`}
                                control={control}
                                label={t('components.programmeBuilder.exercise.setNotes')}
                                size="textarea"
                                placeHolder={t('inputs.placeholders.setNotes')}
                                minRows={4}
                                maxLength={1000}
                                hideErrorMessage
                                />
                        </Box>}
                    slotProps={{
                        arrow: { sx:{ color: 'var(--layer-01)' }},
                        tooltip: {
                            sx: {
                                minWidth: '352px',
                                color: 'var(--text-primary)',
                                bgcolor: 'var(--layer-01)',
                                borderRadius: '6px',
                                boxShadow: '0px 2px 6px 0px #0000004D',
                                '& .MuiTooltip-arrow::before': {
                                    border: 'solid 1px var(--border-subtle-01)',
                                }
                            }
                        }
                    }}
                    onClose={() => setOpen(false)}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    >
                    <IconButton
                        kind={'ghost'}
                        size="small"
                        icon={<TextLongParagraph />}
                        onClick={() => setOpen(!open)}
                        sx={{color: hasErrors() ? 'var(--button-danger-secondary)' : ''}}
                        />
                </Tooltip>
            </Box>
        </ClickAwayListener>
    )
}

export default SetNote;