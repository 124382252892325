import { ReactNode, SyntheticEvent, forwardRef } from 'react';
import { IconButton as MuiIconButton, IconButtonProps, CircularProgress } from '@mui/material';

interface _IconButtonProps extends Omit<IconButtonProps, 'ref'> {
    kind?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'danger' | 'danger-secondary' | 'danger-ghost';
    size?: 'small' | 'medium' | 'large';
    tabIndex?: number;
    icon?: ReactNode;
    onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
    loading?: boolean;
    disabled?: boolean;
    sx?: IconButtonProps['sx'];
}


const IconButton = forwardRef<HTMLButtonElement, _IconButtonProps>(({ 
    kind = 'primary',
    size = 'large',
    tabIndex = -1, 
    icon,
    onClick,
    loading = false,
    disabled = false,
    sx,
    ...rest
}, ref) => {

    const classes = {
        'Button__primary-icon': kind === 'primary',
        'Button__secondary-icon': kind === 'secondary',
        'Button__tertiary-icon': kind === 'tertiary',
        'Button__ghost-icon': kind === 'ghost',
        'Button__danger-icon': kind === 'danger',
        'Button__danger-secondary-icon': kind === 'danger-secondary',
        'Button__danger-ghost-icon': kind === 'danger-ghost',
    }

    const validClasses = Object.entries(classes)
        .filter(([,v]) => !!v)
        .map(([k,]) => k)
        .join(' ')

    const buttonStyles = {
        borderRadius: '4px !important',
        boxShadow: 'none',
    }

    const trigger = (e: SyntheticEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onClick?.(e);
    }

    return (
        <MuiIconButton
            ref={ref}
            tabIndex={tabIndex}
            onClick={trigger}
            disabled={disabled}
            size={size}
            className={validClasses}
            sx={{...buttonStyles, ...sx}}
            {...rest}
            >
            {!loading ? icon : <CircularProgress size="16px" />}
        </MuiIconButton>
    );
});

export default IconButton;