import { FC } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { ITeamMember } from "@/interfaces/team/team";
import { useDeleteTeamMemberMutation } from "@/repositories/team_member";

// Styles
import { TrashCan } from "@carbon/icons-react";

// Components
import Checkbox from "@/components/checkbox";
import Modal from "@/components/modal/modal";


interface _DeleteTeamMemberProps {
    open: boolean;
    onClose: (() => void);
    member: ITeamMember;
}

const DeleteTeamMemberModal: FC<_DeleteTeamMemberProps> = ({
    open,
    onClose,
    member
}) => {

    interface _DeleteMemberForm {
        retain: boolean;
    }

    const { t } = useTranslation();
    const [deleteTeamMember, { isLoading }] = useDeleteTeamMemberMutation();

    const { control, handleSubmit, reset } = useForm<_DeleteMemberForm>({
        mode: 'onBlur',
        defaultValues: {
            retain: false,
        }
    });

    const handleClose = () => {
        onClose();
        reset();
    }

    const submit = (data: _DeleteMemberForm) => {
        deleteTeamMember({id: member.id, retain: data.retain}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.team.memberDeleted.title'), 
                message: t('notifications.team.memberDeleted.message', {name: member.name})})
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
            handleClose();
        });
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={<Trans
                i18nKey="modals.deleteTeamMember.title"
                components={{ strong: <strong /> }}
                values={{ name: member.name }}
            />}
            text={
                <Trans
                    i18nKey="modals.deleteTeamMember.text"
                    components={{ strong: <strong /> }}
                    values={{ name: member.name, number: member.total_clients }}
                />
            }
            children={
                <Checkbox 
                    name="retain"
                    control={control}
                    label={t('components.checkbox.deleteTeamMember')}
                    disabled={isLoading}
                    />
            }
            action1={{
                kind: 'danger',
                label: t('components.buttons.deleteTeamMember'),
                icon: <TrashCan />,
                onClick: handleSubmit(submit),
                loading: isLoading
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: handleClose,
                disabled: isLoading
            }}
            />
    )
}

export default DeleteTeamMemberModal;