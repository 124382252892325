
// Pages
import ErrorPage from "@/pages/error";

// Checkin templates
import CheckinTemplateListPage from "@/pages/templates/checkin_list";
import CheckinTeamTab from "@/pages/templates/checkins/team_tab.tsx";
import CheckinPersonalTab from "@/pages/templates/checkins/personal_tab";

// Programme templates
import ProgrammeTemplateListPage from "@/pages/templates/programme_list";
import ProgrammeTeamTab from "@/pages/templates/programmes/team_tab.tsx";
import ProgrammePersonalTab from "@/pages/templates/programmes/personal_tab";

// Nutrition templates
import NutritionTemplateListPage from "@/pages/templates/nutrition_list";
import NutritionTeamTab from "@/pages/templates/nutrition/team_tab.tsx";
import NutritionPersonalTab from "@/pages/templates/nutrition/personal_tab";

// Supplement templates
import SupplementTemplateListPage from "@/pages/templates/supplement_list";
import SupplementTeamTab from "@/pages/templates/supplements/team_tab.tsx";
import SupplementPersonalTab from "@/pages/templates/supplements/personal_tab";

// Activity templates
import ActivityTemplateListPage from "@/pages/templates/activity_list";
import ActivityTeamTab from "@/pages/templates/activity/team_tab.tsx";
import ActivityPersonalTab from "@/pages/templates/activity/personal_tab";


export const templateRoutes = {
  path: "templates",
  errorElement: <ErrorPage />,
  children: [
    {
      path: "checkins",
      element: <CheckinTemplateListPage />,
      children: [
        {
          path: "personal",
          element: <CheckinPersonalTab />
        },
        {
          path: "team",
          element: <CheckinTeamTab />
        },
      ]
    },
    {
      path: "programmes",
      element: <ProgrammeTemplateListPage />,
      children: [
        {
          path: "personal",
          element: <ProgrammePersonalTab />
        },
        {
          path: "team",
          element: <ProgrammeTeamTab />
        }
      ]
    },
    {
      path: "nutrition",
      element: <NutritionTemplateListPage />,
      children: [
        {
          path: "personal",
          element: <NutritionPersonalTab />
        },
        {
          path: "team",
          element: <NutritionTeamTab />
        }
      ]
    },
    {
      path: "supplements",
      element: <SupplementTemplateListPage />,
      children: [
        {
          path: "personal",
          element: <SupplementPersonalTab />
        },
        {
          path: "team",
          element: <SupplementTeamTab />
        }
      ]
    },
    {
      path: "activity",
      element: <ActivityTemplateListPage />,
      children: [
        {
          path: "personal",
          element: <ActivityPersonalTab />
        },
        {
          path: "team",
          element: <ActivityTeamTab />
        }
      ]
    },
  ],
}
