import { FC, ReactNode, SyntheticEvent, useState } from "react";

// Components
import Button from "@/components/button";
import { Box, Popover } from "@mui/material";
import IconButton from "@/components/icon_button";


interface TooltipProps {
    icon: ReactNode
    children: ReactNode
    label?: string
    onClick?: () => void
}

const PopoverTooltip: FC<TooltipProps> = ({
    icon,
    children,
    label,
    onClick
}) => {
    const [anchor, setAnchor] = useState<HTMLElement|null>(null);
    const handleOpen = (e: SyntheticEvent<HTMLButtonElement>) => setAnchor(e.currentTarget)

    const handleClick = () => {
        setAnchor(null)
        onClick?.();
    }

    return (
        <>
            <IconButton 
                kind='ghost'
                size='small'
                icon={icon}
                onClick={handleOpen}
            />
            <Popover
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={()=>setAnchor(null)}
                anchorOrigin={{horizontal: 'right', vertical: 'center'}}
                transformOrigin={{horizontal: 'left', vertical: 'center'}}
                slotProps={{
                    paper: {
                        sx: {
                            position: 'relative',
                            right: 5,
                            overflow: 'visible',
                            maxWidth: 250,
                            borderRadius: '6px',
                            '&::before': {
                                content: "' '",
                                position: 'absolute',
                                top: '50%',
                                right: '100%',
                                marginTop: '-5px',
                                borderWidth: '5px',
                                borderStyle: 'solid',
                                borderColor: 'transparent white transparent transparent'
                            }
                        }
                    }
                }}
                >
                <Box p={1}>
                    {children}

                    {!!onClick && (<Box display='flex' justifyContent='end'>

                        <Button 
                            label={label}
                            size="small"
                            kind="ghost"
                            minWidth={false}
                            onClick={handleClick}
                        />

                    </Box>)}

                </Box>
            </Popover>
        </>
    )
}

export default PopoverTooltip;