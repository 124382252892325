import dayjs from "dayjs";
import { FC, useMemo } from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Helpers
import { capitalize } from "@/_helpers/text_functions";
import { roundNumber } from "@/_helpers/number_functions";

// Context
import { usePhaseContext } from "@/contexts/phase_context";

// Services and interfaces
import { PhaseWeek } from "@/interfaces/phase/phase";
import { IPhaseNote } from "@/interfaces/phase_note/phase_note";
import { selectSiteSettingsBodyweight } from "@/store/settings";
import { IPhaseMilestone } from "@/interfaces/phase_milestone/phase_milestone";

// Components
import { ColDef } from "@/components/datatable";
import PhaseNotesTooltip from "./notes/notes_tooltip";
import MilestoneTooltip from "./milestones/milestone_tooltip";


const usePhaseTableHeaders = (): ColDef<PhaseWeek>[] => {

    const { t } = useTranslation();
    const bodyweightUnit = useSelector(selectSiteSettingsBodyweight) ?? { value: 'kg' };
    
    const colDefs: ColDef<PhaseWeek>[] = [
        {
            field: 'week',
            headerName: t('components.dataTable.headers.week'),
            render: (row) => <WeekEntry 
                week={row.week} 
                startDate={row.start} 
            />
        },  
        {
            field: 'start',
            headerName: t('components.dataTable.headers.date'),
            render: (row) => dayjs(row.start).format("DD/MM/YYYY")
        },  
        { 
            field: 'bodyweight', 
            headerName: t('components.dataTable.headers.avgBodyweight', {unit: capitalize(bodyweightUnit.value)}),
            render: (row) => row.bodyweight ? <span>{`${roundNumber(row.bodyweight, 1)} ${bodyweightUnit.value}`}</span> : '-'
        },
        { 
            field: 'calories', 
            headerName: t('components.dataTable.headers.avgCalories'),
            render: (row) => row.calories || row.calories_target ? <_ActualTarget actual={row.calories} target={row.calories_target} /> : '-'
        },
        { 
            field: 'steps', 
            headerName: t('components.dataTable.headers.avgSteps'),
            render: (row) => row.steps || row.steps_target ? <_ActualTarget actual={row.steps} target={row.steps_target} /> : '-'
        },
        { 
            field: 'cardio', 
            headerName: t('components.dataTable.headers.avgCardio'),
            render: (row) => row.cardio || row.cardio_target ? <_ActualTarget actual={row.cardio} target={row.cardio_target} /> : '-'
        },
        { 
            field: 'performance', 
            headerName: t('components.dataTable.headers.performance'),
            render: (row) => row.performance ? <_ActualTarget actual={row.performance} target={5} /> : '-'
        },
    ];

    return colDefs;
}

export default usePhaseTableHeaders;

interface _ActualTargetProps {
    actual: number;
    target: number;
}

const _ActualTarget: FC<_ActualTargetProps> = ({
    actual,
    target,
}) => {

    return (
        <Box display="flex" minWidth="125px">
            <span>{roundNumber(actual, 0)}</span>
            {target > 0 && <span style={{marginLeft: '4px', color: 'var(--text-placeholder)'}}>{`/ ${roundNumber(target, 0)}`}</span>}
        </Box>
    )
}

interface WeekEntryProps {
    week: number;
    startDate: string;
}

interface filteredNotes {
    milestones: IPhaseMilestone[]
    notes: IPhaseNote[]
}

const WeekEntry: FC<WeekEntryProps> = ({
    week, 
    startDate
}) => {
    const {phase, setOpen} = usePhaseContext();

    const filtered: filteredNotes = useMemo(() => ({
        milestones: phase?.milestones?.filter((m) => dayjs(m.date).isBetween(startDate, dayjs(startDate).add(6, 'day').endOf('day'), null, '[]')) ?? [],
        notes: phase?.notes?.filter((m) => dayjs(m.date).isBetween(startDate, dayjs(startDate).add(6, 'day').endOf('day'), null, '[]')) ?? []
    }), [phase, startDate])
    
    return (
        <Box display='flex' alignItems='center'>
            <span style={{paddingRight: 4}}>{week}</span>
            {!!phase && filtered.milestones.length > 0 && (
                <MilestoneTooltip 
                    totalCount={phase?.milestones?.length ?? 0} 
                    milestones={filtered.milestones} 
                    phase_id={phase.id} 
                    onShowAll={() => setOpen('milestone_panel')} 
                />
            )}
            {!!phase && filtered.notes.length > 0 && 
                <PhaseNotesTooltip 
                    totalCount={phase?.notes?.length ?? 0} 
                    notes={filtered.notes} 
                    phase_id={phase.id} 
                    onShowAll={() => setOpen('note_panel')}
                />
            }
        </Box>
    )
}