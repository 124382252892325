import baseApi from '@/repositories/base_api';
import { nutritionPlanApi } from './nutrition_plan';
import { IAPIResponse } from '@/interfaces/api/response';
import { INutritionPlan } from '@/interfaces/nutrition/nutrition_plan';
import { INutritionTemplate } from '@/interfaces/nutrition_template/nutrition_template';
import { ITemplateAssignForm, ITemplateAssignManyForm } from '@/interfaces/template/assign_form';
import { INutritionTemplateForm } from '@/interfaces/nutrition_template/nutrition_template_form';


export const nutritionTemplateApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getNutritionTemplates: build.query<INutritionTemplate[], void>({
            query: () => ({ url: '/templates/nutrition', method: 'get' }),
            transformResponse: (response: IAPIResponse<INutritionTemplate[]>) => response.data.sort((a, b) => a.name.localeCompare(b.name)),
            providesTags: ['Templates.nutrition.list'],
            keepUnusedDataFor: 1800
        }),
        getNutritionTemplate: build.query<INutritionTemplate, string>({
            query: (id) => ({ url: `/templates/nutrition/${id}`, method: 'get' }),
            transformResponse: (response: IAPIResponse<INutritionTemplate>) => response.data,
        }),
        addNutritionTemplate: build.mutation<INutritionTemplate, INutritionTemplateForm>({
            query: (data) => ({ url: '/templates/nutrition', method: 'post', data }),
            transformResponse: (response: IAPIResponse<INutritionTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(nutritionTemplateApi.util.updateQueryData('getNutritionTemplates' as never, undefined as never, (templates: INutritionTemplate[]) => {
                    templates.push(response.data);
                    templates.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
        updateNutritionTemplate: build.mutation<INutritionTemplate, {id: string, data: INutritionTemplateForm}>({
            query: ({id, data}) => ({ url: `/templates/nutrition/${id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<INutritionTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(nutritionTemplateApi.util.updateQueryData('getNutritionTemplates' as never, undefined as never, (templates: INutritionTemplate[]) => {
                    const index = templates.findIndex((item) => item.id === response.data.id);
                    templates.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteNutritionTemplate: build.mutation<void, string>({
            query: (id) => ({ url: `/templates/nutrition/${id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(nutritionTemplateApi.util.updateQueryData('getNutritionTemplates' as never, undefined as never, (templates: INutritionTemplate[]) => {
                    return templates.filter((item) => item.id !== id)
                }))).catch(() => {});
            },
        }),
        deleteNutritionTemplates: build.mutation<void, string[]>({
            query: (ids) => ({ url: `/templates/nutrition/bulk-delete`, method: 'post', data: {ids: ids} }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(ids, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(nutritionTemplateApi.util.updateQueryData('getNutritionTemplates', undefined, templates => {
                    return templates.filter((item) => !ids.includes(item.id))
                }))).catch(() => {});
            },
        }),
        copyNutritionTemplate: build.mutation<INutritionTemplate, string>({
            query: (id) => ({ url: `/templates/nutrition/${id}/copy`, method: 'post' }),
            transformResponse: (response: IAPIResponse<INutritionTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(nutritionTemplateApi.util.updateQueryData('getNutritionTemplates' as never, undefined as never, (templates: INutritionTemplate[]) => {
                    templates.push(response.data)
                    templates.sort((a, b) => a.name.localeCompare(b.name))
                }))).catch(() => {});
            },
        }),
        assignNutritionTemplate: build.mutation<INutritionPlan, ITemplateAssignForm>({
            query: (data) => ({ url: `/templates/nutrition/${data.template_id}/assign`, method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<INutritionPlan>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(nutritionPlanApi.util.updateQueryData('getNutritionPlans', _.client_id as never, plans => {
                    plans.push(response.data);
                    plans.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
        assignNutritionTemplates: build.mutation<INutritionTemplate, {id: string, data: ITemplateAssignManyForm}>({
            query: ({id, data}) => ({ url: `/templates/nutrition/${id}/bulk-assign`, method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<INutritionTemplate>) => response.data,
            invalidatesTags: [{ type: 'Plans.nutrition.list', id: 'ALL' }]
        }),
        shareNutritionTemplate: build.mutation<INutritionTemplate, string>({
            query: (id) => ({ url: `/templates/nutrition/${id}/share`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<INutritionTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(nutritionTemplateApi.util.updateQueryData('getNutritionTemplates', undefined, templates=> {
                    const index = templates.findIndex((item) => item.id === response.data.id);
                    if (index == -1) return templates;
                    const t = [...templates];
                    t.splice(index, 1, response.data);
                    return t;
                }))).catch(() => {});
            },
        }),
    }),
})

export const { 
    useGetNutritionTemplatesQuery,
    useLazyGetNutritionTemplateQuery,
    useAddNutritionTemplateMutation,
    useUpdateNutritionTemplateMutation,
    useDeleteNutritionTemplateMutation,
    useDeleteNutritionTemplatesMutation,
    useCopyNutritionTemplateMutation,
    useAssignNutritionTemplateMutation,
    useAssignNutritionTemplatesMutation,
    useShareNutritionTemplateMutation
} = nutritionTemplateApi;

