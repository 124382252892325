import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import { ILeadFormResponse } from '@/interfaces/lead_form_response/lead_form_response';


export const leadFormResponseApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getLeadFormResponses: build.query<ILeadFormResponse[], void>({
            query: () => ({ url: '/leadform-responses', method: 'get' }),
            transformResponse: (response: IAPIResponse<ILeadFormResponse[]>) => response.data,
            providesTags: ['LeadForm.response.list']
        }),
        getLeadFormResponse: build.query<ILeadFormResponse, string>({
            query: (id) => ({ url: `/leadform-responses/${id}`, method: 'get' }),
            transformResponse: (response: IAPIResponse<ILeadFormResponse>) => response.data,
        }),
        deleteLeadFormResponse: build.mutation<void, string>({
            query: (id) => ({ url: `/leadform-responses/${id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(baseApi.util.updateQueryData('getLeadFormResponses' as never, undefined as never, (forms: ILeadFormResponse[]) => {
                    return forms.filter((item) => item.id !== id)
                }))).catch(() => {});
            },
        }),
    }),
})

export const { 
    useGetLeadFormResponsesQuery,
    useGetLeadFormResponseQuery,
    useDeleteLeadFormResponseMutation,
} = leadFormResponseApi;

