import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

// Components
import Modal from "@/components/modal/modal";


interface _DeleteClientModalProps {
    open: boolean;
    onClose: () => void;
    name?: string;
    count?: number;
    isLoading: boolean;
    onDelete: () => void;
}

const DeleteClientModal: FC<_DeleteClientModalProps> = ({
    open,
    onClose,
    name,
    count = 1,
    isLoading,
    onDelete
}) => {

    const { t } = useTranslation();

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.deleteClient.title', {count: count})}
            text={<Trans i18nKey="modals.deleteClient.text" values={{ name: name, count: count }} style={{maxWidth: '400px'}} />}
            action1={{
                kind: 'danger',
                label: t('components.buttons.delete'),
                loading: isLoading,
                onClick: onDelete
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {
                background: 'var(--background)',
                maxWidth: '448px'
            },}}
            />
    )
}

export default DeleteClientModal;