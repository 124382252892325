import { t } from "i18next";
import { ReactNode } from "react";

// Constants
import { ApiErrorEnum } from "@/_constants/api_errors";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";

interface NotificationBodyProps {
    // Can be passed in optionally, from the API
    apiError?: string | null;
    title: string;
    message?: string|ReactNode|undefined;
    // An optional action button
    action?: {
      label: string;
      icon?: ReactNode;
      loading?: boolean;
      disabled?: boolean;
      onClick: () => void;
    };
    isInline?: boolean;
}

export const NotificationBody: React.FC<NotificationBodyProps> = ({
    apiError, 
    title, 
    message, 
    action, 
    isInline = false,
}) => {

    const isEnum = apiError != null && apiError in ApiErrorEnum;

    function _setTitle(): string {
        if (!isEnum) return title;
        return t(`api.errors.${apiError}.title`);
    }

    function _setMessage(): string|ReactNode|undefined {
        if (!isEnum) return message;
        return t(`api.errors.${apiError}.message`);
    }

    function _actionButton(label: string, onClick: () => void, isInline = false) {
        if (isInline) {
            return (
                <Button 
                    kind="ghost"
                    size="small"
                    label={label}
                    endIcon={action?.icon}
                    loading={action?.loading}
                    disabled={action?.disabled}
                    onClick={onClick}
                    minWidth={false}
                    />
            )
        }
        return (
            <Button
                kind="tertiary"
                size="small"
                label={label}
                endIcon={action?.icon}
                loading={action?.loading}
                disabled={action?.disabled}
                onClick={onClick}
                minWidth={false}
                sx={{marginTop: '24px'}}
                />
        );
    }

    return (
        <Box style={{display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'start', textAlign: 'start'}}>
            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                <span className="heading-07">{_setTitle()}</span>
                {action && isInline && _actionButton(action.label, action.onClick, isInline)}
            </Box>
            <span className="body-02" style={{whiteSpace: 'pre-wrap'}}>{_setMessage()}</span>
            {action && !isInline && _actionButton(action.label, action.onClick)}
        </Box>
    )
}