import { FC } from "react";
import { useTranslation } from "react-i18next";

// Components
import Modal from "@/components/modal/modal";


interface _UnsavedChangesModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

const UnsavedChangesModal: FC<_UnsavedChangesModalProps> = ({
    open,
    onClose,
    onSubmit,
}) => {

    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t('modals.unsavedChanges.title')}
            text={t('modals.unsavedChanges.text')}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: () => onClose()
            }}
            action1={{
                kind: "danger",
                label: t('components.buttons.leave'),
                onClick: () => onSubmit()
            }}
            />
    )
}

export default UnsavedChangesModal;