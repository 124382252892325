import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";

// Interfaces
import { selectSiteSettingsBodyweight } from "@/store/settings";
import { IBodyweightData } from "@/interfaces/client/bodyweight_data";

// Styles
import { CaretDown, CaretUp } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import { ColDef } from "@/components/datatable";


const useBodyweightTableHeaders = (): ColDef<IBodyweightData>[] => {

    const { t } = useTranslation();
    const bodyweightUnit = useSelector(selectSiteSettingsBodyweight) ?? { value: 'kg' };

    const headers: ColDef<IBodyweightData>[] = [
        {
            field: 'date',
            headerName: t('components.dataTable.headers.date'),
            width: '20%',
            render: (row) => new Date(row.date).toLocaleDateString()
        },
        {
            field: 'bodyweight',
            headerName: t('components.dataTable.headers.weight'),
            width: '20%',
            render: (row) => row.bodyweight ? `${roundNumber(row.bodyweight, 2)} ${bodyweightUnit?.value}` : '-'
        },
        //TODO To into setting
        {
            field: 'change',
            headerName: t('components.dataTable.headers.change', { unit: bodyweightUnit.value ?? 'kg'}),
            width: '20%',
            render: (row) => 
                <Box display="flex" alignItems="center">
                    {row.change !== 0 && (
                        row.change > 0 ? 
                        <CaretUp size="20px" style={{color: 'var(--support-success)'}} /> :
                        <CaretDown size="20px" style={{color: 'var(--support-error)'}} />
                    )}
                    <span>{row.change ? `${row.change > 0 ? '+' : ''}${roundNumber(row.change, 2)}` : '-'}</span>
                    {row.change !== 0 && <span style={{marginLeft: '4px'}}>{bodyweightUnit?.value}</span>}

                </Box>
        }
    ]

    return headers;
}

export default useBodyweightTableHeaders;


// interface _ChangeProps {
//     change: number;
//     weightUnit: string;
// }

// const Change: FC<_ChangeProps> = ({ change, weightUnit }) => {
//     return (
//         <span>{change ? roundNumber(change, 2) : '-'} {weightUnit}</span>
//     )
// }