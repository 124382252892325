import { FC } from "react";

// Interfaces
import { downloadQuery } from "@/repositories/media";
import IGallery from "@/interfaces/gallery/gallery";
import { useGalleryContext } from "@/contexts/gallery_context";

// Components
import Thumbnail from "./thumbnail";
import Box from "@mui/material/Box";

interface GridProps {
    galleries: IGallery[]
}

const GalleryGrid: FC<GridProps> = ({ galleries }) => {
    
    const { selected, toggleSelected, setLightboxState } = useGalleryContext()

    return (
        <Box display='flex' gap={2} flexWrap='wrap'>
            {galleries.map(gallery => gallery.content.map((p,i) => (
                <Thumbnail 
                    key={p.uuid}
                    url={p.thumb}
                    selected={selected?.includes(`${gallery.id}-${i}`)}
                    onSelect={() => toggleSelected(`${gallery.id}-${i}`)}
                    onClick={() => setLightboxState(p.uuid)}
                    onDownload={() => downloadQuery(p.original)}
                />
            ))).flat()}
        </Box>
    );
}

export default GalleryGrid;