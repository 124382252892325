import { Navigate } from "react-router-dom";

// High level pages
import View from "@/pages/client/view";
import Overview from "@/pages/client/phases/overview";

// Plan Pages
import TrainingPage from "@/pages/client/training/training_page";
import TrainingDataTab from "@/pages/client/training/data_tab";
import ProgrammePlansTab from "@/pages/client/training/plans_tab";
import NutritionPage from "@/pages/client/nutrition/nutrition_page";
import NutritionDataTab from "@/pages/client/nutrition/data_tab";
import NutritionPlansTab from "@/pages/client/nutrition/plans_tab";
import SupplementPage from "@/pages/client/supplement_plans/supplement_page";
import ActivityPage from "@/pages/client/activity/activity_page";
import ActivityPlansTab from "@/pages/client/activity/plans_tab";

// Metric Pages
import RecoveryPage from "@/pages/client/recovery/recovery_page";
import HealthPage from "@/pages/client/health_markers/health_page";

// Other Pages
import PhysiquePage from "@/pages/client/physique/physique_page";
import BodyweightTab from "@/pages/client/physique/bodyweight_tab";
import MeasurementsTab from "@/pages/client/physique/measurements_tab";
import CheckinsPage from "@/pages/client/checkins/checkins_page";
import GalleryPage from "@/pages/client/gallery/gallery_page";
import ActivityDataTab from "@/pages/client/activity/data_tab";


export const clientDetailsRoutes = {
  path: "clients/:id",
  element: <View />,
  children: [
    {
      index: true,
      element: <Navigate to="overview" replace />,
    },
    {
      path: "overview",
      element: <Overview />,
    },
    {
      path: "physique",
      element: <PhysiquePage />,
      children: [
        {
          index: true,
          element: <Navigate to="bodyweight" replace />,
        },
        {
          path: "bodyweight",
          element: <BodyweightTab />
        }, 
        {
          path: "measurements",
          element: <MeasurementsTab />
        }
      ]
    },
    {
      path: "training",
      element: <TrainingPage />,
      children: [
        {
          index: true,
          element: <Navigate to="data" replace />,
        },
        {
          path: "data",
          element: <TrainingDataTab />
        },
        {
          path: "plans",
          element: <ProgrammePlansTab />
        },

      ]
    },
    {
      path: "nutrition",
      element: <NutritionPage />,
      children: [
        {
          index: true,
          element: <Navigate to="data" replace />,
        },
        {
          path: "data",
          element: <NutritionDataTab />
        },
        {
          path: "plans",
          element: <NutritionPlansTab />
        }
      ]
    },
    {
      path: "supplements",
      element: <SupplementPage />
    },
    {
      path: "activity",
      element: <ActivityPage />,
      children: [
        {
          index: true,
          element: <Navigate to="data" replace />,
        },
        {
          path: "data",
          element: <ActivityDataTab />
        },
        {
          path: "plans",
          element: <ActivityPlansTab />
        }
      ]
    },
    {
      path: "recovery",
      element: <RecoveryPage />
    },
    {
      path: "health",
      element: <HealthPage />
    },
    {
      path: "gallery",
      element: <GalleryPage />
    },
    {
      path: "checkins",
      element: <CheckinsPage />
    }
  ]
}