import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { ILeadForm } from "@/interfaces/lead_form/lead_form";
import { useCopyLeadFormMutation, useDeleteLeadFormMutation } from "@/repositories/lead_form";

// Components
import { Box } from "@mui/material";
import CopyFormModal from "./copy_modal";
import { Options } from "@/components/menu";
import DeleteFormModal from "./delete_modal";
import OverflowMenu from "@/components/overflow_menu";
import useBulkActions from "@/components/datatable/use_bulk_actions";


interface _FormMenuProps {
    row: ILeadForm;
}

const FormMenu: FC<_FormMenuProps> = ({row}) => {

    const publicUrl = import.meta.env.VITE_PUBLIC_URL;

    const { t } = useTranslation();
    const { showBulkActions } = useBulkActions();
    const [open, setOpen] = useState<string|null>(null);
    const [copyLeadForm, { isLoading: isCopying }] = useCopyLeadFormMutation();
    const [deleteLeadForm, { isLoading: isDeleting }] = useDeleteLeadFormMutation();

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`${publicUrl}leadforms/${row.id}`);
        showToast({
            type: 'info',
            title: t('notifications.form.linkCopied.title'),
        });
    }

    const options: Options[] = [
        {name: t('components.menuItems.copyForm'), action: () => setOpen('copy')},
        {name: t('components.menuItems.copyFormLink'), disabled: !row.published, action: handleCopyLink}
    ]

    const handleCopy = () => {
        copyLeadForm(row.id).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.form.copied.title', {count: 1}), 
                message: <Trans 
                    i18nKey="notifications.form.copied.message" 
                    values={{name: row.name}}
                    components={{bold: <strong />}}
                />
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleDelete = () => {
        deleteLeadForm(row.id).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.form.deleted.title', {count: 1}), 
                message: <Trans 
                    i18nKey="notifications.form.deleted.message" 
                    values={{name: row.name}}
                    components={{bold: <strong />}}
                />
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Box display="flex" justifyContent="flex-end">
            <OverflowMenu 
                position={{horizontal: 'right', vertical: 'bottom'}}
                options={options}
                deleteLabel={t('components.menuItems.delete')}
                disableDelete={row.published}
                disabled={showBulkActions}
                onDelete={() => setOpen('delete')}
                />
            {open === 'copy' && <CopyFormModal
                open={open === 'copy'}
                onClose={() => setOpen(null)}
                name={row.name}
                isLoading={isCopying}
                onCopy={handleCopy}
                />}
            {open === 'delete' && <DeleteFormModal
                open={open === 'delete'}
                onClose={() => setOpen(null)}
                name={row.name}
                isLoading={isDeleting}
                onDelete={handleDelete}
                />}
            
        </Box>
    )
}

export default FormMenu;