import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { IFood } from "@/interfaces/food/food";
import { useDeleteFoodMutation } from "@/repositories/food";

// Components
import Modal from "@/components/modal/modal";
import { TrashCan } from "@carbon/icons-react";
import IconButton from "@/components/icon_button";
import { ApiError } from "@/interfaces/api/error";


interface DeleteFoodProps {
    row: IFood;
}

const DeleteFood: FC<DeleteFoodProps> = ({
    row
}) => {

    const [open, setOpen] = useState<boolean>(false);
    const disableMenu: boolean = ['default', 'team'].includes(row.library);

    return (
        <>
            <IconButton
                kind="ghost"
                size="small"
                icon={<TrashCan style={{color: !disableMenu ? 'var(--support-error)' : 'var(--icon-disabled)'}} />}
                disabled={disableMenu}
                onClick={() => setOpen(true)}
                />
            {open && <DeleteFoodModal
                open={open}
                onClose={() => setOpen(false)}
                food={row}
                />}
            
        </>
    )
}

export default DeleteFood;

interface DeleteFoodModalProps {
    open: boolean;
    onClose: () => void;
    food: IFood;
}

const DeleteFoodModal: FC<DeleteFoodModalProps> = ({
    open,
    onClose,
    food
}) => {

    const { t } = useTranslation();
    const [deleteFood, { isLoading }] = useDeleteFoodMutation();

    const handleDelete = () => {
        deleteFood(food.id).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.food.deleted.title'), message: t('notifications.food.deleted.message', {name: food.name})});
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.deleteFood.title', {name: food?.name})}
            text={<Trans i18nKey="modals.deleteFood.text" values={{ name: food.name }} />}
            action1={{
                kind: 'danger',
                label: t('components.buttons.delete'),
                loading: isLoading,
                onClick: handleDelete,
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '480px'}}}
            />
    )
}