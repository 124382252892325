import { z } from "zod";
import { t } from "i18next";
import ruleset, { addIssue } from "@/_helpers/ruleset";


export interface ISubscriptionCancellationForm {
    reason: string;
    comments: string;
}

export const subscriptionCancellationFormSchema = z.object({
    reason: ruleset.select,
    comments: ruleset.stringOptional,
}).superRefine((data, ctx) => {
    if (data.reason === 'other' && !data.comments) addIssue(ctx, ['comments'], t('inputs.errors.specifyReason'));
});
