import { Control, useController } from "react-hook-form";

// Phone number input
import Phone, { isSupportedCountry } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'

// Styles
import { Error } from '@mui/icons-material';

// Components
import { FormControl, FormHelperText } from '@mui/material';


interface PhoneInputProps {
  name: string;
  control: Control<any>;
  label?: string;
  placeHolder?: string;
  tabIndex?: number;
  disabled?: boolean;
}

const PhoneInput: React.FC<PhoneInputProps>  = ({
  name,
  control,
  label,
  placeHolder,
  tabIndex,
  disabled = false
}) => {
    // get default country code
    let countryCode = navigator.language.toLocaleUpperCase()
    if (countryCode.includes('-')) countryCode = countryCode.split('-')[1]
    const countryDefault = isSupportedCountry(countryCode) ? countryCode : undefined

    const { field, fieldState } = useController({name, control});

    return (
      // Width set to 100% to always fill what ever container it is in
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: '12px', width: '100%'}}>
        {label != null ? <span className="label-text-02 text-secondary" style={{marginBottom: '0.5em'}}>{label}</span> : null}
        <FormControl error sx={{width: '100%'}}>
          <Phone
            defaultCountry={countryDefault}
            className={`${fieldState.invalid ? 'PhoneInput-error' : '' }`}
            limitMaxLength={true}
            name={name}
            value={field.value}
            tabIndex={tabIndex}
            placeholder={placeHolder}
            disabled={disabled}
            onChange={field.onChange}
            onBlur={field.onBlur}
            />
          {fieldState.invalid && (<Error
            color="error"
            style={{
              fontSize: '16px',
              position: 'absolute',
              right: '10px',
              top: '9px',
            }}
          />)}
          {fieldState.invalid && (<FormHelperText 
            id="component-error-text"
            sx={{marginLeft: '14px'}}
            >{fieldState.error?.message}</FormHelperText>)}
        </FormControl>
      </div>
    );
};

export default PhoneInput;