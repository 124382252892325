

export const buildQueryString = (url: string, params?: Record<string, any>) => {

    if (!params || Object.keys(params).length === 0) {
        return url;
    }

    const queryString = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');
    
    return `${url}?${queryString}`;
}