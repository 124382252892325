import { Button, ButtonGroup } from "@mui/material";
import { BaseSyntheticEvent, forwardRef, ReactNode, useState } from "react";


export interface ContentAction {
    label: string;
    icon?: ReactNode;
    value?: string;
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;
}

interface _ContentSwitcherProps {
    size?: 'small' | 'medium' | 'large';
    initialValue?: string;
    options: ContentAction[];
    onChange?: () => void;
}

interface ClassObject {
    [key: string]: boolean
}

const ContentSwitcher = forwardRef<HTMLButtonElement, _ContentSwitcherProps>(({
    size,
    options,
    onChange,
    initialValue
}, ref) => {
    const idx = options.findIndex(o => o.value === initialValue)
    const [selected, setSelected] = useState<number>(idx != -1 ? idx : 0);

    const handleClick = (e: BaseSyntheticEvent, i: number) => {
        e.preventDefault();
        setSelected(i);
        options[i].onClick();
        onChange && onChange();
    }

    const getClass = (classes: ClassObject) => Object.entries(classes)
        .filter(([,v]) => !!v)
        .map(([k]) => k)
        .join(' ') 

    return (
        <ButtonGroup className="ContentSwitcher" variant="text">
            {options.map((option, i) => {
                return (
                    <Button
                        ref={ref}
                        key={i}
                        size={size}
                        sx={{minWidth: 'unset'}}
                        onClick={(e) => handleClick(e, i)}
                        className={getClass({
                            'ContentSwitcher__button': true,
                            'ContentSwitcher__button--active': selected === i,
                            'ContentSwitcher__button--icon': !!options[i].icon,
                        })}
                    >
                        <span className="body-02-compact">{option.label}</span>
                        {option.icon}
                    </Button>
                )
            })}
        </ButtonGroup>
    )

});

export default ContentSwitcher;