import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { ITeamInvitation } from "@/interfaces/team/team";
import { useCancelTeamInvitationMutation } from "@/repositories/team_invitation";

// Styles
import { TrashCan } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";


interface _CancelInviteModalProps {
    open: boolean;
    onClose: () => void;
    invitation: ITeamInvitation;
}
const CancelInviteModal: FC<_CancelInviteModalProps> = ({
    open,
    onClose,
    invitation
}) => {

    const { t } = useTranslation();
    const [cancelTeamInvitation, { isLoading }] = useCancelTeamInvitationMutation();

    const submit = () => {
        cancelTeamInvitation(invitation.id).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.teamInvitation.cancel.title'), message: t('notifications.teamInvitation.cancel.message', {email: invitation.email})})
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
            onClose();
        });
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t('modals.cancelInvite.title')}
            text={
                <Trans
                    i18nKey="modals.cancelInvite.text"
                    components={{ strong: <strong /> }}
                    values={{ email: invitation.email }}
                />
            }
            action1={{
                kind: 'danger',
                label: t('components.buttons.cancelInvite'),
                icon: <TrashCan />,
                onClick: submit,
                loading: isLoading
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: onClose,
                disabled: isLoading
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px'}}}
            />
    )
}

export default CancelInviteModal;