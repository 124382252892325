import { t } from "i18next";
import { ISelectOption } from "@/interfaces/components/select_option";


export const cardioTypeOptions: ISelectOption<string>[] = [
    { id: 1, label: t('components.select.options.cardioType.any'), value: 'any' },
    { id: 2, label: t('components.select.options.cardioType.hiit'), value: 'hiit' },
    { id: 3, label: t('components.select.options.cardioType.liss'), value: 'liss' },
    { id: 4, label: t('components.select.options.cardioType.tabata'), value: 'tabata' },
    { id: 5, label: t('components.select.options.cardioType.circuit'), value: 'circuit' },
    { id: 6, label: t('components.select.options.cardioType.other'), value: 'other' },
];