import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { ApiError } from "@/interfaces/api/error";
import { ICheckinTemplateForm } from "@/interfaces/checkin_template/checkin_template_form";
import { checkinTemplateFormSchema } from "@/interfaces/checkin_template/checkin_template_form";
import { useLazyGetCheckinTemplateQuery, useUpdateCheckinTemplateMutation } from "@/repositories/checkin_template";

// Styles
import { Edit, Save } from "@carbon/icons-react";

// Components
import { Box, Container } from "@mui/material";
import Form from "@/components/form_builder/form";
import SecondaryToolbar from "@/components/secondary_toolbar";
import RenameFormModal from "@/components/builder/rename_modal";
import UnsavedChangesModal from "@/pages/common/unsaved_changes_modal";


const EditCheckinPage: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const hasTeam = useSelector(selectHasTeam)
    const [open, setOpen] = useState<string|null>(null);
    
    const [loading, setLoading] = useState<boolean>(true);
    const [getTemplate, { data: template }] = useLazyGetCheckinTemplateQuery();
    const [updateCheckinTemplate, { isLoading: isUpdating }] = useUpdateCheckinTemplateMutation();
    const [updateDraftCheckinTemplate, { isLoading: isUpdatingDraft }] = useUpdateCheckinTemplateMutation();

    const formMethods = useForm<ICheckinTemplateForm>({
        resolver: zodResolver(checkinTemplateFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: t('pages.templates.checkin.newForm'),
            draft: false,
            form: [{
                uuid: uuidv4(),
                title: '',
                questions: [{
                        uuid: uuidv4(),
                        title: '',
                        type: 'text',
                        required: true,
                    }]
            }]
        }
    });

    const name = useWatch({ control: formMethods.control, name: 'name' });

    useEffect(() => {
        if (id) {
            getTemplate(id).unwrap().then((t) => {
                formMethods.reset({
                    name: t.name,
                    draft: t.draft,
                    form: t.form
                })
                setLoading(false)
            }).catch((error: ApiError) => {
                navigate(`/templates/checkins${hasTeam ? '/personal' : '/'}`);
                showToast({type: 'error', apiError: error.type})
            });
        }
    }, [id, formMethods, getTemplate, navigate, hasTeam])

    useEffect(() => {
        // Used to ensure the user is prompted when navigating away with unsaved changes
    }, [formMethods.formState.isDirty]);

    const handleBack = () => {
        if (formMethods.formState.isDirty) return setOpen('unsaved');
        navigate(`/templates/checkins${hasTeam ? '/personal' : '/'}`);
    }

    const handleSave = (data: ICheckinTemplateForm) => {
        data.draft = false;
        updateCheckinTemplate({id: String(id), data: data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.template.updated.title'), 
                message: t('notifications.template.updated.message', {name: name})
            });
            navigate(`/templates/checkins${hasTeam ? '/personal' : '/'}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleSaveDraft = () => {
        const data = formMethods.getValues();
        data.draft = true;
        updateDraftCheckinTemplate({id: String(id), data: data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.template.updated.title'), 
                message: t('notifications.template.updated.message', {name: name})
            });
            navigate(`/templates/checkins${hasTeam ? '/personal' : '/'}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }
    
    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar
                title={name}
                titleAction={{  
                    icon: <Edit />,
                    onClick: () => setOpen('edit')
                }}
                isLoading={loading}
                onBack={handleBack}
                action1={{
                    label: t('components.buttons.save'),
                    icon: <Save />,
                    loading: isUpdating,
                    disabled: isUpdatingDraft || template?.readonly,
                    onClick: formMethods.handleSubmit(handleSave),
                    sx: {minWidth: '115px'}
                }}
                action2={{
                    label: t('components.buttons.saveDraft'),
                    loading: isUpdatingDraft,
                    disabled: template?.default || (template?.assigned_count !== undefined && template?.assigned_count > 0) || isUpdating || template?.shared || template?.readonly,
                    onClick: handleSaveDraft
                }}
                />
            {open === 'edit' && 
                <RenameFormModal 
                    open={open === 'edit'} 
                    onClose={() => setOpen(null)}
                    onSubmit={(d) => formMethods.setValue('name', d.name, { shouldDirty: true })}
                    title={t('modals.renameForm')}
                    name={name}
                    />}
            {open === 'unsaved' && 
                <UnsavedChangesModal 
                    open={open === 'unsaved'} 
                    onClose={() => setOpen(null)}
                    onSubmit={() => navigate(`/templates/checkins${hasTeam ? '/personal' : '/'}`)}
                    />}

            <Container sx={{padding: '16px 24px', overflow: 'hidden'}}>

                <FormProvider {...formMethods}>
                    <Form isLoading={loading} />
                </FormProvider>

            </Container>

        </Box>
    )
}

export default EditCheckinPage;