import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

// Styles
import { Save } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";


interface _CatalogueModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
    title: string;
    name: string;
    model: string;
    isLoading?: boolean;
}

const CatalogueModal: FC<_CatalogueModalProps> = ({
    open,
    onClose,
    onSubmit,
    title,
    name,
    model,
    isLoading = false
}) => {

    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={title}
            text={<Trans
                i18nKey="modals.saveToCatalogue.text"
                components={{ strong: <strong /> }}
                values={{ model: model, name: name}}
            />}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: onClose
            }}
            action1={{
                label: t('components.buttons.save'),
                icon: <Save />,
                loading: isLoading,
                onClick: onSubmit
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px', background: 'var(--background)'}}}
            />
    )
}

export default CatalogueModal;