import dayjs from "dayjs";
import { FC, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helpers
import { snakeToCamel } from "@/_helpers/text_functions";
import { roundNumber } from "@/_helpers/number_functions";

// Services and interfaces
import { useGetClientDataQuery } from "@/repositories/client_data";

// Components
import DataTable from "@/components/datatable";
import { Box, Container } from "@mui/material";
import useHealthTableHeaders from "./table_headers";
import Chart from "@/pages/client/components/chart";
import SecondaryToolbar from "@/components/secondary_toolbar";
import ChartCard, { TotalValues } from "@/pages/client/components/chart_card";


const HealthPage: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const headers = useHealthTableHeaders();
    const [open, setOpen] = useState(false);
    const [chartMetric, setChartMetric] = useState<string>('');

    const { data: data, isLoading } = useGetClientDataQuery(id!);

    const hasRhrData = useMemo(() => data?.some(item => item.rhr), [data]);
    const hasHrvData = useMemo(() => data?.some(item => item.hrv), [data]);
    const hasBloodGlucoseData = useMemo(() => data?.some(item => item.blood_glucose), [data]);
    const hasBloodPressureData = useMemo(() => data?.some(item => item.blood_pressure), [data]);

    const sevenDayAverage = (metric: string, decimalPlaces: number): TotalValues => {
        const now = dayjs();
        const sevenDaysAgo = now.subtract(7, 'day');

        const filteredData = data?.filter(item => {
            const itemDate = dayjs(item.date);
            return itemDate.isAfter(sevenDaysAgo) && item[metric];
          });

        if (!filteredData || filteredData.length == 0) return { total: 0, daysLogged: 0 };
        
        if (metric === 'blood_pressure') {
            const systolic = roundNumber(filteredData?.reduce((acc, item) => acc + item.blood_pressure.systolic, 0) / filteredData.length, decimalPlaces).toString();
            const diastolic = roundNumber(filteredData?.reduce((acc, item) => acc + item.blood_pressure.diastolic, 0) / filteredData.length, decimalPlaces).toString();
            const total = `${systolic} / ${diastolic}`;
            return { total, daysLogged: filteredData.length };
        }

        const total = roundNumber(filteredData?.reduce((acc, item) => acc + item[metric], 0) / filteredData.length, decimalPlaces).toString();
        return { total, daysLogged: filteredData.length };
    }

    const lastMenstruation = data?.find(item => item.menstrual_cycle === 'Menstruation')?.date;

    const handleOpenChart = (metric: string) => {
        setChartMetric(metric);
        setOpen(true);
    }

    return (
        <Box width='100%' height='100%'>

            <SecondaryToolbar
                kind="primary"
                title={t('pages.client.health')}
            />

            <Box sx={{overflowY: 'auto', height: 'calc(100% - 48px)'}}>
            <Container sx={{py: '24px'}}>

                <Box display="flex" columnGap="16px">

                    {headers.find(item => item.field === 'rhr') && 
                        <ChartCard 
                            metric="rhr" 
                            totals={sevenDayAverage('rhr', 0)}
                            onShowChart={hasRhrData ? () => handleOpenChart('rhr') : undefined}
                            />}
                    {headers.find(item => item.field === 'hrv') && 
                        <ChartCard 
                            metric="hrv" 
                            totals={sevenDayAverage('hrv', 0)}
                            onShowChart={hasHrvData ? () => handleOpenChart('hrv') : undefined}
                            />}
                    {headers.find(item => item.field === 'blood_glucose') && 
                        <ChartCard 
                            metric="bloodGlucose" 
                            totals={sevenDayAverage('blood_glucose', 1)}
                            onShowChart={hasBloodGlucoseData ? () => handleOpenChart('blood_glucose') : undefined}
                            />}
                    {headers.find(item => item.field === 'blood_pressure') && 
                        <ChartCard 
                            metric="bloodPressure" 
                            totals={sevenDayAverage('blood_pressure', 0)}
                            onShowChart={hasBloodPressureData ? () => handleOpenChart('blood_pressure') : undefined}
                            />}
                    {headers.find(item => item.field === 'menstrual_cycle') && 
                        <ChartCard 
                            metric="menstruation" 
                            lastMenstruation={lastMenstruation ? dayjs().diff(dayjs(lastMenstruation), 'days').toString() : undefined}
                            />}
                    {open == true && 
                        <Chart
                            open={open}
                            onClose={() => setOpen(false)}
                            title={t(`metrics.${snakeToCamel(chartMetric)}`)}
                            data={data ?? []}
                            metric={chartMetric}
                            yLabel={t(`metrics.${snakeToCamel(chartMetric)}`)}
                        />}

                </Box>

                <DataTable
                    data={data}
                    dataKey="id"
                    columns={headers} 
                    hideHeader
                    hideFilter
                    localPagination
                    noDataMessage={t('components.dataTable.noData.noLoggedData')}
                    isLoading={isLoading}
                    />

            </Container>
            </Box>
            
        </Box>
    )
}

export default HealthPage;