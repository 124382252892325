import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Constants
import { 
    supplementUnitOptions, 
    supplementPriorityOptions, 
    supplementCategoryOptions, 
    supplementLibraryTypeOptions
} from "@/_constants/supplement";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { ISupplement } from "@/interfaces/supplement/supplement";

// Components
import { FilterItem } from "@/components/datatable/filter/filter_bar";


const useSupplementFilterItems = (supplements: ISupplement[]): FilterItem[] => {

    const { t } = useTranslation();
    const hasTeam = useSelector(selectHasTeam);
    const brands = Array.from(new Set(supplements.map((supplement) => supplement.brand)))
        .map((brand) => ({
            value: brand,
            label: brand
        }));

    const filters: FilterItem[] = [
        {
            type: 'secondary',
            label: t('components.dataTable.headers.brand'),
            field: 'brand',
            items: brands,
            itemKey: 'value'
        },
        {
            type: 'primary',
            label: t('components.dataTable.headers.category'),
            field: 'category',
            items: supplementCategoryOptions,
            itemKey: 'value'
        },
        {
            type: 'secondary',
            label: t('components.dataTable.headers.priority'),
            field: 'priority',
            items: supplementPriorityOptions,
            itemKey: 'value'
        },
        {
            type: 'secondary',
            label: t('components.dataTable.headers.unit'),
            field: 'unit',
            items: supplementUnitOptions,
            itemKey: 'value'
        },
        {
            type: 'primary',
            label: t('components.dataTable.headers.library'),
            field: 'library',
            items: hasTeam ? supplementLibraryTypeOptions : supplementLibraryTypeOptions.filter(item => item.value !== 'team'),
            itemKey: 'value'
        }
    ];

    return filters;
}

export default useSupplementFilterItems;