import { v4 as uuidv4 } from 'uuid';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from "react-i18next";
import { useSortable } from "@dnd-kit/sortable";
import { BaseSyntheticEvent, FC, useState } from "react";
import { FieldErrors, useFormContext, useWatch } from "react-hook-form";

// Helpers
import showToast from '@/_lib/toast';

// Styles
import { DragVertical, WarningFilled } from "@carbon/icons-react";

// Services and interfaces
import { ApiError } from '@/interfaces/api/error';
import { useAddProgrammeWorkoutMutation } from '@/repositories/programme_workout';
import { IProgrammeBuilder, IWorkout } from "@/interfaces/programme/programme_builder";
import { IProgrammeWorkoutForm } from '@/interfaces/programme_workout/programme_workout_form';

// Components
import { Box, Skeleton } from "@mui/material";
import OverflowMenu from '@/components/overflow_menu';
import DeleteModal from '@/components/builder/delete_modal';
import RenameModal from '@/components/builder/rename_modal';
import CatalogueModal from '@/components/builder/catalogue_modal';


interface _MenuWorkoutProps {
    id: string
    workoutIndex: number;
    selectedWorkout: number;
    handleSelect: (e: BaseSyntheticEvent) => void;
    onCopy: (workout: IWorkout) => void;
    onRemove: (index: number) => void;
    isLoading?: boolean;
}

const MenuSection: FC<_MenuWorkoutProps> = ({
    id,
    workoutIndex,
    selectedWorkout,
    handleSelect,
    onCopy,
    onRemove,
    isLoading = false
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<string|null>(null);
    const [saveWorkoutToCatalogue, { isLoading: saveLoading }] = useAddProgrammeWorkoutMutation();
    const { control, setValue, formState: { errors } } = useFormContext<IProgrammeBuilder>();

    const plan = useWatch({ control, name: 'plan' });
    const disabled = plan.length === 1;
    const workout = useWatch({ control, name: `plan.${workoutIndex}` });
    const settings = useWatch({ control, name: `settings` });
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    const dragStyle = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const disableSaveToCatalogue = workout.name === '' || !workout.name || workout.exercises.length === 0;

    const setErrorMessage = (errors: FieldErrors<IProgrammeBuilder>) => {
        const workoutErrors = errors.plan?.[workoutIndex];
        if (workoutErrors?.name) return workoutErrors.name.message;
        if (workoutErrors?.exercises?.message) return workoutErrors.exercises.message;
        return t('components.programmeBuilder.menu.sectionErrors');
    }

    const handleCopyWorkout = () => {
        const trimCount = workout.name.length + 7 - 40;
        const regex = new RegExp(`.{${trimCount}}$`);
        const name = `${workout.name.length > 33 ? workout.name.replace(regex, '') : workout.name} - ${t('general.copy')}`
        const newWorkout = {
            ...workout,
            uuid: uuidv4(),
            name: name
        }
        onCopy(newWorkout);
    }

    const handleSaveToCatalogue = () => {
        const programmeWorkout: Partial<IProgrammeWorkoutForm> = {
            name: workout.name,
            exercises: workout.exercises,
            settings: settings
        };
        saveWorkoutToCatalogue(programmeWorkout).unwrap().then((workout) => {
            showToast({
                type: 'success', 
                title: t('notifications.catalogue.created.title', {type: t('components.catalogues.workoutCatalogue')}), 
                message: t('notifications.catalogue.created.message', {type: t('components.catalogues.workoutCatalogue'),name: workout.name})
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box 
            key={workoutIndex}
            ref={setNodeRef}
            style={dragStyle}
            className={`Builder__menu-section ${selectedWorkout === workoutIndex ? 'Builder__menu-section--selected' : ''}`} 
            onClick={handleSelect}
            >

            <Box width="8px" />

            {/* Title */}
            {isLoading ? <Skeleton variant="text" width={200} height={28} /> :
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                
                    <Box display="flex" alignItems="center" maxWidth="200px">
                        {errors.plan?.[workoutIndex] && <WarningFilled style={{color: 'var(--support-error)', marginRight: '8px'}} />}
                        <span className="body-02-compact" style={{textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            {workout.name ? workout.name : t('components.programmeBuilder.menu.workoutPlaceholder')}</span>
                    </Box>
                    {errors.plan?.[workoutIndex] && <span className="label-text-02" style={{color: 'var(--support-error)', textAlign: 'left'}}>
                        {setErrorMessage(errors)}</span>}
                </Box>}

            {/* Spacer */}
            <Box flexGrow={1} padding="0 8px" />

            <Box className="Builder__menu-section-actions">
                {!disabled && <Box {...attributes} {...listeners} display="flex" alignItems="center" width="32px" justifyContent="center" sx={{cursor: 'grab'}} >
                    <DragVertical onClick={(e) => e.stopPropagation()} />
                </Box>}

                <OverflowMenu
                    options={[
                        {name: t('components.menuItems.rename'), action: () => setOpen('rename')},
                        {name: t('components.menuItems.copy'), action: handleCopyWorkout},
                        {name: t('components.menuItems.saveToCatalogue'), disabled: disableSaveToCatalogue, action: () => setOpen('saveToCatalogue')},
                    ]}
                    disableDelete={disabled}
                    onDelete={() => setOpen('delete')}
                    />
                {open === 'rename' && 
                    <RenameModal 
                        open={open === 'rename'} 
                        onClose={() => setOpen(null)}
                        onSubmit={(d) => setValue(`plan.${workoutIndex}.name`, d.name, { shouldDirty: true, shouldValidate: true })}
                        title={t('modals.renameSection')}
                        name={workout.name}
                        placeholder={t('components.programmeBuilder.menu.workoutPlaceholder')}
                        />}
                {open === 'saveToCatalogue' && 
                    <CatalogueModal 
                        open={open === 'saveToCatalogue'} 
                        onClose={() => setOpen(null)}
                        onSubmit={handleSaveToCatalogue}
                        title={t('modals.saveToCatalogue.title', {model: t('components.catalogues.workout')})}
                        name={workout.name}
                        model={t('components.catalogues.workout')}
                        isLoading={saveLoading}
                        />}
                {open === 'delete' && 
                    <DeleteModal 
                        open={open === 'delete'} 
                        onClose={() => setOpen(null)}
                        title={t('modals.deleteWorkout.title')}
                        text={t('modals.deleteWorkout.text')}
                        onDelete={() => onRemove(workoutIndex)}
                        />}
            </Box>

    </Box>
    )
}

export default MenuSection;