import { FC } from "react";
import { FieldPath, useFormContext } from "react-hook-form";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";

// Interfaces
import { IMealFood, INutritionBuilder, IUnit } from "@/interfaces/nutrition/nutrition_builder";

// Components
import SelectInput from "../select_input";

type NutritionPath = FieldPath<INutritionBuilder>

interface _UnitInputProps {
    name: NutritionPath;
    units: IUnit[];
}

const UnitInput: FC<_UnitInputProps> = ({ 
    name,
    units,
}) => {

    const { control, setValue, watch } = useFormContext<INutritionBuilder>();
    const food = watch(name) as IMealFood;

    const handleUnitChange = (value: IUnit) => {
        const macros = food.macros;
        const protein = roundNumber(macros.protein / 100 * (food.portion * value.weight), 1);
        const carbs = roundNumber(macros.carbs / 100 * (food.portion * value.weight), 1);
        const fat = roundNumber(macros.fat / 100 * (food.portion * value.weight), 1);
        const fibre = roundNumber(macros.fibre / 100 * (food.portion * value.weight), 1);
        
        console.log(`old calories: ${food.calories}`)
        console.log(`new calories: ${(protein + carbs) * 4 + fat * 9}`)
        
        setValue(`${name}.protein` as NutritionPath, protein);
        setValue(`${name}.carbs` as NutritionPath, carbs);
        setValue(`${name}.fat` as NutritionPath, fat);
        setValue(`${name}.fibre` as NutritionPath, fibre);
        setValue(`${name}.calories` as NutritionPath, (protein + carbs) * 4 + fat * 9);
    };

    
    return (
        <SelectInput
            name={`${name}.unit`}
            control={control}
            items={units}
            itemLabel="label"
            itemValue="weight"
            itemKey="label"
            returnObject
            hideErrorMessage
            gutter="0"
            onChange={handleUnitChange}
            minWidth={false}
        />
    )
}

export default UnitInput;