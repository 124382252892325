import baseApi from '@/repositories/base_api';
import { IFood } from '@/interfaces/food/food';
import { IAPIResponse } from '@/interfaces/api/response';
import { IFoodRecent } from '@/interfaces/food_recent/food_recent';


export const foodRecentApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getRecentFoods: build.query<IFoodRecent[], void>({
            query: () => ({ url: '/recent-foods', method: 'get' }),
            transformResponse: (response: IAPIResponse<IFoodRecent[]>) => response.data,
            providesTags: ['Food.recent.list'],
            keepUnusedDataFor: 1800
        }),
        addRecentFood: build.mutation<void, {foods: IFood[]}>({
            query: (foods) => ({ url: '/recent-foods', method: 'post', data: foods }),
            invalidatesTags: ['Food.recent.list'],
        }),
        updateRecentFood: build.mutation<IFoodRecent, {id: string, data: IFoodRecent}>({
            query: ({id, data}) => ({ url: `/recent-foods/${id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<IFoodRecent>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getRecentFoods' as never, undefined as never, (foods: IFoodRecent[]) => {
                    const index = foods.findIndex((item) => item.id === response.data.id);
                    foods.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteRecentFood: build.mutation<void, string>({
            query: (id) => ({ url: `/recent-foods/${id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(baseApi.util.updateQueryData('getRecentFoods' as never, undefined as never, (foods: IFoodRecent[]) => {
                    return foods.filter((item) => item.id !== id)
                }))).catch(() => {});
            },
        }),
    }),
})

export const {
    useGetRecentFoodsQuery,
    useAddRecentFoodMutation,
    useUpdateRecentFoodMutation,
    useDeleteRecentFoodMutation
} = foodRecentApi;

