
import { FC } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";

// Servics and interfaces
import { useGetSupplementsQuery } from "@/repositories/supplement";
import { ISupplement } from '@/interfaces/supplement_plan/supplement_builder';

// Styles
import { Add } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import SupplementTable from './supplement_table';
import useBulkActions from "@/components/datatable/use_bulk_actions";


interface _AddModalProps {
    open: boolean;
    onClose: () => void;
    onAdd: (supplements: ISupplement[]) => void;
}

const AddModal: FC<_AddModalProps> = ({
    open,
    onClose,
    onAdd
}) => {

    const { t } = useTranslation();
    const { selected } = useBulkActions();
    const { data: list } = useGetSupplementsQuery();

    const handleAdd = () => {
        if (!list) return;
        const supplements = list.filter((s) => selected.includes(s.id));
        console.log(supplements);
        // Convert each ISupplement to ISupplementItem and pass it to onAdd
        const s: ISupplement[] = supplements.map((s) => {
            return {
                uuid: uuidv4(),
                _id: s.id,
                name: s.name,
                brand: s.brand,
                amount: 0,
                unit: s.unit,
                priority: s.priority,
                category: s.category,
                link: s.link,
                discount_code: s.discount_code,
                notes: s.notes ?? ''
            }
        })
        onAdd(s);
    }

    return (
        <Modal 
            open={open}
            onClose={selected.length > 0 ? undefined : onClose}
            title={t('modals.addSupplements')}
            children={<SupplementTable />}
            action1={{
                kind: 'primary',
                label: t('components.buttons.add'),
                icon: <Add />,
                disabled: !selected.length,
                onClick: handleAdd
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: onClose
            }}
            sx={{'.MuiPaper-root': {width: '100%' ,maxWidth: '1400px'}}}
            />
    )
}

export default AddModal;