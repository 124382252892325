import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FC, useContext, useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Helper
import showToast from "@/_lib/toast";

// Context
import setupContext from "@/contexts/setup_context";

// Services and interfaces
import { selectUser } from "@/store/auth";
import { selectCoach } from "@/store/coach";
import { ApiError } from "@/interfaces/api/error";
import { selectIsSubscribed } from "@/store/subscription";
import { useUpdateUserMutation } from "@/repositories/user";
import { useUpdateCoachMutation } from "@/repositories/coach";
import { IProfileForm, profileSchema } from "@/interfaces/coach/profile_form";

// Components
import ProfileForm from "@/pages/common/profile_form";


const ProfilePage: FC = () => {

    const navigate = useNavigate();
    const { setProfile } = useContext(setupContext);
    const [updateUser, { isLoading: isUserLoading }] = useUpdateUserMutation();
    const [updateCoach, { isLoading: isCoachLoading }] = useUpdateCoachMutation();

    const user = useSelector(selectUser);
    const coach = useSelector(selectCoach);
    const isSubscribed = useSelector(selectIsSubscribed);

    useEffect(() => {
        if (!isSubscribed) navigate('/setup/subscription');
    }, [isSubscribed, navigate]);

    const formMethods = useForm<IProfileForm>({
        resolver: zodResolver(profileSchema),
        mode: 'onBlur',
        defaultValues: {
            first_name: user?.first_name,
            last_name: user?.last_name,
            phone_number: user?.phone_number,
            email: user?.email,
            business_name: coach.business_name,
            avatar: coach.avatar,
            business_logo: coach.business_logo,
        }
    });

    const submitForm = async (data: IProfileForm) => {
        if (!formMethods.formState.isDirty) {
            setProfile(true);
            navigate('/setup/settings');
            return;
        }
        const userPayload = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone_number: data.phone_number,
        };

        const formData = new FormData();
        formData.append('business_name', data.business_name);

        if (formMethods.formState.dirtyFields.avatar && data.avatar) {
            const blob = await fetch(data.avatar as URL).then(r => r.blob());
            formData.append('avatar', blob, 'avatar.jpg');
        }

        if (formMethods.formState.dirtyFields.business_logo && data.business_logo) {
            const blob = await fetch(data.business_logo as URL).then(r => r.blob());
            formData.append('business_logo', blob, 'business_logo.jpg');
        }

        await updateUser(userPayload).unwrap().catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
            return;
        });
        await updateCoach(formData).unwrap().catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
            return;
        });
        setProfile(true);
        navigate('/setup/settings');
    }

    return (
        <FormProvider {...formMethods}>
            <ProfileForm 
                isSetup 
                isLoading={isUserLoading || isCoachLoading} 
                submitForm={formMethods.handleSubmit(submitForm)}
                />
        </FormProvider>
    )
}

export default ProfilePage;


