import { FC, ReactNode } from "react";

// Interfaces
import ButtonAction from "@/interfaces/components/button_action";

// Styles
import { Close } from "@carbon/icons-react";

// Components
import Button from "./button";
import Box from "@mui/material/Box";
import IconButton from "./icon_button";
import Drawer from "@mui/material/Drawer";


interface PanelProps {
    title: string;
    subtitle?: string;
    panelWidth?: string;
    open: boolean;
    keepMounted?: boolean;
    onClose: ()=>void;
    children: ReactNode,
    action1?: ButtonAction,
    action2?: ButtonAction,
}

const RightPanel: FC<PanelProps> = ({
    title,
    subtitle,
    panelWidth = '40vw',
    open,
    keepMounted = false,
    onClose,
    children,
    action1,
    action2,
}) => (

    <Drawer
        open={open}
        onClose={onClose}
        anchor='right'
        className="RightPanel"
        sx={{zIndex: 1300, overflow: 'hidden'}}
        ModalProps={{
            keepMounted: keepMounted
        }}
        PaperProps={{sx: {
            width: panelWidth,
            bgcolor: 'var(--background)',
            display: 'flex',
            flexDirection: 'column'
        }}}>

        {/* HEADER */}
        <Box display='flex' alignItems='center' py={2} px={3} bgcolor='var(--nav-background)'>
            <Box flexGrow={1}>
                {!!subtitle && <span className='label-text-02' style={{color: 'var(--nav-text-secondary)', paddingBottom: 1}}>
                    {subtitle}
                </span>}
                <div className='heading-05' style={{color: 'var(--nav-text-primary)'}}>{title}</div>
            </Box>
            <IconButton 
               size='small'
               icon={<Close size={20} />}
               onClick={onClose} 
            />
        </Box>

        {/* CHILDREN */}
        <Box flexGrow={1} py={2} px={3} sx={{overflowY: 'auto'}}>
            {children}
        </Box>

        {/* ACTIONS */}
        {(action1 || action2) && (
            <Box py={2} px={3} 
                borderTop='1px solid var(--border-subtle-01)' 
                display='flex' justifyContent='end'
            >
                {action2 && <Button
                    kind="tertiary"
                    size="medium"
                    label={action2?.label}
                    endIcon={action2?.icon}
                    disabled={action2?.disabled}
                    loading={action2?.loading}
                    onClick={action2?.onClick}
                    sx={{mr: 3}}
                />}

                {action1 && <Button
                    size="medium"
                    label={action1?.label}
                    endIcon={action1?.icon}
                    disabled={action1?.disabled}
                    loading={action1?.loading}
                    onClick={action1?.onClick}
                />}
            </Box>
        )}

    </Drawer>
)

export default RightPanel;