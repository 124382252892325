import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import { ISupplement } from '@/interfaces/supplement/supplement';
import { ISupplementForm } from '@/interfaces/supplement/supplement_form';


export const supplementApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getSupplements: build.query<ISupplement[], void>({
            query: () => ({ url: '/library/supplements', method: 'get' }),
            transformResponse: (response: IAPIResponse<ISupplement[]>) => response.data.sort((a, b) => a.name.localeCompare(b.name)),
            providesTags: ['Supplement.list'],
            keepUnusedDataFor: 1800
        }),
        addSupplement: build.mutation<ISupplement, ISupplementForm>({
            query: (data) => ({ url: '/library/supplements', method: 'post', data }),
            transformResponse: (response: IAPIResponse<ISupplement>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getSupplements' as never, undefined as never, (supplements: ISupplement[]) => {
                    supplements.push(response.data)
                    supplements.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
        updateSupplement: build.mutation<ISupplement, {id: string, data: ISupplementForm}>({
            query: ({id, data}) => ({ url: `/library/supplements/${id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<ISupplement>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getSupplements' as never, undefined as never, (supplements: ISupplement[]) => {
                    const index = supplements.findIndex((item) => item.id === response.data.id);
                    supplements.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteSupplement: build.mutation<void, string>({
            query: (id) => ({ url: `/library/supplements/${id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(baseApi.util.updateQueryData('getSupplements' as never, undefined as never, (supplements: ISupplement[]) => {
                    return supplements.filter((item) => item.id !== id)
                }))).catch(() => {});
            },
        }),
    }),
})

export const { useGetSupplementsQuery, useAddSupplementMutation, useUpdateSupplementMutation, useDeleteSupplementMutation } = supplementApi;

