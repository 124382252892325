import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Helpers
import showToast from '@/_lib/toast';

// Services and interfaces
import { ApiError } from '@/interfaces/api/error';
import { IFolderItem } from '@/interfaces/folder/folder';
import { useGetFolderQuery } from '@/repositories/folder';
import { useDeleteFilesMutation } from '@/repositories/file';
import { useDeleteLinksMutation } from '@/repositories/link';

// Components
import NoItems from './no_items';
import { Container, Box } from '@mui/material';
import DataTable from '@/components/datatable';
import useItemTableHeaders from './item_headers';
import DeleteModal from '@/pages/vault/components/delete_modal';
import useBulkActions from '@/components/datatable/use_bulk_actions';


const FolderPage: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const headers = useItemTableHeaders();
    const [open, setOpen] = useState<string|null>(null);
    const { selected, handleSetSelected, handleSetShowBulkActions } = useBulkActions();
    
    const { data: folder, isLoading} = useGetFolderQuery(id!);
    const [deleteFiles, { isLoading: isDeletingFiles }] = useDeleteFilesMutation();
    const [deleteLinks, { isLoading: isDeletingLinks }] = useDeleteLinksMutation();

    const items: IFolderItem[] = [...(folder?.files ?? []), ...(folder?.links ?? [])]
        .sort((a, b) => a.name.localeCompare(b.name));

    const handleDeleteMany = () => {
        const files = folder?.files?.filter(f => selected.includes(f.uuid)).map(f => f.uuid) ?? [];
        const links = folder?.links?.filter(l => selected.includes(l.uuid)).map(l => l.uuid) ?? [];

        if (files.length > 0) handleDeleteFiles(files);
        if (links.length > 0) handleDeleteLinks(links);
    }

    const handleDeleteFiles = (files: string[]) => {
        deleteFiles({ id: id!, file_ids: files}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.file.deleted.title', {count: selected.length})
            });
            setOpen(null);
            handleSetShowBulkActions(false);
            handleSetSelected([]);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleDeleteLinks = (links: string[]) => {
        deleteLinks({ id: id!, link_ids: links}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.link.deleted.title', {count: selected.length})
            });
            setOpen(null);
            handleSetShowBulkActions(false);
            handleSetSelected([]);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box height='100%' overflow='auto'>
        <Container sx={{padding: '24px 0'}}>

            <DataTable
                data={items}
                columns={headers}
                dataKey="uuid"
                filterKeys={["name"]}
                hideHeader
                localPagination
                filter={{
                    showSelect: true,
                }}
                bulkActions={{
                    action1: {
                        label: t('components.buttons.delete'),
                        onClick: () => setOpen('deleteMany')
                    }
                }}
                noDataMessage={<NoItems folder={folder!} message={t('components.dataTable.noData.noVaultItems')} />}
                noDataFoundMessage={<NoItems folder={folder!} />}
                isLoading={isLoading}
            />

            {open === 'deleteMany' && <DeleteModal
                type="File"
                open={open === 'deleteMany'}
                onClose={() => setOpen(null)}
                name={items.find(f => f.uuid === selected[0])?.name ?? ''}
                count={selected.length}
                isLoading={isDeletingFiles || isDeletingLinks}
                onDelete={handleDeleteMany}
                />}

        </Container>
        </Box>
    )
}

export default FolderPage;
