import { z } from 'zod';
import dayjs from 'dayjs';
import i18next from 'i18next';
import ruleset from '@/_helpers/ruleset';


export interface IPhaseForm {
    name?: string,
    expected_start?: string,
    expected_end?: string,
    start?: string,
    end?: string,
    description?: string,
}

export const phaseSchema = z.object({
    name: ruleset.required,
    expected_start: ruleset.dateOptional,
    expected_end: ruleset.dateOptional,
    start: ruleset.dateOptional,
    end: ruleset.dateOptional,
    description: ruleset.stringOptional,
}).superRefine(({ expected_start, expected_end, start }, ctx) => {
    if (!!start && dayjs(start).isAfter(dayjs())) {
        ctx.addIssue({
            code: "custom",
            message: i18next.t('inputs.errors.startDateInFuture'),
            path: ['start']
        });
    }
    if (dayjs(expected_end).isBefore(expected_start)) {
        ctx.addIssue({
            code: "custom",
            message: i18next.t('inputs.errors.endDateBeforeStart'),
            path: ['expected_end']
        });
    }
});