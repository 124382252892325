import { FC } from "react";
import { useTranslation } from "react-i18next";

// Contenxt
import { usePhaseContext } from "@/contexts/phase_context";

// Styles
import { Milestone, Add, TextLongParagraph } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import Card from "@/components/card";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@/components/icon_button";

const PhaseCards: FC = () => {

    const { t } = useTranslation()
    const { phase, setOpen } = usePhaseContext();
    
    return (
        <Box display='flex' gap={2} mt={2}>
            <Card sx={{flex:'0 0 30%', py: 1, pr: 1}} onClick={() => setOpen('milestone_panel')}>
                <Box className='body-02-compact' display='flex' alignItems='center'>
                    <Milestone style={{marginRight: 16}}/>
                    {t('components.phases.milestones')}
                    <Box flexGrow={1} />
                    <IconButton 
                        size='small'
                        kind='ghost'
                        icon={<Add />}
                        disabled={phase?.readonly}
                        onClick={() => setOpen('milestone_modal')}
                    />
                </Box>
                {!!phase ? (
                    <Box className='heading-05' pb={1}>
                        {phase.milestones?.filter(m => !!m.completed_at).length ?? 0}/
                        {phase.milestones?.length ?? 0}
                    </Box>
                ) : (
                    <Skeleton sx={{pb: 1}} />
                )}
            </Card>
            <Card sx={{flex:'0 0 30%', py: 1, pr: 1}} onClick={() => setOpen('note_panel')}>
                <Box className='body-02-compact' display='flex' alignItems='center'>
                    <TextLongParagraph style={{marginRight: 16}} />
                    {t('components.phases.notes')}
                    <Box flexGrow={1} />
                    <IconButton 
                        size='small'
                        kind='ghost'
                        icon={<Add />}
                        disabled={phase?.readonly}
                        onClick={() => setOpen('note_modal')}
                    />
                </Box>
                {!!phase ? (
                    <Box className='heading-05' pb={1}>
                        {phase?.notes?.length ?? 0}
                    </Box>
                ) : (
                    <Skeleton sx={{pb: 1}} />
                )}
            </Card>
        </Box>
    )
}

export default PhaseCards;