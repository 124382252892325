import baseApi from '@/repositories/base_api';
import { IFood } from '@/interfaces/food/food';
import { IFoodForm } from '@/interfaces/food/food_form';
import { IAPIResponse } from '@/interfaces/api/response';


export const foodApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getFoods: build.query<IFood[], void>({
            query: () => ({ url: '/library/foods', method: 'get' }),
            transformResponse: (response: IAPIResponse<IFood[]>) => response.data.sort((a, b) => a.name.localeCompare(b.name)),
            providesTags: ['Food.list'],
            keepUnusedDataFor: 1800
        }),
        addFood: build.mutation<IFood, IFoodForm>({
            query: (data) => ({ url: '/library/foods', method: 'post', data }),
            transformResponse: (response: IAPIResponse<IFood>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getFoods' as never, undefined as never, (foods: IFood[]) => {
                    foods.push(response.data)
                    foods.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
        updateFood: build.mutation<IFood, {id: string, data: IFoodForm}>({
            query: ({id, data}) => ({ url: `/library/foods/${id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<IFood>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getFoods' as never, undefined as never, (foods: IFood[]) => {
                    const index = foods.findIndex((item) => item.id === response.data.id);
                    foods.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteFood: build.mutation<void, string>({
            query: (id) => ({ url: `/library/foods/${id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(baseApi.util.updateQueryData('getFoods' as never, undefined as never, (foods: IFood[]) => {
                    return foods.filter((item) => item.id !== id)
                }))).catch(() => {});
            },
        }),
    }),
})

export const { useGetFoodsQuery, useAddFoodMutation, useUpdateFoodMutation, useDeleteFoodMutation } = foodApi;

