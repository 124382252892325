export const appearance = {
    // theme: "stripe",
  
    variables: {
        //   colorBackground: styles.getPropertyValue("--support-error"),
        //   colorText: 'var(--text-primary)',
        fontFamily: '"IBM Plex Sans", sans-serif',
        borderRadius: '4px',
    },
    rules: {
        '.Input': {
            color: 'var(--text-primary)',
            boxShadow: 'none',
            // backgroundColor: 'var(--support-error)',
            borderRadius: '4px',
            padding: '8px',
            fontSize: '16px',
        },
        '.Input--invalid': {

        },
        '.Input:focus': {
            outline: 'none',
            boxShadow: 'none',
            border: 'solid 1px var(--focus)'
        }
    }
};

export default appearance;