import { t } from "i18next";
import { FC, useState } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";

// Services and interfaces
import { selectHasTeam, selectIsOwner } from "@/store/team";
import { useGetReferralStatsQuery } from "@/repositories/referral";

// Styles
import { SkillLevelAdvanced, SkillLevelBasic, SkillLevelIntermediate } from "@carbon/icons-react";

// Components
import ReferralModal from "./referral_modal";
import { Box, Skeleton } from "@mui/material";
import { InlineNotification } from "@/components/notification/notification";


const ReferralStatsHeader: FC = () => {

    const hasTeam = useSelector(selectHasTeam);
    const isOwner = useSelector(selectIsOwner);
    const [open, setOpen] = useState<boolean>(false);
    const { data: stats, isLoading } = useGetReferralStatsQuery();
    const level = stats?.status ?? 'bronze';

    const _icon = () => {
        switch (level) {
            case 'bronze':
                return <SkillLevelBasic />;
            case 'silver':
                return <SkillLevelIntermediate />;
            case 'gold':
                return <SkillLevelAdvanced />;
            default:
                return <SkillLevelBasic />;
        }
    }

    return (
        <Box display="flex" flexDirection="column" rowGap="24px" padding="24px 32px" borderRadius="6px" border="solid 1px var(--border-subtle-01)" sx={{bgcolor: 'var(--layer-01)'}}>
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr">

                <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <span className="label-text-02">{t('pages.settings.referral.stats.succesfulReferrals')}</span>
                    {isLoading ? 
                        <Skeleton variant="rectangular" height="36px" width="50px" /> : 
                        <span className="heading-04">{stats?.total_referrals}</span>}
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <span className="label-text-02">{t('pages.settings.referral.stats.activeReferrals')}</span>
                    {isLoading ? 
                        <Skeleton variant="rectangular" height="36px" width="50px" /> : 
                        <span className="heading-04">{stats?.active_referrals}</span>}
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <span className="label-text-02">{t('pages.settings.referral.stats.totalCredit')}</span>
                    {isLoading ? 
                        <Skeleton variant="rectangular" height="36px" width="75px" /> : 
                        <span className="heading-04">£{stats?.total_value && stats.total_value/100}</span>}
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <span className="label-text-02">{t('pages.settings.referral.stats.status')}</span>
                    {isLoading ? 
                        <Skeleton variant="rectangular" height="36px" width="150px" /> : 
                        <Box display="flex" alignItems="center">
                            <span className="heading-04">{t(`enums.referralClass.${stats?.status}`)}</span>
                        </Box>}
                </Box>
                
            </Box>

            {/* Status notification */}
            {(!hasTeam || isOwner) && <InlineNotification
                type="success"
                title={t(`inlineNotifications.referrals.${level}Status.title`)}
                message={<Trans 
                    i18nKey={`inlineNotifications.referrals.${level}Status.message`} 
                    components={{strong: <strong />}}
                    values={{context: `${hasTeam}`}}
                />}
                action={{
                    label: t('components.buttons.viewLevels'),
                    icon: _icon(),
                    onClick: () => setOpen(true)
                }}
                />}
                {stats && open && <ReferralModal 
                    open={open} 
                    onClose={() => setOpen(false)} 
                    status={level} 
                    />}
        </Box>
    )
}

export default ReferralStatsHeader;