import { axiosBaseQuery } from '@/_lib/axios'
import { createApi } from '@reduxjs/toolkit/query/react'

const paymentMethodTags = ['PaymentMethod.list'];
const invoiceTags = ['Invoice.list', 'Invoice.latest', 'Invoice.upcoming'];
const nutritionTemplateTags = ['Templates.nutrition.list'];

export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: [...paymentMethodTags, ...invoiceTags, ...nutritionTemplateTags],
    endpoints: () => ({}),
})

export default baseApi;
