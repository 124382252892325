import { FC } from "react";
import { t } from "i18next";
import { Trans } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { ILeadForm } from "@/interfaces/lead_form/lead_form";
import { useTogglePublishedMutation } from "@/repositories/lead_form";

// Styles
import { CloudOffline, CloudUpload } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Tooltip from "@/components/tooltip";
import IconButton from "@/components/icon_button";
import useBulkActions from "@/components/datatable/use_bulk_actions";


interface _TogglePublishedProps {
    row: ILeadForm;
}

const TogglePublished: FC<_TogglePublishedProps> = ({
    row
}) => {
    
    const publicUrl = import.meta.env.VITE_PUBLIC_URL;
    const { showBulkActions } = useBulkActions();
    const [updateLeadForm, { isLoading }] = useTogglePublishedMutation();

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`${publicUrl}coach/enquiries/${row.id}`);
    }

    const handleTogglePublished = async () => {
        updateLeadForm(row.id).unwrap().then((r) => {
            showToast({
                type: 'success', 
                title: t('notifications.form.published.title', {context: `${!row.published}`}), 
                message: <Trans 
                    i18nKey="notifications.form.published.message"
                    values={{context: `${!row.published}`}}
                    />
            });
            if (r.published) handleCopyLink();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Tooltip kind="nav" size="medium" title={row.published ? t('tooltips.unpublish') : t('tooltips.publish')} placement="top">
            <Box>
                <IconButton
                    kind="ghost"
                    size="small"
                    icon={row.published ? <CloudOffline /> : <CloudUpload color={showBulkActions ? 'var(--icon-disabled)' : 'var(--support-info)'} />}
                    loading={isLoading}
                    disabled={showBulkActions}
                    onClick={handleTogglePublished}
                    />
            </Box>
        </Tooltip>
    )
}

export default TogglePublished;