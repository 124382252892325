import baseApi from '@/repositories/base_api';
import { IPlanForm } from '@/interfaces/plan/plan';
import { IAPIResponse } from '@/interfaces/api/response';
import { ICopyPlanForm } from '@/interfaces/plan/copy_plan';
import { ISupplementPlan } from '@/interfaces/supplement_plan/supplement_plan';
// import { ISupplementPlanForm } from '@/interfaces/supplement_plan/supplement_plan_form';


export const supplementPlanApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getSupplementPlans: build.query<ISupplementPlan[], string>({
            query: (id) => ({ url: `clients/${id}/supplements`, method: 'get' }),
            transformResponse: (response: IAPIResponse<ISupplementPlan[]>) => response.data.sort((a,b) => Number(b.active)-Number(a.active)),
            providesTags: (result, error, id) => [
                { type: 'Plans.supplement.list', id }, 
                { type: 'Plans.supplement.list', id: 'ALL' }
            ],
            keepUnusedDataFor: 900
        }),
        getSupplementPlan: build.query<ISupplementPlan, IPlanForm>({
            query: (p) => ({ url: `clients/${p.client_id}/supplements/${p.plan_id}`, method: 'get' }),
            transformResponse: (response: IAPIResponse<ISupplementPlan>) => response.data,
        }),
        addSupplementPlan: build.mutation<ISupplementPlan, {id: string, data: ISupplementPlan}>({
            query: ({id, data}) => ({ url: `clients/${id}/supplements`, method: 'post', data }),
            transformResponse: (response: IAPIResponse<ISupplementPlan>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getSupplementPlans' as never, _.id as never, (plans: ISupplementPlan[]) => {
                    plans.push(response.data)
                    plans.sort((a, b) => a.name.localeCompare(b.name)).sort((a,b) => Number(b.active)-Number(a.active));
                }))).catch(() => {});
            },
        }),
        updateSupplementPlan: build.mutation<ISupplementPlan, {p: IPlanForm, data: ISupplementPlan}>({
            query: ({p, data}) => ({ url: `clients/${p.client_id}/supplements/${p.plan_id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<ISupplementPlan>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getSupplementPlans' as never, _.p.client_id as never, (plans: ISupplementPlan[]) => {
                    const index = plans.findIndex((item) => item.id === response.data.id);
                    plans.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteSupplementPlan: build.mutation<void, IPlanForm>({
            query: (p) => ({ url: `/clients/${p.client_id}/supplements/${p.plan_id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(p, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(baseApi.util.updateQueryData('getSupplementPlans' as never, p.client_id as never, (plans: ISupplementPlan[]) => {
                    return plans.filter((item) => item.id !== p.plan_id)
                }))).catch(() => {});
            },
        }),
        deleteSupplementPlans: build.mutation<void, {id: string, plans: string[]}>({
            query: ({id, plans}) => ({ url: `/clients/${id}/supplements/bulk-delete`, method: 'post', data: {ids: plans} }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(baseApi.util.updateQueryData('getSupplementPlans' as never, params.id as never, (prospects: ISupplementPlan[]) => {
                    return prospects.filter((item) => !params.plans.includes(item.id))
                }))).catch(() => {});
            },
        }),
        copySupplementPlan: build.mutation<ISupplementPlan, ICopyPlanForm>({
            query: (p) => ({ url: `/clients/${p.client_id}/supplements/${p.plan_id}/copy?template=${p.copy_to_template}`, method: 'post' }),
            transformResponse: (response: IAPIResponse<ISupplementPlan>) => response.data,
            invalidatesTags: (result, _, arg) => {
                if (arg.copy_to_template) return ['Templates.supplement.list'];
                return [];
            },
            async onQueryStarted({ client_id }, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getSupplementPlans' as never, client_id as never, (plans: ISupplementPlan[]) => {
                    if (!response.data) return;
                    plans.push(response.data)
                    plans.sort((a, b) => a.name.localeCompare(b.name)).sort((a,b) => Number(b.active)-Number(a.active));
                }))).catch(() => {});
            },
        }),
        setActiveSupplementPlan: build.mutation<ISupplementPlan, {client_id: string, plan_id: string}>({
            query: ({client_id, plan_id}) => ({ url: `/clients/${client_id}/supplements/${plan_id}/active`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<ISupplementPlan>) => response.data,
            async onQueryStarted({ client_id }, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getSupplementPlans' as never, client_id as never, (plans: ISupplementPlan[]) => {
                    const index = plans.findIndex((item) => item.id === response.data.id);
                    if (index == -1) return plans;
                    plans.splice(index, 1, response.data);
                    plans.map((item) => {
                        if (item.id !== response.data.id) return item.active = false;
                        return item;
                    });
                }))).catch(() => {});
            },
        }),
    }),
})

export const { 
    useGetSupplementPlansQuery,
    useGetSupplementPlanQuery,
    useLazyGetSupplementPlanQuery,
    useAddSupplementPlanMutation,
    useUpdateSupplementPlanMutation,
    useDeleteSupplementPlanMutation,
    useDeleteSupplementPlansMutation,
    useCopySupplementPlanMutation,
    useSetActiveSupplementPlanMutation,
} = supplementPlanApi;

