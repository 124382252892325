import { FC } from "react";
import { useTranslation } from "react-i18next";

// Helpers
import { roundNumber, totalMacro } from "@/_helpers/number_functions";

// Services and interfaces
import { IClientNutrition } from "@/interfaces/client/client_nutrition";

// Components
import { Box } from "@mui/material";
import useFoodTableHeaders from "./food_headers";
import BuilderTable from "@/components/builder/table";


interface _NutritionBreakdownProps {
    nutrition: IClientNutrition;
}

const NutritionBreakdown: FC<_NutritionBreakdownProps> = ({
    nutrition
}) => {

    const { t } = useTranslation();
    const headers = useFoodTableHeaders();
    
    return (
        <Box display="flex" flexDirection="column" rowGap="24px">

            <Box display="flex" flexDirection="column">

                {/* Date */}
                <Box display="flex" justifyContent="space-between" alignItems="center" padding="8px 0" sx={{borderBottom: 'solid 1px var(--border-subtle-01)'}}>
                    <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{t('timeDate.date')}</span>
                    <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{new Date(nutrition.date).toLocaleDateString()}</span>
                </Box>

                {/* Plan day */}
                <Box display="flex" justifyContent="space-between" alignItems="center" padding="8px 0" sx={{borderBottom: 'solid 1px var(--border-subtle-01)'}}>
                    <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{t('rightPanel.nutrition.daySelected')}</span>
                    <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{nutrition.plan?.name ?? t('rightPanel.nutrition.noPlan')}</span>
                </Box>

                {/* Total calories */}
                <Box display="flex" justifyContent="space-between" alignItems="center" padding="8px 0" sx={{borderBottom: 'solid 1px var(--border-subtle-01)'}}>
                    <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{t('metrics.calories')}</span>
                    <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{`${totalMacro(nutrition.meals, 'calories')} ${t('metrics.kcal')}`}</span>
                </Box>

                {/* Total protein */}
                <Box display="flex" justifyContent="space-between" alignItems="center" padding="8px 0" sx={{borderBottom: 'solid 1px var(--border-subtle-01)'}}>
                    <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{t('metrics.protein')}</span>
                    <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{`${totalMacro(nutrition.meals, 'protein')} ${t('metrics.gram')}`}</span>
                </Box>

                {/* Total carbs */}
                <Box display="flex" justifyContent="space-between" alignItems="center" padding="8px 0" sx={{borderBottom: 'solid 1px var(--border-subtle-01)'}}>
                    <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{t('metrics.carbs')}</span>
                    <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{`${totalMacro(nutrition.meals, 'carbs')} ${t('metrics.gram')}`}</span>
                </Box>

                {/* Total fat */}
                <Box display="flex" justifyContent="space-between" alignItems="center" padding="8px 0" sx={{borderBottom: 'solid 1px var(--border-subtle-01)'}}>
                    <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{t('metrics.fat')}</span>
                    <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{`${totalMacro(nutrition.meals, 'fat')} ${t('metrics.gram')}`}</span>
                </Box>

                {/* Total fibre */}
                <Box display="flex" justifyContent="space-between" alignItems="center" padding="8px 0">
                    <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{t('metrics.fibre')}</span>
                    <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{`${totalMacro(nutrition.meals, 'fibre')} ${t('metrics.gram')}`}</span>
                </Box>

            </Box>

            {nutrition.meals.map((meal) => (
                <Box key={meal.uuid} display="flex" flexDirection="column" width="100%" padding="16px 8px" boxSizing="border-box" borderRadius="6px" border="solid 1px var(--border-subtle-01)" sx={{bgcolor: 'var(--layer-01)'}}>

                    <Box display="flex" justifyContent="space-between" alignItems="center" padding="0 16px 0 8px">
                        <Box display="flex" flexDirection="column" justifyContent="center" overflow="hidden">
                            <span style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{meal.name}</span>
                            {/* <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{'12:30pm'}</span> */}
                        </Box>

                        <Box display="flex" alignItems="center" boxSizing="border-box">
                            <Box display="flex" alignItems="flex-end" padding="0 8px" boxSizing="border-box">
                                <span className="body-01" style={{color: 'var(--text-secondary)', marginRight: '4px'}}>{roundNumber(meal.protein as number, 1)}</span>
                                <span className="body-02-compact" style={{color: 'var(--text-placeholder)'}}>{t('metrics.proteinLetter')}</span>
                            </Box>
                            <Box display="flex" padding="0 8px" alignItems="flex-end">
                                <span className="body-01" style={{color: 'var(--text-secondary)', marginRight: '4px'}}>{roundNumber(meal.carbs as number, 1)}</span>
                                <span className="body-02-compact" style={{color: 'var(--text-placeholder)'}}>{t('metrics.carbsLetter')}</span>
                            </Box>
                            <Box display="flex" padding="0 8px" alignItems="flex-end">
                                <span className="body-01" style={{color: 'var(--text-secondary)', marginRight: '4px'}}>{roundNumber(meal.fat as number, 1)}</span>
                                <span className="body-02-compact" style={{color: 'var(--text-placeholder)'}}>{t('metrics.fatLetter')}</span>
                            </Box>
                            <Box display="flex" padding="0 8px" alignItems="flex-end">
                                <span className="body-01" style={{color: 'var(--text-secondary)', marginRight: '4px'}}>{roundNumber(meal.fibre as number, 1)}</span>
                                <span className="body-02-compact" style={{color: 'var(--text-placeholder)'}}>{t('metrics.fibreLetter')}</span>
                            </Box>
                            <Box display="flex" padding="0 8px" alignItems="flex-end" boxSizing="border-box">
                                <span className="body-01" style={{color: 'var(--text-secondary)', marginRight: '4px'}}>{roundNumber(meal.calories, 0)}</span>
                                <span className="body-02-compact" style={{color: 'var(--text-placeholder)'}}>{t('metrics.kcal')}</span>
                            </Box>
                        </Box>

                    </Box>

                    {meal.foods.length > 0 && <BuilderTable
                        size="large"
                        data={meal.foods}
                        columns={headers}
                        dataKey="_id"
                        />}
                </Box>
                ))}

        </Box>
    )
}

export default NutritionBreakdown;


// interface _MealTotalsRowProps {
//     meal: IMeal;
// }

// const _MealTotalsRow: FC<_MealTotalsRowProps> = ({
//     meal
// }) => {

//     const { t } = useTranslation();

//     return (
//         <Table>
//             <TableBody>
//                 <TableRow>
//                     <TableCell width="45%" sx={{padding: '0 8px', border: 'none'}}>
//                         {/* Empty cell */}
//                     </TableCell>
//                     <TableCell width="10%" sx={{padding: '0 8px', border: 'none'}}>
//                         <Box display="flex">
//                             <span>{roundNumber(meal.protein as number, 1)}</span>
//                             <span style={{color: 'var(--text-placeholder)', marginLeft: '4px'}}>{t('metrics.gram')}</span>
//                         </Box>
//                     </TableCell>
//                     <TableCell width="10%" sx={{padding: '0 8px', border: 'none'}}>
//                         <Box display="flex">
//                             <span>{roundNumber(meal.carbs as number, 1)}</span>
//                             <span style={{color: 'var(--text-placeholder)', marginLeft: '4px'}}>{t('metrics.gram')}</span>
//                         </Box>
//                     </TableCell>
//                     <TableCell width="10%" sx={{padding: '0 8px', border: 'none'}}>
//                         <Box display="flex">
//                             <span>{roundNumber(meal.fat as number, 1)}</span>
//                             <span style={{color: 'var(--text-placeholder)', marginLeft: '4px'}}>{t('metrics.gram')}</span>
//                         </Box>
//                     </TableCell>
//                     <TableCell width="10%" sx={{padding: '0 8px', border: 'none'}}>
//                         <Box display="flex">
//                             <span>{roundNumber(meal.fibre as number, 1)}</span>
//                             <span style={{color: 'var(--text-placeholder)', marginLeft: '4px'}}>{t('metrics.gram')}</span>
//                         </Box>
//                     </TableCell>
//                     <TableCell width="15%" sx={{padding: '0 8px 0 0', border: 'none'}}>
//                         <Box display="flex">
//                             <span style={{whiteSpace: 'nowrap'}}>{roundNumber(meal.calories, 0)}</span>
//                             <span style={{color: 'var(--text-placeholder)', marginLeft: '4px'}}>{t('metrics.kcal')}</span>
//                         </Box>
//                     </TableCell>
//                 </TableRow>
//             </TableBody>
//         </Table>
//     )
// }