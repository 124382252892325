import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Helpers
// import { capitalize } from "@/_helpers/text_functions";

// Interfaces
import { selectHasTeam } from "@/store/team";
import { INutritionTemplate } from "@/interfaces/nutrition_template/nutrition_template";

// Components
import TableToggle from "./toggle_shared";
import TemplateMenu from "./nutrition_menu";
import { ColDef } from "@/components/datatable";


const useNutritionTemplateTableHeaders = (): ColDef<INutritionTemplate>[] => {

    const { t } = useTranslation();
    const hasTeam = useSelector(selectHasTeam);

    const headers: ColDef<INutritionTemplate>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '30%',
            sortable: true
        },
        {
            field: 'created_at',
            headerName: t('components.dataTable.headers.dateAdded'),
            width: '10%',
            render: (row: INutritionTemplate) => new Date(row.created_at).toLocaleDateString()
        },
        // {
        //     field: 'duration',
        //     headerName: t('components.dataTable.headers.duration'),
        //     width: '10%',
        //     render: (row: INutritionTemplate) => row.duration ? t('timeDate.week', { count: row.duration }) : '-'
        // },
        // {
        //     field: 'tags',
        //     headerName: t('components.dataTable.headers.tag'),
        //     width: '10%',
        //     //TODO
        //     render: (row: INutritionTemplate) => row.tags?.map((tag) => tag).join(', ')
        // },
        {
            field: 'status',
            headerName: t('components.dataTable.headers.status'),
            width: '15%',
            render: (row: INutritionTemplate) => row.draft ? t('general.draft') : t('general.ready')
        },
        {
            field: 'shared',
            headerName: t('components.dataTable.headers.shared'),
            width: '10%',
            render: (row: INutritionTemplate) => <TableToggle row={row} />
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (row: INutritionTemplate) => <TemplateMenu row={row} />
        }
    ];

    if (!hasTeam) {
        return headers.filter((h) => h.field !== 'shared');
    }

    return headers;
}

export default useNutritionTemplateTableHeaders;