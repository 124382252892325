import { FC } from "react";
import { t } from "i18next";

// Services and interfaces
import { IPhaseNote } from "@/interfaces/phase_note/phase_note";

// Styles
import { TextLongParagraph } from "@carbon/icons-react";

// Components
import PhaseNote from "./note";
import Box from "@mui/material/Box";
import PopoverTooltip from "@/components/phases/popover_tooltip";


interface TooltipProps {
    totalCount: number;
    phase_id: string;
    notes: IPhaseNote[];
    onShowAll: () => void
}

const PhaseNotesTooltip: FC<TooltipProps> = ({
    totalCount,
    phase_id,
    notes,
    onShowAll
}) => (
    <PopoverTooltip 
        icon={<TextLongParagraph />}
        label={t('components.buttons.showAll', {count: totalCount})}
        onClick={onShowAll}
    >
        <Box p={1} display='flex' flexDirection='column' gap={2} >
            <span className="heading-07-compact">{t('components.phases.notes')}</span>
            {notes.map(n => (
                <PhaseNote key={n.id} phase_id={phase_id} note={n} />
            ))}
        </Box>
    </PopoverTooltip>
)

export default PhaseNotesTooltip;