import { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Interfaces
import { TotalValues } from "@/interfaces/components/totals_card";

// Styles
import { CaretDown, CaretUp } from "@carbon/icons-react";

// Components
import Button from "@/components/button";
import { Box, Skeleton } from "@mui/material";
import { selectSiteSettingsBodyweight } from "@/store/settings";


interface _TotalsCardProps {
    metric: 'average' | 'high' | 'low';
    totals: TotalValues;
    isLoading?: boolean;
    hideChartButton?: boolean;
    onShowChart?: () => void;
}

const TotalsCard: FC<_TotalsCardProps> = ({ 
    metric,
    totals,
    isLoading = false,
    hideChartButton = false,
    onShowChart
}) => {

    const { t } = useTranslation();
    const weightUnit = useSelector(selectSiteSettingsBodyweight) ?? {value: 'kg'};

    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start" height="114px" width="100%" padding="10px 16px 16px 16px" boxSizing="border-box" borderRadius="6px" border="solid 1px var(--border-subtle-01)" marginBottom="16px" sx={{bgcolor: 'var(--layer-01)'}}>
                        
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" marginBottom="8px" minHeight="32px">
                {isLoading ?
                    <Skeleton variant="rounded" width="100px" height="18px" sx={{marginBottom: '20px'}} /> :
                    <span className="body-02-compact" style={{textAlign: 'left'}}>{t(`components.chartCard.bodyweight.${metric}`)}</span>}
                    {!hideChartButton && <Button 
                        kind="ghost"
                        size="small"
                        label={t('components.buttons.showChart')}
                        minWidth={false}
                        disabled={!onShowChart}
                        onClick={() => onShowChart!()} />}
            </Box>

            {isLoading ?
                <Skeleton variant="rounded" height="28px" width="100px" sx={{marginBottom: '4px'}} /> :
                <Box display="flex" alignItems="center">
                    <span className="heading-05" style={{marginBottom: '4px'}}>{`${totals.value ? totals.value : '-'} ${weightUnit.value}`}</span>
                    {totals.change !== 0 ? 
                        <Box display="flex" alignItems="center" marginLeft="8px">
                            {totals.change > 0 ? <CaretUp size="20px" style={{color: 'var(--support-success)'}} /> : <CaretDown size="20px" style={{color: 'var(--support-error)'}} />}
                            <span className="body-02">{`${totals.change > 0 ? '+' : ''}${totals.change} ${weightUnit.value}`}</span>
                        </Box> : <span className="body-02" style={{marginLeft: '8px'}}>{`(${t('components.chartCard.bodyweight.noChange')})`}</span>}
                </Box>}

            {isLoading ?
                <Skeleton variant="rounded" width="100px" height="16px" /> :
                <span className="helper-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.chartCard.bodyweight.daysLogged', {count: totals.daysLogged})}</span>}

        </Box>
    )
}

export default TotalsCard;