import { FC } from "react";
import { Outlet } from "react-router-dom";

// Components
import { Box, Toolbar } from '@mui/material';
import NavToolbar from "@/components/navigation/nav_toolbar";


const EditLayout:FC = () => {

    return (
        <>
            <NavToolbar />
            <Toolbar variant='dense' />

            <Box component='main' minHeight='calc(100vh - 40px)' overflow='hidden'>

                <Outlet />

            </Box>
        </>
    )
}

export default EditLayout;