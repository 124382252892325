import dayjs from "dayjs";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import IPhase from "@/interfaces/phase/phase";
import { ApiError } from "@/interfaces/api/error";
import { useEndPhaseMutation } from "@/repositories/phase";

// Components
import Modal from "@/components/modal/modal";
import { ArrowRight } from "@carbon/icons-react";

interface EndPhaseModalProps {
    open: boolean;
    onClose: () => void;
    phase: IPhase;
}

const ResetPhaseModal: FC<EndPhaseModalProps> = ({
    open,
    onClose,
    phase
}) => {
    
    const { id } = useParams();
    const { t } = useTranslation();
    const [endPhase, { isLoading }] = useEndPhaseMutation();

    const handleEndPhase = () => {
        const now = dayjs.utc().endOf('day').toISOString();
        const req = {client_id: id!, phase_id: phase.id, data: {end: now, reset: true}};
        endPhase(req).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.phase.reset.title'), 
                message: t('notifications.phase.reset.message', {name: phase.name})
            });
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.endPhase.title')}
            text={<Trans i18nKey={'modals.endPhase.text_reset'} />}
            action1={{
                icon: <ArrowRight />,
                label: t('components.buttons.resetPhase'),
                loading: isLoading,
                onClick: handleEndPhase,
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px', background: 'var(--background)'}}}
        />
    )
}

export default ResetPhaseModal;