import { z } from "zod";
import { t } from "i18next";
import ruleset, { addIssue, transformEmptyStringToNull } from "@/_helpers/ruleset";

export interface IProgrammeBuilder {
    id: string;
    team_id: number;
    name: string;
    focus: string;
    notes?: string|undefined;
    duration?: number|undefined;
    plan: IWorkout[];
    settings: ISettings;
    tags: string[];
    type: string;
    draft: boolean;
    status: string;
    shared: boolean;
    created_at: string;
}

export interface ISettings {
    rir: boolean;
    rpe: boolean;
    tempo: boolean;
    set_type: boolean;
    rest_time: boolean;
}

export interface IWorkout {
    uuid: string;
    name: string;
    exercises: IExercise[];
}

export interface IExercise {
    uuid: string;
    _id: string;
    superset_id?: string|undefined;
    name: string;
    metrics: IMetrics;
    sets: ISet[];
}

export interface IMetrics {
    weight: boolean;
    reps: boolean;
    duration: boolean;
    distance: boolean;
    calories: boolean;
}

export interface ISet {
    id: string;
    notes?: string|undefined;
    rep_min?: number|string|undefined;
    rep_max?: number|string|undefined;
    distance_min?: number|string|undefined;
    duration_min?: number|string|undefined;
    calories_min?: number|string|undefined;
    target_rpe?: number|string|undefined;
    target_rir?: number|string|undefined;
    target_rest_time?: number|string|undefined;
    tempo_con?: number|string|undefined;
    tempo_sho?: number|string|undefined;
    tempo_ecc?: number|string|undefined;
    tempo_len?: number|string|undefined;
}

// TODO add in lenth check on all fields
const setSchema = z.array(z.object({
    id: ruleset.uuid,
    notes: ruleset.stringOptional,
    rep_min: transformEmptyStringToNull(ruleset.numberOptional),
    rep_max: ruleset.numberOptional,
    distance_min: transformEmptyStringToNull(ruleset.numberOptional),
    duration_min: transformEmptyStringToNull(ruleset.numberOptional),
    calories_min: transformEmptyStringToNull(ruleset.numberOptional),
    target_rpe: ruleset.numberOptional,
    target_rir: ruleset.numberOptional,
    tempo_con: transformEmptyStringToNull(ruleset.numberOptional),
    tempo_sho: transformEmptyStringToNull(ruleset.numberOptional),
    tempo_ecc: transformEmptyStringToNull(ruleset.numberOptional),
    tempo_len: transformEmptyStringToNull(ruleset.numberOptional),
    target_rest_time: ruleset.numberOptional,
    set_type: ruleset.stringOptional
}));

const exerciseMetricsSchema = z.object({
    weight: z.boolean(),
    reps: z.boolean(),
    duration: z.boolean(),
    distance: z.boolean(),
    calories: z.boolean()
}, {message: t('inputs.errors.required')});

export const exercisesSchema = z.array(z.object({
    uuid: ruleset.uuid,
    _id: ruleset.required,
    superset_id: ruleset.uuidOptional,
    name: ruleset.required,
    metrics: exerciseMetricsSchema,
    sets: setSchema
})).min(1, t('components.programmeBuilder.errors.noExercises'));

export const workoutSchema = z.array(z.object({
    uuid: ruleset.uuid,
    name: ruleset.stringOptional, // Validation of workout name is done in the form schema
    exercises: exercisesSchema
}).superRefine((data, ctx) => {
    if (!data.name) addIssue(ctx, ['name'], t('components.programmeBuilder.errors.noWorkoutTitle'));
    if (data.name && data.name.length > 40) addIssue(ctx, ['name'], t('inputs.errors.titleTooLong', { length: 40 }));
})).min(1, t('components.programmeBuilder.errors.noWorkouts'))

export const programmeSettingsSchema = z.object({
    rir: z.boolean(),
    rpe: z.boolean(),
    tempo: z.boolean(),
    set_type: z.boolean(),  
    rest_time: z.boolean()
});

export const programmeFormSchema = z.object({
    name: ruleset.required,
    focus: ruleset.stringOptional,
    notes: ruleset.stringOptional,
    settings: programmeSettingsSchema
});