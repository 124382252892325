import { useDispatch, useSelector } from 'react-redux';
import { selectSelected, selectShowBulkActions, setShowBulkActions, setSelected } from '@/store/datatable';


const useBulkActions = () => {

  const dispatch = useDispatch();

  const showBulkActions = useSelector(selectShowBulkActions);
  const selected = useSelector(selectSelected);

  const handleSetShowBulkActions = (show: boolean) => {
    dispatch(setShowBulkActions(show));
  };

  const handleSetSelected = (selected: string[]) => {
    dispatch(setSelected(selected));
  };

  return {
    showBulkActions,
    handleSetShowBulkActions,
    selected,
    handleSetSelected
  };

};

export default useBulkActions;