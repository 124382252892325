import { useTranslation } from "react-i18next";

// Helpers
import { capitalizeWords } from "@/_helpers/text_functions";

// Services and interfaces
import { IReferral } from "@/interfaces/referral/referral";

// Styles
import { CircleFill } from "@carbon/icons-react";

// Components
import Tag from "@/components/tag";
import { Box } from "@mui/material";
import { ColDef } from "@/components/datatable";


const useReferralsTableHeaders = (): ColDef<IReferral>[] => {

    const { t } = useTranslation();

    const tagColor = (status: string) => {
        switch (status) {
            case 'registered':
                return 'grey';
            case 'pending':
                return 'grey';
            case 'payable':
                return 'blue';
            case 'paid':
                return 'green';
            case 'cancelled':
                return 'red';
            default:
                return 'grey';
        }
    }

    const getName = (type: string, name?: string) => {
        if (name) return capitalizeWords(name);
        if (type == 'referral') return '-';
        return t(`enums.referralType.${type}`);
    }

    const getStatusColour = (status: string, subscribed: boolean): string => {
        if (status == 'paid' && subscribed) return 'var(--background-brand)';
        if (['pending', 'payable'].includes(status)) return 'var(--support-warning)';
        return 'var(--support-error)';
    }

    const getStatusText = (status: string, subscribed: boolean): string => {
        if (status == 'paid' && subscribed) return t('general.active');
        if (['pending', 'payable'].includes(status)) return t('general.registered');
        return t('general.inactive');
    }

    const headers: ColDef<IReferral>[] = [
        {
            field: 'redeemed_at',
            headerName: t('components.dataTable.headers.date'),
            width: '10%',
            render: (row) => row.redeemed_at ? new Date(row.redeemed_at).toLocaleDateString() : '-'
        },
        {
            field: 'referred_name',
            headerName: t('components.dataTable.headers.name'),
            width: '20%',
            render: (row) => getName(row.type, row.referred_name)
        },
        {
            field: 'amount',
            headerName: t('components.dataTable.headers.value'),
            width: '10%',
            render: (row) => row.amount ? `£${row.amount/100}` : '-'
        },
        {
            field: 'status',
            headerName: t('components.dataTable.headers.creditStatus'),
            width: '15%',
            render: (row) => <Tag label={t(`enums.referralStatus.${row.status}`)} colour={tagColor(row.status)} />
        },
        {
            field: 'subscribed',
            headerName: t('components.dataTable.headers.status'),
            width: '15%',
            render: (row) =>
                <Box display="flex" alignItems="center">
                    {row.type === 'referral' && <CircleFill size="8" style={{color: getStatusColour(row.status, row.subscribed), marginRight: '8px'}} />}
                    {row.type === 'referral' ? getStatusText(row.status, row.subscribed) : '-'}
                </Box>
        },
    ];

    return headers;
}

export default useReferralsTableHeaders;