import { useEffect, useState, useRef } from "react";

function useDebounce<T>(callback: T, delay: number) {
  const [debounceValue, setDebounceValue] = useState(callback);
  useEffect(() => {
      // set delay and add callback to state
      const handler = setTimeout(() => setDebounceValue(callback), delay);

      // cleanup on done
      return () => clearTimeout(handler)
  }, [callback, delay]);
  return debounceValue;
}

function useFirstRender() {
  const firstRender = useRef(true);

  useEffect(() => {
    return () => {
      firstRender.current = false;
    }}, [])

  return firstRender;
}

export { useDebounce, useFirstRender };