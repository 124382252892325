import { FC } from "react";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// Services and interfaces
import { IActivityTemplate } from "@/interfaces/activity_template/activity_template";
import { IProgrammeTemplate } from "@/interfaces/programme_template/programme_template";
import { INutritionTemplate } from "@/interfaces/nutrition_template/nutrition_template";
import { ISupplementTemplate } from "@/interfaces/supplement_template/supplement_template";
import { ITemplateAssignForm, templateAssignSchema } from "@/interfaces/template/assign_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal"
import SelectInput from "@/components/select_input";
import { Box } from "@mui/material";


interface AssignModalProps {
    open: boolean;
    onClose: () => void;
    client_id: string;
    templates: IProgrammeTemplate[] | INutritionTemplate[] | ISupplementTemplate[] | IActivityTemplate[];
    isLoading?: boolean;
    isTemplatesLoading?: boolean;
    onSave: (data: ITemplateAssignForm) => void;
}

const AssignModal: FC<AssignModalProps> = ({
    open,
    onClose,
    client_id,
    templates,
    isLoading = false,
    isTemplatesLoading = false,
    onSave
}) => {

    const { handleSubmit, control, formState } = useForm<ITemplateAssignForm>({
        resolver: zodResolver(templateAssignSchema),
        mode: 'onBlur',
        defaultValues: {
            client_id: client_id,
            template_id: ''
        }
    })
    
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t('modals.assignTemplate.title')}
            children={<>
                <span className='body-02'>
                    {t('modals.assignTemplate.alternateText')}
                </span>
                <Box height={20} />
                <SelectInput 
                    name="template_id"
                    control={control}
                    label={t('components.select.titles.templates')}
                    itemsLoading={isTemplatesLoading}
                    items={templates}
                    itemValue="id"
                    itemLabel="name"
                    />
            </>}
            action1={{
                label: t('components.buttons.assign'),
                icon: <ArrowRight />,
                loading: isLoading,
                disabled: !formState.isValid,
                onClick: handleSubmit(onSave)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            />
    )
}

export default AssignModal;