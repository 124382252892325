
import { FC } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";

// Services and interfaces
import { IExercise } from '@/interfaces/exercise/exercise';
import { useGetExercisesQuery } from "@/repositories/exercise";
import { IExercise as IWorkoutExercise } from '@/interfaces/programme/programme_builder';

// Styles
import { Add } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import ExerciseTable from './exercise_table';
import useBulkActions from "@/components/datatable/use_bulk_actions";


interface _AddModalProps {
    open: boolean;
    onClose: () => void;
    onAdd?: (exercises: IWorkoutExercise[]) => void;
    onReplace?: (exercise: IWorkoutExercise) => void;
}

const AddModal: FC<_AddModalProps> = ({
    open,
    onClose,
    onAdd,
    onReplace,
}) => {

    const { t } = useTranslation();
    const { selected } = useBulkActions();
    const { data: list } = useGetExercisesQuery();

    const mapExercises = (exercises: IExercise[]): IWorkoutExercise[] => {
        return exercises.map((ex) => {
            return {
                uuid: uuidv4(),
                _id: ex.id,
                name: ex.name,
                sets: [{
                    id: uuidv4(),
                    rep_min: '',
                    rep_max: '',
                    duration_min: '',
                    distance_min: '',
                    calories_min: '',
                    tempo_con: '',
                    tempo_ecc: '',
                    tempo_len: '',
                    tempo_sho: '',
                }],
                metrics: {
                    weight: ex.metrics.weight,
                    reps: ex.metrics.reps,
                    duration: ex.metrics.duration,
                    distance: ex.metrics.distance,
                    calories: ex.metrics.calories
                }
            }
        })
    }

    const handleAdd = () => {
        if (!list || !onAdd) return;
        const exercises = selected.map((id) => list.find((e) => e.id === id))
            .filter((e): e is IExercise => e !== undefined);

        // Convert each IExercise to IExercise and pass it to onAdd
        const e: IWorkoutExercise[] = mapExercises(exercises);
        onAdd(e);
    }

    const handleReplace = () => {
        if (!list || !onReplace) return;
        const exercises = list.filter((e) => selected.includes(e.id));

        // Convert each IExercise to IExercise and pass it to onReplace
        const e: IWorkoutExercise[] = mapExercises(exercises);
        onReplace(e[0]);
    }

    return (
        <Modal 
            open={open}
            onClose={selected.length ? undefined : onClose} 
            title={t(`modals.programmeExercise.${onReplace != null ? 'title_replace' : 'title_add'}`)}
            children={<ExerciseTable isReplace={onReplace != null} />}
            action1={{
                kind: 'primary',
                label: t('components.buttons.add'),
                icon: <Add />,
                disabled: !selected.length,
                onClick: onReplace != null ? handleReplace : handleAdd
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: onClose
            }}
            sx={{'.MuiPaper-root': {width: '100%' ,maxWidth: '1400px'}}}
            />
    )
}

export default AddModal;