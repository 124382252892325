import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import NavToolbarActions from "./nav_toolbar_actions"
import Logo from '@/assets/logos/1fit_logo_mini_teal.png';


export default function NavToolbar() {
    return (
        <AppBar position="fixed" elevation={0} sx={{ 
            zIndex: (theme) => theme.zIndex.drawer + 2, 
            bgcolor: 'var(--nav-background)' 
        }}>
            <Toolbar variant='dense' sx={{px: '14px'}}>
                <img src={Logo} alt="1fit-logo" height={26} />
                <Box flexGrow={1} />
                <NavToolbarActions />
            </Toolbar>
        </AppBar>
    )
}