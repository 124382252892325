import dayjs from "dayjs";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

// Services and interfaces
import { IPhaseMilestone } from "@/interfaces/phase_milestone/phase_milestone";

// Components
import { Box } from "@mui/material";
import Card from "@/components/card";
import PhaseMilestone from "./milestone";


interface msGroup {
    [key: string]: IPhaseMilestone[] 
}

interface _MilestonesProps {
    phase_id: string,
    phase_start: string,
    milestones?: IPhaseMilestone[];
}

const Milestones: FC<_MilestonesProps> = ({
    phase_id,
    phase_start,
    milestones
}) => {

    const { t } = useTranslation();

    const mappedMilestones: msGroup = useMemo(() => {
        return milestones?.reduce<msGroup>((prev, curr) => {
            const w = dayjs.utc(curr.date).diff(phase_start, 'week');
            const week = (w + 1).toString()
            prev[week] = prev[week] ?? [];
            prev[week].push(curr);
            return prev;
        }, {}) ?? {};
    }, [milestones, phase_start])
    
    const hasMilestones = milestones && milestones?.length > 0;

    return (
        <>
            {/* No Milestones */}
            {!hasMilestones && (
                <Card sx={{mb: 0.5}}>
                    <span className='body-02'>{t('components.phases.noMilestoneData')}</span>
                </Card>
            )}

            {hasMilestones && Object.entries(mappedMilestones).map(([week, milestones]) => (
                <Box key={week} mb={2}>
                    
                    <span className="heading-07">{`${t('timeDate.week')} ${week}`}</span> 

                    {milestones.map(n => (
                        <Card key={n.id} sx={{mb: 1, mt: '4px'}}>
                            <PhaseMilestone
                                phase_id={phase_id}
                                milestone={n}
                                showMenu
                                />
                        </Card>
                    ))}

                </Box>
            ))}
        </>
    )
}

export default Milestones;