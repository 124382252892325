import { FC } from "react";
import { useTranslation } from "react-i18next";

// Styles
import { Copy } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";


interface _CopyCatalogueItemModalProps {
    open: boolean;
    onClose: () => void;
    name: string;
    type: string;
    isLoading: boolean;
    onCopy: () => void;
}

const CopyCatalogueItemModal: FC<_CopyCatalogueItemModalProps> = ({
    open,
    onClose,
    name,
    type,
    isLoading,
    onCopy
}) => {

    const { t } = useTranslation();

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.copyTemplate.title', {type: type})}
            text={t('modals.copyTemplate.text', {type: type, name: name})}
            action1={{
                kind: 'primary',
                label: t('components.buttons.copy'),
                icon: <Copy />,
                loading: isLoading,
                onClick: onCopy
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px'}}}
            />
    )
}

export default CopyCatalogueItemModal;