import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { ITeamInvitation } from "@/interfaces/team/team";
import { useResendTeamInvitationMutation } from "@/repositories/team_invitation";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";


interface _InvitationMenuItemProps {
    open: boolean;
    onClose: (() => void);
    invitation: ITeamInvitation;
}

const ResendInviteModal: FC<_InvitationMenuItemProps> = ({
    open,
    onClose,
    invitation
}) => {

    const { t } = useTranslation();
    const [resendTeamInvitation, { isLoading }] = useResendTeamInvitationMutation();

    const submit = () => {
        resendTeamInvitation(invitation.id).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.teamInvitation.resend.title'), message: t('notifications.teamInvitation.resend.message', {email: invitation.email})})
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
            onClose();
        });
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t('modals.resendInvite.title')}
            text={
                <Trans
                    i18nKey="modals.resendInvite.text"
                    components={{ strong: <strong /> }}
                    values={{ email: invitation.email }}
                />
            }
            action1={{
                label: t('components.buttons.resendInvite'),
                icon: <ArrowRight />,
                onClick: submit,
                loading: isLoading
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: onClose,
                disabled: isLoading
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px'}}}
            />
    )
}

export default ResendInviteModal;