import { z } from "zod";
import ruleset from "@/_helpers/ruleset";


export interface IFolderForm {
    name: string;
    description: string;
}

export const folderFormSchema = z.object({
    name: ruleset.required,
    description: ruleset.stringOptional
})