import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { ApiError } from "@/interfaces/api/error";
import { useLazyGetActivityTemplateQuery, useUpdateActivityTemplateMutation } from "@/repositories/activity_template";
import { IActivityTemplateForm, activityTemplateFormSchema } from "@/interfaces/activity_template/activity_template_form";

// Styles
import { Save, Settings } from "@carbon/icons-react";

// Components
import { Box, Container } from "@mui/material";
import Steps from "@/components/activity_builder/steps";
import Cardio from "@/components/activity_builder/cardio"
import SecondaryToolbar from "@/components/secondary_toolbar";
import ActivityMenu from "@/components/activity_builder/activity_menu";
import UnsavedChangesModal from "@/pages/common/unsaved_changes_modal";
import ActivityModal from "@/components/activity_builder/activity_modal";
import { IActivityBuilder } from "@/interfaces/activity_plan/activity_builder";


const EditActivityTemplatePage: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const hasTeam = useSelector(selectHasTeam)
    const [open, setOpen] = useState<string|null>(null);
    const [selectedPlan, setSelectedPlan] = useState<string>('steps');
    
    const [getTemplate, { data: template }] = useLazyGetActivityTemplateQuery();
    const [saveTemplate, { isLoading: isSaving }] = useUpdateActivityTemplateMutation();
    const [saveDraftTemplate, { isLoading: isSavingDraft }] = useUpdateActivityTemplateMutation();

    const formMethods = useForm<IActivityTemplateForm>({
        resolver: zodResolver(activityTemplateFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: '',
            steps_notes: '',
            cardio_notes: '',
            steps: [{
                uuid: uuidv4(),
                name: '',
                steps: ''
            }],
            cardio: [{
                uuid: uuidv4(),
                name: '',
                duration: ''
            }],
            draft: true
        }
    });

    const name = useWatch({ control: formMethods.control, name: 'name' });

    useEffect(() => {
        if (id) {
            getTemplate(id).unwrap().then((t) => {
                formMethods.reset({
                    name: t.name,
                    steps_notes: t.steps_notes,
                    cardio_notes: t.cardio_notes,
                    steps: t.steps,
                    cardio: t.cardio,
                    draft: t.draft ?? false
                })
                setLoading(false)
            }).catch((error: ApiError) => {
                navigate(`/templates/activity${hasTeam ? '/personal' : '/'}`);
                showToast({type: 'error', apiError: error.type})
            });
        }
    }, [id, formMethods, getTemplate, navigate, hasTeam])

    useEffect(() => {
        // Used to ensure the user is prompted when navigating away with unsaved changes
    }, [formMethods.formState.isDirty]);

    const handleSelectPlan = (plan: 'steps' | 'cardio') => {
        setSelectedPlan(plan);
    }

    const handleUpdateSettings = (data: IActivityBuilder) => {
        formMethods.setValue('name', data.name, { shouldDirty: true });
        formMethods.setValue('steps_notes', data.steps_notes, { shouldDirty: true });
        formMethods.setValue('cardio_notes', data.cardio_notes, { shouldDirty: true });
        setOpen(null);
    }

    const handleBack = () => {
        if (formMethods.formState.isDirty) return setOpen('unsaved');
        navigate(`/templates/activity${hasTeam ? '/personal' : '/'}`);
    }

    const handleSave = (data: IActivityTemplateForm) => {
        if (!id) return;
        data.draft = false;
        saveTemplate({id, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.template.updated.title'), 
                message: t('notifications.template.updated.message', {name: name})
            });
            navigate(`/templates/activity${hasTeam ? '/personal' : '/'}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleSaveDraft = () => {
        if (!id) return;
        const data = formMethods.getValues();
        data.draft = true;
        saveDraftTemplate({id, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.template.updated.title'), 
                message: t('notifications.template.updated.message', {name: name})
            });
            navigate(`/templates/activity${hasTeam ? '/personal' : '/'}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar
                title={name}
                isLoading={loading}
                onBack={handleBack}
                iconActions={([{kind: "ghost", icon: <Settings />, onClick: () => setOpen('settings')}])}
                action1={{
                    label: t('components.buttons.save'),
                    icon: <Save />,
                    loading: isSaving,
                    disabled: isSavingDraft || template?.readonly,
                    onClick: formMethods.handleSubmit(handleSave),
                    sx: {minWidth: '115px'}
                }}
                action2={{
                    label: t('components.buttons.saveDraft'),
                    loading: isSavingDraft,
                    disabled: isSaving || template?.shared || template?.readonly,
                    onClick: () => handleSaveDraft(),
                }}
                sx={{borderBottom: 'none'}}
                />
            {open === 'settings' && <ActivityModal
                open={open === 'settings'}
                onClose={() => setOpen(null)}
                activity={formMethods.getValues()}
                onSave={handleUpdateSettings}
                />}
            {open === 'unsaved' && 
                <UnsavedChangesModal 
                    open={open === 'unsaved'} 
                    onClose={() => setOpen(null)}
                    onSubmit={() => navigate(`/templates/activity${hasTeam ? '/personal' : '/'}`)}
                    />}

            <FormProvider {...formMethods}>
                <Container sx={{padding: '16px 24px 0 24px', overflow: 'hidden'}}>
                    <Box className="Builder">

                        <ActivityMenu 
                            section={selectedPlan}
                            onSelectPlan={handleSelectPlan}
                            />

                        {selectedPlan == 'steps' && <Steps />}
                        {selectedPlan == 'cardio' && <Cardio />}
                    </Box>
                </Container>
            </FormProvider>
        </Box>
    )
}

export default EditActivityTemplatePage;