import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useWatch } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { IFood } from "@/interfaces/food/food";
import { ApiError } from "@/interfaces/api/error";
import { IFoodForm, foodFormSchema } from "@/interfaces/food/food_form";

// Components
import FoodForm from "./food_form";
import Modal from "@/components/modal/modal";
import { useAddFoodMutation, useUpdateFoodMutation } from "@/repositories/food";


interface FoodModalProps {
    open: boolean;
    onClose: () => void;
    food?: IFood;
}

const FoodModal: FC<FoodModalProps> = ({
    open,
    onClose,
    food
}) => {

    const { t } = useTranslation();
    const [addFood, { isLoading: isAddingFood }] = useAddFoodMutation();
    const [updateFood, { isLoading: isUpdatingFood }] = useUpdateFoodMutation();

    const formMethods = useForm<IFoodForm>({
        resolver: zodResolver(foodFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: food?.name ?? '',
            macros: food?.macros ?? {
                protein: '',
                carbs: '',
                fat: '',
                fibre: '',
                calories: 0
            },
            unit: food?.unit ?? {
                label: 'Gram',
                weight: 1
            },
            units: food?.units.filter((u) => u.custom == undefined) ?? [{label: 'Gram', weight: 1}],
            custom_units: food?.units.filter((u) => u.custom == true) ?? []
        }
    });

    const macros = useWatch({control: formMethods.control, name: 'macros'});
    useEffect(() => {
        if (macros.protein !== '' && macros.carbs !== '' && macros.fat !== '') {
            const calculatedCalories = parseFloat((
                    ((macros.protein as number || 0) * 4) +
                    ((macros.carbs as number || 0) * 4) +
                    ((macros.fat as number || 0) * 9)
                ).toFixed(1));
            formMethods.setValue('macros.calories', calculatedCalories, { shouldValidate: true });
        } else {
            formMethods.setValue('macros.calories', 0);
        }
    }, [macros.protein, macros.carbs, macros.fat]);

    const handleClose = () => {
        formMethods.reset();
        onClose();
    }

    const submitAddFood = (data: IFoodForm) => {
        addFood(data).unwrap().then((exercise) => {
            showToast({type: 'success', title: t('notifications.food.created.title'), message: t('notifications.food.created.message', {name: exercise.name})});
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const submitUpdateFood = (data: IFoodForm) => {
        if (!food) return;
        updateFood({id: food.id, data: data}).unwrap().then((exercise) => {
            showToast({type: 'success', title: t('notifications.food.updated.title'), message: t('notifications.food.updated.message', {name: exercise.name})});
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Modal
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : onClose}
            title={food ? food.name : t('modals.addFood')}
            children={
                <FormProvider {...formMethods}>
                    <FoodForm 
                        type={food?.library}
                        />
                </FormProvider>}
            action1={{
                label: t('components.buttons.save'),
                disabled: !formMethods.formState.isDirty,
                loading: isAddingFood || isUpdatingFood,
                onClick: formMethods.handleSubmit(food ? submitUpdateFood : submitAddFood),
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isAddingFood || isUpdatingFood,
                onClick: handleClose
            }}
            />
    )
}

export default FoodModal;