import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import { ICheckinTemplate } from '@/interfaces/checkin_template/checkin_template';
import { ICheckinTemplateForm } from '@/interfaces/checkin_template/checkin_template_form';
import { ITemplateAssignForm, ITemplateAssignManyForm } from '@/interfaces/template/assign_form';


export const checkinTemplateApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCheckinTemplates: build.query<ICheckinTemplate[], void>({
            query: () => ({ url: '/templates/checkins', method: 'get' }),
            transformResponse: (response: IAPIResponse<ICheckinTemplate[]>) => response.data,
            providesTags: ['Templates.checkin.list']
        }),
        getCheckinTemplate: build.query<ICheckinTemplate, string>({
            query: (id) => ({ url: `/templates/checkins/${id}`, method: 'get' }),
            transformResponse: (response: IAPIResponse<ICheckinTemplate>) => response.data,
        }),
        addCheckinTemplate: build.mutation<ICheckinTemplate, ICheckinTemplateForm>({
            query: (data) => ({ url: '/templates/checkins', method: 'post', data }),
            transformResponse: (response: IAPIResponse<ICheckinTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getCheckinTemplates' as never, undefined as never, (templates: ICheckinTemplate[]) => {
                    templates.push(response.data)
                }))).catch(() => {});
            },
        }),
        updateCheckinTemplate: build.mutation<ICheckinTemplate, {id: string, data: ICheckinTemplateForm}>({
            query: ({id, data}) => ({ url: `/templates/checkins/${id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<ICheckinTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getCheckinTemplates' as never, undefined as never, (templates: ICheckinTemplate[]) => {
                    const index = templates.findIndex((item) => item.id === response.data.id);
                    templates.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteCheckinTemplate: build.mutation<void, string>({
            query: (id) => ({ url: `/templates/checkins/${id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(baseApi.util.updateQueryData('getCheckinTemplates' as never, undefined as never, (templates: ICheckinTemplate[]) => {
                    return templates.filter((item) => item.id !== id)
                }))).catch(() => {});
            },
        }),
        deleteCheckinTemplates: build.mutation<void, string[]>({
            query: (ids) => ({ url: `/templates/checkins/bulk-delete`, method: 'post', data: {ids: ids} }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(ids, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(checkinTemplateApi.util.updateQueryData('getCheckinTemplates', undefined, templates => {
                    return templates.filter((item) => !ids.includes(item.id))
                }))).catch(() => {});
            },
        }),
        copyCheckinTemplate: build.mutation<ICheckinTemplate, string>({
            query: (id) => ({ url: `/templates/checkins/${id}/copy`, method: 'post' }),
            transformResponse: (response: IAPIResponse<ICheckinTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(checkinTemplateApi.util.updateQueryData('getCheckinTemplates', undefined, templates => {
                    templates.push(response.data)
                }))).catch(() => {});
            },
        }),
        setDefaultCheckinTemplate: build.mutation<ICheckinTemplate, string>({
            query: (id) => ({ url: `/templates/checkins/${id}/default`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<ICheckinTemplate>) => response.data,
            invalidatesTags: ['Templates.checkin.list'],
        }),
        assignCheckinTemplate: build.mutation<ICheckinTemplate, {id: string, data: ITemplateAssignForm}>({
            query: ({id, data}) => ({ url: `/templates/checkins/${id}/assign`, method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<ICheckinTemplate>) => response.data,
            invalidatesTags: (result, error, { id }) => [
                { type: 'Client', id:  id},
                'Templates.checkin.list'
            ],
        }),
        assignCheckinTemplates: build.mutation<ICheckinTemplate, {id: string, data: ITemplateAssignManyForm}>({
            query: ({id, data}) => ({ url: `/templates/checkins/${id}/bulk-assign`, method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<ICheckinTemplate>) => response.data,
            invalidatesTags: [
                { type: 'Client', id: 'ALL' },
                'Templates.checkin.list'
            ],
        }),
        shareCheckinTemplate: build.mutation<ICheckinTemplate, string>({
            query: (id) => ({ url: `/templates/checkins/${id}/share`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<ICheckinTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(checkinTemplateApi.util.updateQueryData('getCheckinTemplates', undefined, templates => {
                    const index = templates.findIndex((item) => item.id === response.data.id);
                    if (index == -1) return templates;
                    const t = [...templates];
                    t.splice(index, 1, response.data);
                    return t;
                }))).catch(() => {});
            },
        }),
    }),
})

export const { 
    useGetCheckinTemplatesQuery,
    useGetCheckinTemplateQuery,
    useLazyGetCheckinTemplateQuery,
    useAddCheckinTemplateMutation,
    useUpdateCheckinTemplateMutation,
    useDeleteCheckinTemplateMutation,
    useDeleteCheckinTemplatesMutation,
    useCopyCheckinTemplateMutation,
    useSetDefaultCheckinTemplateMutation,
    useAssignCheckinTemplateMutation,
    useAssignCheckinTemplatesMutation,
    useShareCheckinTemplateMutation
} = checkinTemplateApi;

