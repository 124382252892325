import { FC, ReactNode } from "react";
import { useDraggable } from "@dnd-kit/core";

// Components
import { Box } from "@mui/material";


interface _DragHandleProps {
  id: string;
  children: ReactNode;
}

const DragHandle: FC<_DragHandleProps> = ({ id, children }) => {
    const { attributes, listeners, setNodeRef } = useDraggable({ id: id });
  
    return (
      <Box ref={setNodeRef} {...attributes} {...listeners} tabIndex={-1}>
        {children}
      </Box>
    );
};

export default DragHandle;