import { FC } from "react"

// Context
import { usePhaseContext } from "@/contexts/phase_context"

// Components
import NotesModal from "./notes/note_modal";
import NotesPanel from "./notes/notes_panel";
import MilestoneModal from "./milestones/milestone_modal";
import MilestonePanel from "./milestones/milestone_panel";

const PhaseDialogs: FC = () => {

    const {phase, open, setOpen} = usePhaseContext()

    if (!phase) return <></>
    return (
        <>
            {/* Notes */}
            <NotesPanel />
            {open === 'note_modal' && <NotesModal 
                phase_id={phase.id} 
                open={open === 'note_modal'} 
                onClose={() => setOpen(null)}
            />}

            {/* Milestones */}
            <MilestonePanel />
            {open === 'milestone_modal' && <MilestoneModal 
                phase_id={phase.id}
                open={open === 'milestone_modal'}
                onClose={() => setOpen(null)}
                />}
        </>
    )
}

export default PhaseDialogs