// Text helpers

export const capitalize = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const capitalizeWords = (string: string) => {
    return string.replace(/\b\w/g, (char: string) => char.toUpperCase());
};

export const snakeToCamel = (string: string) => {
    return string.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
};

export const snakeToCapitalizedWords = (str: string): string => {
    return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}