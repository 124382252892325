import { t } from "i18next";
import { FC, useState } from "react";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import FoodModal from "@/pages/library/foods/food_modal";


interface _NoFoodsProps {
    message?: string;
    hideAction?: boolean;
}

const NoFoods: FC<_NoFoodsProps> = ({
    message = t('components.nutritionBuilder.table.noFoodsFound'),
    hideAction = false
}) => {

    const [open, setOpen] = useState<boolean>(false);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" paddingBottom="12px">

            <Box height="32px" />
            <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{message}</span>
            <Box height="12px" />
            {!hideAction && <Button
                kind="ghost"
                size="small"
                label={t('components.buttons.addFood')}
                endIcon={<Add />}
                minWidth={false}
                onClick={() => setOpen(true)}
                />}
            {open && <FoodModal 
                open={open}
                onClose={() => setOpen(false)}
                />}

        </Box>
    )
}

export default NoFoods;