import { FC, ReactNode } from "react"

import Box, { BoxProps } from "@mui/material/Box"

interface CardProps {
    kind?: 'primary'|'secondary';
    className?: string;
    children: ReactNode | ReactNode[];
    onClick?: ()=>void;
    sx?: BoxProps['sx'];
}

const Card: FC<CardProps> = ({
    kind = 'primary',
    className,
    children,
    onClick,
    sx
}) => (
    <Box onClick={onClick} className={className} sx={{
        p: 2,
        position: 'relative',
        borderRadius: '4px',
        border: `1px solid ${kind == 'secondary' ? 'var(--border-subtle-02)' : 'var(--border-subtle-01)'}`,
        bgcolor: kind == 'secondary' ? 'var(--background)' : 'white',
        '&:hover': !!onClick ? {
            bgcolor: 'var(--layer-01-hover)',
            cursor: 'pointer'
        } : undefined,
        ...sx
    }}>
        {children}
    </Box>
)

export default Card;