import { useTranslation } from "react-i18next";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";
import { capitalize, capitalizeWords } from "@/_helpers/text_functions";

// Interfaces
import { IFood } from "@/interfaces/food/food";

// Components
import DeleteFood from "./delete_food";
import { ColDef } from "@/components/datatable";


const useFoodTableHeaders = (): ColDef<IFood>[] => {

    const { t } = useTranslation();

    const headers: ColDef<IFood>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '25%',
            sortable: true,
            render: (row) => capitalizeWords(row.name)
        },
        {
            field: 'macros.protein',
            headerName: t('components.dataTable.headers.protein'),
            width: '10%',
            render: (row) => <span>{`${roundNumber(row.macros.protein, 2)}g`}</span>
        },
        {
            field: 'macros.carbs',
            headerName: t('components.dataTable.headers.carbs'),
            width: '15%',
            render: (row) => <span>{`${roundNumber(row.macros.carbs, 2)}g`}</span>
        },
        {
            field: 'macros.fat',
            headerName: t('components.dataTable.headers.fat'),
            width: '15%',
            render: (row) => <span>{`${roundNumber(row.macros.fat, 2)}g`}</span>
        },
        {
            field: 'macros.fibre',
            headerName: t('components.dataTable.headers.fibre'),
            width: '10%',
            render: (row) => <span>{`${roundNumber(row.macros.fibre, 2)}g`}</span>
        },
        {
            field: 'macros.calories',
            headerName: t('components.dataTable.headers.calories'),
            width: '10%',
            render: (row) => <span>{`${roundNumber(row.macros.calories, 0)}kcal`}</span>
        },
        {
            field: 'library',
            headerName: t('components.dataTable.headers.library'),
            width: '10%',
            // sortable: true,
            render: (row) => <span>{row.library ? capitalize(row.library) : t('general.na')}</span>
        },
        {
            field: 'actions',
            headerName: '',
            width: '5%',
            align: 'right',
            render: (row) => <DeleteFood row={row} />
        }
    ];

    return headers;
}

export default useFoodTableHeaders;