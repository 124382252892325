import { FC } from "react";
import { useTranslation } from "react-i18next";

// Interfaces
import { ICheckinSubmission } from "@/interfaces/checkin_submission/checkin_submission";

// Components
import Tag from "@/components/tag";
import DeleteCheckin from "./delete_checkin";
import { ColDef } from "@/components/datatable";


const useCheckinsTableHeaders = (): ColDef<ICheckinSubmission>[] => {

    const { t } = useTranslation();

    const headers: ColDef<ICheckinSubmission>[] = [
        {
            field: 'created_at',
            headerName: t('components.dataTable.headers.dateDue'),
            width: '20%',
            render: (row: ICheckinSubmission) => new Date(row.created_at).toLocaleDateString()
        },
        {
            field: 'submitted_at',
            headerName: t('components.dataTable.headers.dateSubmitted'),
            width: '20%',
            render: (row: ICheckinSubmission) => row.submitted_at ? new Date(row.submitted_at).toLocaleDateString() : '-'
        },
        {
            field: 'reviewed_at',
            headerName: t('components.dataTable.headers.dateResponded'),
            width: '20%',
            render: (row: ICheckinSubmission) => row.reviewed_at ? new Date(row.reviewed_at).toLocaleDateString() : '-'
        },
        {
            field: 'status',
            headerName: t('components.dataTable.headers.status'),
            width: '10%',
            render: (row: ICheckinSubmission) => <StatusTag status={row.status} />
        },
        {
            field: 'actions',
            headerName: '',
            width: '10%',
            render: (row: ICheckinSubmission) => <DeleteCheckin row={row} />
        }
    ];

    return headers;
}

export default useCheckinsTableHeaders;

interface _StatusTagProps {
    status: string;
}

const StatusTag: FC<_StatusTagProps> = ({ status }) => {

    const { t } = useTranslation();
    const getTag = () => {
        switch (status) {
            case 'due_today':
                return <Tag label={t('enums.checkinStatus.due_today')} colour='high-contrast' />
            case 'to_do':
                return <Tag label={t('enums.checkinStatus.to_do')} colour='outline' outlined />
            case 'overdue':
                return <Tag label={t('enums.checkinStatus.overdue')} colour='red' />
            case 'in_progress':
                return <Tag label={t('enums.checkinStatus.in_progress')} colour='grey' />
            case 'done':
                return <Tag label={t('enums.checkinStatus.done')} colour='green' />
            case 'missed':
                return <Tag label={t('enums.checkinStatus.missed')} colour='red' />
            default:
                return <Tag label={t('enums.checkinStatus.unknown')} colour='outline' />
        }
    }

    return (
        getTag()
    )
}