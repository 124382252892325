import { Trans, useTranslation } from "react-i18next";
import { Control, useController } from "react-hook-form";
import { BaseSyntheticEvent, useEffect, useState } from "react";

// Styles
import { Error } from '@mui/icons-material';
import { Checkmark, ChevronDown, Close, Help } from "@carbon/icons-react";

// Components
import Tag from "./tag";
import Tooltip from "./tooltip";
import IconButton from "@/components/icon_button";
import { Select, InputAdornment, FormControl, FormHelperText, MenuItem, ListItemIcon, SelectChangeEvent, Box, CircularProgress } from "@mui/material";

export interface ISelectItem {
  [key: string]: any;
}

interface MultielectInputProps {
  name: string;
  control: Control<any>;
  size?: 'small' | 'medium' | 'large';
  // Label for the select
  label?: string;
  // Takes the key of the tooltip from the i18n file
  tooltip?: string;
  // Placeholder text for the select
  placeHolder?: string;
  // Tab index for accessibility
  tabIndex?: number;
  // Allows the select to have an empty value (to reset to null for example)
  allowEmpty?: boolean;
  // Forces the select to take up the full width of the container
  fullWidth?: boolean;
  // Forces the select to have a minimum width of 160px
  minWidth?: boolean;
  // If set, the select will be disabled
  disabled?: boolean;
  // Optional disable clear button
  disableClear?: boolean;
  // Items to be shown in the dropdown
  items: ISelectItem[];
  // If set, the key of the item to be used as the value, e.g. "id"
  itemKey?: string;
  // If set, the value will be shown in the dropdown, e.g. "label (value)"
  itemLabel?: string;
  // If set, the value will be returned, e.g. "value"
  itemValue?: string;
  // Optional loader for items if they are derived from an API
  itemsLoading?: boolean;
  // Optional disabled state function for items
  itemDisabled?: (item: ISelectItem) => boolean;
  // Function to render the label
  renderLabel?: (item: ISelectItem) => string;
  // If set, the value will be returned as an object
  returnObject?: boolean;
  // Optional on change event
  onChange?: (value: any) => void;
}

const MultiselectInput: React.FC<MultielectInputProps>  = ({
  name,
  control,
  size = 'small',
  label,
  tooltip,
  placeHolder,
  tabIndex = -1,
  allowEmpty = false,
  fullWidth = true,
  minWidth = true,
  disabled = false,
  disableClear = false,
  items,
  itemKey = 'id',
  itemLabel = 'label',
  itemValue = 'value',
  itemsLoading = false,
  itemDisabled = () => false,
  renderLabel,
  returnObject = false,
  onChange
}) => {

  const { t } = useTranslation();
  const { field, fieldState } = useController({name, control});
  const [value, setValue] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const valueInRange = value.every(v => items.some(i => i[itemKey] === v));

  const classes = {
    'SelectInput': true,
    'SelectInput-small': size == 'small',
    'SelectInput-medium': size == 'medium',
    'SelectInput-large': size == 'large',
  }

  const validClasses = Object.entries(classes)
      .filter(([,v]) => !!v)
      .map(([k,]) => k)
      .join(' ')

  const styles = {
    width: fullWidth ? '100%' : 'auto',
    minWidth: minWidth ? '160px' : 'auto',
    textAlign: 'left',
    '& .MuiSelect-select': {
      paddingRight: fieldState.invalid ? '0 !important' : '8px !important'
    },
  }

  const CustomArrowIcon: React.FC = (props) => (
    <ChevronDown {...props} />
  );

  useEffect(() => {
    if (field.value == null) {
      setValue([]);
      return;
    }
    if (returnObject) {
      const selectedValues = field.value.map((val: any) => items.find(item => item[itemKey] == val[itemKey])?.[itemKey] ?? '');
      setValue(selectedValues);
    } else if (Array.isArray(field.value)) {
      setValue(field.value);
    } else {
      setValue([]);
    }
  },[field.value, items, itemKey, itemValue, returnObject])

  const handleOpen = (e: BaseSyntheticEvent) => {
    if (e.target.tagName !== 'DIV' && e.target.tagName !== 'SPAN') return;
    setOpen(true);
  };

  const handleChange = (e: SelectChangeEvent<string[]>) => {
    const newValue = e.target.value as string[];
    setValue(newValue);
    // const selectedItems = items.filter(item => newValue.includes(item[itemKey]));
    const selectedItems = newValue.map(value => items.find(item => item[itemKey] === value)).filter(item => item !== undefined);

    if (returnObject) {
      field.onChange(selectedItems);
      onChange && onChange(selectedItems);
    } else {
      const itemValues = selectedItems.map(item => item?.[itemValue]);
      field.onChange(itemValues); 
      onChange && onChange(itemValues);
    }
  }

  const handleDelete = () => {
    setValue([]);
    field.onChange([]);
  }

  return (
    // Width set to 100% to always fill what ever container it is in
    <FormControl sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: '12px', width: '100%'}}>

      {/* Label and tooltip row */}
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" sx={{marginBottom: '6px'}}>

        {/* Label */}
        {label && <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{label}</span>}

        {/* Tooltip */}
        {tooltip && <Tooltip size="medium" title={<Trans className="body-01" i18nKey={tooltip}></Trans>} placement="top">
            <Help />
        </Tooltip>}

      </Box>
        
      {/* Select */}
      <Select
        className={validClasses}
        sx={styles}
        {...field}
        multiple
        // Checks if the value used exists in the items arrray (useful when parent can change items list)
        value={valueInRange ? value : []}
        onChange={handleChange}
        open={open}
        onOpen={(e) => handleOpen(e)}
        onClose={() => setOpen(false)}
        displayEmpty
        tabIndex={tabIndex}
        autoComplete="true"
        disabled={disabled}
        error={fieldState.invalid}
        IconComponent={CustomArrowIcon}
        renderValue={(selected) => {
          if (selected.length == 0) {
            return <span style={{color: 'var(--text-secondary)'}}>{placeHolder ?? t('components.select.placeholder')}</span>;
          }

          const item = items.find(item => item[itemKey] === selected[0]);
          return <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', position: 'relative' }}>
            {item && <span className="body-02-compact">{renderLabel?.(item) ?? item[itemLabel]}</span>}
            {item && selected.length > 1 ? <Tag
              label={`+${selected.length - 1}`}
              sx={{
                backgroundColor: 'var(--tag-high-contrast-background)', 
                color: 'var(--tag-high-contrast-color) !important',
                // Added to override the default color of the text when field disabled
                WebkitTextFillColor: 'var(--tag-high-contrast-color) !important',
                marginLeft: '4px',
              }}
              /> : null
            }
            {/* <span className="body-02-compact" style={{marginLeft: '8px'}}>{t('components.select.multi.options')}</span> */}
            <Box width="100%" />
            {!disableClear && <IconButton
              kind="ghost"
              size={size}
              sx={{padding: '0px', }}
              icon={<Close size="16px" />}
              disabled={disabled}
              onClick={handleDelete}
              />}
            <Box 
              width="1px" height={`calc(100% - 18px)`} 
              sx={{position: 'absolute', right: '1px', backgroundColor: 'var(--border-strong-01)'}} 
              />
          </Box>
        }}
        endAdornment={
          <InputAdornment position="end">
            {fieldState.invalid && <IconButton
              kind="ghost"
              size={size}
              sx={{paddingRight: '20px', marginLeft: '0', '&:hover': {backgroundColor: 'transparent !important'}}}
              icon={fieldState.invalid && <Error color="error" sx={{fontSize: '16px'}} />}
              onClick={() => setOpen(!open)}
              />}
          </InputAdornment>
        }
        MenuProps={{
          sx: {
            '& .MuiMenu-list': {
              maxHeight: '320px'
            }
          }
        }}
        >
        {allowEmpty && <MenuItem value='' sx={{height: '32px'}}>
          {value.includes('') && <Checkmark />}
        </MenuItem>}
        {itemsLoading && <MenuItem value=''>
            <Box display="flex" width="100%" alignItems="center" justifyContent="center">
              <CircularProgress size="16px" />
            </Box>
          </MenuItem>}
        {!itemsLoading && items.map((item) => (
          <MenuItem key={item[itemKey]} value={item[itemKey]} disabled={itemDisabled(item)}>
            <ListItemIcon>
              {value.includes(item[itemKey]) && <Checkmark />}
            </ListItemIcon>
            <span className="body-02">{renderLabel?.(item) ?? item[itemLabel]}</span>
          </MenuItem>
        ))}
      </Select>
      {/* Span inside form helper text due to inability for classes to take precedence */}
      {fieldState.error?.message && <FormHelperText sx={{color: 'var(--text-error)'}}>
        <span className="helper-text-02">{fieldState.error?.message}</span>
      </FormHelperText>}
    </FormControl>
  );
};

export default MultiselectInput;