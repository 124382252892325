import React, { ReactNode, useState } from "react";

// Components
import { Box, BoxProps, Slide, SxProps } from "@mui/material";
import { InlineNotification } from "./notification/notification";




interface BannerProps {
    type: 'error' | 'success' | 'info' | 'warning';
    position?: 'top' | 'bottom';
    title: string;
    message?: string|ReactNode;
    action?: InlineNotificationAction;
    keepOpen?: boolean;
    canDismiss?: boolean;
    sx?: BoxProps['sx'];
}

interface InlineNotificationAction {
    label: string;
    onClick: () => void;
}

export const Banner: React.FC<BannerProps> = ({
    type, 
    position = 'bottom',
    title, 
    message, 
    action, 
    keepOpen = false,
    canDismiss = false,
    sx
}) => {

    const [hide, setHide] = useState(false);
    const handleClick = () => {
        action?.onClick();
        if (!keepOpen) setHide(true);
    }

    const bannerStyles: SxProps = {
        minHeight: '40px',
        position: 'absolute',
        bottom: position == 'bottom' ? 0 : 'unset',
        top: position == 'top' ? 0 : 'unset',
        width: '100%'
    }

    return (
        <Slide in={!hide} direction="up">
            <Box
                sx={{...bannerStyles, ...sx}}
                >
                {/* <Box> */}
                <InlineNotification 
                    type={type}
                    title={title} 
                    message={message}
                    action={action ? {
                        label: action.label,
                        onClick: handleClick
                    } : undefined}
                    canDismiss={canDismiss}
                    onDismiss={() => setHide(true)}
                    />  
            </Box>
        </Slide>
    )
}