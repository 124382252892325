import { Error } from '@mui/icons-material';
import { ChangeEvent, ReactNode } from 'react';
import { Control, useController } from "react-hook-form";
import { Checkbox as MuiCheckBox, FormControlLabel, FormControlLabelProps } from "@mui/material";

//NOTE - Checkbox will not receive form validation error message until manual onBlur
// Literally don't give a fuck as it is only used (validation) on registration for terms

interface CheckboxProps {
  name: string;
  control: Control<any>;
  label?: string|ReactNode;
  tabIndex?: number;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  sx?: FormControlLabelProps['sx']
}

const Checkbox: React.FC<CheckboxProps>  = ({
  name,
  control,
  label,
  tabIndex = -1,
  labelPlacement = 'end',
  disabled = false,
  onChange,
  sx
}) => {

    const { field, fieldState } = useController({name, control});

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      field.onChange(checked)
      onChange && onChange(checked)
    }

    return (
      // Width set to 100% to always fill what ever container it is in
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', textAlign: 'left', marginBottom: '4px', width: '100%'}}>
        <FormControlLabel
          name={name}
          className="body-02-compact text-secondary prevent-select"
          checked={field.value}
          control={
            <MuiCheckBox
              inputProps={{
                ...field,
                onChange: handleChange
              }}
              tabIndex={tabIndex}
              disabled={disabled}
              sx={{padding: '4px 9px !important', alignItems: 'center'}}
            />}
          label={label}
          labelPlacement={labelPlacement}
          sx={{...sx, alignItems: 'center'}}
        />
        {fieldState.invalid && (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <Error color="error" sx={{fontSize: '16px', marginRight: '12px'}} />
            <span className="helper-text-02 text-error">{fieldState.error?.message}</span>
          </div>
        )}
      </div>
    );
};

export default Checkbox;