import { z } from 'zod';
import { t } from 'i18next';
import ruleset from '@/_helpers/ruleset';

export interface ITeamForm {
    [key: string]: string | boolean;
    team_name: string;
}

export const teamSchema = z.object({
    team_name: ruleset.teamName,
})

export const deleteTeamSchema = z.object({
    team_name: ruleset.teamName,
    team_name_confirmation: ruleset.teamName
  }).refine(data => data.team_name === data.team_name_confirmation, {
    path: ['team_name_confirmation'],
    message: t('inputs.errors.teamNameMismatch')
  });