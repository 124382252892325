import { registrationApi } from "@/repositories/registration";
import { SerializedError, createSlice } from "@reduxjs/toolkit";
import { IRegistrationForm } from "@/interfaces/registration/registration_form";

interface IState {
  loading: boolean;
  error: SerializedError | null;
  registered: boolean;
  form: IRegistrationForm;
}

const initialState: IState = {
  loading: false,
  error: null,
  registered: false,
  form: {
    email: '',
    phone_number: '',
    first_name: '',
    last_name: '',
    access_code: '',
    password: '',
    password_confirmation: '',
    business_name: '',
    terms_accepted: false,
  }
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    submitDetails: (state, { payload }) => {
      state.form.first_name = payload.first_name;
      state.form.last_name = payload.last_name;
      state.form.email = payload.email;
      state.form.phone_number = payload.phone_number;
      state.form.access_code = payload.access_code;
    },
    submitPassword: (state, { payload }) => {
      state.form.password = payload.password;
      state.form.password_confirmation = payload.password_confirmation;
    },
    submitBusiness: (state, { payload }) => {
      state.form.business_name = payload.business_name;
      state.form.terms_accepted = payload.terms_accepted;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(registrationApi.endpoints.register.matchFulfilled, (state) => {
      state.registered = true;
      state.form = initialState.form;
    })
  },
  selectors: {
    selectIsRegistered: (state) => state.registered,
    selectRegistrationForm: (state) => state.form,
  }
});

export const { submitDetails, submitPassword, submitBusiness } = registrationSlice.actions;
export const { selectIsRegistered, selectRegistrationForm } = registrationSlice.selectors;

export default registrationSlice.reducer;