import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";
import { downloadFile } from "@/_helpers/file_functions";

// Services and interfaces
import { Options } from "@/components/menu";
import { ApiError } from "@/interfaces/api/error";
import { IFolderItem } from "@/interfaces/folder/folder";
import { MoveFileForm } from "@/interfaces/file/move_file_form";
import { useDeleteLinkMutation, useMoveLinkMutation } from "@/repositories/link";
import { useDeleteFileMutation, useMoveFileMutation } from "@/repositories/file";

// Components
import Box  from "@mui/material/Box";
import MoveModal from "./move_modal";
import LinkModal from "./link_modal";
import OverflowMenu from "@/components/overflow_menu";
import { useGetFolderQuery } from "@/repositories/folder";
import DeleteModal from "@/pages/vault/components/delete_modal";
import useBulkActions from "@/components/datatable/use_bulk_actions";


interface _ItemMenuProps {
    row: IFolderItem;
}

const ItemMenu: FC<_ItemMenuProps> = ({
    row
}) => {

    const { t } = useTranslation();
    const { id } = useParams();
    const { data: folder} = useGetFolderQuery(id!);
    const is_link = row.type === 'link';

    const [open, setOpen] = useState<string|null>(null);
    const { showBulkActions } = useBulkActions();

    const disableMenu = showBulkActions
    const options: Options[] = [
        ...(is_link) ? [{name: t('components.menuItems.edit'), action: () => setOpen('edit_link')}] : [],
        {name: t('components.menuItems.move'), action: () => setOpen('move')},
        ...(!is_link) ? [{name: t('components.menuItems.download'), action: () => downloadFile(row.url, row.name)}] : [],
        ...(is_link) ? [{name: t('components.menuItems.openLink'), action: () => window.open(row.url, '_blank', 'noopener,noreferrer')}] : [],
    ]

    const [moveFile, { isLoading: isMovingFile }] = useMoveFileMutation();
    const [moveLink, { isLoading: isMovingLink }] = useMoveLinkMutation();
    const [deleteFile, { isLoading: isDeletingFile }] = useDeleteFileMutation(); 
    const [deleteLink, { isLoading: isDeletingLink }] = useDeleteLinkMutation();

    const handleMoveFile = (data: MoveFileForm) => {
        moveFile({id: id!, file_id: row.uuid, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.file.moved.title'), 
                message: <Trans 
                    i18nKey="notifications.file.moved.message" 
                    components={{bold: <strong />}}
                    values={{name: row.name}}
                />
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleMoveLink = (data: MoveFileForm) => {
        moveLink({id: id!, link_id: row.uuid, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.link.moved.title'), 
                message: <Trans 
                    i18nKey="notifications.link.moved.message" 
                    components={{bold: <strong />}}
                    values={{name: row.name}}
                />
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleDeleteFile = () => {
        deleteFile({id: id!, file_id: row.uuid}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.file.deleted.title', {count: 1}), 
                message: <Trans 
                    i18nKey="notifications.file.deleted.message" 
                    values={{name: row.name, count: 1}}
                    components={{bold: <strong />}}
                />
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleDeleteLink = () => {
        deleteLink({folder_id: id!, link_id: row.uuid}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.link.deleted.title', {count: 1}), 
                message: <Trans 
                    i18nKey="notifications.link.deleted.message" 
                    values={{name: row.name, count: 1}}
                    components={{bold: <strong />}}
                />
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Box display="flex" justifyContent="flex-end">
            <OverflowMenu 
                position={{horizontal: 'right', vertical: 'bottom'}}
                options={options}
                deleteLabel={t('components.menuItems.delete')}
                // disableDelete={disableMenu || disableDelete}
                disabled={disableMenu}
                onDelete={() => setOpen('delete')}
                />
            {open === 'edit_link' && <LinkModal
                open={open === 'edit_link'}
                onClose={() => setOpen(null)}
                folder={folder!}
                link={row}
                />}
            {open === 'move' && <MoveModal
                open={open === 'move'}
                onClose={() => setOpen(null)}
                item={row}
                isLoading={isMovingFile || isMovingLink}
                onMove={is_link ? handleMoveLink : handleMoveFile}
            />}
            {open === 'delete' && <DeleteModal
                type={is_link ? 'Link' : 'File'}
                open={open === 'delete'}
                onClose={() => setOpen(null)}
                name={row.name}
                isLoading={isDeletingFile || isDeletingLink}
                onDelete={is_link ? handleDeleteLink : handleDeleteFile}
            />}
            
        </Box>
    )
}

export default ItemMenu;