import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FieldPath, useFormContext } from "react-hook-form";

// Interfaces
import { IMealFood, INutritionBuilder } from "@/interfaces/nutrition/nutrition_builder";

// Styles
import { TrashCan } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import UnitInput from "./unit_field";
import PortionInput from "./portion_field";
import { ColDef } from "@/components/datatable";
import TextInput from "@/components/text_input";
import IconButton from "@/components/icon_button";


interface _FoodHeadersProps {
    dayIndex: number;
    mealIndex: number;
    optionIndex?: number;
    onRemoveFood: (index: number) => void;
}

const useFoodHeaders = ({ 
    dayIndex,
    mealIndex,
    optionIndex,
    onRemoveFood,
}: _FoodHeadersProps): ColDef<IMealFood>[] => {

    const { t } = useTranslation();
    const { control } = useFormContext<INutritionBuilder>();

    const handleDeleteFood = (index: number) => {
        onRemoveFood(index);
    }

    const path: `plan.${number}.meals.${number}.options.${number}.foods` | `plan.${number}.meals.${number}.foods` =
        optionIndex || optionIndex === 0 ? 
        `plan.${dayIndex}.meals.${mealIndex}.options.${optionIndex}.foods` : 
        `plan.${dayIndex}.meals.${mealIndex}.foods`

    const headers: ColDef<IMealFood>[] = [
        {
            field: 'name',
            headerName: t('components.nutritionBuilder.headers.food'),
            width: '20%',
            render: (_, i) =>
                <TextInput
                    name={`${path}.${i}.name`}
                    control={control}
                    gutter="0"
                    hideErrorMessage
                />
        },
        {
            field: 'portion',
            headerName: t('components.nutritionBuilder.headers.portion'),
            width: '7.5%',
            render: (_, i) => 
                <PortionInput 
                    name={`${path}`}
                    mealName={`plan.${dayIndex}.meals.${mealIndex}`}
                    foodIndex={i}
                    optionIndex={optionIndex}
                />
        },
        {
            field: 'unit',
            headerName: t('components.nutritionBuilder.headers.unit'),
            width: '15%',
            render: (row, i) => 
                <UnitInput 
                    key={i}
                    name={`${path}.${i}`}
                    units={row.units}
                />
        },
        {
            field: 'protein',
            headerName: t('components.nutritionBuilder.headers.protein'),
            width: '10%',
            // render: (_, i) => <FoodMacro dayIndex={dayIndex} mealIndex={mealIndex} optionIndex={optionIndex} foodIndex={i} macro="protein" />
            render: (_, i) => <FoodMacro name={`${path}.${i}.protein`} />
        },
        {
            field: 'carbs',
            headerName: t('components.nutritionBuilder.headers.carbs'),
            width: '10%',
            // render: (_, i) => <FoodMacro dayIndex={dayIndex} mealIndex={mealIndex} optionIndex={optionIndex} foodIndex={i} macro="carbs" />
            render: (_, i) => <FoodMacro name={`${path}.${i}.carbs`} />
        },
        {
            field: 'fat',
            headerName: t('components.nutritionBuilder.headers.fat'),
            width: '10%',
            // render: (_, i) => <FoodMacro dayIndex={dayIndex} mealIndex={mealIndex} optionIndex={optionIndex} foodIndex={i} macro="fat" />
            render: (_, i) => <FoodMacro name={`${path}.${i}.fat`} />
        },
        {
            field: 'fibre',
            headerName: t('components.nutritionBuilder.headers.fibre'),
            width: '10%',
            // render: (_, i) => <FoodMacro dayIndex={dayIndex} mealIndex={mealIndex} optionIndex={optionIndex} foodIndex={i} macro="fibre" />
            render: (_, i) => <FoodMacro name={`${path}.${i}.fibre`}  />
        },
        {
            field: 'calories',
            headerName: t('components.nutritionBuilder.headers.calories'),
            width: '10%',
            // render: (_, i) => <FoodMacro dayIndex={dayIndex} mealIndex={mealIndex} optionIndex={optionIndex} foodIndex={i} macro="calories" />
            render: (_, i) => <FoodMacro name={`${path}.${i}.calories`}  />
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (_, i) => 
                <Box display="flex" justifyContent="flex-end">
                    <IconButton
                        kind="danger-ghost"
                        size="small"
                        icon={<TrashCan />}
                        onClick={() => handleDeleteFood(i)}
                    />
                </Box>
        }
    ];

    return headers;
}

export default useFoodHeaders;


interface _FoodMacroProps {
    name: FieldPath<INutritionBuilder>;
    // macro: 'protein' | 'carbs' | 'fat' | 'fibre' | 'calories';
}

const FoodMacro: FC<_FoodMacroProps> = ({
    // dayIndex,
    // mealIndex,
    // optionIndex,
    // foodIndex,
    name,
    // macro
}) => {

    const { t } = useTranslation();
    const { watch } = useFormContext<INutritionBuilder>();
    const value = watch(name);

    return (
        !name.endsWith('.calories') ? 
            <span className="body-02-compact">{`${Number(value).toLocaleString(undefined, {maximumFractionDigits: 1})} g`}</span> :
            <span className="body-02-compact">{`${Number(value).toLocaleString(undefined, {maximumFractionDigits: 1})} ${t('components.nutritionBuilder.macros.kcal')}`}</span>
    );
    
}

export { FoodMacro };