import { createContext, FC, ReactNode, useContext, useState } from "react";

// Interface
import IPhase from "@/interfaces/phase/phase";

interface IPhaseContext {
    phase?: IPhase;
    open: PhaseModals|null;
    setOpen: (val: PhaseModals|null) => void;
}

interface ProviderProps {
    children?: ReactNode|ReactNode[]
    phase?: IPhase
}

export type PhaseModals = 'note_modal'|'note_panel'|'milestone_modal'|'milestone_panel'|null;

const PhaseContext = createContext<IPhaseContext|undefined>(undefined);

const usePhaseContext = () => {
    const context = useContext(PhaseContext);
    if (!context) throw Error('phase context not initialized!')
    return context;
};

const PhaseProvider: FC<ProviderProps> = ({ children, phase }) => {
    const [open, setOpen] = useState<PhaseModals|null>(null)
    return (
        <PhaseContext.Provider value={{ 
            phase, 
            open,
            setOpen
        }}>
            {children}
        </PhaseContext.Provider>
    );
};
  
export { PhaseProvider, usePhaseContext };
export default PhaseContext;