import { FC, useState, useRef } from "react";

// Styles
import { CaretDown, CaretLeft, CaretRight, Checkmark } from "@carbon/icons-react";

// Components
import Button from "@/components/button";
import IconButton from "@/components/icon_button";
import { Box, Menu, MenuItem } from "@mui/material";


interface PaginationProps {
    total: number;
    page: number;
    perPage: number;
    disablePageLimit?: boolean;
    onChange: ((newPage: number, newLimit: number) => (void));
}

const PaginationFooter: FC<PaginationProps> = ({
    total,
    page,
    perPage,
    disablePageLimit = false,
    onChange
}) => {
    
    const min: number = page * perPage - perPage + 1
    const max: number = page * perPage > total ? total : page * perPage

    return (
        <Box display='flex' alignItems='center' borderTop="solid 1px var(--border-subtle-01)" marginTop="-1px">

            {/* Page limit */}
            <Box display="flex" alignItems="center" height={48}>
                <DropdownSelect 
                    value={perPage} 
                    items={[5, 10, 15, 20]} 
                    onSelect={(v) => onChange(page, v)} 
                    disabled={disablePageLimit || total === 0}
                />
            </Box>

            {/* Count position */}
            <Box display="flex" alignItems="center" height={48}  borderLeft="1px solid  var(--border-subtle-01)">
                <span className="body-02-compact text-secondary" style={{margin: '0 16px'}}>{total > 0 ? min : 0}-{max} of {total} items</span>
            </Box>

            <Box flexGrow={1} height={48} borderLeft="1px solid  var(--border-subtle-01)" />

            {/* Page number */}
            <Box display="flex" alignItems="center" height={48} padding="0 16px" borderLeft="1px solid  var(--border-subtle-01)">
                <span className="body-02-compact text-secondary">{total > 0 ? page : 0} of {Math.ceil(total/perPage)} pages</span>
            </Box>

            {/* Page actions */}
            <IconButton
                kind="ghost"
                icon={<CaretLeft />}
                disabled={page <= 1} 
                onClick={() => onChange(page - 1, perPage)}
                sx={{
                    borderLeft: '1px solid var(--border-subtle-01)', 
                    borderRadius: 0
                }}
            />
            <IconButton
                kind="ghost"
                icon={<CaretRight />}
                disabled={page >= Math.ceil(total/perPage)} 
                onClick={() => onChange(page + 1, perPage)}
                sx={{
                    borderLeft: '1px solid  var(--border-subtle-01)', 
                    borderRadius: 0
                }}
            />
        </Box>
    );
}

interface DropdownProps {
    value: number;
    items: number[];
    disabled?: boolean;
    onSelect: (val: number)=>void;
}

const DropdownSelect: FC<DropdownProps> = ({
   value,
   items,
   disabled = false,
   onSelect,
}) => {

    const buttonRef = useRef<HTMLDivElement>(null)
    const [anchor, setAnchor] = useState<null | HTMLElement>(null)
    const open = Boolean(anchor)

    const handleOpen = () => setAnchor(buttonRef.current)
    const handleClose = (v: number) => {
        onSelect(v)
        setAnchor(null)
    }

    const iconStyles = {
        display: 'flex',
        height: 16,
        color: disabled ? 'unset' : 'var(--icon-primary)',
        marginLeft: '8px',
        '& svg': {
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.1s linear'
        }
    }

    return (
        <>
            <div ref={buttonRef}>
                <Button 
                    kind="ghost" 
                    onClick={handleOpen} 
                    disabled={disabled} 
                    sx={{minWidth: 'unset', mx: 0, borderRadius: 0}}
                    >
                    <span className="body-02-compact text-secondary">{value}</span>
                    <Box sx={iconStyles}>
                        <CaretDown />
                    </Box>
                </Button>
            </div>

            <Menu anchorEl={anchor} open={open} onClose={()=>setAnchor(null)}>
                {items.map(i => (
                <MenuItem key={i} onClick={()=>handleClose(i)} sx={{
                    px: 1, width: buttonRef.current?.clientWidth,
                    textAlign: 'center',
                    justifyContent: 'center',
                }}>
                    {i === value && <Checkmark size="12px" style={{position: 'absolute', left: 6}} />}
                    <span className="label-text-02">{i}</span>
                </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default PaginationFooter;
