import { z } from "zod";
import ruleset from "@/_helpers/ruleset";


export interface ILinkForm {
    name: string;
    url: string;
}

export const linkFormSchema = z.object({
    name: ruleset.required,
    url: ruleset.link
})