
import { t } from "i18next";
import { v4 as uuidv4 } from 'uuid';
import { FC, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

// Servics and interfaces
import { IMealFood, INutritionBuilder } from "@/interfaces/nutrition/nutrition_builder";

// Styles
import { Add, TrashCan, WarningFilled } from "@carbon/icons-react";

// Components
import AddModal from "./add_modal";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Button from "@/components/button";
import IconButton from "@/components/icon_button";


interface _OptionTabsProps {
    dayIndex: number;
    mealIndex: number;
    current: number;
    setCurrent: (val: number) => void;
}

const MAX_OPTIONS = 5;

const OptionTabs: FC<_OptionTabsProps> = ({ 
    dayIndex,
    mealIndex, 
    current, 
    setCurrent 
}) => {

    const [open, setOpen] = useState<string|null>(null);
    const { control, watch, setValue, formState: { errors } } = useFormContext<INutritionBuilder>();
    const { fields: options, append, remove, swap } = useFieldArray({
        control,
        name: `plan.${dayIndex}.meals.${mealIndex}.options`
    });
    const hasErrors = errors?.plan?.[dayIndex]?.meals?.[mealIndex]?.options
    const foods = watch(`plan.${dayIndex}.meals.${mealIndex}.options.${current}.foods`)


    const onAdd = () => {
        append({ uuid: uuidv4(), calories: 0, protein: 0, carbs: 0, fat: 0, fibre: 0, foods: [] })
    }

    const onDelete = () => {
        if (current >= options.length - 1) setCurrent(options.length - 2);
        remove(current)
    }

    const onMove = () => {
        swap(current, 0)
        setCurrent(0);
    }
    
    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
      setCurrent(newValue);
    };

    const handleAddFoods = (arr: IMealFood[]) => {        
        setValue(`plan.${dayIndex}.meals.${mealIndex}.options.${current}.foods`, [...foods, ...arr])
        setOpen(null)
    }

    return (
        <Box mb={2} px={2} display='flex' alignItems='center' sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs className="Tabs" value={current} onChange={handleChange} sx={{mx: 0}}>
                {new Array(options.length).fill(0).map((_,i) => (
                    <Tab 
                    className="Tabs__tab"
                    key={i} 
                    value={i} 
                    label={<Box display="flex" alignItems="center">
                        {`Option ${i+1}`}
                        {hasErrors && hasErrors[i] && <WarningFilled size="12px" style={{color: 'var(--support-error)', marginLeft: '8px'}} />}
                        
                    </Box>} />
                ))}
            </Tabs>

            {options.length < 5 && <Button  
                kind="ghost"
                size="small"
                endIcon={options.length < MAX_OPTIONS && <Add />} 
                minWidth={false}
                onClick={onAdd}
                disabled={options.length >= MAX_OPTIONS} 
                label={t(options.length >= MAX_OPTIONS ? 
                    'components.nutritionBuilder.meals.maxOptions' : 
                    'components.nutritionBuilder.meals.addOption'
                )}  
                />}

            <Box flexGrow={1} />

            <Button
                kind="ghost"
                size="small"
                label={t('components.buttons.addFood')}
                endIcon={<Add />}
                minWidth={false}
                onClick={() => setOpen('add_food')}
                sx={{marginRight: '4px'}}
                />
            {open === 'add_food' && <AddModal
                open={open === 'add_food'}
                selectedFoods={foods?.map(f => f._id)}
                onClose={() => setOpen(null)}
                onAdd={handleAddFoods}
                />}

            <Button  
                kind="ghost"
                size="small"
                minWidth={false}
                onClick={onMove}
                disabled={current == 0} 
                label={t('components.nutritionBuilder.meals.setOptionOne')}
                sx={{marginRight: '4px'}}
                />

            <IconButton  
                kind="danger-ghost"
                size="small"
                icon={<TrashCan />} 
                disabled={options.length == 1} 
                onClick={onDelete}
                />
        </Box>
    )
}

export default OptionTabs;