import { t } from "i18next";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import ExerciseModal from "@/pages/library/exercises/exercise_modal";


interface _NoExercisesProps {
    message?: string;
}

const NoExercises: FC<_NoExercisesProps> = ({
    message = t('components.programmeBuilder.table.noExercisesFound')
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" paddingBottom="12px">

            <Box height="32px" />
            <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{message}</span>
            <Box height="12px" />
            <Button
                kind="ghost"
                size="small"
                label={t('components.buttons.addExercise')}
                endIcon={<Add />}
                minWidth={false}
                onClick={() => setOpen(true)}
                />
            {open && <ExerciseModal 
                open={open}
                onClose={() => setOpen(false)}
                />}

        </Box>
    )
}

export default NoExercises;