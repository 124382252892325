import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

// Services and interfaces
import { useGetFolderQuery } from '@/repositories/folder';

// Styles
import { Add } from '@carbon/icons-react';

// Components
import { Box } from '@mui/material';
import FileModal from './files/file_modal';
import LinkModal from './files/link_modal';
import FolderModal from './folders/folder_modal';
import SecondaryToolbar from '@/components/secondary_toolbar';


const VaultPage: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null);
    const isFolder = location.pathname.includes('folders/');

    const { data: folder } = useGetFolderQuery(id!, { skip: !isFolder || !id });

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar 
                title={t('pages.vault.title')}
                onBack={isFolder ? () => navigate('/vault') : undefined}
                action1={isFolder ? {
                    label: t('components.buttons.uploadFiles'),
                    icon: <Add />,   
                    onClick: () => setOpen('addFiles')     
                } : {
                    label: t('components.buttons.addFolder'),
                    icon: <Add />,   
                    onClick: () => setOpen('addFolder')   
                }}
                action2={isFolder ? {
                    label: t('components.buttons.addLink'),
                    icon: <Add />,
                    onClick: () => setOpen('addLink')
                } : undefined}
                />
                {open === 'addFolder' && <FolderModal 
                    open={open === 'addFolder'} 
                    onClose={() => setOpen(null)}
                    />}
                {open === 'addFiles' && <FileModal
                    open={open === 'addFiles'}
                    folder={folder!}
                    onClose={() => setOpen(null)}
                    />}
                {open === 'addLink' && <LinkModal
                    open={open === 'addLink'}
                    folder={folder!}
                    onClose={() => setOpen(null)}
                    />}

            <Outlet />
            
        </Box>
    )
}

export default VaultPage;