import baseApi from '@/repositories/base_api';
import { activityPlanApi } from './activity_plan';
import { IAPIResponse } from '@/interfaces/api/response';
import { IActivityPlan } from '@/interfaces/activity_plan/activity_plan';
import { IActivityTemplate } from '@/interfaces/activity_template/activity_template';
import { IActivityTemplateForm } from '@/interfaces/activity_template/activity_template_form';
import { ITemplateAssignForm, ITemplateAssignManyForm } from '@/interfaces/template/assign_form';


export const activityTemplateApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getActivityTemplates: build.query<IActivityTemplate[], void>({
            query: () => ({ url: '/templates/activity', method: 'get' }),
            transformResponse: (response: IAPIResponse<IActivityTemplate[]>) => response.data.sort((a, b) => a.name.localeCompare(b.name)),
            providesTags: ['Templates.activity.list'],
            keepUnusedDataFor: 1800
        }),
        getActivityTemplate: build.query<IActivityTemplate, string>({
            query: (id) => ({ url: `/templates/activity/${id}`, method: 'get' }),
            transformResponse: (response: IAPIResponse<IActivityTemplate>) => response.data,
        }),
        addActivityTemplate: build.mutation<IActivityTemplate, IActivityTemplateForm>({
            query: (data) => ({ url: '/templates/activity', method: 'post', data }),
            transformResponse: (response: IAPIResponse<IActivityTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(activityTemplateApi.util.updateQueryData('getActivityTemplates', undefined, templates => {
                    templates.push(response.data)
                    templates.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
        updateActivityTemplate: build.mutation<IActivityTemplate, {id: string, data: IActivityTemplateForm}>({
            query: ({id, data}) => ({ url: `/templates/activity/${id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<IActivityTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(activityTemplateApi.util.updateQueryData('getActivityTemplates', undefined, templates => {
                    const index = templates.findIndex((item) => item.id === response.data.id);
                    templates.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteActivityTemplate: build.mutation<void, string>({
            query: (id) => ({ url: `/templates/activity/${id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(activityTemplateApi.util.updateQueryData('getActivityTemplates', undefined, templates => {
                    return templates.filter((item) => item.id !== id)
                }))).catch(() => {});
            },
        }),
        deleteActivityTemplates: build.mutation<void, string[]>({
            query: (ids) => ({ url: `/templates/activity/bulk-delete`, method: 'post', data: {ids: ids} }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(ids, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(activityTemplateApi.util.updateQueryData('getActivityTemplates', undefined, templates => {
                    return templates.filter((item) => !ids.includes(item.id))
                }))).catch(() => {});
            },
        }),
        copyActivityTemplate: build.mutation<IActivityTemplate, string>({
            query: (id) => ({ url: `/templates/activity/${id}/copy`, method: 'post' }),
            transformResponse: (response: IAPIResponse<IActivityTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(activityTemplateApi.util.updateQueryData('getActivityTemplates', undefined, templates => {
                    templates.push(response.data);
                    templates.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
        assignActivityTemplate: build.mutation<IActivityPlan, ITemplateAssignForm>({
            query: (data) => ({ url: `/templates/activity/${data.template_id}/assign`, method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<IActivityPlan>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(activityPlanApi.util.updateQueryData('getActivityPlans', _.client_id, plans => {
                    plans.push(response.data);
                    plans.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
        assignActivityTemplates: build.mutation<IActivityTemplate, {id: string, data: ITemplateAssignManyForm}>({
            query: ({id, data}) => ({ url: `/templates/activity/${id}/bulk-assign`, method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<IActivityTemplate>) => response.data,
            invalidatesTags: [{ type: 'Plans.activity.list', id: 'ALL' }]
        }),
        shareActivityTemplate: build.mutation<IActivityTemplate, string>({
            query: (id) => ({ url: `/templates/activity/${id}/share`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<IActivityTemplate>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(activityTemplateApi.util.updateQueryData('getActivityTemplates', undefined, templates => {
                    const index = templates.findIndex((item) => item.id === response.data.id);
                    if (index == -1) return templates;
                    const t = [...templates];
                    t.splice(index, 1, response.data);
                    return t;
                }))).catch(() => {});
            },
        }),
    }),
})

export const { 
    useGetActivityTemplatesQuery,
    useGetActivityTemplateQuery,
    useLazyGetActivityTemplateQuery,
    useAddActivityTemplateMutation,
    useUpdateActivityTemplateMutation,
    useDeleteActivityTemplateMutation,
    useDeleteActivityTemplatesMutation,
    useCopyActivityTemplateMutation,
    useAssignActivityTemplateMutation,
    useAssignActivityTemplatesMutation,
    useShareActivityTemplateMutation
} = activityTemplateApi;

