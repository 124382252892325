import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

// Constants
import { setTypeOptions } from "@/_constants/programme";

// Interfaces
import { IProgrammeBuilder, ISet } from "@/interfaces/programme/programme_builder";
import { IProgrammeWorkoutBuilder } from "@/interfaces/programme_workout/programme_workout_builder";

// Styles
import { TrashCan } from "@carbon/icons-react";

// Components
import SetNote from "./set_note";
import { Box } from "@mui/material";
import { ColDef } from "@/components/datatable";
import IconButton from "@/components/icon_button";
import NumberInput from "@/components/number_input";
import SelectInput from "@/components/select_input";
import DurationInput from "@/components/duration_input";
import DeleteModal from "@/components//builder/delete_modal";


interface _SetHeadersProps {
    basePath: `plan.${number}.exercises`|'exercises';
    exerciseIndex: number;
    supersetIndex?: number;
    onRemoveSet: (index: number) => void;
    onRemoveExercise: (index: number) => void;
}

const useSetHeaders = ({ 
    basePath,
    exerciseIndex,
    supersetIndex,
    onRemoveSet,
    onRemoveExercise
}: _SetHeadersProps): ColDef<ISet>[] => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<string|null>(null);
    const { control } = useFormContext<IProgrammeBuilder|IProgrammeWorkoutBuilder>();
    const settings = useWatch({ control, name: `settings` });
    const exercise = useWatch({ control, name: `${basePath}.${exerciseIndex}`});
    const metrics = useWatch({ control, name: `${basePath}.${exerciseIndex}.metrics` });

    const handleDeleteSet = (index: number) => {
        if (exercise.sets.length === 1) return setOpen('delete');
        onRemoveSet(index);
    }

    const handleDeleteExercise = () => {
        onRemoveExercise(exerciseIndex);
        setOpen(null);
    }

    const headers: ColDef<ISet>[] = [
        {
            field: 'set',
            headerName: t('components.programmeBuilder.headers.set'),
            width: '1%',
            render: (_: ISet, i: number) => <span>{`${i+1}${supersetIndex != undefined ? String.fromCharCode(65 + supersetIndex) : ''}`}</span>
        },
        ...(metrics?.reps ? [{
            field: 'rep_range',
            headerName: t('components.programmeBuilder.headers.repRange'),
            width: '10%',
            render: (_: ISet, i: number) => 
                <Box display="flex" alignItems="center">
                    <NumberInput
                        name={`${basePath}.${exerciseIndex}.sets.${i}.rep_min`}
                        control={control}
                        maxLength={3}
                        placeHolder="0"
                        hideErrorMessage
                        gutter="0"
                        />
                    <Box padding="0px 4px">
                        -
                    </Box>
                    <NumberInput
                        name={`${basePath}.${exerciseIndex}.sets.${i}.rep_max`}
                        control={control}
                        maxLength={3}
                        placeHolder="0"
                        hideErrorMessage
                        gutter="0"
                        />
                </Box>
        }] : []),
        ...(metrics?.distance ? [{
            field: 'distance_min',
            headerName: t('components.programmeBuilder.headers.distance'),
            width: '10%',
            render: (_: ISet, i: number) => 
                <NumberInput
                    name={`${basePath}.${exerciseIndex}.sets.${i}.distance_min`}
                    control={control}
                    maxLength={5}
                    placeHolder="0"
                    hideErrorMessage
                    gutter="0"
                    suffixText="m"
                    />

        }] : []),
        ...(metrics?.duration ? [{
            field: 'duration_min',
            headerName: t('components.programmeBuilder.headers.duration'),
            width: '10%',
            render: (_: ISet, i: number) => 
                <DurationInput
                    name={`${basePath}.${exerciseIndex}.sets.${i}.duration_min`}
                    control={control}
                    format="mm:ss"
                    hideErrorMessage
                    gutter="0"
                    convertToZero
                    />

        }] : []),
        ...(metrics?.calories ? [{
            field: 'calories_min',
            headerName: t('components.programmeBuilder.headers.calories'),
            width: '10%',
            render: (_: ISet, i: number) => 
                <NumberInput
                    name={`${basePath}.${exerciseIndex}.sets.${i}.calories_min`}
                    control={control}
                    maxLength={4}
                    placeHolder="0"
                    hideErrorMessage
                    gutter="0"
                    suffixText={t('metrics.kcal')}
                    />

        }] : []),
        ...(settings.tempo && metrics?.reps ? [{
            field: 'tempo',
            headerName: t('components.programmeBuilder.headers.tempo'),
            width: '15%',
            render: (_: ISet, i: number) => 
                <Box display="flex" alignItems="center">
                    <NumberInput
                        name={`${basePath}.${exerciseIndex}.sets.${i}.tempo_ecc`}
                        control={control}
                        maxLength={2}
                        placeHolder="0"
                        hideErrorMessage
                        gutter="0"
                        />
                    <Box width="8px" />
                    <NumberInput
                        name={`${basePath}.${exerciseIndex}.sets.${i}.tempo_len`}
                        control={control}
                        maxLength={2}
                        placeHolder="0"
                        hideErrorMessage
                        gutter="0"
                        />
                    <Box width="8px" />
                    <NumberInput
                        name={`${basePath}.${exerciseIndex}.sets.${i}.tempo_con`}
                        control={control}
                        maxLength={2}
                        placeHolder="0"
                        hideErrorMessage
                        gutter="0"
                        />
                    <Box width="8px" />
                    <NumberInput
                        name={`${basePath}.${exerciseIndex}.sets.${i}.tempo_sho`}
                        control={control}
                        maxLength={2}
                        placeHolder="0"
                        hideErrorMessage
                        gutter="0"
                        />
                </Box>
           
        }] : []),
        ...(settings.rpe && metrics?.reps? [{
            field: 'rpe',
            headerName: t('components.programmeBuilder.headers.rpe'),
            width: '5%',
            render: (_: ISet, i: number) => 
                <NumberInput
                    name={`${basePath}.${exerciseIndex}.sets.${i}.target_rpe`}
                    control={control}
                    maxLength={2}
                    maxValue={10}
                    placeHolder="0"
                    hideErrorMessage
                    gutter="0"
                    />
        }] : []),
        ...(settings.rir && metrics?.reps ? [{
            field: 'rir',
            headerName: t('components.programmeBuilder.headers.rir'),
            width: '5%',
            render: (_: ISet, i: number) => 
                <NumberInput
                    name={`${basePath}.${exerciseIndex}.sets.${i}.target_rir`}
                    control={control}
                    maxLength={2}
                    placeHolder="0"
                    hideErrorMessage
                    gutter="0"
                    />
        }] : []),
        ...(settings.set_type ? [{
            field: 'set_type',
            headerName: t('components.programmeBuilder.headers.setType'),
            width: '10%',
            render: (_: ISet, i: number) => 
                <SelectInput
                    name={`${basePath}.${exerciseIndex}.sets.${i}.set_type`}
                    control={control}
                    items={setTypeOptions}
                    hideErrorMessage
                    gutter="0"
                    allowEmpty
                    />
        }] : []),
        ...(settings.rest_time ? [{
            field: 'target_rest_time',
            headerName: t('components.programmeBuilder.headers.restTime'),
            width: '10%',
            render: (_: ISet, i: number) => 
                <DurationInput
                    name={`${basePath}.${exerciseIndex}.sets.${i}.target_rest_time`}
                    control={control}
                    format="mm:ss"
                    hideErrorMessage
                    gutter="0"
                    />
        }] : []),
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (_: ISet, i: number) => 
                <Box display="flex" justifyContent="flex-end">
                    <SetNote
                        basePath={`${basePath}.${exerciseIndex}.sets.${i}`}
                        exerciseIndex={exerciseIndex}
                        setIndex={i}
                        />
                    <IconButton
                        kind="danger-ghost"
                        size="small"
                        icon={<TrashCan />}
                        onClick={() => handleDeleteSet(i)}
                        />
                        {open === 'delete' && <DeleteModal
                            open={open === 'delete'}
                            onClose={() => setOpen(null)}
                            title={t('modals.deleteWorkoutExercise.title')}
                            text={t('modals.deleteWorkoutExercise.text_last')}
                            onDelete={handleDeleteExercise}
                            />}
                </Box>
        }
    ];

    return headers;
}

export default useSetHeaders;