import { FC } from 'react';

// Services
import { useGetUserQuery } from '@/repositories/auth';


const AppWrapper: FC = () => {

  useGetUserQuery();

  return null;
};

export default AppWrapper;