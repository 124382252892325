import { useTranslation } from "react-i18next";

// Helpers
import { capitalize, capitalizeWords } from "@/_helpers/text_functions";

// Interfaces
import { IExercise } from "@/interfaces/exercise/exercise";

// Components
import { ColDef } from "@/components/datatable";
import useFilterActions from "@/components/datatable/use_filter_actions";


const useExerciseTableHeaders = (): ColDef<IExercise>[] => {

    const { t } = useTranslation();
    const { hiddenColumns } = useFilterActions();

    const headers: ColDef<IExercise>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '25%',
            sortable: true,
            render: (row) => capitalizeWords(row.name)
        },
        ...(!hiddenColumns.includes('type') ? [{
            field: 'type',
            headerName: t('components.dataTable.headers.type'),
            width: '10%',
            sortable: true,
            render: (row: IExercise) => <span>{t(`enums.exerciseType.${row.type}`)}</span>
        }] : []),
        ...(!hiddenColumns.includes('bodypart') ? [{
            field: 'bodypart',
            headerName: t('components.dataTable.headers.primaryFocus'),
            width: '10%',
            sortable: true,
            render: (row: IExercise) => <span>{t(`enums.exerciseBodypart.${row.bodypart}`)}</span>
        }] : []),
        ...(!hiddenColumns.includes('category') ? [{
            field: 'category',
            headerName: t('components.dataTable.headers.category'),
            width: '15%',
            sortable: true,
            render: (row: IExercise) => <span>{t(`enums.exerciseCategory.${row.category}`)}</span>
        }] : []),
        ...(!hiddenColumns.includes('machine_type') ? [{
            field: 'machine_type',
            headerName: t('components.dataTable.headers.machineType'),
            width: '15%',
            sortable: true,
            render: (row: IExercise) => <span>{t(`enums.machineType.${!row.machine_type || row.machine_type === '' ? 'not_available' : row.machine_type}`)}</span>
        }] : []),
        ...(!hiddenColumns.includes('machine_brand') ? [{
            field: 'machine_brand',
            headerName: t('components.dataTable.headers.machineBrand'),
            width: '10%',
            sortable: true,
            render: (row: IExercise) => <span>{row.machine_brand ?? 'N/A'}</span>
        }] : []),
        ...(!hiddenColumns.includes('library') ? [{
            field: 'library',
            headerName: t('components.dataTable.headers.library'),
            width: '10%',
            sortable: true,
            render: (row: IExercise) => <span>{capitalize(row.library)}</span>
        }] : [])
    ];

    return headers;
}

export default useExerciseTableHeaders;