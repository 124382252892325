import { t } from "i18next";
import { ReactNode } from "react";

// Components
import { Box } from "@mui/material"
import Button from "@/components/button";
import useBulkActions from "./use_bulk_actions";


interface _SelectActionProps {
    label: string;
    onClick: (selected: string[]) => void;
    modal?: ReactNode;
}

export interface SelectionHeaderProps<T> {
    hideCancel?: boolean;
    action1?: _SelectActionProps;
    action2?: _SelectActionProps;
    action3?: _SelectActionProps;
    showDisableChecked?: boolean;
    disableSelectAll?: boolean;
    disableSelect?: (r: T) => boolean;
    onSelect?: (selected: T) => void;
    onDeselect?: (selected: T) => void;
}

const SelectionHeader = <T extends object>({
    hideCancel = false,
    action1,
    action2,
    action3
}: SelectionHeaderProps<T>) => {

    const { selected, showBulkActions, handleSetSelected, handleSetShowBulkActions } = useBulkActions();

    const handleCancel = () => {
        handleSetSelected([]);
        handleSetShowBulkActions(false);
    }

    const handleAction1 = () => {
        if(action1 && selected.length > 0) {
            action1.onClick(selected);
        }
    }

    const handleAction2 = () => {
        if(action2 && selected.length > 0) {
            action2.onClick(selected);
        }
    }

    const handleAction3 = () => {
        if(action3 && selected.length > 0) {
            action3.onClick(selected);
        }
    }

    if (!showBulkActions) return null; 
    
    return (
        <Box mt={1} display='flex' alignItems='center' height="48px" bgcolor='var(--background-inverse)'>
            <Box flex={1} ml={2} color='white' textAlign='left'>
                <span className='body-02-compact'>
                    {t('components.dataTable.selection.selected', {count: selected.length})}
                </span>
            </Box>
            
            {action3 && <Button label={action3.label} onClick={handleAction3} sx={{ minWidth: 'unset' }} />}
            {action2 && <Button label={action2.label} onClick={handleAction2} sx={{ minWidth: 'unset' }} />}
            {action1 && <Button label={action1.label} onClick={handleAction1} sx={{ minWidth: 'unset' }} />}

            {action1?.modal}
            {action2?.modal}

           {!hideCancel && <>
           
            <Box borderRight='1px solid white' height={20} />

            <Button
                label={t('components.dataTable.selection.cancel')}
                onClick={handleCancel} 
                minWidth={false}
                />
            </>}

        </Box>
    )
}

export default SelectionHeader;