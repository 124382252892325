import { FC, useEffect } from "react";
import { t } from "i18next";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Services and interfaces
import { selectCoachId } from "@/store/coach";
import { selectHasTeam, selectIsMember, selectMembers, selectSelectedMember, setSelectedMember } from "@/store/team";

// Components
import Box from "@mui/material/Box";
import MenuButton from "@/components/menu_button";
import SecondaryToolbar from "@/components/secondary_toolbar";


const tabs = [
    {label: t('pages.client.active'), link: "/clients/active", sx: {width:'120px'}},
    {label: t('pages.client.inactive'), link: "/clients/inactive", sx: {width:'120px'}}
]

const ClientList: FC = () => {

    const dispatch = useDispatch();
    const coachId = useSelector(selectCoachId);
    const hasTeam = useSelector(selectHasTeam);
    const members = useSelector(selectMembers);
    const isMember = useSelector(selectIsMember);
    const selectedMember = useSelector(selectSelectedMember);

    const options = members.map(member => ({
        name: member.name,
        action: () => dispatch(setSelectedMember(member.id))
    }));

    useEffect(() => {
        if (coachId && hasTeam && !selectedMember) {
            dispatch(setSelectedMember(coachId));
        }
    }, [coachId, hasTeam, selectedMember, dispatch]);
    
    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar 
                title={t('pages.client.clients')}
                tabs={tabs}
                slot={
                    hasTeam && !isMember && <MenuButton
                        kind="tertiary"
                        size="small"
                        label={selectedMember?.name}
                        options={options}
                        activeOption={selectedMember?.name}
                        />
                }
                />

            <Outlet />

        </Box>
    );
}

export default ClientList;