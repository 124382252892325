import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import { ILeadForm } from '@/interfaces/lead_form/lead_form';
import { ILeadFormForm } from '@/interfaces/lead_form/lead_form_form';


export const leadFormApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getLeadForms: build.query<ILeadForm[], void>({
            query: () => ({ url: '/leadforms', method: 'get' }),
            transformResponse: (response: IAPIResponse<ILeadForm[]>) => response.data.sort((a, b) => a.name.localeCompare(b.name)),
            providesTags: ['LeadForms.list']
        }),
        getLeadForm: build.query<ILeadForm, string>({
            query: (id) => ({ url: `/leadforms/${id}`, method: 'get' }),
            transformResponse: (response: IAPIResponse<ILeadForm>) => response.data,
        }),
        addLeadForm: build.mutation<ILeadForm, ILeadFormForm>({
            query: (data) => ({ url: '/leadforms', method: 'post', data }),
            transformResponse: (response: IAPIResponse<ILeadForm>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getLeadForms' as never, undefined as never, (forms: ILeadForm[]) => {
                    forms.push(response.data)
                }))).catch(() => {});
            },
        }),
        updateLeadForm: build.mutation<ILeadForm, {id: string, data: ILeadFormForm}>({
            query: ({id, data}) => ({ url: `/leadforms/${id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<ILeadForm>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getLeadForms' as never, undefined as never, (forms: ILeadForm[]) => {
                    const index = forms.findIndex((item) => item.id === response.data.id);
                    forms.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteLeadForm: build.mutation<void, string>({
            query: (id) => ({ url: `/leadforms/${id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(baseApi.util.updateQueryData('getLeadForms' as never, undefined as never, (forms: ILeadForm[]) => {
                    return forms.filter((item) => item.id !== id)
                }))).catch(() => {});
            },
        }),
        deleteLeadForms: build.mutation<void, string[]>({
            query: (ids) => ({ url: `/leadforms/bulk-delete`, method: 'post', data: {ids: ids} }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(ids, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(baseApi.util.updateQueryData('getLeadForms' as never, undefined as never, (forms: ILeadForm[]) => {
                    return forms.filter((item) => !ids.includes(item.id))
                }))).catch(() => {});
            },
        }),
        copyLeadForm: build.mutation<ILeadForm, string>({
            query: (id) => ({ url: `/leadforms/${id}/copy`, method: 'post' }),
            transformResponse: (response: IAPIResponse<ILeadForm>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getLeadForms' as never, undefined as never, (forms: ILeadForm[]) => {
                    forms.push(response.data)
                }))).catch(() => {});
            },
        }),
        togglePublished: build.mutation<ILeadForm, string>({
            query: (id) => ({ url: `/leadforms/${id}/publish`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<ILeadForm>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getLeadForms' as never, undefined as never, (forms: ILeadForm[]) => {
                    const index = forms.findIndex((item) => item.id === response.data.id);
                    forms.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
    }),
})

export const { 
    useGetLeadFormsQuery,
    useGetLeadFormQuery,
    useLazyGetLeadFormQuery,
    useAddLeadFormMutation,
    useUpdateLeadFormMutation,
    useDeleteLeadFormMutation,
    useDeleteLeadFormsMutation,
    useCopyLeadFormMutation,
    useTogglePublishedMutation,
} = leadFormApi;

