
import { FC, ReactNode, useEffect, useState } from "react";

// Interfaces
import ButtonAction from "@/interfaces/components/button_action";

// Components
import PanelDrawer from "./drawer";


export interface PanelProps {
    title: string;
    subtitle?: string;
    panelWidth?: string;
    open: boolean;
    showClose?: boolean;
    keepMounted?: boolean;
    onClose?: () => void;
    children: ReactNode,
    action1?: ButtonAction,
    action2?: ButtonAction,
    cancel?: ButtonAction
}

const RightPanel: FC<PanelProps> = ({
    title,
    open,
    onClose,
    children,
    ...rest
}) => {

    const [panelOpen, setPanelOpen] = useState(false);

    const handleClose = () => {
        if (!onClose) return;
        setPanelOpen(false);
        setTimeout(() => onClose?.(), 500);
    }

    const handleCancel = () => {
        if (!rest.cancel?.onClick) return;
        setPanelOpen(false);
        setTimeout(() => rest.cancel?.onClick(), 500);
    }

    useEffect(() => {
        if (open) setPanelOpen(true);
    }, [open])

    return (
        <PanelDrawer
            title={title}
            open={panelOpen}
            onClose={handleClose}
            onCancel={handleCancel}
            children={children}
            {...rest}
            />
    )
}

export default RightPanel;