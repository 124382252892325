import { FC } from "react";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Services and interfaces
import { deleteTeamSchema, ITeamForm } from "@/interfaces/team/team_form";

// Styles
import { TrashCan } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import TextInput from "@/components/text_input";


interface _DeleteTeamModalProps {
    open: boolean;
    onClose: () => void;
    team_name?: string;
    isLoading: boolean;
    onSubmit: (data: ITeamForm) => void;
}

const DeleteTeamModal: FC<_DeleteTeamModalProps> = ({
    open,
    onClose,
    team_name,
    isLoading = false,
    onSubmit,
}) => {

    const { t } = useTranslation();
    const formMethods = useForm<ITeamForm>({
        resolver: zodResolver(deleteTeamSchema),
        mode: 'onBlur',
        defaultValues: {
            team_name: team_name,
            team_name_confirmation: ''
        }
    });

    const handleClose = () => {
        onClose();
        formMethods.reset();
    }
    
    return (
        <Modal
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : onClose}
            title={t('modals.deleteTeam.title', {name: team_name})}
            text={t('modals.deleteTeam.text')}
            children={
                <FormProvider {...formMethods}>
                    <TextInput
                        name="team_name_confirmation"
                        control={formMethods.control}
                        label={t('inputs.titles.teamName')}
                        placeHolder={t('inputs.placeholders.teamName')}
                        />
                </FormProvider>}
            action1={{
                kind: "danger",
                label: t('components.buttons.deleteTeam'),
                icon: <TrashCan />,
                onClick: formMethods.handleSubmit(onSubmit),
                loading: isLoading,
                disabled: !formMethods.formState.isValid || !formMethods.formState.isDirty
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: handleClose,
                disabled: isLoading
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '480px'}}}
            />
    )
}

export default DeleteTeamModal;