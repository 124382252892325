import { FC, useEffect } from "react";
import { FieldPath, useFormContext, useWatch } from "react-hook-form";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";

// Interfaces
import { IMeal, IMealFood, INutritionBuilder } from "@/interfaces/nutrition/nutrition_builder";

// Components
import NumberInput from "@/components/number_input";

type NutritionPath = FieldPath<INutritionBuilder>

interface _PortionInputProps {
    name: NutritionPath;
    mealName: NutritionPath;
    foodIndex: number;
    optionIndex?: number;
}

const PortionInput: FC<_PortionInputProps> = ({ 
    name,
    mealName,
    foodIndex,
    optionIndex
}) => {

    const { control, setValue, getValues } = useFormContext<INutritionBuilder>();
    const foods = useWatch({ control, name }) as IMealFood[];
    const food = foods[foodIndex];

    useEffect(() => {
        // set meal macros if option = 0 or is foods
        const meal = getValues(mealName) as IMeal;
        const foodTally = meal.options?.length > 0 ? meal.options[0].foods : foods
      
        const p = roundNumber(foodTally.reduce((acc, food) => acc + food.protein, 0), 1);
        const c = roundNumber(foodTally.reduce((acc, food) => acc + food.carbs, 0), 1);
        const f = roundNumber(foodTally.reduce((acc, food) => acc + food.fat, 0), 1);
        const fi = roundNumber(foodTally.reduce((acc, food) => acc + food.fibre, 0), 1);

        setValue(`${mealName}.protein` as NutritionPath, p);
        setValue(`${mealName}.carbs` as NutritionPath, c);
        setValue(`${mealName}.fat` as NutritionPath, f);
        setValue(`${mealName}.fibre` as NutritionPath, fi);
        setValue(`${mealName}.calories` as NutritionPath, (p + c) * 4 + (f * 9));

        if (meal.options?.length > 0) {
            const protein = roundNumber(foods.reduce((acc, food) => acc + food.protein, 0), 1);
            const carbs = roundNumber(foods.reduce((acc, food) => acc + food.carbs, 0), 1);
            const fat = roundNumber(foods.reduce((acc, food) => acc + food.fat, 0), 1);
            const fibre = roundNumber(foods.reduce((acc, food) => acc + food.fibre, 0), 1);

            setValue(`${mealName}.options.${optionIndex}.protein` as NutritionPath, protein);
            setValue(`${mealName}.options.${optionIndex}.carbs` as NutritionPath, carbs);
            setValue(`${mealName}.options.${optionIndex}.fat` as NutritionPath, fat);
            setValue(`${mealName}.options.${optionIndex}.fibre` as NutritionPath, fibre);
            setValue(`${mealName}.options.${optionIndex}.calories` as NutritionPath, (protein + carbs) * 4 + (fat * 9));
        }

    }, [foods]);

    const handlePortionChange = (value: number) => {
        // updates food macros
        const macros = food.macros;
        const protein = roundNumber(macros.protein / 100 * (value * food.unit.weight), 1);
        const carbs = roundNumber(macros.carbs / 100 * (value * food.unit.weight), 1);
        const fat = roundNumber(macros.fat / 100 * (value * food.unit.weight), 1);
        const fibre = roundNumber(macros.fibre / 100 * (value * food.unit.weight), 1);

        // update individual food values
        setValue(`${name}.${foodIndex}.protein` as NutritionPath, protein);
        setValue(`${name}.${foodIndex}.carbs` as NutritionPath, carbs);
        setValue(`${name}.${foodIndex}.fat` as NutritionPath, fat);
        setValue(`${name}.${foodIndex}.fibre` as NutritionPath, fibre);
        setValue(`${name}.${foodIndex}.calories` as NutritionPath, (protein + carbs) * 4 + fat * 9);
    };

    
    return (
        <NumberInput
            name={`${name}.${foodIndex}.portion`}
            control={control}
            maxLength={3}
            decimalPlaces={1}
            placeHolder="0"
            hideErrorMessage
            gutter="0"
            onChange={handlePortionChange}
        />
    )
}

export default PortionInput;