import { FC } from 'react';

// icon paths 
import amex from '/src/assets/cards/amex.svg';
import applepay from '/src/assets/cards/applepay.svg';
import diners from '/src/assets/cards/diners.svg';
import discover from '/src/assets/cards/discover.svg';
import googlepay from '/src/assets/cards/googlepay.svg';
import mastercard from '/src/assets/cards/mastercard.svg';
import unionpay from '/src/assets/cards/unionpay.svg';
import visa from '/src/assets/cards/visa.svg';
import fallback from '/src/assets/cards/default.svg';

interface CardIconProps {
  type: CardKey;
  size?: 'small' | 'medium' | 'large';
}

export type CardKey = 'amex' | 'applepay' | 'diners' | 'discover' | 'googlepay' | 'mastercard' | 'unionpay' | 'visa' | 'default';

const iconSizes = {
  small: { width: 23, height: 16 },
  medium: { width: 35, height: 24 },
  large: { width: 70, height: 48 },
};

const iconPaths = {
  amex,
  applepay,
  diners,
  discover,
  googlepay,
  mastercard,
  unionpay,
  visa,
  default: fallback,
};

const CardIcon: FC<CardIconProps> = ({ type, size = 'medium' }) => {
  const iconSize = iconSizes[size];
  const iconSrc = iconPaths[type] || iconPaths.default;

  return <img src={iconSrc} width={iconSize.width} height={iconSize.height} alt={`${type} card icon`} />;
};

export default CardIcon;