import { z } from "zod";
import { t } from "i18next";
import ruleset, { addIssue } from "@/_helpers/ruleset";

export interface IFormBuilder {
    form: IFormSection[];
}

export interface IFormSection {
    uuid: string;
    title: string;
    required: boolean;
    questions: IFormQuestion[];
}

export interface IFormQuestion {
    uuid: string;
    type: 'text' | 'long_text' | 'select' | 'multiselect' | 'rating' | 'email' | 'phone';
    title: string;
    answer?: string|string[];
    options?: IFormQuestionOptions|undefined;
    required?: boolean;
}

export interface IFormQuestionOptions {
    multiple?: boolean|undefined;
    items: string[];
}

export const formSectionSchema = z.array(z.object({
  uuid: ruleset.required,
  title: ruleset.stringOptional,
  required: ruleset.booleanOptional,
  questions: z.array(z.object({
      uuid: ruleset.required,
      title: ruleset.required,
      type: ruleset.questionType,
      required: ruleset.booleanOptional,
      options: z.object({
          multiple: z.boolean().optional(),
          items: z.array(ruleset.required)
      }).optional()
  }).superRefine((data, ctx) => {
    if (['select', 'multiselect'].includes(data.type)) {
      if (!data.options?.items) {
        addIssue(ctx, ['options.items'], t('inputs.errors.questionItemsRequred'));
      }
      if (data.type == 'select' && data.options?.items && data.options?.items?.length < 2) {
        addIssue(ctx, ['options.multiple'], t('inputs.errors.questionSelectLength'));
      }
      if (data.type == 'multiselect' && data.options?.items && data.options?.items?.length < 2) {
        addIssue(ctx, ['options.multiple'], t('inputs.errors.questionMultipleLength'));
      }
    }
  }))
}).superRefine((data, ctx) => {
  if (!data.title) addIssue(ctx, ['title'], t('components.formBuilder.errors.noSectionTitle'));
  if (data.title && data.title.length > 40) addIssue(ctx, ['title'], t('inputs.errors.titleTooLong', { length: 40 }));
}));