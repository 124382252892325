import { FC, ReactNode } from "react";

// Components
import { Box } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "../icon_button";

interface Option {
    name: string;
    disabled?: boolean;
    action: (() => void);
    render?: (() => ReactNode);
}

interface Action {
    onClick: (() => void);
    icon: ReactNode;
}

interface MenuProps {
    open: boolean;
    onClose: (() => void);
    options: Option[];
    title?: string;
    removePadding?: boolean;
    divider?: boolean;
    anchorEl?: HTMLElement|null;
    action?: Action
}

const NavMenu: FC<MenuProps> = ({
    open,
    onClose,
    options,
    title,
    removePadding,
    divider,
    anchorEl,
    action
}) => {

    //NOTE Can be achieved without ref, using an id on parent and document.getElementById(anchorEl)

    const handleClick = (event: unknown, act: (() => void)): void => {
        act();
        onClose();
    }

    return (
        <>  
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            autoFocus={false}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            slotProps={{
                paper: {
                    sx: {
                        maxHeight: 400,
                        minWidth: 180,
                        bgcolor: 'var(--nav-layer-01)',
                        borderRadius: '6px',
                        mt: 0.5
                    }
                }
            }}
            MenuListProps={{
                sx: {
                    px: removePadding ? 0 : 2
                }
            }}
        >
            {title && 
            <Box color='var(--text-overlay)' mb={1} pr={1} pl={removePadding ? 2 : 0} display='flex' justifyContent='space-between' alignItems='center'>
                <span className="body-02-compact">{title}</span>
                {!!action && (
                    <IconButton
                        size='small'
                        kind='ghost'
                        icon={action.icon}
                        onClick={action.onClick}
                        sx={{
                            color: 'var(--text-overlay) !important' 
                        }}
                    />
                )}
            </Box>
            }
            {options.map((opt, i) => (
            <MenuItem
                key={opt.name}
                onClick={(event) => handleClick(event, opt.action)}
                disabled={opt.disabled}
                divider={divider && i !== options.length - 1}
                sx={{
                    px: 0,
                    py: 1,
                    borderColor: 'var(--nav-border-subtle-01)',
                    color: 'var(--nav-text-secondary)',
                }}
            >
                { opt.render ? <opt.render/> : <span className="body-02-compact">{opt.name}</span>}
            </MenuItem>
            ))}
        </Menu>
        </>
    );
};

export type { Option, Action, MenuProps }
export default NavMenu;