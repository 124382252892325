import { FC, ReactNode } from "react";
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from "@dnd-kit/sortable";

// Components
import { Box } from "@mui/material";


interface _SortableItemProps {
    id: string;
    children: ReactNode;
}

const SortableItem: FC<_SortableItemProps> = ({
    id, 
    children 
}) => {

    const { attributes, setNodeRef, transform, transition, isDragging } = useSortable({ id });
  
    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
      zIndex: isDragging ? 999 : 'auto', // Ensure the dragged item is on top
      opacity: isDragging ? 0.8 : 1, // Add some opacity during drag
      backgroundColor: isDragging ? 'rgba(255, 255, 255, 0.9)' : 'inherit',
      width: '100%'
    };
        
    return (
      <Box ref={setNodeRef} style={style} {...attributes} tabIndex={-1}>
        {children}
      </Box>
    );
};

export default SortableItem;