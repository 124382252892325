import { z } from "zod";
import { t } from "i18next";
import ruleset, { addIssue, transformEmptyStringToNull, transformEmptyStringToNullAndNonZero } from "@/_helpers/ruleset";

export interface IActivityBuilder {
    id: string;
    name: string;
    steps: ISteps[];
    cardio: ICardio[];
    steps_notes: string;
    cardio_notes: string;
}

export interface IPlanEntry {
    uuid: string;
    name: string;
    notes?: string|undefined;
    updated_at?: string|undefined;
}



export interface ISteps extends IPlanEntry {
    steps: number|string;
}

export interface ICardio extends IPlanEntry {
    exercise_id: string;
    exercise_name: string;
    duration: number|string;
    distance?: number|string;
    calories?: number|string;
    hr?: number|string;
    type: string;
}

export const stepsSchema = (enabled: boolean) => z.array(z.object({
    uuid: ruleset.uuid,
    name: ruleset.stringOptional,
    steps: transformEmptyStringToNull(ruleset.numberOptional),
    notes: transformEmptyStringToNull(ruleset.stringOptional),
    updated_at: ruleset.stringOptional
})).superRefine((data, ctx) => {
    if (!enabled) return;
    data.forEach((day, dayIndex) => {
        const path = [dayIndex];
        if (day.name == null || day.name == '') addIssue(ctx, [...path, 'name'], t('inputs.errors.required'));
        if (day.steps == null || day.steps == '') addIssue(ctx, [...path, 'steps'], t('inputs.errors.required'));
    });
});

export const cardioSchema = (enabled: boolean) => z.array(z.object({
    uuid: ruleset.uuid,
    name: ruleset.stringOptional,
    exercise_id: ruleset.stringOptional,
    exercise_name: ruleset.stringOptional,
    duration: ruleset.numberOptional,
    distance: transformEmptyStringToNullAndNonZero(ruleset.numberOptional, 'inputs.errors.distanceNotZero'),
    calories: transformEmptyStringToNullAndNonZero(ruleset.numberOptional, 'inputs.errors.caloriesNotZero'),
    hr: transformEmptyStringToNullAndNonZero(ruleset.numberOptional, 'inputs.errors.heartrateNotZero'),
    type: ruleset.stringOptional,
    notes: transformEmptyStringToNull(ruleset.stringOptional),
    updated_at: ruleset.stringOptional,
})).superRefine((data, ctx) => {
    if (!enabled) return;
    data.forEach((day, dayIndex) => {
        const path = [dayIndex];
        if (day.name == null || day.name == '') addIssue(ctx, [...path, 'name'], t('inputs.errors.required'));
        if (day.exercise_id == null || day.exercise_id == '') addIssue(ctx, [...path, 'exercise_id'], t('inputs.errors.required'));
        if (day.exercise_name == null || day.exercise_name == '') addIssue(ctx, [...path, 'exercise_name'], t('inputs.errors.required'));
        if (day.type == null || day.exercise_name == '') addIssue(ctx, [...path, 'type'], t('inputs.errors.required'));
        if (day.duration == null) addIssue(ctx, [...path, 'duration'], t('inputs.errors.required'));
        if (day.duration == 0) addIssue(ctx, [...path, 'duration'], t('inputs.errors.durationNotZero'));
    });
});


export const activityFormSchema = z.object({
    name: ruleset.required,
    steps_notes: ruleset.stringOptional,
    cardio_notes: ruleset.stringOptional,
});