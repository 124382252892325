import dayjs from "dayjs";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Services and interfaces
import { selectHasTrialCode } from "@/store/coach";

// Components
import { Box } from "@mui/material";


interface _IPageMeta {
    title: string;
    subtitle: string;
}

const SetupLayoutHeader = () => {

    const currentPath = useLocation().pathname;
    const isTrial = useSelector(selectHasTrialCode);
    const trialEndDate = dayjs().add(7, 'day').format('D MMMM YYYY');
    const [page, setPage] = useState<_IPageMeta>({title: '', subtitle: ''});

    useEffect(() => {
        switch (currentPath) {
            case '/setup/subscription':
                setPage({title: t(`pages.setup.subscription.form.${isTrial ? 'trialTitle' : 'title'}`), subtitle: t(`pages.setup.subscription.form.${isTrial ? 'trialSubtitle' : 'subtitle'}`, {date: trialEndDate})});
                break;
            case '/setup/subscribed':
                setPage({title: t(`pages.setup.subscription.complete.${isTrial ? 'trialTitle' : 'title'}`), subtitle: t(`pages.setup.subscription.complete.${isTrial ? 'trialSubtitle' : 'subtitle'}`)});
                break;
            case '/setup/profile':
                setPage({title: t('pages.setup.profile.title'), subtitle: t('pages.setup.profile.subtitle')});
                break;
            case '/setup/settings':
                setPage({title: t('pages.setup.settings.title'), subtitle: t('pages.setup.settings.subtitle')});
                break;
            // case '/setup/checkin':
            //     setPage({title: t('pages.setup.checkin.title'), subtitle: t('pages.setup.checkin.subtitle')});
            //     break;
            default: 
                setPage({title: '', subtitle: ''});
        }
    }, [currentPath, isTrial]);
    
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: '70px', '@media (max-width: 768px)': {marginBottom: '24px'}, textAlign: 'left'}}>
            <span className="heading-03" style={{marginBottom: '12px'}}>{page.title}</span>
            {/* TODO simplify this */}
            {currentPath == '/setup/subscription' ?
                <Box display='-webkit-box' color="var(--text-secondary)" textAlign="left" maxWidth="880px">
                    <Trans className="body-01" i18nKey={page.subtitle} values={{date: trialEndDate}}></Trans>
                </Box> :
                <Box className="body-01" sx={{color: 'var(--text-secondary)', '@media (max-width: 768px)': {whiteSpace: 'pre-wrap'}}}>{page.subtitle}</Box>
            }
        </Box>
    )
}

export default SetupLayoutHeader;