import { useTranslation } from "react-i18next";

// Helpers
import { capitalize, capitalizeWords } from "@/_helpers/text_functions";

// Interfaces
import { ISupplement } from "@/interfaces/supplement/supplement";

// Components
import Tag from "@/components/tag";
import { Box } from "@mui/material";
import Tooltip from "@/components/tooltip";
import { ColDef } from "@/components/datatable";
import DeleteSupplement from "./delete_supplement";
import useFilterActions from "@/components/datatable/use_filter_actions";


const useSupplementTableHeaders = (): ColDef<ISupplement>[] => {

    const { t } = useTranslation();
    const { hiddenColumns } = useFilterActions();

    const headers: ColDef<ISupplement>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '25%',
            sortable: true,
            render: (row) => capitalizeWords(row.name)
        },
        ...(!hiddenColumns.includes('brand') ? [{
            field: 'brand',
            headerName: t('components.dataTable.headers.brand'),
            width: '15%',
            sortable: true,
            render: (row: ISupplement) => <span>{capitalize(row.brand)}</span>
        }] : []),
        ...(!hiddenColumns.includes('category') ? [{
            field: 'category',
            headerName: t('components.dataTable.headers.category'),
            width: '15%',
            render: (row: ISupplement) => <Box display="flex">
                <Tag label={t(`enums.supplementCategory.${!row.category[0] || row.category[0] === '' ? 'not_available' : row.category[0]}`)} />
                {row.category.length > 1 &&
                    <Tooltip
                        size="medium"
                        slot={row.category.slice(1).map((cat, i) => 
                            <Tag key={i} index={i+1} 
                                label={t(`enums.supplementCategory.${cat}`)}
                                sx={{marginRight: row.category.length-2 !== i ? '4px' : 'unset'}}
                                />)}
                        >
                        <Tag label={`+${row.category.length-1}`} sx={{
                            marginLeft: '4px',
                            backgroundColor: 'var(--tag-grey-background)',
                            color: 'var(--tag-grey-color)',
                            '&:hover': {backgroundColor: 'var(--tag-grey-hover)'}
                        }} />
                    </Tooltip>}
            </Box>
        }] : []),
        ...(!hiddenColumns.includes('priority') ? [{
            field: 'priority',
            headerName: t('components.dataTable.headers.priority'),
            width: '15%',
            render: (row: ISupplement) => <Tag label={t(`enums.supplementPriority.${!row.priority || row.priority === '' ? 'not_available' : row.priority}`)} />
        }] : []),
        ...(!hiddenColumns.includes('unit') ? [{
            field: 'unit',
            headerName: t('components.dataTable.headers.unit'),
            width: '10%',
            render: (row: ISupplement) => <span>{row.unit ?? 'N/A'}</span>
        }] : []),
        ...(!hiddenColumns.includes('library') ? [{
            field: 'type',
            headerName: t('components.dataTable.headers.library'),
            width: '10%',
            // sortable: true,
            render: (row: ISupplement) => <span>{capitalize(row.library)}</span>
        }] : []),
        {
            field: 'actions',
            headerName: '',
            width: '5%',
            align: 'right',
            render: (row) => <DeleteSupplement row={row} />
        }
    ];

    return headers;
}

export default useSupplementTableHeaders;