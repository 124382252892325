import { t } from "i18next";
import { useForm } from "react-hook-form";
import { FC, ReactNode, useEffect } from "react";

// Helpers
import { useDebounce } from "@/_helpers/hooks";

// Interface
import ButtonAction from "@/interfaces/components/button_action";

// Styles
import { ListChecked } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import Tooltip from "@/components/tooltip";
import IconButton from "@/components/icon_button";
import SearchInput from "@/components/search_input";
import FilterBar, { FilterBarProps } from "./filter/filter_bar";
import useBulkActions from "@/components/datatable/use_bulk_actions";
import ContentSwitcher, { ContentAction } from "@/components/content_switcher";


export interface ContentSwitcherProps {
    size: 'small' | 'large';
    options: ContentAction[];
}

export interface IFilterProps {
    showSelect?: boolean;
    slot?: ReactNode;
    contentSwitcher?: ContentSwitcherProps;
    filterBar?: FilterBarProps;
    button?: ButtonAction;
    action1?: ButtonAction;
    action2?: ButtonAction;
    action3?: ButtonAction;
    onSearch: (filter: string) => (void);
}

const FilterSection: FC<IFilterProps> = ({
    showSelect,
    slot,
    contentSwitcher,
    filterBar,
    button,
    action1,
    action2,
    action3,
    onSearch,
}) => {

    const { control, watch, setValue } = useForm({defaultValues: {search: ''}})
    const searchVal = watch('search');
    const debouncedVal = useDebounce(searchVal, 500)

    const { showBulkActions, handleSetShowBulkActions } = useBulkActions();

    const handleToggleBulkActions = () => {
        handleSetShowBulkActions(!showBulkActions)
    }

    useEffect(() => {
        onSearch(debouncedVal ?? '')
    }, [debouncedVal])

    const filterStyle = {
        display:'flex', 
        alignItems:'center', 
        justifyContent:'end', 
        padding: '0px 24px 8px 24px',
        '& .input__container': { mb: 0, width: 'unset' }
    }

    return (
        <Box sx={filterStyle}>

            <SearchInput name="search" control={control} />

            <Box flexGrow={1}></Box>

            {slot}

            {contentSwitcher && <ContentSwitcher 
                size={contentSwitcher.size}
                options={contentSwitcher.options}
                onChange={() => setValue('search', '')}
                />}

            {filterBar && <FilterBar
                {...filterBar}
                />}

            {action3 && <IconButton kind="ghost" size="small" icon={action3.icon} onClick={action3.onClick} />}
            {action2 && <IconButton kind="ghost" size="small" icon={action2.icon} onClick={action2.onClick} />}
            {action1 && <IconButton kind="ghost" size="small" icon={action1.icon} onClick={action1.onClick} />}
            {showSelect && <Tooltip kind="nav" size="large" title={t('tooltips.bulkEdit')}>
                <IconButton 
                    kind='ghost' 
                    size='small'
                    icon={<ListChecked />}
                    onClick={handleToggleBulkActions}
                    />
            </Tooltip>}

            {button && <Button 
                kind="primary"
                size="small"
                label={button.label}
                endIcon={button.icon}
                onClick={button.onClick}
                sx={{marginLeft: '16px'}}
                />}
        </Box>
    );
}

export default FilterSection;