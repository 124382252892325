import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import { ISubmissionForm } from '@/interfaces/checkin_submission/submission';
import { ICheckinSubmission, IFeedback } from '@/interfaces/checkin_submission/checkin_submission';


export const checkinSubmissionApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCheckinSubmissions: build.query<ICheckinSubmission[], void>({
            query: () => ({ url: '/checkin-submissions', method: 'get' }),
            transformResponse: (response: IAPIResponse<ICheckinSubmission[]>) => response.data,
            providesTags: ['Checkin.submission.list']
        }),
        getClientCheckinSubmissions: build.query<ICheckinSubmission[], string>({
            query: (client_id) => ({ url: `/clients/${client_id}/submissions`, method: 'get' }),
            transformResponse: (response: IAPIResponse<ICheckinSubmission[]>) => response.data,
            providesTags: ['Checkin.submission.client.list']
        }),
        getCheckinSubmission: build.query<ICheckinSubmission, ISubmissionForm>({
            query: (p) => ({ url: `/clients/${p.client_id}/submissions/${p.submission_id}`, method: 'get' }),
            transformResponse: (response: IAPIResponse<ICheckinSubmission>) => response.data,
        }),
        updateCheckinSubmission: build.mutation<ICheckinSubmission, {p: ISubmissionForm, draft: boolean, data: IFeedback}>({
            query: ({p, draft, data}) => ({ url: `/clients/${p.client_id}/submissions/${p.submission_id}?draft=${draft}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<ICheckinSubmission>) => response.data,
            async onQueryStarted({ p }, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) => 
                dispatch(checkinSubmissionApi.util.updateQueryData('getClientCheckinSubmissions', String(p.client_id), submissions => {
                    const index = submissions.findIndex((item) => item.id === response.data.id);
                    submissions.splice(index, 1, response.data);
                }))).catch(() => {});
            },
            invalidatesTags: ['Checkin.submission.list']
        }),
        deleteCheckinSubmission: build.mutation<void, ISubmissionForm>({
            query: (p) => ({ url: `/clients/${p.client_id}/submissions/${p.submission_id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(p, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(checkinSubmissionApi.util.updateQueryData('getClientCheckinSubmissions', p.client_id, submissions => {
                    return submissions.filter((item) => item.id !== p.submission_id);
                }))).catch(() => {});
            },
        }),
    }),
})

export const { 
    useGetCheckinSubmissionsQuery,
    useGetClientCheckinSubmissionsQuery,
    useGetCheckinSubmissionQuery,
    useLazyGetCheckinSubmissionQuery,
    useUpdateCheckinSubmissionMutation,
    useDeleteCheckinSubmissionMutation,
} = checkinSubmissionApi;

