import { z } from "zod";
import ruleset from "@/_helpers/ruleset";
import { ICheckinTemplateSection } from "./checkin_template";
import { formSectionSchema } from "@/interfaces/form_builder/form_builder";

export interface ICheckinTemplateForm {
    id: string;
    name: string;
    type: string;
    default: boolean;
    draft: boolean;
    form: ICheckinTemplateSection[];
}

export const checkinTemplateFormSchema = z.object({
    name: ruleset.required,
    draft: ruleset.boolean,
    form: formSectionSchema
});

export const checkinTemplateCreateSchema = z.object({
    name: ruleset.required,
});