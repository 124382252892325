import { ISelectOption } from "@/interfaces/components/select_option";

export const questionTypeOptions: ISelectOption<string>[] = [
    { label: 'Text', value: 'text' },
    { label: 'Long text', value: 'long_text' },
    { label: 'Select', value: 'select' },
    { label: 'Multiselect', value: 'multiselect' },
    { label: 'Rating (1-5)', value: 'rating' },
    { label: 'Email', value: 'email' },
    { label: 'Phone', value: 'phone' },
];