import dayjs from 'dayjs';
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

// Context
import { usePhaseContext } from '@/contexts/phase_context';

// Services and interfaces
import { IPhaseNote } from "@/interfaces/phase_note/phase_note";

// Components
import NoteModal from './note_modal';
import Box from "@mui/material/Box";
import DeleteNoteModal from "./delete_note";
import Typography from "@mui/material/Typography";
import OverflowMenu from "@/components/overflow_menu";


interface NoteProps {
    note: IPhaseNote;
    phase_id: string;
    showMenu?: boolean;
}

const PhaseNote: FC<NoteProps> = ({
    note,
    phase_id,
    showMenu = false,
}) => {

    const { t } = useTranslation();
    const { phase } = usePhaseContext();
    const [open, setOpen] = useState<string|null>(null)

    return ( 
        <Box position='relative'>

            <Typography mb={0.5}>
                <span className='label-text-02' style={{color: 'var(--text-secondary)'}}>{dayjs(note.date).format('DD/MM/YYYY')}</span>
            </Typography>
            <Typography mb={0.5}>
                <span className='heading-07-compact max-lines-3'>{note.title}</span>
            </Typography>
            <Typography mb={0.5}>
                <span className='body-02 max-lines-3'>{note.content}</span>
            </Typography>
            {showMenu && (
                <>
                    <Box position='absolute' top={0} right={0}>
                    <OverflowMenu
                        options={[
                            { name: t('components.menuItems.edit'), action: () => setOpen('edit') }
                        ]}
                        deleteLabel={t('components.menuItems.delete')}
                        onDelete={() => setOpen('delete')}
                        disabled={phase?.readonly}
                    />
                    </Box>
                    {open === 'edit' && <NoteModal
                        open={open === 'edit'} 
                        onClose={() => setOpen(null)}
                        note={note} 
                        phase_id={phase_id} 
         
                        />}
                    {open === 'delete' && <DeleteNoteModal
                        open={open === 'delete'} 
                        onClose={() => setOpen(null)} 
                        note={note} 
                        phase_id={phase_id} 
                        />}
                </>
            )}

        </Box>
    );
}

export default PhaseNote;