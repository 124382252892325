import { FC, ReactElement, ReactNode } from "react";
import { Tooltip as MuiTooltip, tooltipClasses } from "@mui/material";

interface _ITooltipProps  {
  kind?: 'primary' | 'nav',
  size?: 'small' | 'medium' | 'large';
  placement?: "top" | "bottom" | "left" | "right" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start" | undefined
  // Optional title, for use with single line tooltips
  title?: ReactNode|string;
  // Optional slot, for use with multi-line or component based tooltips
  slot?: ReactNode;
  // Child element that the tooltip is attached to
  children: ReactElement
}

const Tooltip: FC<_ITooltipProps> = ({ 
  kind = 'primary',
  size = 'small',
  placement = 'top',
  title,
  slot,
  children
}) => {

  const classes = {
    'Tooltip__primary': kind === 'primary',
    'Tooltip__nav': kind === 'nav',
    'Tooltip--popover': slot,
    'MuiTooltip-tooltip-small': size === 'small',
    'MuiTooltip-tooltip-medium': size === 'medium',
    'MuiTooltip-tooltip-large': size === 'large',
  }

  const toolTipClasses = Object.entries(classes)
  .filter(([,v]) => !!v)
  .map(([k,]) => k)
  .join(' ')

  const tooltipStyles = {
    [`& .${tooltipClasses.arrow}`]: {
      color: 'var(--background-inverse)'
    },
    [`& .${tooltipClasses.tooltip}`]: {
      borderRadius: 2,
      backgroundColor: 'var(--background-inverse)',
    },
  }

  return (
      <MuiTooltip 
        title={slot ?? title}
        placement={placement} 
        arrow
        enterDelay={300}
        componentsProps={{ tooltip: { className: toolTipClasses } }}
        sx={tooltipStyles}
        >
          {children}
      </MuiTooltip>
  );
}

  export default Tooltip;