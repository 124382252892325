import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import { IExercise } from '@/interfaces/exercise/exercise';
import { IExerciseForm } from '@/interfaces/exercise/exercise_form';


export const exerciseApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getExercises: build.query<IExercise[], void>({
            query: () => ({ url: '/library/exercises', method: 'get' }),
            transformResponse: (response: IAPIResponse<IExercise[]>) => response.data.sort((a, b) => a.name.localeCompare(b.name)),
            providesTags: ['Exercise.list'],
            keepUnusedDataFor: 1800
        }),
        addExercise: build.mutation<IExercise, IExerciseForm>({
            query: (data) => ({ url: '/library/exercises', method: 'post', data }),
            transformResponse: (response: IAPIResponse<IExercise>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(exerciseApi.util.updateQueryData('getExercises', undefined, exercises => {
                    exercises.push(response.data)
                    exercises.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            },
        }),
        updateExercise: build.mutation<IExercise, {id: string, data: IExerciseForm}>({
            query: ({id, data}) => ({ url: `/library/exercises/${id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<IExercise>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getExercises' as never, undefined as never, (exercises: IExercise[]) => {
                    const index = exercises.findIndex((item) => item.id === response.data.id);
                    exercises.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        uploadVideo: build.mutation<IExercise, {id: string, data: FormData}>({
            query: ({id, data}) => ({ url: `/library/exercises/${id}/media`, method: 'post', data, formData: true, headers: { 'content-type': 'multipart/form-data' } }),
            transformResponse: (response: IAPIResponse<IExercise>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getExercises' as never, undefined as never, (exercises: IExercise[]) => {
                    const index = exercises.findIndex((item) => item.id === response.data.id);
                    exercises.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteExercise: build.mutation<void, string>({
            query: (id) => ({ url: `/library/exercises/${id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(baseApi.util.updateQueryData('getExercises' as never, undefined as never, (exercises: IExercise[]) => {
                    return exercises.filter((item) => item.id !== id)
                }))).catch(() => {});
            },
        }),
        uploadRequest: build.query<UploadRequestData, string>({
            query: (id) => ({ url: `/library/exercises/${id}/upload-request`, method: 'get' }),
            transformResponse: (response: IAPIResponse<UploadRequestData>) => response.data,            
        })
    }),
})

export const { 
    useGetExercisesQuery,
    useAddExerciseMutation,
    useDeleteExerciseMutation,
    useUpdateExerciseMutation,
    useUploadVideoMutation,
    useLazyUploadRequestQuery
} = exerciseApi;

