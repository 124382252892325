import { CSSProperties, FC } from "react";

import Box from "@mui/material/Box";
import IconButton from "../icon_button";
import { ZoomIn, ZoomOut } from "@carbon/icons-react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface ImageProps {
    src: string
    alt? :string
}

const Image: FC<ImageProps> = ({
    src,
    alt
}) => {

    // required as content-fit defaults cause images to be cropped
    const transformStyle: CSSProperties = {
        height: '100%', 
        width: '100%'
    }

    return (
        <Box height='100%' overflow='hidden' position='relative' bgcolor='#000000bb' minWidth={400} width='100%' maxWidth={1200}>
        <TransformWrapper centerOnInit>
        {({ zoomIn, zoomOut }) => (
        <>
            {/* zoom buttons */}
            <Box sx={{
                position: 'absolute',
                p: 2, bottom: 0, left: 0,
                display: 'flex', gap: 1,
                flexDirection: 'column',
                zIndex: 1000,
            }}>
                <IconButton size='small' icon={<ZoomIn />} onClick={() => zoomIn()} />
                <IconButton size='small' icon={<ZoomOut />} onClick={() => zoomOut()} />
            </Box>

            {/* image */}
            <TransformComponent wrapperStyle={transformStyle} contentStyle={transformStyle}>
                <img 
                    // style={{width: '100%', maxWidth: 1200, maxHeight: '80vh'}}
                    style={{objectFit: 'contain', height: '100%', maxWidth: 1200, width: '100%'}}
                    src={src}
                    alt={alt}
                />
            </TransformComponent>
        </>
        )}
        </TransformWrapper>
        </Box>
    );
};

export default Image;