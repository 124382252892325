import { Navigate } from "react-router-dom";
import VaultPage from "@/pages/vault/vault_page";
import FolderPage from "@/pages/vault/files/folder_page";
import FoldersPage from "@/pages/vault/folders/folders_page";


export const vaultRoutes = {
  path: "vault",
  element: <VaultPage />,
  children: [
    {
      index: true,
      element: <Navigate to="folders" replace />,
    },
    {
      path: "folders",
      element: <FoldersPage />
    },
    {
      path: "folders/:id",
      element: <FolderPage />
    },
  ]
}