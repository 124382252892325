import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

// Services and interfaces
import { IExercise, IProgrammeBuilder } from "@/interfaces/programme/programme_builder";
import { IProgrammeWorkoutBuilder } from "@/interfaces/programme_workout/programme_workout_builder";

// Components
import ReorderForm from "./reorder_form";
import RightPanel from "@/components/panel/right_panel";


interface _ReorderPanelProps {
    open: boolean;
    onClose: () => void;
    basePath: `plan.${number}.exercises`|'exercises';
    // workoutIndex: number;
    exercises: IExercise[];
}

const ReorderPanel: FC<_ReorderPanelProps> = ({
    open,
    onClose,
    basePath,
    // workoutIndex,
    exercises
}) => {

    const { t } = useTranslation();
    const processedSupersetIds = new Set();
    const { setValue } = useFormContext<IProgrammeBuilder|IProgrammeWorkoutBuilder>();
    const [orderedExercises, setOrderedExercises] = useState<IExercise[]>(exercises.map((e) => {
        if (e.superset_id) {
            if (processedSupersetIds.has(e.superset_id)) return null;
            processedSupersetIds.add(e.superset_id);
            return e;
        }
        return e;
    }).filter((e): e is IExercise => e !== null));

    const handleSave = () => {
        const sortedExercises: IExercise[] = [];
        const handledSupersets = new Set(); // Keep track of handled supersets
      
        orderedExercises.forEach(item => {
          if (item.superset_id) {
            // If it's part of a superset, and we haven't handled this superset yet
            if (!handledSupersets.has(item.superset_id)) {
              const supersetItems = exercises.filter(exercise => exercise.superset_id === item.superset_id);
              
              sortedExercises.push(...supersetItems);
              
              handledSupersets.add(item.superset_id);
            }
          } else {
            // If the item is not part of any superset, add it directly
            sortedExercises.push(item);
          }
        });

        // After sorting, update the plan exercises with the new order
        setValue(basePath, sortedExercises);
        onClose();
    }

    return (
        <RightPanel
            open={open}
            onClose={onClose}
            title="Reorder exercises"
            children={<ReorderForm 
                exercises={exercises}
                mappedExercises={orderedExercises}
                onSetExercises={setOrderedExercises}
                />}
            action1={{
                label: t('components.buttons.save'),
                onClick: handleSave
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: onClose
            }}
            />
    )
}

export default ReorderPanel;