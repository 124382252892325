import baseApi from '@/repositories/base_api';
import { IAPIResponse } from '@/interfaces/api/response';
import IPaymentMethod from '@/interfaces/payment_methods/payment_method';
import IPaymentMethodForm from '@/interfaces/payment_methods/payment_method_form';


export const invoiceApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getPaymentMethods: build.query<IPaymentMethod[], void>({
            query: () => ({ url: '/payment-methods', method: 'get' }),
            transformResponse: (response: IAPIResponse<IPaymentMethod[]>) => response.data,
            providesTags: ['PaymentMethod.list']
        }),
        addPaymentMethod: build.mutation<IPaymentMethod, IPaymentMethodForm>({
            query: (paymentMethod) => ({ url: '/payment-methods', method: 'post', data: paymentMethod }),
            transformResponse: (response: IAPIResponse<IPaymentMethod>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getPaymentMethods' as never, undefined as never, (paymentMethods: IPaymentMethod[]) => {
                    paymentMethods.push(response.data)
                    if (response.data.default && paymentMethods.length > 1) {
                        paymentMethods = paymentMethods.map((item) => {
                            if (item.id !== response.data.id) {
                                item.default = false
                            }
                            return item
                        })
                    }
                }))).catch(() => {});
            },
        }),
        setDefaultPaymentMethod: build.mutation<IPaymentMethod, IPaymentMethod>({
            query: (paymentMethod) => ({ url: `/payment-methods/${paymentMethod.id}`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<IPaymentMethod>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(baseApi.util.updateQueryData('getPaymentMethods' as never, undefined as never, (paymentMethods: IPaymentMethod[]) => {
                    paymentMethods.map((item) => {
                        if (item.id === response.data.id) return item.default = true;
                        return item.default = false;    
                    })
                }))).catch(() => {});
            },
        }),
        removePaymentMethod: build.mutation<IPaymentMethod[], IPaymentMethod>({
            query: (paymentMethod) => ({ url: `/payment-methods/${paymentMethod.id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<IPaymentMethod[]>) => response.data,
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(baseApi.util.updateQueryData('getPaymentMethods' as never, undefined as never, (paymentMethods: IPaymentMethod[]) => {
                    return paymentMethods.filter((item) => item.id !== id)
                }))).catch(() => {});
            },
        })
    }),
})

export const { useGetPaymentMethodsQuery, useAddPaymentMethodMutation, useSetDefaultPaymentMethodMutation, useRemovePaymentMethodMutation } = invoiceApi;

