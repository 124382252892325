import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { selectCoachId } from "@/store/coach";
import { ApiError } from "@/interfaces/api/error";
import { ILeadForm } from "@/interfaces/lead_form/lead_form";
import { selectHasTeam, selectSelectedMember } from "@/store/team";
import { useAddLeadFormMutation, useDeleteLeadFormsMutation, useGetLeadFormsQuery } from "@/repositories/lead_form";

// Styles
import { Add } from "@carbon/icons-react";

// Compnents
import NoForms from "./no_forms";
import FormModal from "./form_modal";
import { Container } from "@mui/material";
import DeleteFormModal from "./delete_modal";
import DataTable from "@/components/datatable";
import useLeadFormTableHeaders from "./table_headers_forms";
import useBulkActions from "@/components/datatable/use_bulk_actions";


const FormsTab: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const headers = useLeadFormTableHeaders();
    const hasTeam = useSelector(selectHasTeam);
    const coachId = useSelector(selectCoachId);
    const selectedMember = useSelector(selectSelectedMember);
    const [open, setOpen] = useState<string|null>(null);
    const [addLeadForm, { isLoading: isAdding }] = useAddLeadFormMutation();
    const [deleteLeadForms, { isLoading: isDeletingMany }] = useDeleteLeadFormsMutation();
    const { showBulkActions, selected, handleSetSelected, handleSetShowBulkActions } = useBulkActions();

    const { data: list, isLoading } = useGetLeadFormsQuery();

    const noDataMessage = () => {
        if (!hasTeam) return t('components.dataTable.noData.noLeadforms');
        if (selectedMember?.id !== coachId) return t('components.dataTable.noData.noTeamLeadforms', {name: selectedMember?.name});
    }

    const handleAdd = (data: ILeadForm) => {
        addLeadForm(data).unwrap().then((form) => {
            showToast({
                type: 'success',
                title: t('notifications.form.created.title'), 
                message: t('notifications.form.created.message', {name: form.name})
            });
            setOpen(null);
            navigate(`/prospects/lead-forms/${form.id}`);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleDeleteMany = () => {
        deleteLeadForms(selected).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.form.deleted.title', {count: selected.length})});
            setOpen(null);
            handleSetShowBulkActions(false);
            handleSetSelected([]);1
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleOpenForm = (form: ILeadForm) => {
        if (showBulkActions) return;
        navigate(`/prospects/lead-forms/${form.id}`);
    }

    return (
        <Container sx={{padding: '24px 0', overflowY: 'auto'}}>

            <DataTable
                data={list}
                dataKey="id"
                columns={headers} 
                hideHeader
                filter={{
                    showSelect: true,
                    button: {
                        label: t('components.buttons.addForm'),
                        icon: <Add />,
                        onClick: () => setOpen('add')
                    },
                }}
                filterKeys={["name"]}
                bulkActions={{
                    action1: {
                        label: t('components.buttons.delete'),
                        onClick: () => selected.length > 0 && setOpen('deleteMany'),
                    },
                    disableSelect: (row: ILeadForm) => row.published,
                }}
                localPagination
                noDataMessage={<NoForms message={noDataMessage()} disabled={hasTeam && selectedMember?.id !== coachId} />}
                noDataFoundMessage={<NoForms disabled={hasTeam && selectedMember?.id !== coachId} />}
                isLoading={isLoading}
                rowClick={handleOpenForm}
                />
            {open === 'add' && <FormModal 
                open={open === 'add'} 
                onClose={() => setOpen(null)}
                isLoading={isAdding}
                onSave={handleAdd} 
                />}
            {open === 'deleteMany' && <DeleteFormModal
                open={open === 'deleteMany'}
                onClose={() => setOpen(null)}
                count={selected.length}
                name={list?.find((form) => form.id === selected[0])?.name ?? ''}
                isLoading={isDeletingMany}
                onDelete={handleDeleteMany}
                />}

        </Container>
    );
};

export default FormsTab;