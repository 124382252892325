import { t } from "i18next";
import { FC, useState } from "react";

// Styles
import Earn from "/src/assets/referrals/earn.png";
import Level from "/src/assets/referrals/level.png";
import Rewards from "/src/assets/referrals/rewards.png";

// Components
import { Box } from "@mui/material";


const ReferralIntroPreview: FC = () => {

    const [selectedMenuItem, setSelectedMenuItem] = useState(0);

    const menuItems = [
        {title: t('components.referralsPamphlet.menuItems.earn.title'), description: t('components.referralsPamphlet.menuItems.earn.description')},
        {title: t('components.referralsPamphlet.menuItems.level.title'), description: t('components.referralsPamphlet.menuItems.level.description')},
        {title: t('components.referralsPamphlet.menuItems.rewards.title'), description: t('components.referralsPamphlet.menuItems.rewards.description')},
    ]

    const content = () => {
        switch (selectedMenuItem) {
            case 0:
                return <_EarnContent />
            case 1:
                return <_LevelContent />
            case 2:
                return <_RewardsContent />
            default:
                return <></>
        }
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center">

            {/* Pamphlet */}
            <Box 
                display="grid" 
                gridTemplateColumns="1fr 1fr" 
                width="100%" 
                maxWidth="1164px" 
                borderRadius="6px"
                minHeight="434px"
                border="solid 1px var(--border-subtle-01)"
                bgcolor="var(--layer-01)"
                boxShadow="0px 2px 6px 0px rgba(0, 0, 0, 0.30)"
                >

                {/* Menu */}
                <Box 
                    display="flex" 
                    flexDirection="column" 
                    padding="32px 48px" 
                    rowGap="12px"
                    minWidth="clamp(200px, 25%, 350px)"
                    sx={{
                        borderTopLeftRadius: '6px', 
                        borderBottomLeftRadius: '6px'}}
                    >
                    {menuItems.map((item, index) => (
                        <Box 
                            key={index} 
                            display="flex" 
                            padding="16px 24px" 
                            boxSizing="border-box" 
                            borderRadius="6px"
                            onClick={() => setSelectedMenuItem(index)}
                            sx={{
                                bgcolor: selectedMenuItem == index ? 'var(--teal-10)' : '',
                                cursor: 'pointer',
                                ":hover": {bgcolor: 'var(--teal-10-hover)'}
                            }}
                            >

                            {/* Number */}
                            <span className="heading-07-compact" style={{marginRight: '16px'}}>{index+1}</span>

                            {/* Item title and description */}
                            <Box key={index} display="flex" flexDirection="column" textAlign="left" sx={{overflow: 'hidden'}}>
                                <span className="heading-07-compact">{item.title}</span>
                                <span className="body-02">{item.description}</span>
                            </Box>

                        </Box>
                    ))}

                    {/* Spacer */}
                    <Box height="48px" />

                    {/* Actions */}
                    <Box display="flex" alignItems="center" justifyContent="space-between">

                    </Box>
                
                </Box>

                {/* Content */}
                <Box 
                    display="flex" 
                    flexDirection="column"
                    sx={{
                        bgcolor: 'var(--teal-10)', 
                        borderTopRightRadius: '6px', 
                        borderBottomRightRadius: '6px',
                        overflow: 'hidden'
                    }}
                    >
                    {content()}
                </Box>

            </Box>
        
        </Box>
    )
}

export default ReferralIntroPreview;

const _EarnContent: FC = () => {

    return (
        <Box display="flex" height="100%" alignItems="flex-end" justifyContent="center" padding="16px 16px 0 16px">

            <Box
                className="fade-in"
                component="img"
                src={Earn}
                sx={{
                    maxWidth: '440px',
                    maxHeight: '509px',
                    width: '100%',
                    height: 'auto',
                }}
            />

        </Box>
    )
}

const _LevelContent: FC = () => {

    return (
        <Box display="flex" height="100%" alignItems="center" justifyContent="center" padding="16px">

            <Box
                className="fade-in"
                component="img"
                src={Level}
                sx={{
                    maxWidth: '474px',
                    maxHeight: '279px',
                    width: '100%',
                    height: 'auto',
                }}
            />

        </Box>
    )
}

const _RewardsContent: FC = () => {

    return (
        <Box display="flex" height="100%" alignItems="center" justifyContent="center" padding="16px">

            <Box
                className="fade-in"
                component="img"
                src={Rewards}
                sx={{
                    maxWidth: '397px',
                    maxHeight: '255px',
                    width: '100%',
                    height: 'auto',
                }}
            />

        </Box>
    )
}