import { FC, useState } from "react"
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { ApiError } from "@/interfaces/api/error";
import { selectDataMetricsTraining } from "@/store/settings";
import { useAddProgrammeTemplateMutation } from "@/repositories/programme_template";
import { IProgrammeTemplateForm } from "@/interfaces/programme_template/programme_template_form";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import PersonalTab from "./programmes/personal_tab";
import SecondaryToolbar from "@/components/secondary_toolbar";
import ProgrammeModal from "@/components/programme_builder/programme_modal";


const ProgrammeTemplateListPage: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const hasTeam = useSelector(selectHasTeam);
    const trainingMetrics = useSelector(selectDataMetricsTraining)
    const [open, setOpen] = useState<string|null>(null);
    const [addProgramme, { isLoading }] = useAddProgrammeTemplateMutation();

    const tabs = [
        {
            label: t('tabs.personal'),
            link: '/templates/programmes/personal',
            sx: {width: '90px'}
        },
        {
            label: t('tabs.team'),
            link: '/templates/programmes/team',
            sx: {width: '90px'}
        }
    ]

    const handleAdd = (data: IProgrammeTemplateForm) => {
        addProgramme(data).unwrap().then((template) => {
            showToast({
                type: 'success', 
                title: t('notifications.template.created.title'), 
                message: t('notifications.template.created.message', {name: template.name})
            });
            setOpen(null);
            navigate(`/templates/programmes/${template.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>
            <SecondaryToolbar 
                title={t('pages.templates.programme.title')}
                tabs={hasTeam ? tabs : []}
                action1={{
                    label: t('components.buttons.addProgramme'),
                    icon: <Add />,   
                    onClick: () => setOpen('add')            
                }}
                />
                {open === 'add' && <ProgrammeModal 
                    open={open === 'add'} 
                    onClose={() => setOpen(null)}
                    isLoading={isLoading}
                    onSave={handleAdd} 
                    defaultMetrics={trainingMetrics}
                />}

            {hasTeam && <Outlet />}
            {!hasTeam && <PersonalTab />}
        </Box>
    )
}

export default ProgrammeTemplateListPage