import { FC } from "react";
import { useTranslation } from "react-i18next";

// Styles
import { Copy } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";


interface CopyPlanModalProps {
    open: boolean;
    onClose: () => void;
    name: string;
    isTemplate?: boolean;
    isLoading: boolean;
    onCopy: () => void;
}

const CopyPlanModal: FC<CopyPlanModalProps> = ({
    open,
    onClose,
    name,
    isTemplate = false,
    isLoading,
    onCopy
}) => {

    const { t } = useTranslation();

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={isTemplate ? t('modals.copyPlan.title_template') : t('modals.copyPlan.title')}
            text={isTemplate ? t('modals.copyPlan.text_template', {name: name}) : t('modals.copyPlan.text', {name: name})}
            action1={{
                kind: 'primary',
                label: t('components.buttons.copy'),
                icon: <Copy />,
                loading: isLoading,
                onClick: onCopy
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px'}}}
            />
    )
}

export default CopyPlanModal;