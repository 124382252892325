import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

// Services and interfaces
import { useGetClientsQuery } from "@/repositories/client";
import { ITemplateAssignManyForm } from "@/interfaces/template/assign_form";

// Components
import { Box } from "@mui/material";
import Checkbox from "@/components/checkbox";
import MultiselectInput from "@/components/multiselect_input";


interface _AssignTemplateFormProps {
    showActive: boolean;
}

const AssignTemplateForm: FC<_AssignTemplateFormProps> = ({
    showActive
}) => {

    const { t } = useTranslation();
    const { control } = useFormContext<ITemplateAssignManyForm>();
    const { data, isLoading } = useGetClientsQuery(undefined);
    const list = data?.filter((client) => client.active);

    return (
        <Box display="flex" flexDirection="column" gap="12px">
            <MultiselectInput
                control={control}
                name="clients"
                label={t('components.select.titles.selectClient', {count: 2})}
                items={list ?? []}
                itemKey="id"
                itemLabel="full_name"
                itemValue="id"
                itemsLoading={isLoading}
            />
            {showActive && <Checkbox
                control={control}
                name="active"
                label={t('components.checkbox.setActive')}
                />}
        </Box>
    )
}

export default AssignTemplateForm;