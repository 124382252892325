import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Constants
import { 
    exerciseBodypartOptions, 
    exerciseCategoryOptions, 
    exerciseLibraryTypeOptions, 
    exerciseMachineBrandOptions,
    exerciseMachineTypeOptions, 
    exerciseTypeOptions 
} from "@/_constants/exercise";

// Store
import { selectHasTeam } from "@/store/team";

// Components
import { FilterItem } from "@/components/datatable/filter/filter_bar";


const useExerciseFilterItems = (): FilterItem[] => {

    const { t } = useTranslation();
    const hasTeam = useSelector(selectHasTeam);

    const filters: FilterItem[] = [
        {
            type: 'primary',
            label: t('components.dataTable.headers.type'),
            field: 'type',
            items: exerciseTypeOptions,
            itemKey: 'value'
        },
        {
            type: 'primary',
            label: t('components.dataTable.headers.primaryFocus'),
            field: 'bodypart',
            items: exerciseBodypartOptions,
            itemKey: 'value'
        },
        {
            type: 'secondary',
            label: t('components.dataTable.headers.category'),
            field: 'category',
            items: exerciseCategoryOptions,
            itemKey: 'value'
        },
        {
            type: 'secondary',
            label: t('components.dataTable.headers.machineType'),
            field: 'machine_type',
            items: exerciseMachineTypeOptions,
            itemKey: 'value'
        },
        {
            type: 'secondary',
            label: t('components.dataTable.headers.machineBrand'),
            field: 'machine_brand',
            items: exerciseMachineBrandOptions,
            itemKey: 'value'
        },
        {
            type: 'primary',
            label: t('components.dataTable.headers.library'),
            field: 'library',
            items: hasTeam ? exerciseLibraryTypeOptions : exerciseLibraryTypeOptions.filter(item => item.value !== 'team'),
            itemKey: 'value'
        }
    ];

    return filters;
}

export default useExerciseFilterItems;