import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Constants
import { filterColumns } from "@/_constants/food";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { IFood } from "@/interfaces/food/food";
import { useGetFoodsQuery } from "@/repositories/food";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import FoodModal from "./foods/food_modal";
import { Box, Container } from "@mui/material";
import DataTable from "@/components/datatable";
import useFoodFilterItems from "./foods/filter_items";
import useFoodTableHeaders from "./foods/table_headers";
import SecondaryToolbar from "@/components/secondary_toolbar";
import NoFoods from "@/components/nutrition_builder/no_foods";


const FoodListPage: FC = () => {

    const { t } = useTranslation();
    const headers = useFoodTableHeaders();
    const hssTeam = useSelector(selectHasTeam);
    const [open, setOpen] = useState<boolean>(false);
    const [food, selectFood] = useState<IFood|undefined>(undefined);
    const filters = useFoodFilterItems();
    const { data: list, isLoading } = useGetFoodsQuery();

    const handleCloseExercise = () => {
        setOpen(false);
        selectFood(undefined);
    }

    const handleOpenExercise = (exercise: IFood) => {
        selectFood(exercise);
        setOpen(true);
    }

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar 
                title={t('pages.library.foods.title')}
                action1={{
                    label: t('components.buttons.addFood'),
                    icon: <Add />,
                    onClick: () => setOpen(true)
                
                }}
                />
            {open && <FoodModal food={food} open={open} onClose={handleCloseExercise} />}
            
            <Box height='100%' overflow='auto'>
            <Container sx={{padding: '24px 0'}}>

                <DataTable
                    data={list}
                    dataKey="id"
                    columns={headers} 
                    hideHeader
                    localPagination
                    filterKeys={["name"]}
                    filter={hssTeam ? {
                        filterBar: {
                            filterItems: filters,
                            columns: filterColumns,
                            showVisibility: false
                        }
                    } : undefined}
                    isLoading={isLoading}
                    noDataMessage={<NoFoods message={t('components.dataTable.noData.noFoods')} />}
                    noDataFoundMessage={<NoFoods />}
                    rowClick={handleOpenExercise}
                    />

            </Container>
            </Box>
            
        </Box>
    )
}

export default FoodListPage