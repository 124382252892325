import dayjs from "dayjs";
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"

// Helpers
import showToast from "@/_lib/toast";
import { ApiErrorEnum } from "@/_constants/api_errors";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import IPaymentMethod from "@/interfaces/payment_methods/payment_method";
import { useSetDefaultPaymentMethodMutation } from "@/repositories/payment_methods";

// Styles
import { TrashCan } from "@carbon/icons-react"

// Components
import Button from "@/components/button";
import { Box, Card, Skeleton } from "@mui/material";
import DeletePaymentModal from "./delete_payment_modal";
import CardIcon, { CardKey } from "@/components/card_icon";


interface _PaymentMethodProps {
    paymentMethod: IPaymentMethod;
    dataLoading?: boolean;
}

const PaymentMethod: FC<_PaymentMethodProps> = ({ paymentMethod, dataLoading = false }) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const isExpired = dayjs().isAfter(dayjs(`${paymentMethod.exp_year}-${paymentMethod.exp_month}-31`), 'month');
    const hasError = paymentMethod.failure || isExpired;
    const [setDefaultPaymentMethod, { isLoading: isSetting }] = useSetDefaultPaymentMethodMutation();

    const translationKey: () => string = () => {
        if (`card_declined_${paymentMethod.failure?.failure_code}` in ApiErrorEnum) {
            return `api.errors.card_declined_${paymentMethod.failure?.failure_code}.message`;
        }
        return 'api.errors.card_declined.message';
    }

    const submitSetDefault = () => {
        setDefaultPaymentMethod(paymentMethod).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.billing.defaultChanged.title'), message: t('notifications.billing.defaultChanged.message')});
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const cardStyles = {
        padding: '16px 32px', 
        marginBottom: '12px',
        borderRadius: '6px',
        border: 'solid 1px var(--border-subtle-01)',
        borderColor: hasError ? 'var(--support-error)' : 'var(--border-subtle-01)',
    }

    return (
        <Card key={paymentMethod.id} elevation={0} sx={cardStyles}>

            <Box display="flex" alignItems="center">

                {dataLoading ?
                    <Skeleton variant="rounded" width={70} height={48} sx={{minWidth: '70px'}} /> : 
                    <CardIcon type={paymentMethod.brand as CardKey} size="large" />}

                {/* Spacer */}
                <Box width={32} />

                <Box display="flex" flexDirection="column" rowGap="8px" width="100%">
                    {dataLoading ? 
                        <Skeleton variant="text" width={140} height={18} /> : 
                        <span className="label-text-01">{t('billing.endsWith', {last4: paymentMethod.last_four})}</span>}
                    {dataLoading ?
                        <Skeleton variant="text" width={140} height={18} /> :
                        <span className="label-text-01">{t('billing.expirey', {expirey: `${paymentMethod.exp_month}/${paymentMethod.exp_year}`})}</span>}

                    {!paymentMethod.failure && isExpired &&
                        <span className="label-text-01" style={{color: 'var(--support-error)'}}>{t('billing.paymentMethodExpired')}</span>}

                    {paymentMethod.failure && !isExpired &&
                        <span className="label-text-01" style={{color: 'var(--support-error)'}}>{t(translationKey())}</span>}
                </Box>

                {/* Spacer */}
                <Box width={32} />

                {/* Set as default */}
                <Button
                    kind="ghost"
                    label={paymentMethod.default ? t('components.buttons.default') : t('components.buttons.setAsDefault')}
                    minWidth={false}
                    disabled={paymentMethod.default || isExpired}
                    loading={isSetting}
                    onClick={submitSetDefault}
                    sx={{minWidth: '125px', justifyContent: 'flex-end !important'}}
                    />

                {/* Delete */}
                <Button
                    kind="danger-ghost"
                    label={t('components.buttons.delete')}
                    endIcon={<TrashCan />}
                    minWidth={false}
                    disabled={paymentMethod.default || isSetting}
                    onClick={() => setOpen(true)}
                    />
                <DeletePaymentModal open={open} onClose={() => setOpen(false)} paymentMethod={paymentMethod} />

            </Box>
        </Card>
    )
}

export default PaymentMethod;