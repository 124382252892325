import { z } from "zod";
import ruleset from "@/_helpers/ruleset";
import { cardioSchema, IActivityBuilder, stepsSchema } from "./activity_builder";


export interface IActivityPlan extends IActivityBuilder {
    id: string;
    active: boolean;
    start: string;
    end: string;
    draft: boolean;
    readonly: boolean;
}

export const activityPlanSchema = (stepsEnabled: boolean, cardioEnabled: boolean) => z.object({
    name: ruleset.required,
    steps_notes: ruleset.stringOptional,
    cardio_notes: ruleset.stringOptional,
    steps: stepsSchema(stepsEnabled),
    cardio: cardioSchema(cardioEnabled),
    draft: ruleset.boolean
}).superRefine((val, ctx) => {
    if (!!val.steps && val.steps.length === 0) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['steps']
        })
    }

    if (!!val.cardio && val.cardio.length === 0) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['cardio']
        })
    }
});