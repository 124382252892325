import { Navigate } from "react-router-dom";

import ErrorPage from "@/pages/error";
import AuthLayout from "@/layouts/auth";
import LoginPage from "@/pages/auth/login";
import VerificationPage from "@/pages/auth/verification";
import ForgotPasswordPage from "@/pages/auth/forgot_password";

export const authRoutes = {
  path: "auth",
  element: <AuthLayout />,
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Navigate to="login" replace />,
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "forgot-password",
      element: <ForgotPasswordPage />
    },
    {
      path: "verify",
      element: <VerificationPage />,
    },
    {
      path: "signin",
      element: <Navigate to="/auth/login" replace />,
    }
  ],
}
