import baseApi from '@/repositories/base_api';

// Helpers
import { dateIsSameOrBeforeNow } from '@/_helpers/time_functions';

// Interfaces and services
import { IAPIResponse } from '@/interfaces/api/response';
import { IClientData } from '@/interfaces/client/client_data';
import { IClientActivity } from '@/interfaces/client/client_activity';
import { IClientNutrition } from '@/interfaces/client/client_nutrition';


export const clientDataApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getClientData: build.query<IClientData[], string>({
            query: (id) => ({ url: `/clients/${id}/data`, method: 'get' }),
            transformResponse: (response: IAPIResponse<IClientData[]>) =>
                response.data.filter((item) => dateIsSameOrBeforeNow(item.date)),
            providesTags: ['ClientData'],
            keepUnusedDataFor: 900
        }),
        getClientMeals: build.query<IClientNutrition[], string>({
            query: (id) => ({ url: `/clients/${id}/meals`, method: 'get' }),
            transformResponse: (response: IAPIResponse<IClientNutrition[]>) => 
                response.data.filter((item) => dateIsSameOrBeforeNow(item.date)),
            keepUnusedDataFor: 900
        }),
        getClientActivity: build.query<IClientActivity[], string>({
            query: (id) => ({ url: `/clients/${id}/activity-logs`, method: 'get' }),
            transformResponse: (response: IAPIResponse<IClientActivity[]>) => 
                response.data.filter((item) => dateIsSameOrBeforeNow(item.date)),
            keepUnusedDataFor: 900
        })
    }),
})

export const { 
    useGetClientDataQuery,
    useGetClientMealsQuery,
    useGetClientActivityQuery
} = clientDataApi;

