import baseApi from '@/repositories/base_api';

// Interfaces
import { phaseApi } from './phase';
import { IAPIResponse } from '@/interfaces/api/response';
import { IPhaseNote } from '@/interfaces/phase_note/phase_note';
import { IPhaseNoteForm } from '@/interfaces/phase_note/phase_note_form';


export const phaseNotesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    addPhaseNote: build.mutation<IPhaseNote, {client_id: string, phase_id: string, data: IPhaseNoteForm}>({
      query: ({client_id, phase_id, data}) => ({ url: `clients/${client_id}/phases/${phase_id}/notes`, method: 'post', data }),
      transformResponse: (response: IAPIResponse<IPhaseNote>) => response.data,
      async onQueryStarted({ client_id, phase_id }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) =>
        dispatch(phaseApi.util.updateQueryData('getPhase', { client_id, phase_id }, phase => {
          phase.notes?.push(response.data);
          phase.notes?.sort((a, b) => a.date.localeCompare(b.date));
        }))).catch(() => {});
      },
    }),
    updatePhaseNote: build.mutation<IPhaseNote, {client_id: string, phase_id: string, id: string, data: IPhaseNoteForm}>({
      query: ({client_id, phase_id, id, data}) => ({ url: `clients/${client_id}/phases/${phase_id}/notes/${id}`, method: 'patch', data }),
      transformResponse: (response: IAPIResponse<IPhaseNote>) => response.data,
      async onQueryStarted({ client_id, phase_id }, { dispatch, queryFulfilled }) {
        queryFulfilled.then((response) =>
        dispatch(phaseApi.util.updateQueryData('getPhase', { client_id, phase_id }, phase => {
          const index = phase.notes?.findIndex((item) => item.id === response.data.id);
          if (index == -1 || index == undefined) return;
          phase.notes?.splice(index, 1, response.data);
        }))).catch(() => {});
      },
    }),
    deletePhaseNote: build.mutation<IPhaseNote, {client_id: string, phase_id: string, id: string}>({
      query: ({client_id, phase_id, id}) => ({ url: `clients/${client_id}/phases/${phase_id}/notes/${id}`, method: 'delete' }),
      transformResponse: (response: IAPIResponse<IPhaseNote>) => response.data,
      async onQueryStarted({ client_id, phase_id, id }, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() =>
        dispatch(phaseApi.util.updateQueryData('getPhase', { client_id, phase_id }, phase => {
          phase.notes = phase.notes?.filter(note => note.id !== id);
        }))).catch(() => {});
      },
    }),
  }),
})

export const { 
  useAddPhaseNoteMutation,
  useUpdatePhaseNoteMutation,
  useDeletePhaseNoteMutation,
} = phaseNotesApi