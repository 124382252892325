import EditLayout from "@/layouts/edit";

// Client Pages
import EditProgrammePlanPage from "@/pages/client/training/edit_programme";
import EditNutritionPlanPage from "@/pages/client/nutrition/edit_nutrition";
import EditSupplementPlanPage from "@/pages/client/supplement_plans/edit_supplement";
import EditActivityPlanPage from "@/pages/client/activity/edit_activity_plan";

// Template Pages
import EditCheckinPage from "@/pages/templates/checkins/edit_checkin";
import EditProgrammePage from "@/pages/templates/programmes/edit_programme";
import EditNutritionPage from "@/pages/templates/nutrition/edit_nutrition";
import EditSupplementTemplatePage from "@/pages/templates/supplements/edit_supplement";
import EditActivityTemplatePage from "@/pages/templates/activity/edit_activity";

// Catalogue Pages
import EditWorkoutPage from "@/pages/catalogues/workouts/edit_workout";

// Other Pages
import EditLeadFormPage from "@/pages/prospects/form/edit_form";


export const editRoutes = {
  element: <EditLayout />,
  children: [
    {
      path: "prospects/lead-forms/:id",
      element: <EditLeadFormPage />
    },
    {
      path: "clients/:id",
      children: [
        {
          path: "programmes/:planId",
          element: <EditProgrammePlanPage />
        },
        {
          path: "nutrition/:planId",
          element: <EditNutritionPlanPage />
        },
        {
          path: "supplements/:planId",
          element: <EditSupplementPlanPage />
        },
        {
          path: "activity/:planId",
          element: <EditActivityPlanPage />
        }
      ]
    },
    {
      path: "templates",
      children: [
        {
          path: "checkins/:id",
          element: <EditCheckinPage />
        },
        {
          path: "programmes/:id",
          element: <EditProgrammePage />
        },
        {
          path: "nutrition/:id",
          element: <EditNutritionPage />
        },
        {
          path: "supplements/:id",
          element: <EditSupplementTemplatePage />
        },
        {
          path: "activity/:id",
          element: <EditActivityTemplatePage />
        },
      ]
    },
    {
      path: "catalogues",
      children: [
          {
            path: "workouts/:id",
            element: <EditWorkoutPage />
          }
      ]
    }
  ]
};