import { useTranslation } from "react-i18next";

// Helpers
import { capitalize } from "@/_helpers/text_functions";

// Interfaces
import { ILeadForm } from "@/interfaces/lead_form/lead_form";

// Components
import FormMenu from "./form_menu";
import { Box } from "@mui/material";
import { ColDef } from "@/components/datatable";
import TogglePublished from "./toggle_published";


const useLeadFormTableHeaders = (): ColDef<ILeadForm>[] => {

    const { t } = useTranslation();

    const headers: ColDef<ILeadForm>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '30%',
            sortable: true,
            render: (row) => capitalize(row.name)
        },
        {
            field: 'created_at',
            headerName: t('components.dataTable.headers.dateAdded'),
            width: '15%',
            render: (row: ILeadForm) => new Date(row.created_at).toLocaleDateString()
        },
        {
            field: 'total_responses',
            headerName: t('components.dataTable.headers.totalResponses'),
            width: '15%',
        },
        {
            field: 'published',
            headerName: t('components.dataTable.headers.status'),
            width: '20%',
            sortable: true,
            render: (row: ILeadForm) => row.published ? t('general.published') : t('general.unpublished')
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (row: ILeadForm) => <Box display="flex" columnGap={1}>
                <TogglePublished row={row} />
                <FormMenu row={row} />
            </Box>
        }
    ];

    return headers;
}

export default useLeadFormTableHeaders;