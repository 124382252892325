import { FC } from "react";
import { useTranslation } from "react-i18next";

// Components
import { Box } from "@mui/material";


interface _ComingSoonOverlayProps {
    isBlurred?: boolean;
    hasBackground?: boolean;
}

const ComingSoonOverlay: FC<_ComingSoonOverlayProps> = ({
    isBlurred = true,
    hasBackground = true
}) => {

    const { t } = useTranslation();

    return (
      <Box
        sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backdropFilter: isBlurred ? 'blur(5px)' : 'unset',
          backgroundColor: hasBackground ? 'var(--layer-01)' : 'transparent',
          opacity: 1,
          zIndex: 10,
        }}>
            <span className="heading-07-compact" style={{ fontWeight: 'bold'}}>{t('components.comingSoonOverlay.title')}</span>
      </Box>
    );
};

export default ComingSoonOverlay;