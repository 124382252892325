import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Helpers
import { capitalizeWords } from "@/_helpers/text_functions";

// Interfaces
import { selectCurrency } from "@/store/settings";
import { IProspect } from "@/interfaces/prospect/prospect";

// Components
import { Box } from "@mui/material";
import Avatar from "@/components/avatar";
import ProspectMenu from "./prospect_menu";
import { ColDef } from "@/components/datatable";


const useProspectTableHeaders = (): ColDef<IProspect>[] => {

    const { t } = useTranslation();
    const currency = useSelector(selectCurrency);

    const headers: ColDef<IProspect>[] = [
        {
            field: 'full_name',
            headerName: t('components.dataTable.headers.name'),
            width: '30%',
            sortable: true,
            render: (row, i) => <Box display="flex" alignItems="center">
                <Avatar index={i} name={`${row.first_name} ${row.last_name}`} src={''} />
                <Box ml="12px">
                    {capitalizeWords(row.full_name)}
                </Box>
            </Box>
        },
        {
            field: 'created_at',
            headerName: t('components.dataTable.headers.dateAdded'),
            width: '10%',
            // sortable: true,
            render: (row: IProspect) => new Date(row.created_at).toLocaleDateString()
        },
        {
            field: 'value',
            headerName: t('components.dataTable.headers.dealValue'),
            width: '10%',
            // sortable: true,
            render: (row: IProspect) => row.value ? t(`${currency?.value}${row.value}`) : '-'
        },
        {
            field: 'stage',
            headerName: t('components.dataTable.headers.stage'),
            width: '15%',
            sortable: true,
            render: (row: IProspect) => row.stage ? t(`enums.prospectStage.${row.stage}`) : '-'
        },
        {
            field: 'close_percentage',
            headerName: t('components.dataTable.headers.closePercentage'),
            width: '10%',
            // sortable: true,
            render: (row: IProspect) => row.close_percentage ? `${row.close_percentage}%` : '-'
        },
        {
            field: 'days_in_stage',
            headerName: t('components.dataTable.headers.daysInStage'),
            width: '10%',
            // sortable: true,
            render: (row: IProspect) => row.days_in_stage ?? '-'
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (row: IProspect) => <ProspectMenu row={row} />
        }
    ];

    return headers;
}

export default useProspectTableHeaders;