import { t } from "i18next";
import { NavItem } from "@/interfaces/components/nav_item";


export const SettingsMenuItems: Array<NavItem> = [
    {
        "label": t("components.settingsMenu.profile"), 
        "path": "/settings/profile"
    },
    {
        "label": t("components.settingsMenu.privacy"), 
        "path": "/settings/privacy"
    },
    {
        "label": t("components.settingsMenu.billing"), 
        "path": "/settings/billing"
    },
    {
        "label": t("components.settingsMenu.settings"), 
        "path": "/settings/metrics"
    },
    {
        "label": t("components.settingsMenu.teams"), 
        "path": "/settings/teams"
    },
    // {
    //     "label": t("components.settingsMenu.notifications"), 
    //     "path": "/settings/notifications"
    // },
    {
        "label": t("components.settingsMenu.referrals"), 
        "path": "/settings/referrals"
    },
]