import { FC } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import { DndContext, closestCenter, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';


// Interfaces
import { IFormBuilder, IFormQuestion, IFormSection } from "@/interfaces/form_builder/form_builder";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import Button from "@/components/button";
import FormQuestion from "./form_question";
import { Box, Skeleton } from "@mui/material";
import useSort from "@/components/sortable/sort_hooks";
import SortableItem from "@/components/sortable/sortable_item";


interface _FormSectionProps {
    section: IFormSection;
    sectionIndex: number;
    isLoading?: boolean;
}

const FormSection: FC<_FormSectionProps> = ({
    section,
    sectionIndex,
    isLoading = false
}) => {

    const { t } = useTranslation();
    const { control } = useFormContext<IFormBuilder>();
    const { sensors, restrictToVerticalAxis } = useSort();

    const { fields, append, remove, move } = useFieldArray({
        control,
        name: `form.${sectionIndex}.questions`
    });
    
    const newQuestion: IFormQuestion = {
        uuid: uuidv4(),
        title: '',
        type: 'text',
        required: false,
        options: {
            multiple: false,
            items: []
        }
    }
    
    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (over !== null && active.id !== over.id) {
            const oldIndex = fields.findIndex(item => item.id === active.id);
            const newIndex = fields.findIndex(item => item.id === over.id);

            move(oldIndex, newIndex);
        }
    };
    
    return (
        <Box className="Builder__content">
                
            <Box className="Builder__content-header">

                {isLoading ? <Skeleton variant="rounded" width={200} height={22} />:
                <span className="heading-06-compact">{section.title ? section.title : t('components.formBuilder.menu.sectionPlaceholder')}</span>}
                <Button 
                    kind="ghost" 
                    size="small" 
                    label={t('components.buttons.addQuestion')}
                    endIcon={<Add />}
                    minWidth={false}
                    disabled={isLoading || section.required}
                    onClick={() => append(newQuestion)}
                    />
            </Box>

            <Box width="100%" height="calc(100% - 40px)" sx={{overflowY: 'auto', scrollbarWidth: 'none'}}>

                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
                    <SortableContext items={fields.map(field => field.id)} strategy={verticalListSortingStrategy}>

                        
                        <Box className="Builder__content-section">
                            {fields.map((question, index) => (
                                <SortableItem key={question.id} id={question.id}>
                                    <FormQuestion
                                        key={question.id}
                                        id={question.id}
                                        sectionIndex={sectionIndex}
                                        questionIndex={index}
                                        remove={remove}
                                        isLoading={isLoading}
                                    />
                                </SortableItem>
                            ))}
                        </Box>


                    </SortableContext>
                </DndContext>

            </Box>

        </Box>
    )
}

export default FormSection;