import { useTranslation } from "react-i18next";

// Helpers
import { capitalize, capitalizeWords } from "@/_helpers/text_functions";

// Interfaces
import { IProgrammeWorkout } from "@/interfaces/programme_workout/programme_workout";

// Components
import { ColDef } from "@/components/datatable";
import useFilterActions from "@/components/datatable/use_filter_actions";


const useWorkoutTableHeaders = (): ColDef<IProgrammeWorkout>[] => {

    const { t } = useTranslation();
    const { hiddenColumns } = useFilterActions();

    const headers: ColDef<IProgrammeWorkout>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '25%',
            sortable: true,
            render: (row) => capitalizeWords(row.name)
        },
        ...(!hiddenColumns.includes('created_at') ? [{
            field: 'created_at',
            headerName: t('components.dataTable.headers.dateAdded'),
            width: '10%',
            sortable: true,
            render: (row: IProgrammeWorkout) => new Date(row.created_at).toLocaleDateString()
        }] : []),
        ...(!hiddenColumns.includes('total_exercises') ? [{
            field: 'total_exercises',
            headerName: t('components.dataTable.headers.totalExercises'),
            width: '10%',
            sortable: true,
            // render: (row: IProgrammeWorkout) => <span>{t(`enums.exerciseBodypart.${row.bodypart}`)}</span>
        }] : []),
        ...(!hiddenColumns.includes('draft') ? [{
            field: 'draft',
            headerName: t('components.dataTable.headers.status'),
            width: '10%',
            // sortable: true,
            render: (row: IProgrammeWorkout) => row.draft ? t('general.draft') : t('general.ready')
        }] : []),
        ...(!hiddenColumns.includes('catalogue') ? [{
            field: 'library',
            headerName: t('components.dataTable.headers.catalogue'),
            width: '10%',
            sortable: true,
            render: (row: IProgrammeWorkout) => <span>{capitalize(row.catalogue)}</span>
        }] : [])
    ];

    return headers;
}

export default useWorkoutTableHeaders;