import { Box } from "@mui/material";
import { ArrowRight } from "@carbon/icons-react";
import { Link as LinkItem } from "react-router-dom";
import { ReactNode } from "react";


interface LinkProps {
    size?: 'small' | 'medium' | 'large';
    label: string;
    // Optional icon
    icon?: ReactNode;
    // Takes an optional to string to navigate to
    to?: string;
    newWindow?: boolean;
    // Alternatively, takes an onClick function
    onClick?: () => void;
}

const Link = ({
    size = 'medium', // Default size is 'medium'
    label,
    icon,
    to = '',
    newWindow,
    onClick
}: LinkProps) => {

    const classes = {
        'Link': true,
        'Link-small': size === 'small',
        'Link-medium': size === 'medium',
        'Link-large': size === 'large',
        'Link--disabled': !to && !onClick
    }

    const validClasses = Object.entries(classes)
        .filter(([,v]) => !!v)
        .map(([k,]) => k)
        .join(' ')

    const iconSize = () => {
        switch (size) {
            case 'small':
                return "Link-small--icon";
            case 'medium':
                return "Link-medium--icon";
            case 'large':
                return "Link-large--icon";
            default:
                return "Link-small--icon";
        }
    }

    return (
        <LinkItem 
            className={validClasses} 
            to={to} 
            target={newWindow && to !== '' ? '_blank' : undefined}
            style={{display: 'flex', alignItems: 'center'}}
            onClick={to === '' ? onClick : undefined}
            >
                <span>{label}</span>
                <Box display="flex" marginTop="2px" marginLeft="8px">
                    {icon ?? <ArrowRight className={iconSize()} />}
                </Box>
        </LinkItem>
    )

}

export default Link;