import baseApi from '@/repositories/base_api';

// Interfaces
import IGallery from '@/interfaces/gallery/gallery';
import { IAPIResponse } from '@/interfaces/api/response';
import { IGalleryList } from '@/interfaces/gallery/gallery_list';
import { IGalleryOverview } from '@/interfaces/gallery/gallery_overview';
import IPagination, { toQueryString } from '@/interfaces/api/pagination';

export const galleryApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getGalleries: build.query<IGalleryList, {id: string, pagination: IPagination}>({
      query: ({id, pagination}) => ({ url: `clients/${id}/galleries${toQueryString(pagination)}` }),
      onQueryStarted: async (req, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(async (response) => {
          for (const gallery of response.data.data) {
            await dispatch(galleryApi.util.upsertQueryData('getGallery', {
              clientId: req.id, 
              id: gallery.id.toString() 
            }, gallery)).catch(() => {});
          }
        })
    },
    }),
    getGallery: build.query<IGallery, {clientId: string, id: string}>({
      query: ({clientId, id}) => ({ url: `clients/${clientId}/galleries/${id}` }),
      transformResponse: (res: IAPIResponse<IGallery>) => res.data
    }),
    getGalleryDates: build.query<IGalleryOverview[], string>({
      query: (id) => ({ url: `clients/${id}/galleries/dates` }),
      transformResponse: (res: IAPIResponse<IGalleryOverview[]>) => res.data
    }),
  }),
})

export const { 
  useGetGalleriesQuery,
  useLazyGetGalleriesQuery,
  useGetGalleryQuery,
  useGetGalleryDatesQuery
} = galleryApi;
