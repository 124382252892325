import { FC } from "react";
import { useTranslation } from "react-i18next";

// Compoments
import DataTable from "@/components/datatable";
import useInvoiceTableHeaders from "./table_headers";
import { useGetInvoicesQuery } from "@/repositories/invoice";


const BillingTable: FC = () => {

    const { t } = useTranslation();
    const { data: list, isLoading } = useGetInvoicesQuery(undefined);
    const headers = useInvoiceTableHeaders();

    return (
        <>
            <DataTable 
                data={list ?? []} 
                dataKey="id"
                noDataMessage={t('components.dataTable.noData.payments')}
                columns={headers} 
                header={{title: t('pages.settings.billing.paymentHistory')}}
                hideFilter
                localPagination
                paginationData={{limit: 10, page: 1}}
                isLoading={isLoading}
                />

        </>
    )
}

export default BillingTable;