import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast"

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectIsOwner, selectTeam } from "@/store/team";
import { useUpdateTeamMutation } from "@/repositories/team";
import { ITeamForm, teamSchema } from "@/interfaces/team/team_form";

// Styles
import { ArrowRight, Edit } from "@carbon/icons-react";

// Components
import TeamForm from "./team_form";
import { Box, Card } from "@mui/material";
import Modal from "@/components/modal/modal";
import IconButton from "@/components/icon_button";


const TeamHeader: FC = () => {

    const { t } = useTranslation();
    const team = useSelector(selectTeam);
    const isOwner = useSelector(selectIsOwner);
    const [open, setOpen] = useState(false);
    const [updateTeam, { isLoading }] = useUpdateTeamMutation();
    const total_clients = team.members?.reduce((acc, member) => acc + member.total_clients, 0);

    const formMethods = useForm<ITeamForm>({
        resolver: zodResolver(teamSchema),
        mode: 'onBlur',
        defaultValues: {
            team_name: team.name
        }
    });

    const handleClose = () => {
        setOpen(false);
        formMethods.reset();
    }

    const submit = (data: ITeamForm) => {
        updateTeam(data).unwrap().then(() => {
            setOpen(false);
            formMethods.reset(data);
            showToast({type: 'success', title: t('notifications.team.updated.title')});
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        });
    }

    return (
        <Card elevation={0} sx={{padding: '24px 32px', border: 'solid 1px var(--border-subtle-01)', borderRadius: '6px'}}>

            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" columnGap="8px">

                {/* Team Name */}
                <Box display="flex" alignItems="center">

                    <IconButton 
                        kind="tertiary"
                        size="small"
                        icon={<Edit />}
                        onClick={() => setOpen(true)}
                        disabled={!isOwner}
                        />
                    <Modal
                        open={open}
                        onClose={formMethods.formState.isDirty ? undefined : handleClose}
                        title={t('modals.createTeam.title')}
                        text={t('modals.createTeam.text')}
                        children={
                            <FormProvider {...formMethods}>
                                <TeamForm />
                            </FormProvider>}
                        action1={{
                            label: t('components.buttons.save'),
                            icon: <ArrowRight />,
                            onClick: formMethods.handleSubmit(submit),
                            loading: isLoading,
                            disabled: !formMethods.formState.isValid || !formMethods.formState.isDirty
                        }}

                        cancel={{
                            label: t('components.buttons.cancel'),
                            onClick: handleClose,
                            disabled: isLoading
                        }}
                        />

                    {/* Spacer */}
                    <Box width="16px" />

                    <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <span className="label-01">{t('pages.settings.team.teamName')}</span>
                        <span className="heading-07 TwoLine--ellipsis" style={{textAlign: 'left'}}>{team.name}</span>
                    </Box>

                </Box>

                {/* Team members */}
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <span className="label-01">{t('pages.settings.team.teamMembers')}</span>
                    <span className="heading-07">{team.members?.length ?? 0}</span>
                </Box>

                {/* Clients */}
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <span className="label-01">{t('pages.settings.team.clients')}</span>
                    {/* //TODO tie in client count */}
                    <span className="heading-07">{total_clients}</span>
                </Box>
            </Box>
        </Card>
    )
}

export default TeamHeader