import { FC } from "react";
import { useTranslation } from "react-i18next";

// Components
import Modal from "@/components/modal/modal";


interface _DeleteSectionModalProps {
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
}

const DeleteSectionModal: FC<_DeleteSectionModalProps> = ({
    open,
    onClose,
    onDelete
}) => {

    const { t } = useTranslation();

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.deleteSection.title')}
            text={t('modals.deleteSection.text')}
            action1={{
                kind: 'danger',
                label: t('components.buttons.delete'),
                onClick: onDelete
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: onClose
            }}
            />
    )
}

export default DeleteSectionModal;