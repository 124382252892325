import { FC } from "react";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { useForm } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useShareProgrammeTemplateMutation } from "@/repositories/programme_template";
import { IProgrammeTemplate } from "@/interfaces/programme_template/programme_template";

// Components
import Toggle from "@/components/toggle";


interface ITemplateShareForm {
    shared: boolean;
}

interface TableToggleProps {
    row: IProgrammeTemplate;
}

const TableToggle: FC<TableToggleProps> = ({
    row
}) => {

    const [shareTemplate, { isLoading }] = useShareProgrammeTemplateMutation();
    const { control } = useForm<ITemplateShareForm>({
        mode: 'onBlur',
        defaultValues: {
            shared: row.shared ?? false
        }
    });

    const submit = () => {
        shareTemplate(row.id).unwrap().then((template) => {
            showToast({
                type: 'success', 
                title: t('notifications.template.shared.title', {context: `${template.shared}`}),
                message: <Trans 
                    i18nKey="notifications.template.shared.message"
                    components={{ strong: <strong /> }}
                    values={{context: `${template.shared}`, name: row.name}} />
            })
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Toggle 
            name="shared"
            control={control}
            sx={{marginRight: '0'}}
            onChange={submit}
            disabled={isLoading || row.draft}
            />
    )
}

export default TableToggle;