import dayjs from "dayjs";
import { FC, useState } from 'react';
import { useTranslation } from "react-i18next";

// Services and interfaces
import IPhase from "@/interfaces/phase/phase";

// Components
import Box from "@mui/material/Box";
import PhaseMenu from "./phase_menu";
import Button from "@/components/button";
import ActivePhaseModal from "./phase/active_modal";


interface _OverviewProps {
    phase: IPhase;
}

const PhaseOverview: FC<_OverviewProps> = ({ phase }) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false)

    const startsTomorrow = dayjs(dayjs.utc(phase.start).format('YYYY-MM-DDTHH:mm:ss')).startOf('day').isSame(dayjs.utc().add(1, 'day'), 'day');
    const dayDiff = dayjs().startOf('day').diff(dayjs(phase.start), 'days', true);

    return (
        <Box className='MuiAccordionSummary-internal _Expandable' alignItems='center' display='flex' width='100%'>

            {/* titles */}
            <Box className="_Expandable" textAlign='left'>
                {/* titles */}
                <Box className='heading-07-compact _Expandable'>
                    {phase.name}
                    {!!phase.start && (
                        <Box component='span' className='label-text-02 _Expandable' 
                            alignSelf='start' 
                            color={`var(--tag-${!!phase.end ? 'grey' : 'teal'}-color)`}
                            bgcolor={`var(--tag-${!!phase.end ? 'grey' : 'teal'}-background)`}
                            borderRadius='9px' 
                            py={0.25} px={1} mx={1}
                            > 
                            {!phase.end ? t('components.phases.active') : t('components.phases.completed')}
                        </Box>
                    )}
                </Box>

                {/* dates */}
                <Box style={{marginTop: 4}} className="body-02-compact text-secondary _Expandable">
                    {dayjs.utc(phase.start ?? phase.expected_start).format("DD MMMM YYYY")} 
                    <span className="_Expandable" style={{padding: '0 4px'}}>&#8594;</span>  
                    {dayjs.utc(phase.end ?? phase.expected_end).format("DD MMMM YYYY")}
                    {!phase.end && (
                        <span className="_Expandable" style={{ color: 'var(--text-placeholder)', padding: '0 4px'}}>
                            ({t('components.phases.projected')})
                        </span>  
                    )}
                </Box>
            </Box>
            
            <Box flexGrow={1} />

            {/* Duration */}
            {!startsTomorrow && !!phase.start && !phase.end && (
                <>
                    <span className="body-02-compact _Expandable" style={{marginRight: '0.5em'}}>
                        {t('timeDate.week', {count: Math.floor(Math.ceil(dayDiff)/7)})}
                    </span>
                    <span className="body-02-compact _Expandable" style={{marginRight: '0.2em'}}>
                        {t('timeDate.day', {count: Math.ceil(dayDiff)%7})} /
                    </span>
                </>
            )} 
            {startsTomorrow && <span className="body-02-compact _Expandable" style={{marginRight: '0.5em'}}>{`(${t('components.phases.startsTomorrow')})`}</span>}

            <span className="body-02-compact _Expandable" style={{color: 'var(--text-secondary)', marginRight: '16px'}}>{t('timeDate.week', {count: phase.duration})}</span>

            {!phase.start && (
                <Button
                    label={t('components.phases.setActive')}
                    kind="ghost"
                    size="small"
                    sx={{minWidth: 'unset'}}
                    disabled={phase.readonly}
                    onClick={() => setOpen(true)}
                />
            )}
            {open && <ActivePhaseModal 
                open={open} 
                onClose={() => setOpen(false)} 
                phase={phase} 
                />}

            <PhaseMenu phase={phase} />
        </Box>
    );
}

export default PhaseOverview;