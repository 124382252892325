import { FC, useEffect } from 'react';
import { useAppDispatch } from '@/hooks';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { zodResolver } from "@hookform/resolvers/zod";

// Helpers
import { capitalize } from '@/_helpers/text_functions.tsx';

// Services and interfaces
import { selectRegistrationForm, submitPassword } from '@/store/registration';
import { IRegistrationForm, passwordSchema } from '@/interfaces/registration/registration_form';

// Styles
import { East } from '@mui/icons-material';
import styles from '@/layouts/auth.module.scss';

// Components
import { Box } from '@mui/material';
import Button from '@/components/button';
import PasswordInput from '@/components/password_input';


const RegistrationPasswordPage: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const form = useSelector(selectRegistrationForm);

    useEffect(() => {
        const requiredFields = ['first_name', 'last_name', 'email', 'phone_number', 'access_code'];
        if (!requiredFields.every(field => form[field])) {
            navigate('/register/details')
        }
    }, [form, navigate]);

    const { control, handleSubmit, formState } = useForm<IRegistrationForm>({
        resolver: zodResolver(passwordSchema),
        mode: 'onBlur',
        defaultValues: {
            password: form.password ?? '',
            password_confirmation: form.password_confirmation ?? ''
        }
    });

    const submitForm = async (data: IRegistrationForm) => {
        dispatch(submitPassword(data))
        navigate('/register/business')
    }

    return (
        <Box className={styles.form} sx={{textAlign: 'left'}}>

            {/* Title, subtitle and 40px spacer */}
            <Box display="flex" flexDirection="column" maxWidth="100%">
                <span className="heading-05" style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{t('pages.registration.password.title', {firstName: capitalize(form.first_name)})}</span>
                <span className="body-01" style={{color: 'var(--text-secondary)'}}>{t('pages.registration.password.subtitle')}</span>
            </Box>

            <Box height={40} />

            {/* Form */}
            <form onSubmit={handleSubmit(submitForm)} style={{width: '100%'}}>
                <PasswordInput
                    name='password'
                    control={control}
                    label={t('inputs.titles.password')} 
                    tabIndex={1}
                    placeHolder={t('inputs.placeholders.passwordNew')}
                    showRequirements
                    />
                <PasswordInput
                    name='password_confirmation'
                    control={control}
                    label={t('inputs.titles.passwordConfirmation')}
                    placeHolder={t('inputs.placeholders.passwordNew')}
                    tabIndex={2}
                    />

                {/* Spacer */}
                <Box height={40} />

                {/* Actions */}
                <Box className={styles.actions}>
                    <Box className={styles.actions__registration}>
                        <Link className="body-02" to="/register/details">{t('components.buttons.back')}</Link>
                    </Box>
                    <Button
                        type="submit"
                        label={t('components.buttons.next')}
                        endIcon={<East />}
                        disabled={!formState.isValid || formState.isSubmitting}
                        onClick={handleSubmit(submitForm)}
                        sx={{'@media (max-width: 768px)': {width: '100%'}}}
                        />
                </Box>
            </form>
                
        </Box>
    )
}

export default RegistrationPasswordPage;

