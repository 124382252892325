import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Interfaces
import { selectHasTeam } from "@/store/team";
import { IActivityTemplate } from "@/interfaces/activity_template/activity_template";

// Components
import TableToggle from "./toggle_shared";
import TemplateMenu from "./activity_menu";
import { ColDef } from "@/components/datatable";


const useActivityTemplateTableHeaders = (): ColDef<IActivityTemplate>[] => {

    const { t } = useTranslation();
    const hasTeam = useSelector(selectHasTeam);

    const headers: ColDef<IActivityTemplate>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '25%',
            sortable: true
        },
        {
            field: 'created_at',
            headerName: t('components.dataTable.headers.dateAdded'),
            width: '10%',
            render: (row: IActivityTemplate) => new Date(row.created_at).toLocaleDateString()
        },
        // {
        //     field: 'focus',
        //     headerName: t('components.dataTable.headers.focus'),
        //     width: '25%',
        //     render: (row: IActivityTemplate) => row.focus ?? '-'
        // },
        // {
        //     field: 'tags',
        //     headerName: t('components.dataTable.headers.tag'),
        //     width: '10%',
        //     //TODO
        //     // render: (row: IActivityTemplate) => row.tags?.map((tag) => tag).join(', ')
        // },
        {
            field: 'draft',
            headerName: t('components.dataTable.headers.status'),
            width: '10%',
            render: (row: IActivityTemplate) => row.draft ? t('general.draft') : t('general.ready')
        },
        {
            field: 'shared',
            headerName: t('components.dataTable.headers.shared'),
            width: '10%',
            render: (row: IActivityTemplate) => <TableToggle row={row} />
        },
        {
            field: 'actions',
            headerName: '',
            width: '10%',
            render: (row: IActivityTemplate) => <TemplateMenu row={row} />
        }
    ];

    if (!hasTeam) {
        return headers.filter((h) => h.field !== 'shared');
    }

    return headers;
}

export default useActivityTemplateTableHeaders;