import { FC } from "react";
import { useTranslation } from "react-i18next";

// Styles
import { Copy } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";


interface CopyFormModalProps {
    open: boolean;
    onClose: () => void;
    name: string;
    isLoading: boolean;
    onCopy: () => void;
}

const CopyFormModal: FC<CopyFormModalProps> = ({
    open,
    onClose,
    name,
    isLoading,
    onCopy
}) => {

    const { t } = useTranslation();

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.copyForm.title')}
            text={t('modals.copyForm.text', {name: name})}
            action1={{
                kind: 'primary',
                label: t('components.buttons.copy'),
                icon: <Copy />,
                loading: isLoading,
                onClick: onCopy
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px'}}}
            />
    )
}

export default CopyFormModal;