import { FC, useEffect } from 'react';
import { useAppDispatch } from '@/hooks';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { zodResolver } from "@hookform/resolvers/zod";
import { Trans, useTranslation } from 'react-i18next';

// Helpers
import showToast from '@/_lib/toast';

// Services and interfaces
import { ApiError } from '@/interfaces/api/error';
import { useRegisterMutation } from '@/repositories/registration';
import { IRegistrationForm, businessSchema } from '@/interfaces/registration/registration_form';
import { selectIsRegistered, selectRegistrationForm, submitBusiness } from '@/store/registration';

// Styles
import { East } from '@mui/icons-material';
import styles from '@/layouts/auth.module.scss';

// Components
import { Box } from '@mui/material';
import Button from '@/components/button';
import Checkbox from '@/components/checkbox';
import TextInput from '@/components/text_input';


const RegistrationBusinessPage: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const form = useSelector(selectRegistrationForm);
    const registered = useSelector(selectIsRegistered);
    const [register, { isLoading }] = useRegisterMutation();

    useEffect(() => {
        const requiredFields = ['first_name', 'last_name', 'email', 'phone_number', 'access_code', 'password', 'password_confirmation'];
        if (!requiredFields.every(field => form[field]) && !registered) {
            navigate('/register/details')
        }
    }, [form, registered, navigate]);

    const { control, handleSubmit, formState } = useForm<IRegistrationForm>({
        resolver: zodResolver(businessSchema),
        mode: 'onBlur',
        defaultValues: form
    });

    const submitForm = async (data: IRegistrationForm) => {
        dispatch(submitBusiness(data));
        const payload = {...form, ...data};
        await register(payload).unwrap().then((res) => {
            navigate('/register/success', { state: { id: res.user_id }})
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Box className={styles.form}>

            {/* Title, subtitle and 40px spacer */}
            <span className="heading-05">{t('pages.registration.business.title')}</span>
            <span className="body-01" style={{ color: 'var(--text-secondary)', textAlign: 'left' }}>{t('pages.registration.business.subtitle')}</span>
            <Box height={40} />

            <form onSubmit={handleSubmit(submitForm)} style={{width: '100%'}}>

                {/* Business name */}
                <TextInput
                    name='business_name'
                    control={control}
                    label={t('inputs.titles.businessName')} 
                    placeHolder={t('inputs.placeholders.businessName')}
                    tabIndex={1}
                    maxLength={100}
                    />

                <Box height={24} />

                {/* Terms checkbox */}
                <Checkbox
                    name='terms_accepted'
                    control={control}
                    label={
                        <Trans i18nKey="components.checkbox.termsAgreement">
                            I agree to the <Link to="https://1fit.com/terms" target="_blank">Terms and Conditions</Link> of 1FITUK Limited.
                        </Trans>
                    }
                    labelPlacement='end'
                    tabIndex={2}
                    />

                <Box height={48} />

                {/* Actions */}
                <Box className={styles.actions}>
                    <Box className={styles.actions__registration}>
                        <Link className="body-02" to={isLoading ? '#' : "/register/password"}>{t('components.buttons.back')}</Link>
                    </Box>
                    <Button
                        type="submit"
                        label={t('components.buttons.createAccount')}
                        endIcon={<East />}
                        loading={isLoading}
                        disabled={!formState.isValid || formState.isSubmitting}
                        onClick={handleSubmit(submitForm)}
                        sx={{'@media (max-width: 768px)': {width: '100%'}}}
                        />
                </Box>
            </form>
                
        </Box>
    )
}

export default RegistrationBusinessPage;

