import dayjs from "dayjs";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

// Helpers
import { capitalizeWords } from "@/_helpers/text_functions";
import { formatDurationText } from "@/_helpers/time_functions";

// Interfaces
import IWorkout from "@/interfaces/workout/workout";

// Components
import Tag from "@/components/tag";
import { ColDef } from "@/components/datatable";


const useTrainingDataHeaders = (): ColDef<IWorkout>[] => {

    const { t } = useTranslation();

    const headers: ColDef<IWorkout>[] = [
        {
            field: 'date',
            headerName: t('components.dataTable.headers.date'),
            width: '10%',
            render: (row) => dayjs(row.started).format('DD/MM/YYYY')
        },
        {
            field: 'name',
            headerName: t('components.dataTable.headers.workout'),
            width: '20%',
            render: (row) => capitalizeWords(row.name)
        },
        {
            field: 'duration',
            headerName: t('components.dataTable.headers.duration'),
            width: '15%',
            render: (row) => formatDurationText(row.duration)
        },
        {
            field: 'performance',
            headerName: t('components.dataTable.headers.performance'),
            width: '15%',
            render: (row) => `${row.rating} / 5`
        },
        {
            field: 'compliance',
            headerName: t('components.dataTable.headers.compliance'),
            width: '15%',
            render: (row) => <ComplianceTag value={row.rating} />
        },
    ];

    return headers;
}

export default useTrainingDataHeaders;

interface ComplianceTagProps {
    value: number;
}

const ComplianceTag: FC<ComplianceTagProps> = ({ value }) => {

    const { t } = useTranslation();

    const choice: [label: string, colour: 'green'|'orange'|'red'] = useMemo(() => {
        switch (value) {
            case 1:
            case 2:
                return [t('components.training.poorPerformance'), 'red']
            case 3:
                return [t('components.training.mediumPerformance'), 'orange']
            default:
                return [t('components.training.goodPerformance'), 'green']
        }
    }, [value])

    return (
        <Tag 
            size='small'
            label={choice[0]}
            colour={choice[1]}
        />
    )
}