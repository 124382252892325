import { StrictMode, useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, RouterProvider, useLocation, useNavigationType } from 'react-router-dom';

// Styles
import '@/i18n';
import "@/App.scss";
import '@/index.css'
import theme from './theme'
import { CircularProgress } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles/';

// Store and router
import store from "@/store/store.tsx";
import router from '@/_routes/index.tsx';

// Toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppWrapper from './components/app_wrapper';

// dayjs
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import relativeTime from "dayjs/plugin/relativeTime";
import isBetween from "dayjs/plugin/isBetween";
import isToday from "dayjs/plugin/isToday";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(localizedFormat)
dayjs.extend(isSameOrBefore)

// sentry


Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ],
  // Tracing
  tracesSampleRate: 1.0, 
  tracePropagationTargets: ["localhost", /^\/api/],
  replaysSessionSampleRate: 0.1, 
  replaysOnErrorSampleRate: 1.0,
});

const muiTheme = createTheme(theme)

ReactDOM.createRoot(document.getElementById('root')!).render(
  //NOTE Strict mode will cause useEffect to run twice in dev mode
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={muiTheme}>
        {/* <CssBaseline /> */}
        <AppWrapper />
        <RouterProvider router={router} fallbackElement={<CircularProgress />} />
        <ToastContainer
          position="bottom-center"
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </ThemeProvider>
    </Provider>
  </StrictMode>
)
