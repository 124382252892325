import { folderApi } from './folder';
import baseApi from '@/repositories/base_api';
import { IFile } from '@/interfaces/file/file';
import { IAPIResponse } from '@/interfaces/api/response';
import { IFileForm } from '@/interfaces/file/file_form';
import { MoveFileForm } from '@/interfaces/file/move_file_form';

export const fileApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        addFile: build.mutation<IFile, {id: string, data: FormData, onProgress?: (val: number)=>void}>({
            query: ({id, data, onProgress}) => ({ 
                url: `/folders/${id}/files/`, 
                method: 'post',
                headers: {'Content-Type': 'multipart/form-data'},
                data: data,
                onUploadProgress: (e) => onProgress?.(Math.round((e.loaded / (e.total ?? 1)) * 100)) }),
            transformResponse: (response: IAPIResponse<IFile>) => response.data,
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(folderApi.util.updateQueryData('getFolder', id, folder => {
                    folder.files?.push(response.data)
                    folder.files?.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            }
        }),
        updateFile: build.mutation<IFile, {id: string, file_id: string, data: IFileForm}>({
            query: ({id, file_id, data}) => ({ url: `/folders/${id}/files/${file_id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<IFile>) => response.data,
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(folderApi.util.updateQueryData('getFolder', id, folder => {
                    const index = folder.files?.findIndex((item) => item.uuid === response.data.uuid);
                    if (index == -1 || index == undefined) return;
                    folder.files?.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteFile: build.mutation<void, {id: string, file_id: string}>({
            query: ({id, file_id}) => ({ url: `folders/${id}/files/${file_id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted({ id, file_id }, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(folderApi.util.updateQueryData('getFolder', id, folder => {
                    folder.files = folder.files?.filter((item) => item.uuid !== file_id)
                }))).catch(() => {});
            },
        }),
        moveFile: build.mutation<IFile, {id: string, file_id: string, data: MoveFileForm}>({
            query: ({id, file_id, data}) => ({ url: `folders/${id}/files/${file_id}/move`, method: 'post', data }),
            transformResponse: (response: IAPIResponse<IFile>) => response.data,
            async onQueryStarted({ id, file_id, data }, { dispatch, queryFulfilled }) {
                queryFulfilled.then((res) => {
                    dispatch(folderApi.util.updateQueryData('getFolder', id, folder => {
                        folder.files = folder.files?.filter((item) => item.uuid !== file_id)
                    }))
                    dispatch(folderApi.util.updateQueryData('getFolder', String(data.folder_id), folder => {
                        folder.files?.push(res.data)
                    }))
                })
                .catch(() => {});
            },
            invalidatesTags: ['Folder.list']
        }),
        deleteFiles: build.mutation<void, {id: string, file_ids: string[]}>({
            query: ({id, file_ids}) => ({ url: `folders/${id}/files/bulk-delete`, method: 'post', data: {ids: file_ids} }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted({id, file_ids}, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(folderApi.util.updateQueryData('getFolder', id, folder => {
                    folder.files = folder.files?.filter((f) => !file_ids.includes(f.uuid))
                }))).catch(() => {});
            },
        }),
    }),
})

export const { 
    useAddFileMutation, 
    useUpdateFileMutation, 
    useDeleteFileMutation,
    useMoveFileMutation,
    useDeleteFilesMutation
} = fileApi;

