import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Interfaces
import { selectHasTeam } from "@/store/team";
import { ISupplementTemplate } from "@/interfaces/supplement_template/supplement_template";

// Components
import TableToggle from "./toggle_shared";
import TemplateMenu from "./supplement_menu";
import { ColDef } from "@/components/datatable";


const useSupplementTemplateTableHeaders = (): ColDef<ISupplementTemplate>[] => {

    const { t } = useTranslation();
    const hasTeam = useSelector(selectHasTeam);

    const headers: ColDef<ISupplementTemplate>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '25%',
            sortable: true
        },
        {
            field: 'created_at',
            headerName: t('components.dataTable.headers.dateAdded'),
            width: '10%',
            render: (row: ISupplementTemplate) => new Date(row.created_at).toLocaleDateString()
        },
        {
            field: 'focus',
            headerName: t('components.dataTable.headers.focus'),
            width: '25%',
            render: (row: ISupplementTemplate) => row.focus ?? '-'
        },
        {
            field: 'draft',
            headerName: t('components.dataTable.headers.status'),
            width: '10%',
            render: (row: ISupplementTemplate) => row.draft ? t('general.draft') : t('general.ready')
        },
        {
            field: 'shared',
            headerName: t('components.dataTable.headers.shared'),
            width: '10%',
            render: (row: ISupplementTemplate) => <TableToggle row={row} />
        },
        {
            field: 'actions',
            headerName: '',
            width: '10%',
            render: (row: ISupplementTemplate) => <TemplateMenu row={row} />
        }
    ];

    if (!hasTeam) {
        return headers.filter((h) => h.field !== 'shared');
    }

    return headers;
}

export default useSupplementTemplateTableHeaders;