import { FC } from "react";
import { useTranslation } from "react-i18next";

// Components
import { Box } from "@mui/material";


interface NoCheckinsProps {
    status: 'overdue' | 'due_today' | 'to_do' | 'in_progress' | 'done';
}

const NoCheckins: FC<NoCheckinsProps> = ({status}) => {

    const { t } = useTranslation();

    return (
        <Box 
            display="flex"
            flexGrow={1}
            alignItems="center"
            justifyContent="center"
            width="100%"
            // minWidth="236px"
            maxHeight="70px" 
            padding="16px" 
            boxSizing="border-box"
            borderRadius="6px" 
            // border="solid 1px var(--border-subtle-01)" 
            bgcolor='var(--layer-01)'
        >
            <span className="body-02-compact" style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }}>{t(`pages.checkins.noCheckins.${status}`)}</span>
        </Box>
    )
}

export default NoCheckins;