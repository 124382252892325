import { z } from 'zod';
import ruleset from '@/_helpers/ruleset';

export interface IPhaseMilestoneForm {
    title?: string,
    date?: string,
    completed_at?: string | null,
}


export const milestoneSchema = z.object({
    title: ruleset.required,
    date: ruleset.date,
    completed_at: ruleset.dateOptional,
});