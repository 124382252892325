import { FC } from "react"
import { useTranslation } from "react-i18next";

// Context
import { usePhaseContext } from "@/contexts/phase_context";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import Milestones from "./milestones";
import RightPanel from "@/components/panel/right_panel";


const MilestonePanel: FC = () => {
    
    const { t } = useTranslation()
    const { phase, open, setOpen } = usePhaseContext();

    return (
        <RightPanel
            open={open === 'milestone_panel'}
            onClose={() => setOpen(null)}
            title={t('rightPanel.phase.milestones')}
            showClose
            action1={{
                onClick: () => setOpen('milestone_modal'), 
                label: t('components.buttons.addMilestone'), 
                icon: <Add />,
                disabled: phase?.readonly
            }}
            children={<Milestones
                phase_id={phase!.id}
                phase_start={phase!.start ?? phase?.expected_start}
                milestones={phase!.milestones}
            />}
            />
    )
}

export default MilestonePanel;