import { z } from "zod";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";

// Helpers
import ruleset from "@/_helpers/ruleset";

// Components
import Modal from "@/components/modal/modal";
import TextInput from "@/components/text_input";



interface _RenameModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: _IRenameForm) => void;
    title: string;
    name: string;
    placeholder?: string;
}

interface _IRenameForm {
    name: string;
}

const RenameModal: FC<_RenameModalProps> = ({
    open,
    onClose,
    onSubmit,
    title,
    name,
    placeholder,
}) => {

    const { t } = useTranslation();

    const { control, reset, handleSubmit, formState: { isDirty } } = useForm<_IRenameForm>({
        resolver: zodResolver(z.object({
            name: ruleset.required
        })),
        mode: 'onBlur',
        defaultValues: {
            name: name
        }
    });

    const handleClose = () => {
        reset();
        onClose();
    }

    const submit = (data: _IRenameForm) => {
        onSubmit(data);
        handleClose();
    }

    return (
        <Modal
            open={open}
            onClose={isDirty ? undefined : onClose}
            title={title}
            children={
                <TextInput
                    control={control}
                    name="name"
                    placeHolder={placeholder}
                    maxLength={40}
                    />
            }
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: handleClose
            }}
            action1={{
                label: t('components.buttons.save'),
                onClick: handleSubmit(submit)
            }}
            />
    )
}

export default RenameModal;