import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

// Helpers
import showToast from '@/_lib/toast';

// Services and interfaces
import { ILink } from "@/interfaces/link/link";
import { ApiError } from "@/interfaces/api/error";
import { IFolder } from "@/interfaces/folder/folder";
import { ILinkForm, linkFormSchema } from "@/interfaces/link/link_form";
import { useAddLinkMutation, useUpdateLinkMutation } from "@/repositories/link";

// Styles
import { Add, Save } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import Modal from "@/components/modal/modal";
import TextInput from '@/components/text_input';
import { zodResolver } from "@hookform/resolvers/zod";


interface _LinkModalProps {
    open: boolean;
    onClose: () => void;
    folder: IFolder;
    link?: ILink;
}

const LinkModal: FC<_LinkModalProps> = ({
    open,
    onClose,
    folder,
    link,
}) => {

    const { t } = useTranslation();
    const [addLink, { isLoading: isAdding }] = useAddLinkMutation();
    const [upddateLink, { isLoading: isUpdating }] = useUpdateLinkMutation();

    const formMethods = useForm<ILinkForm>({
        resolver: zodResolver(linkFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: link?.name ?? '',
            url: link?.url ?? ''
        }
    });

    const handleAddLink = async (data: ILinkForm) => {
        addLink({id: String(folder.id), data: data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.link.created.title'),
                message: t('notifications.link.created.message', {name: data.name})
            });
            formMethods.reset();
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    };

    const handleUpdateLink = async (data: ILinkForm) => {
        if (!link?.uuid) return;
        upddateLink({id: String(folder.id), link_id: link?.uuid, data: data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.link.updated.title'),
                message: t('notifications.link.updated.message', {name: data.name})
            });
            formMethods.reset();
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    };


    const handleClose = () => {
        formMethods.reset();
        onClose();
    }
    
    return (
        <Modal
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : handleClose}
            title={t('modals.addLink', {name: folder.name})}
            children={
                <FormProvider {...formMethods}>
                    <LinkForm />
                </FormProvider>
            }
            action1={{
                label: t(`components.buttons.${link ? 'save' : 'add'}`),
                icon: link ? <Save /> :  <Add />,
                loading: isAdding || isUpdating,
                disabled: !formMethods.formState.isValid,
                onClick: link ? formMethods.handleSubmit(handleUpdateLink) : formMethods.handleSubmit(handleAddLink)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isAdding || isUpdating,
                onClick: handleClose
            }}
        />
    )
}

export default LinkModal



const LinkForm: FC = () => {

    const { t } = useTranslation();
    const { control, watch } = useFormContext();
    const url = watch('url');

    return (
        <Box display="flex" flexDirection="column" rowGap="16px" minWidth="640px">
            
            <TextInput
                control={control}
                name="name"
                label={t('inputs.titles.linkName')}
                maxLength={40}
                placeHolder={t('inputs.placeholders.linkName')}
                />

            <TextInput
                control={control}
                name="url"
                label={t('inputs.titles.linkUrl')}
                placeHolder={t('inputs.placeholders.linkUrl')}
                minRows={4}
                />

            <VideoEmbed url={url} />

        </Box>
        
    )
}

interface VideoEmbedProps {
    url: string;
}

const VideoEmbed: FC<VideoEmbedProps> = ({ url }) => {

    if (!url) return null;

    const youTube1Regex = /^(https:\/\/)?(www\.)?(youtube\.com\/watch\?v=)([A-Za-z0-9\_-]){11}(&.*)?$/;
    const youTube2Regex = /^https:\/\/youtu\.be\/[A-Za-z0-9_-]{11}(\?si=[A-Za-z0-9_-]+)(&.*)?$/;
    const vimeoRegex = /^(https:\/\/(www\.)?vimeo\.com\/(channels\/[A-Za-z0-9_-]+\/)?[0-9]{8,9})?$/;

    if (youTube1Regex.test(url) || youTube2Regex.test(url)) {
        const videoId = url.includes('v=') ? url.split('v=')[1].split('&')[0] : url.split('youtu.be/')[1].split('?')[0];
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
        return (
            <Box display="flex" marginBottom="12px" width='100%'>
                <iframe width='100%' style={{aspectRatio: '16/9'}} src={embedUrl} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Box>
        );
    } else if (vimeoRegex.test(url)) {
        const videoId = url.split('/').pop();
        const embedUrl = `https://player.vimeo.com/video/${videoId}`;
        return (
            <Box display="flex" marginBottom="12px" width='100%' bgcolor="var(--black)">
                <iframe width='100%' style={{aspectRatio: '16/9'}} src={embedUrl}  frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            </Box>
        );
    }
    return null;
};