import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

// Interfaces
import { ITeamMemberForm } from "@/interfaces/team/member_form";

// Components
import { Box } from "@mui/material";
import EmailInput from "@/components/email_input";
import SelectInput, { ISelectItem } from "@/components/select_input";


interface _MemberFormProps {
    edit?: boolean;
}

const MemberForm: FC<_MemberFormProps> = ({
    edit = false,
}) => {

    const { t } = useTranslation();
    const { control } = useFormContext<ITeamMemberForm>();

    const roles: ISelectItem[] = [
        {label: t('components.select.options.teamRole.admin'), value: 'admin'},
        {label: t('components.select.options.teamRole.member'), value: 'member'},
    ];
    
    return (
        <Box display="flex" flexDirection="column" gap="16px">

            <EmailInput 
                name="email" 
                control={control} 
                label={t('inputs.titles.emailAddress')} 
                placeHolder="John@1fit.com"
                disabled={edit}
                />
            <SelectInput 
                name="role" 
                control={control} 
                label={t('components.select.titles.role')}
                tooltip="tooltips.teamRole"
                items={roles}
                itemKey="value"
                fullWidth
                />

        </Box>
    )
}

export default MemberForm;