import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

// Components
import TextInput from "@/components/text_input";
import { Box, Container, Skeleton } from "@mui/material";
import { IFormSection } from "@/interfaces/form_builder/form_builder";
import { IFeedback } from "@/interfaces/checkin_submission/checkin_submission";


interface _SubmissionFormProps {
    sections?: IFormSection[];
    isLoading: boolean;
    isDisabled?: boolean;
}

const SubmissionForm: FC<_SubmissionFormProps> = ({
    sections,
    isLoading = false,
    isDisabled = false
}) => {

    const { t } = useTranslation();
    const { control } = useFormContext<IFeedback>();

    const columnStyles = {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        maxWidth: '752px',
        alignItems: 'flex-start',
        textAlign: 'left',
        overflow: 'hidden'
    }

    const cardStyles = {
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        width: '100%',
        maxHeight: '80vh',
        padding: '24px',
        border: 'solid 1px var(--border-subtle-01)',
        borderRadius: '6px',
        bgcolor: 'var(--layer-01)',
        marginTop: '8px',
        overflow: 'auto'
    }

    return (
        <Container sx={{display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '24px', boxSizing: 'border-box', padding: '16px 24px 0 24px', overflow: 'hidden'}}>

            <Box sx={columnStyles}>

                {/* Title */}
                <span className="heading-06-compact" style={{color: 'var(--text-secondary)'}}>{t('pages.checkinSubmission.response')}</span>
                
                {/* Response */}
                <Box sx={cardStyles}>

                    {/* Loader */}
                    {isLoading && Array(2).fill(0).map((section, i) => (
                        <Box key={i} display="flex" flexDirection="column" rowGap="24px" alignItems="flex-start" sx={{marginTop: i > 0 ? '16px' : '0'}}>

                            {/* Section title */}
                            <Skeleton variant="text" width="150px" />

                            {/* Questions */}
                            {Array(3).fill(0).map((question, j) => (
                                <Box key={j} display="flex" flexDirection="column" alignItems="flex-start" sx={{marginTop: j > 0 ? '16px' : '0'}}>

                                    {/* Question */}
                                    <Skeleton variant="text" width="150px" />

                                    {/* Text answer */}
                                    <Skeleton variant="text" width="300px" />

                                </Box>
                            ))}

                        </Box>
                    ))}

                    {!isLoading && sections?.map((section, i) => (
                        <Box 
                            key={section.uuid} 
                            display="flex" 
                            flexDirection="column" 
                            rowGap="16px" 
                            alignItems="flex-start" 
                            textAlign="left" 
                            sx={{marginTop: i > 0 ? '32px' : '0'}}>

                            {/* Section title */}
                            <span className="heading-07-compact">{section.title}</span>

                            {/* Questions */}
                            {section.questions.map((question, j) => (
                                <Box key={question.uuid} display="flex" flexDirection="column" alignItems="flex-start" sx={{marginTop: j > 0 ? '0px' : '0'}}>

                                    {/* Question */}
                                    <span className="label-text-02" style={{color: 'var(--text-secondary)', marginBottom: '8px'}}>{question.title}</span>

                                    {/* Text answer */}
                                    {['text', 'long_text'].includes(question.type) && <span className="body-02-compact">{question.answer}</span>}

                                    {/* Select answer */}
                                    {question.type == "select" && <span className="body-02-compact">{question.answer}</span>}

                                    {/* Multiselect answer */}
                                    {question.type == "multiselect" && <span className="body-02-compact">{question.answer && Array.isArray(question.answer) ? question.answer.join(', ') : '-'}</span>}

                                    {/* Rating answer */}
                                    {question.type == "rating" && <span className="body-02-compact">{question.answer ? t(`components.formBuilder.question.rating_${question.answer}`) : '-'}</span>}

                                </Box>
                            ))}

                        </Box>
                    ))}
                
                </Box>
            </Box>

            {/* Feedback */}
            <Box sx={columnStyles}>

                {/* Title */}
                <span className="heading-06-compact" style={{color: 'var(--text-secondary)'}}>{t('pages.checkinSubmission.comments')}</span>

                {/* Form */}
                <Box sx={cardStyles}>

                    <TextInput
                        name="video_link"
                        control={control}
                        label={t('inputs.titles.videoFeedback')}
                        helperText={t('inputs.helperText.checkinVideo')}
                        isLoading={isLoading}
                        disabled={isDisabled}
                        />

                    <TextInput
                        name="comments"
                        control={control}
                        size="textarea"
                        label={t('inputs.titles.comments')}
                        minRows={10}
                        allowEmoji
                        isLoading={isLoading}
                        disabled={isDisabled}
                        />

                </Box>

            </Box>

        </Container>
    )
}

export default SubmissionForm;