import dayjs from "dayjs";
import { t } from "i18next";
import { FC, useEffect, useState } from "react";

// Components
import Card from "@/components/card";
import Box, { BoxProps } from "@mui/material/Box";
import RightPanel from "@/components/right_panel";
import { IClientActivity } from "@/interfaces/client/client_activity";
import { IActivityLog } from "@/interfaces/activity_log/activity_log";


interface _ActivityDetailsPanelProps {
    open: boolean;
    onClose: ()=>void;
    logs: IClientActivity|null;
}

const ActivityDetailsPanel: FC<_ActivityDetailsPanelProps> = ({
    open,
    onClose,
    logs
}) => {

    //TODO add settings?
    const [panelOpen, setPanelOpen] = useState(false);

    const boxProps: BoxProps = {
        className: 'body-02',
        display: 'flex', pb: 1, mb: 1,
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--border-subtle-01)',
        color: 'var(--text-secondary)'
    }

    const cardioTarget = logs?.plans?.cardio?.duration;
    const totalCardio = logs?.activity ? logs.activity.reduce((acc, log) => acc + log.duration, 0) / 60 : 0;
    const stepsTarget = logs?.plans?.steps?.steps;

    const handleClose = () => {
        setPanelOpen(false);
        onClose();
    }

    useEffect(() => {
        if (open) setPanelOpen(true);
    }, [open])

    return (
        <RightPanel title={t('components.activity.activityBreakdown')} open={panelOpen} onClose={handleClose}>
            {/* Date */}
            <Box {...boxProps}>
                <span>{t('components.activity.date')}</span> 
                <span>{dayjs(logs?.date).format('DD/MM/YYYY')}</span>
            </Box>
            {/* Cardio day */}
            <Box {...boxProps}>
                <span>{t('components.activity.cardioSelected')}</span> 
                <span>{logs?.plans?.cardio?.name ?? '-'}</span>
            </Box>
            {/* Cardio total */}
            <Box {...boxProps}>
                <span>{t('components.activity.cardioTotal')}</span>
                <Box display="flex" alignItems="center">
                    <span>{totalCardio > 0 ? totalCardio : '-'}</span>
                    {cardioTarget && <span style={{marginLeft: '4px'}}>{'/'}</span>}
                    {cardioTarget && <span style={{marginLeft: '4px', color: 'var(--text-placeholder)'}}>{cardioTarget}</span>}
                    {cardioTarget && <span style={{marginLeft: '4px', color: 'var(--text-placeholder)'}}>{t('metrics.minutes')}</span>}
                </Box>
            </Box>
            {/* Steps day */}
            <Box {...boxProps}>
                <span>{t('components.activity.stepsSelected')}</span>
                <span>{logs?.plans?.steps?.name ?? '-'}</span>
            </Box>
            {/* Steps total */}
            <Box {...boxProps} sx={{borderBottom: 'none'}}>
                <span>{t('components.activity.stepsTotal')}</span>
                <Box display="flex" alignItems="center">
                    <span>{logs?.steps ?? '-'}</span>
                    {stepsTarget && <span style={{marginLeft: '4px'}}>{'/'}</span>}
                    {stepsTarget && <span style={{marginLeft: '4px', color: 'var(--text-placeholder)'}}>{stepsTarget}</span>}
                    {stepsTarget && <span style={{marginLeft: '4px', color: 'var(--text-placeholder)'}}>{t('metrics.stepsValue')}</span>}
                </Box>
            </Box>

            {logs?.activity.map(log => (
                <Card key={log.id} sx={{my: 2, borderRadius: '6px'}}>
                    <Box display="flex" justifyContent='space-between' mb={2}>
                        <span className="body-01">{log.activity}</span>
                    </Box>
                    <_ActivityTable log={log} />
                </Card>
            ))}
        </RightPanel>
    )
}

export default ActivityDetailsPanel


interface _ActivityTableProps {
    log: IActivityLog,
}

const _ActivityTable: FC<_ActivityTableProps> = ({
    log,
}) => {

    
    return (
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" rowGap="12px">

            {/* Headers */}
            <span className='heading-07-compact'>{t('components.activity.duration')}</span>
            {/* <span className='heading-07-compact'>{t('components.activity.type')}</span> */}
            <span className='heading-07-compact'>{t('components.activity.calories')}</span>
            <span className='heading-07-compact'>{t('components.activity.distance')}</span>
            <span className='heading-07-compact'>{t('components.activity.hr')}</span>
        
            {/* Data */}
            <span className='body-02'>{`${log.duration / 60} ${t('metrics.minutes')}`}</span>
            {/* <span className='body-02'>{log.activity}</span> */}
            <span className='body-02'>{log.calories ? `${log.calories} ${t('metrics.kcal')}` : '-'}</span>
            <span className='body-02'>{log.distance ? `${log.distance} m` : '-'}</span>
            <span className='body-02'>{log.hr ?? '-'}</span>
            
        </Box>
    )
}