import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { ApiError } from "@/interfaces/api/error";
import { INutritionBuilder } from "@/interfaces/nutrition/nutrition_builder";
import { INutritionPlan, nutritionPlanSchema } from "@/interfaces/nutrition/nutrition_plan";
import { useLazyGetNutritionPlanQuery, useUpdateNutritionPlanMutation } from "@/repositories/nutrition_plan";

// Styles
import { Save, Settings } from "@carbon/icons-react";

// Components
import { Box, Container } from "@mui/material";
import Day from "@/components/nutrition_builder/day";
import DayMenu from '@/components/nutrition_builder/day_menu';
import SecondaryToolbar from "@/components/secondary_toolbar";
import RenameFormModal from "@/components/builder/rename_modal";
import UnsavedChangesModal from "@/pages/common/unsaved_changes_modal";
import NutritionModal from "@/components/nutrition_builder/nutrition_modal";


const EditNutritionPlanPage: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id, planId } = useParams();
    const hasTeam = useSelector(selectHasTeam)
    const [open, setOpen] = useState<string|null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedDay, setSelectedDay] = useState<number>(0);
    
    const [getPlan, { data: plan }] = useLazyGetNutritionPlanQuery();
    const [savePlan, { isLoading: isSaving }] = useUpdateNutritionPlanMutation();
    const [saveDraftPlan, { isLoading: isSavingDraft }] = useUpdateNutritionPlanMutation();

    const formMethods = useForm<INutritionPlan>({
        resolver: zodResolver(nutritionPlanSchema),
        mode: 'onBlur',
        defaultValues: {
            name: '',
            notes: '',
            plan: [],
            draft: true
        }
    });

    const name = useWatch({ control: formMethods.control, name: 'name' });

    useEffect(() => {
        if (id && planId) {
            getPlan({client_id: id!, plan_id: planId}).unwrap().then((p) => {
                formMethods.reset({
                    name: p.name,
                    notes: p.notes,
                    plan: p.plan,
                    draft: p.draft ?? false
                })
                setLoading(false);
            }).catch((error: ApiError) => {
                navigate(`/clients/${id}/nutrition/plans`);
                showToast({type: 'error', apiError: error.type})
            });
        }
    }, [id, formMethods, getPlan, navigate, hasTeam]);

    useEffect(() => {
        if (!loading && plan?.readonly) showToast({
            type: 'info',
            title: t('notifications.plan.readonly.title'),
            message: t('notifications.plan.readonly.message')
        });
    }, [loading]);

    const handleSelectDay = (index: number) => {
        setSelectedDay(index);
    }

    const handleUpdateSettings = (data: INutritionBuilder) => {
        formMethods.setValue('name', data.name, { shouldDirty: true });
        formMethods.setValue('notes', data.notes ?? '', { shouldDirty: true });
        setOpen(null);
    }

    const handleBack = () => {
        if (formMethods.formState.isDirty) return setOpen('unsaved');
        navigate(`/clients/${id}/nutrition/plans`);
    }

    const handleSave = (data: INutritionPlan) => {
        if (!id || !planId) return;
        data.draft = false;
        savePlan({p: {client_id: id!, plan_id: planId}, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.plan.updated.title'), 
                message: t('notifications.plan.updated.message', {name: name})
            });
            navigate(`/clients/${id}/nutrition/plans`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleSaveDraft = () => {
        if (!id || !planId) return;
        const data = formMethods.getValues();
        data.draft = true;
        saveDraftPlan({p: {client_id: id!, plan_id: planId}, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.plan.updated.title'), 
                message: t('notifications.plan.updated.message', {name: name})
            });
            navigate(`/clients/${id}/nutrition/plans`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar
                title={name}
                isLoading={loading}
                onBack={handleBack}
                iconActions={([{kind: "ghost", icon: <Settings />, onClick: () => setOpen('settings')}])}
                action1={{
                    label: t('components.buttons.save'),
                    icon: <Save />,
                    loading: isSaving,
                    disabled: isSavingDraft || plan?.readonly,
                    onClick: formMethods.handleSubmit(handleSave),
                    sx: {minWidth: '115px'}
                }}
                action2={{
                    label: t('components.buttons.saveDraft'),
                    loading: isSavingDraft,
                    disabled: isSaving || plan?.active || plan?.readonly,
                    onClick: () => handleSaveDraft(),
                }}
                sx={{borderBottom: 'none'}}
                />
            {open === 'edit' && 
                <RenameFormModal 
                    open={open === 'edit'} 
                    onClose={() => setOpen(null)}
                    onSubmit={(d) => formMethods.setValue('name', d.name)}
                    title={t('modals.renameForm')}
                    name={name}
                    />}
            {open === 'settings' && <NutritionModal
                open={open === 'settings'}
                onClose={() => setOpen(null)}
                nutrition={formMethods.getValues()}
                onSave={handleUpdateSettings}
                />}
            {open === 'unsaved' && 
                <UnsavedChangesModal 
                    open={open === 'unsaved'} 
                    onClose={() => setOpen(null)}
                    onSubmit={() => navigate(`/clients/${id}/nutrition/plans`)}
                    />}

            {!loading && <FormProvider {...formMethods}>
                <Container sx={{padding: '16px 24px 0 24px', overflow: 'hidden'}}>
                    <Box className="Builder">

                        <DayMenu 
                            selectedIndex={selectedDay}
                            onSelectDay={handleSelectDay}
                            />

                        <Day 
                            key={selectedDay}
                            selectedDay={selectedDay}
                            />

                    </Box>
                </Container>
            </FormProvider>}
        </Box>
    )
}

export default EditNutritionPlanPage;