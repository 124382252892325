import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormProvider,  useForm, useFormContext } from "react-hook-form";

// Services and interfaces
import { IFolderItem } from "@/interfaces/folder/folder";
import { useGetFoldersQuery } from "@/repositories/folder";
import { MoveFileForm } from "@/interfaces/file/move_file_form";

// Components
import Box from "@mui/material/Box";
import Modal from "@/components/modal/modal";
import { ArrowRight } from "@carbon/icons-react";
import SelectInput from "@/components/select_input";

interface _MoveModalProps {
    open: boolean;
    onClose: () => void;
    item: IFolderItem;
    isLoading?: boolean;
    onMove: (data: MoveFileForm) => void;
}

const MoveModal: FC<_MoveModalProps> = ({
    open,
    onClose,
    item,
    isLoading,
    onMove
}) => {

    const { t } = useTranslation();

    const formMethods = useForm<MoveFileForm>({
        mode: 'onBlur',
        defaultValues: {
            folder_id: ""
        }
    });

    const handleClose = () => {
        formMethods.reset();
        onClose();
    }
    
    return (
        <Modal
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : handleClose}
            title={t('modals.moveFile', {name: item.name})}
            children={
                <FormProvider {...formMethods}>
                    <MoveForm />
                </FormProvider>
            }
            action1={{
                label: t('components.buttons.move'),
                icon: <ArrowRight />,
                loading: isLoading,
                disabled: !formMethods.formState.isDirty,
                onClick: formMethods.handleSubmit(onMove)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: handleClose
            }}
        />
    )
}

export default MoveModal


const MoveForm: FC = () => {

    const { t } = useTranslation();
    const { id } = useParams();
    const { control } = useFormContext<MoveFileForm>();

    const { data, isLoading } = useGetFoldersQuery();
    const items = useMemo(() => {
        return data?.filter((d) => d.id != id).map(d => ({
            id: d.id,
            value: d.id,
            label: d.name
        })) ?? []
    }, [data, id])

    return (
        <Box minWidth="400px">
            <SelectInput 
                name='folder_id'
                control={control}
                label={t('inputs.titles.folder')}
                items={items}
                itemsLoading={isLoading}
                />
        </Box>
        
    )
}