import { FC } from "react";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Services and interfaces
import { ITeamMember } from "@/interfaces/team/team";
import { ITeamMemberForm, teamMemberSchema } from "@/interfaces/team/member_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import MemberForm from "./member_form";
import Modal from "@/components/modal/modal";


interface _TeamMemberModalProps {
    open: boolean;
    onClose: (() => void);
    member?: ITeamMember;
    isLoading: boolean;
    onSubmit: ((data: ITeamMemberForm) => void);
}

const TeamMemberModal: FC<_TeamMemberModalProps> = ({
    open,
    onClose,
    member,
    isLoading = false,
    onSubmit,
}) => {

    const { t } = useTranslation();
    const formMethods = useForm<ITeamMemberForm>({
        resolver: zodResolver(teamMemberSchema),
        mode: 'onBlur',
        defaultValues: {
            email: member?.email ?? '',
            role: member?.role ?? 'member'
        }
    });

    const handleClose = () => {
        onClose();
        formMethods.reset();
    }
    
    return (
        <Modal
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : onClose}
            title={member ? t('modals.editTeamMember.title') : t('modals.addTeamMember.title')}
            children={
                <FormProvider {...formMethods}>
                    <MemberForm edit={member != undefined} />
                </FormProvider>}
            action1={{
                label: member ? t('components.buttons.save') : t('components.buttons.sendInvite'),
                icon: <ArrowRight />,
                onClick: formMethods.handleSubmit(onSubmit),
                loading: isLoading,
                disabled: !formMethods.formState.isValid || !formMethods.formState.isDirty
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: handleClose,
                disabled: isLoading
            }}
            />
    )
}

export default TeamMemberModal;