import { useTranslation } from "react-i18next";

// Helpers
import { capitalize } from "@/_helpers/text_functions";

// Interfaces
import { ICheckinTemplate } from "@/interfaces/checkin_template/checkin_template";

// Components
import TemplateMenu from "./checkin_menu";
import { ColDef } from "@/components/datatable";


const useTeamCheckinTemplateTableHeaders = (): ColDef<ICheckinTemplate>[] => {

    const { t } = useTranslation();

    const headers: ColDef<ICheckinTemplate>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '30%',
            sortable: true
        },
        {
            field: 'created_at',
            headerName: t('components.dataTable.headers.dateAdded'),
            width: '10%',
            render: (row: ICheckinTemplate) => new Date(row.created_at).toLocaleDateString()
        },
        {
            field: 'shared_by',
            headerName: t('components.dataTable.headers.sharedBy'),
            width: '15%',
            render: (row: ICheckinTemplate) => capitalize(row.shared_by ?? '')
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (row: ICheckinTemplate) => <TemplateMenu row={row} />
        }
    ];

    return headers;
}

export default useTeamCheckinTemplateTableHeaders;