import { FC } from "react"
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

// Interfaces
import { checkinTemplateCreateSchema, ICheckinTemplateForm } from "@/interfaces/checkin_template/checkin_template_form";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";
import TextInput from "@/components/text_input";


interface _CheckinModalProps {
    open: boolean;
    onClose: () => void;
    isLoading?: boolean;
    onSave: (data: ICheckinTemplateForm) => void;
}

const CheckinModal: FC<_CheckinModalProps> = ({
    open,
    onClose,
    isLoading,
    onSave
}) => {

    const { t } = useTranslation();
    const formMethods = useForm<ICheckinTemplateForm>({
        resolver: zodResolver(checkinTemplateCreateSchema),
        mode: 'onBlur',
        defaultValues: {
            name: '',
        }
    });

    const handleSave = (data: ICheckinTemplateForm) => {
        onSave(data);
    }
    
    return (
        <Modal
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : onClose}
            title={t('modals.addForm')}
            children={
                <FormProvider {...formMethods}>
                    <CheckinForm />
                </FormProvider>}
            action1={{
                label: t('components.buttons.save'),
                loading: isLoading,
                disabled: !formMethods.formState.isDirty,
                onClick: formMethods.handleSubmit(handleSave)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            />
    )
}

export default CheckinModal


const CheckinForm: FC = () => {

    const { t } = useTranslation();
    const { control } = useFormContext<ICheckinTemplateForm>();

    return (
        <Box display="flex" flexDirection="column" rowGap="16px" minWidth="640px">
            
            <TextInput
                control={control}
                name="name"
                label={t('inputs.titles.formName')}
                maxLength={75}
                placeHolder={t('inputs.placeholders.checkinFormName')}
                />

        </Box>
    )
}