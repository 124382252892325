import { t } from "i18next";
import { useSelector } from "react-redux";
import { selectHasTeam } from "@/store/team";
import { NavItem } from "@/interfaces/components/nav_item";
import { AlignBoxTopLeft, Book, Catalog, FileStorage, Fish, List, ListCheckedMirror, Notebook, Pills, Restaurant, Running, Template, User, UserServiceDesk } from "@carbon/icons-react";


const useNavigationItems = (): Array<NavItem> => {
    const hasTeam = useSelector(selectHasTeam);
    const NavItems: Array<NavItem> = [
        {
            "label": t('navigation.clients'),
            "startIcon": <User />,
            "path": "/clients/active",
            "tabsRoot": "/clients"
        },
        {
            "label": t('navigation.prospects'),
            "startIcon": <UserServiceDesk />,
            "path": "/prospects/list",
            "tabsRoot": "/prospects"
        },
        {
            "label": t('navigation.checkins'),
            "startIcon": <List />,
            "path": "/checkins"
        },
        {
            "label": t('navigation.vault'),
            "startIcon": <FileStorage />,
            "path": "/vault/folders",
            "tabsRoot": "/vault"
        },
        {
            "label": t('navigation.templates'),
            "startIcon": <Template />,
            "path": "/templates",
            "children" : [
                {
                    "label": t('navigation.checkins'),
                    "startIcon": <ListCheckedMirror />,
                    "path": hasTeam ? "/templates/checkins/personal" : "/templates/checkins",
                    "tabsRoot": "/templates/checkins"
                },
                {
                    "label": t('navigation.programmes'),
                    "startIcon": <AlignBoxTopLeft />,
                    "path": hasTeam ? "/templates/programmes/personal" : "/templates/programmes",
                    "tabsRoot": "/templates/programmes"
                },
                {
                    "label": t('navigation.nutritionPlans'),
                    "startIcon": <Restaurant />,
                    "path": hasTeam ? "/templates/nutrition/personal" : "/templates/nutrition",
                    "tabsRoot": "/templates/nutrition"
                },
                {
                    "label": t('navigation.supplementPlans'),
                    "startIcon": <Pills />,
                    "path": hasTeam ? "/templates/supplements/personal" : "/templates/supplements",
                    "tabsRoot": "/templates/supplements"
                },
                {
                    "label": t('navigation.activityPlans'),
                    "startIcon": <Running />,
                    "path": hasTeam ? "/templates/activity/personal" : "/templates/activity",
                    "tabsRoot": "/templates/activity"
                },
            ]
        },
        {
            "label": t('navigation.catalogues'),
            "startIcon": <Catalog />,
            "path": "/catalogues",
            "children" : [
                {
                    "label": t('navigation.workouts'),
                    "startIcon": <Book />,
                    "path": "/catalogues/workouts",
                    "tabsRoot": "/catalogues/workouts"
                }
            ]
        },
        {
            "label": t('navigation.library'),
            "startIcon": <Notebook />,
            "path": "/library",
            "children" : [
                {
                    "label": t('navigation.exercises'),
                    "startIcon": <Running />,
                    "path": "/library/exercises"
                },
                {
                    "label": t('navigation.foods'),
                    "startIcon": <Fish />,
                    "path": "/library/foods"
                },
                {
                    "label": t('navigation.supplements'),
                    "startIcon": <Pills />,
                    "path": "/library/supplements"
                }
            ]
        },
        // {
        //     "label": "Business",
        //     "startIcon": <Currency />,
        //     "path": "/business"
        // }
    ]

    return NavItems;
}

export default useNavigationItems;