import { Control, useController } from "react-hook-form";
import { BaseSyntheticEvent, FC, ReactNode, useEffect, useState } from "react";

// Helpers
import { emojiRegex } from '@/_constants/text';

// Styles
import { Error } from '@mui/icons-material';

// Components
import { TextField, InputAdornment, Box } from "@mui/material";


interface _DurationInputProps {
  name: string;
  control: Control<any>;
  size?: 'small' | 'medium' | 'large';
  format?: 'mm:ss' | 'hh:mm:ss';
  label?: string;
  placeHolder?: string;
  tabIndex?: number;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  gutter?: string;
  hideErrorMessage?: boolean;
  convertToZero?: boolean;
  disabled?: boolean;
}

const DurationInput: FC<_DurationInputProps>  = ({
  name,
  control,
  size = 'small',
  format = 'mm:ss',
  label,
  placeHolder,
  tabIndex = -1,
  startIcon,
  endIcon,
  gutter = '12px',
  hideErrorMessage = false,
  convertToZero = false,
  disabled = false
}) => {

  const { field, fieldState } = useController({name, control});
  
  const classes = {
    'TextInput': true,
    'TextInput-small': size == 'small',
    'TextInput-medium': size == 'medium',
    'TextInput-large': size == 'large',
  };

  const validClasses = Object.entries(classes)
      .filter(([,v]) => !!v)
      .map(([k,]) => k)
      .join(' ')

  const handleBeforeInput = (event: BaseSyntheticEvent) => {
    const nativeEvent = event.nativeEvent as InputEvent;
    const inputValue = nativeEvent.data;

    if (inputValue && emojiRegex.test(inputValue)) {
      event.preventDefault();
    }
  };

  const formatDuration = (seconds: number, format: string): string => {
    if (seconds === 0 && !convertToZero) return '';
    if (seconds === 0 && convertToZero) return format === 'hh:mm:ss' ? '00:00:00' : '00:00';
    const sec = seconds % 60;
    const min = Math.floor(seconds / 60) % 60;
    const hrs = Math.floor(seconds / 3600);
    if (format === 'hh:mm:ss') {
      return `${hrs.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
    }
    return `${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
  };

  // const convertToSeconds = (time: string, format: string): number => {
  //   const parts = time.match(/(\d{2})(\d{2})(\d{2})?/);
  //   if (format === 'hh:mm:ss' && parts) {
  //     return (parseInt(parts[1], 10) * 3600) + (parseInt(parts[2], 10) * 60) + (parseInt(parts[3] || '0', 10));
  //   } else if (parts) {
  //     return (parseInt(parts[1], 10) * 60) + parseInt(parts[2], 10);
  //   }
  //   return 0;
  // };

  const [inputValue, setInputValue] = useState<string>(formatDuration(field.value || 0, format));

  useEffect(() => {
    setInputValue(formatDuration(field.value || 0, format));
  }, [field.value, format]);

  const handleChange = (event: BaseSyntheticEvent) => {
    let value = event.target.value.replace(/[^0-9]/g, '');
    // Remove leading zeros
    value = value.replace(/^0+/, '');

    if (format === 'hh:mm:ss') {
      const paddedValue = value.padStart(6, '0');
      const hrs = paddedValue.slice(0, 2);
      const mins = paddedValue.slice(2, 4);
      const secs = paddedValue.slice(4, 6);

      const formattedTime = `${hrs}:${mins}:${secs}`;
      setInputValue(formattedTime);
      // Do not update field.onChange here
    } else {
      const paddedValue = value.padStart(4, '0');
      const mins = paddedValue.slice(0, 2);
      const secs = paddedValue.slice(2, 4);

      const formattedTime = `${mins}:${secs}`;
      setInputValue(formattedTime);
      // Do not update field.onChange here
    }
  };
  

  const handleBlur = () => {
    const value = inputValue.replace(/[^0-9]/g, '');
    if (value === '') {
      setInputValue('');
      field.onChange(0);
      return;
    }

    let totalSeconds = 0;

    if (format === 'hh:mm:ss') {
      const paddedValue = value.padStart(6, '0');
      let hrs = parseInt(paddedValue.slice(0, 2), 10);
      let mins = parseInt(paddedValue.slice(2, 4), 10);
      let secs = parseInt(paddedValue.slice(4, 6), 10);

      // Adjust for overflow
      if (secs >= 60) {
        mins += Math.floor(secs / 60);
        secs = secs % 60;
      }
      if (mins >= 60) {
        hrs += Math.floor(mins / 60);
        mins = mins % 60;
      }

      // Maximum total seconds for hh:mm:ss format (99:59:59)
      const maxSeconds = 99 * 3600 + 59 * 60 + 59;
      totalSeconds = hrs * 3600 + mins * 60 + secs;

      // Cap total seconds at maximum allowed
      if (totalSeconds > maxSeconds) {
        totalSeconds = maxSeconds;
      }

      // Recalculate hrs, mins, secs from totalSeconds
      hrs = Math.floor(totalSeconds / 3600);
      mins = Math.floor((totalSeconds % 3600) / 60);
      secs = totalSeconds % 60;

      const formattedTime = `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
      setInputValue(formattedTime);
      field.onChange(totalSeconds);
    } else {
      const paddedValue = value.padStart(4, '0');
      let mins = parseInt(paddedValue.slice(0, 2), 10);
      let secs = parseInt(paddedValue.slice(2, 4), 10);

      // Adjust for overflow
      if (secs >= 60) {
        mins += Math.floor(secs / 60);
        secs = secs % 60;
      }

      // Maximum total seconds for mm:ss format (59:59)
      const maxSeconds = 59 * 60 + 59;
      totalSeconds = mins * 60 + secs;

      // Cap total seconds at maximum allowed
      if (totalSeconds > maxSeconds) {
        totalSeconds = maxSeconds;
      }

      // Recalculate mins, secs from totalSeconds
      mins = Math.floor(totalSeconds / 60);
      secs = totalSeconds % 60;

      const formattedTime = `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
      setInputValue(formattedTime);
      field.onChange(totalSeconds);
    }
  };
  

  return (
    // Width set to 100% to always fill what ever container it is in
    <Box className='input__container' sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: gutter, width: '100%'}}>
      {label && <Box display="flex" width="100%">
          <span className="label-text-02" style={{marginBottom: '0.5em', color: 'var(--text-secondary)'}}>{label}</span>
        </Box>}
      <TextField
        className={validClasses}
        value={inputValue}
        inputProps={{
          ref: field.ref,
          name: field.name,
          onBlur: field.onBlur,
        }}
        onBeforeInput={handleBeforeInput}
        placeholder={placeHolder ?? '00:00'}
        tabIndex={tabIndex}
        autoComplete="true"
        error={fieldState.invalid}
        helperText={hideErrorMessage ? null : fieldState.error?.message}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        onPaste={(e) => e.preventDefault()}
        InputProps={{
          startAdornment: startIcon && (
            <InputAdornment position="start" sx={{mr: 0, paddingRight: '12px'}}>
              {startIcon}
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {fieldState.invalid && <InputAdornment position="end"><Error color="error" sx={{fontSize: '16px', marginRight: endIcon ? '4px' : '0'}} /></InputAdornment>}
              {endIcon && <InputAdornment position="end" sx={{ml: 0}}>{endIcon}</InputAdornment>}
            </>
          ),
        }}
      />
    </Box>
  );
};

export default DurationInput;