import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectIsClientReadonly } from "@/repositories/client";
import { INutritionPlan } from "@/interfaces/nutrition/nutrition_plan";
import { ITemplateAssignForm } from "@/interfaces/template/assign_form";
import { useAddNutritionPlanMutation } from "@/repositories/nutrition_plan";
import { INutritionBuilder } from "@/interfaces/nutrition/nutrition_builder";
import { useAssignNutritionTemplateMutation, useGetNutritionTemplatesQuery } from "@/repositories/nutrition_template";

// Components
import { Box, Container } from "@mui/material";
import PlanMenu from "@/pages/client/components/plan_menu";
import SecondaryToolbar from "@/components/secondary_toolbar";
import AssignModal from "@/pages/client/components/assign_modal";
import NutritionModal from "@/components/nutrition_builder/nutrition_modal";


const NutritionPage: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null);
    const readonly = useSelector((state) => selectIsClientReadonly(state, id!));
    const [addNutritionPlan, { isLoading }] = useAddNutritionPlanMutation();
    const [assignNutritionTemplate, { isLoading: isAssigning }] = useAssignNutritionTemplateMutation();
    const { data: templates, isLoading: isTemplatesLoading } = useGetNutritionTemplatesQuery();

    const tabs = [
        {
            label: t('tabs.data'),
            link: `/clients/${id}/nutrition/data`,
            sx: {width: '120px'}
        },
        {
            label: t('tabs.plans'),
            link: `/clients/${id}/nutrition/plans`,
            sx: {width: '120px'}
        }
    ];

    const handleAdd = (data: INutritionBuilder) => {
        addNutritionPlan({id: id!, data: data as INutritionPlan}).unwrap().then((plan) => {
            showToast({
                type: 'success', 
                title: t('notifications.plan.created.title'), 
                message: t('notifications.plan.created.message', {name: plan.name})
            });
            setOpen(null);
            navigate(`/clients/${id}/nutrition/${plan.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleAssign = (data: ITemplateAssignForm) => {
        assignNutritionTemplate(data).unwrap().then((plan) => {
            showToast({
                type: 'success', 
                title: t('notifications.template.assigned.title', { count: 1 }), 
            });
            setOpen(null);
            navigate(`/clients/${id}/nutrition/${plan.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box width='100%' height='100%'>

            <SecondaryToolbar
                kind="primary"
                title={t('pages.plans.nutrition.title')}
                tabs={tabs}
                slot={<PlanMenu label={t('components.buttons.addNutritionPlan')} disabled={readonly} setOpen={setOpen} />}
                />
                {open === 'add' && <NutritionModal
                    open={open === 'add'}
                    onClose={() => setOpen(null)}
                    isLoading={isLoading}
                    onSave={handleAdd}
                    />}
                {open === 'assign' && <AssignModal 
                    open={open === 'assign'} 
                    onClose={() => setOpen(null)}
                    client_id={id!}
                    templates={templates?.filter((t) => !t.draft) ?? []}
                    isLoading={isAssigning}
                    isTemplatesLoading={isTemplatesLoading}
                    onSave={handleAssign}
                    />}

            <Container sx={{py: '24px', overflowY: 'auto', height: 'calc(100% - 48px)'}}>
                <Outlet />
            </Container>
            
        </Box>
    )
}

export default NutritionPage