import { z } from 'zod';
import ruleset from '@/_helpers/ruleset';

export interface ITeamMemberForm {
    [key: string]: string | boolean;
    email: string;
    role: string;
}

export const teamMemberSchema = z.object({
    email: ruleset.email,
    role: ruleset.required
})