import { FC } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";

// Interfaces
import { INutritionBuilder } from '@/interfaces/nutrition/nutrition_builder';

// Styles
import { Add } from "@carbon/icons-react";

// Components
import Meal from './meal';
import Button from "@/components/button";
import MacrosHeader from "./macro_header";
import { Box, Skeleton } from "@mui/material";
import useSort from '@/components/sortable/sort_hooks';
import SortableItem from '@/components/sortable/sortable_item';


interface _DayProps {
    selectedDay: number;
    isLoading?: boolean;
}

const Day: FC<_DayProps> = ({
    selectedDay = 0,
    isLoading = false
}) => {

    const { t } = useTranslation();
    const { sensors, restrictToVerticalAxis } = useSort();

    const { control, setValue } = useFormContext<INutritionBuilder>();
    const day = useWatch({ control, name: `plan.${selectedDay}` });
    const { fields: meals, move, append, remove } = useFieldArray({
        control,
        name: `plan.${selectedDay}.meals`
    });

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (over !== null && active.id !== over.id) {
            const oldIndex = meals.findIndex(item => item.id === active.id);
            const newIndex = meals.findIndex(item => item.id === over.id);
            move(oldIndex, newIndex);
        }
    }

    const handleAddMeal = () => {
        append({ uuid: uuidv4(), name: t('components.nutritionBuilder.meals.meal', { number: meals.length + 1 }), protein: 0, carbs: 0, fat: 0, fibre: 0, calories: 0, foods: [], options: [] });
    }

    const handleRemoveMeal = (index: number) => {
        remove(index);
        const meal = day.meals[index];
        const newMeals = day.meals.filter((m) => m.uuid !== meal.uuid);
        
        const p = roundNumber(newMeals.reduce((acc, meal) => acc + (meal.protein == '' ? 0 : meal.protein as number), 0), 1);
        const c = roundNumber(newMeals.reduce((acc, meal) => acc + (meal.carbs == '' ? 0 :meal.carbs as number), 0), 1);
        const f = roundNumber(newMeals.reduce((acc, meal) => acc + (meal.fat == '' ? 0 : meal.fat as number), 0), 1);
        const fi = roundNumber(newMeals.reduce((acc, meal) => acc + (meal.fibre == '' ? 0 : meal.fibre as number), 0), 1);

        setValue(`plan.${selectedDay}.protein`, p);
        setValue(`plan.${selectedDay}.carbs`, c);
        setValue(`plan.${selectedDay}.fat`, f);
        setValue(`plan.${selectedDay}.fibre`, fi);
    }

    return (
        <Box overflow="hidden">

            <Box display="flex" width="100%" justifyContent="space-between" height="48px" alignItems="center">

                {isLoading ? <Skeleton variant="rounded" width={200} height={22} />:
                        <span className="heading-06-compact">{day.name ? day.name : t('components.nutritionBuilder.menu.dayPlaceholder')}</span>}

                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addMeal')}
                    endIcon={<Add />}
                    minWidth={false}
                    onClick={handleAddMeal}
                />
                    
            </Box>

            <MacrosHeader selectedDay={selectedDay} hasMeals={meals.length > 0} />

            {/* Set height allowing for secondary tool bar and height of macros header */}
            <Box height="calc(100% - 40px - 120px)" marginBottom="24px" sx={{overflowY: 'auto', scrollbarWidth: 'none'}}>
                
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
                    <SortableContext items={meals.map(meal => meal.id)} strategy={verticalListSortingStrategy}>

                        {meals.length > 0 && meals.map((meal, index) => (
                            <SortableItem key={meal.id} id={meal.id}>
                                <Meal
                                    key={meal.uuid}
                                    id={meal.id}
                                    dayIndex={selectedDay}
                                    mealIndex={index}
                                    onRemoveMeal={handleRemoveMeal}
                                    />
                            </SortableItem>
                        ))}

                    </SortableContext>
                </DndContext>
                
            </Box>

        </Box>
    )
}

export default Day;