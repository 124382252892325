import { createSlice } from "@reduxjs/toolkit";

// Apis
import { authApi } from "@/repositories/auth";
import { coachApi } from "@/repositories/coach";

// Interfaces
import { ISiteSettings } from "@/interfaces/settings/site_settings"; 
import { IDataMetricsPhysique } from "@/interfaces/settings/data_metrics";
import { IDataMetricsActivity, IDataMetricsHealth, IDataMetricsRecovery, IDataMetricsSupplementation, IDataMetricsTraining } from "@/interfaces/settings/data_metrics";

interface ISettingsState {
  physique: IDataMetricsPhysique|undefined;
  training: IDataMetricsTraining|undefined;
  supplementation: IDataMetricsSupplementation|undefined;
  activity: IDataMetricsActivity|undefined;
  recovery: IDataMetricsRecovery|undefined;
  health: IDataMetricsHealth|undefined;
  units: ISiteSettings|undefined;
}

const initialState: ISettingsState = {
  physique: undefined,
  training: undefined,
  supplementation: undefined,
  activity: undefined,
  recovery: undefined,
  health: undefined,
  units: undefined,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.getUser.matchFulfilled, (state, { payload }) => {
      state.physique = payload.settings.data_metrics.physique;
      state.training = payload.settings.data_metrics.training;
      state.supplementation = payload.settings.data_metrics.supplementation;
      state.activity = payload.settings.data_metrics.activity;
      state.recovery = payload.settings.data_metrics.recovery;
      state.health = payload.settings.data_metrics.health;
      state.units = payload.settings.site_settings;
    }).addMatcher(coachApi.endpoints.updateSettings.matchFulfilled, (state, { payload }) => {
      state.physique = payload.data_metrics.physique;
      state.training = payload.data_metrics.training;
      state.supplementation = payload.data_metrics.supplementation;
      state.activity = payload.data_metrics.activity;
      state.recovery = payload.data_metrics.recovery;
      state.health = payload.data_metrics.health;
      state.units = payload.site_settings;
    }).addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      resetState(state);
    })
  },
  selectors: {
    selectSettings: (state): ISettingsState => state,
    selectSiteSettings: (state) => state.units,
    selectSiteSettingsWeight: (state) => state.units?.weight,
    selectSiteSettingsBodyweight: (state) => state.units?.bodyweight,
    selectCurrency: (state) => state.units?.currency,
    selectDataMetricsPhysique: (state) => state.physique,
    selectDataMetricsTraining: (state) => state.training,
    selectDataMetricsSupplementation: (state) => state.supplementation,
    selectDataMetricsActivity: (state) => state.activity,
    selectDataMetricsRecovery: (state) => state.recovery,
    selectDataMetricsHealth: (state) => state.health,
    selectDataMetrics: (state) => {
      return {
        physique: state.physique,
        training: state.training,
        supplementation: state.supplementation,
        activity: state.activity,
        recovery: state.recovery,
        health: state.health,
      }
    },
  },
});

const resetState = (state: ISettingsState) => {
  state.physique = undefined;
  state.training = undefined;
  state.supplementation = undefined;
  state.activity = undefined;
  state.recovery = undefined;
  state.health = undefined;
  state.units = undefined;
}

export const { 
  selectSettings, 
  selectSiteSettings,
  selectSiteSettingsWeight,
  selectSiteSettingsBodyweight,
  selectCurrency, 
  selectDataMetrics,
  selectDataMetricsTraining
 } = settingsSlice.selectors;

export default settingsSlice.reducer;