
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services
import { useRequestTeamMutation } from "@/repositories/team";

// Interfaces
import { ApiError } from "@/interfaces/api/error";

// Styles
import { CheckmarkFilled, Events } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import Modal from "@/components/modal/modal";


const RequestTeam: FC = () => {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [requestTeam, { isLoading }] = useRequestTeamMutation();

    const handleClose = () => {
        setOpen(false);
    }

    const handleSubmit = () => {
        requestTeam().unwrap().then(() => {
            setOpen(false);
            setRequestSubmitted(true);
            showToast({
                type: 'success', 
                title: t('notifications.team.requested.title'), 
                message: t('notifications.team.requested.message')
            });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        });
    }

    return (
        <Box 
            display="flex" flexDirection="column" flexGrow={1} alignItems="center" justifyContent="center" padding="24px 32px"
            border="1px solid var(--border-subtle-01)" borderRadius="6px"
            sx={{backgroundColor: "var(--layer-01)"}}>

            {requestSubmitted && <>
                <span className="label-text-01">{t('pages.settings.team.requestSubmitted')}</span>
                <Box height="8px" />
                <CheckmarkFilled size="24" style={{color: 'var(--support-success)'}} />
                </>}

            {!requestSubmitted && <>
                <span className="label-text-01">{t('pages.settings.team.requestAccess')}</span>

                {/* Spacer */}
                <Box height="16px" />

                <Button 
                    kind="primary"
                    size="large" 
                    label={t('components.buttons.requestTeamAccess')} 
                    endIcon={<Events />} 
                    onClick={() => setOpen(true)} 
                    />
                <Modal
                    open={open}
                    onClose={handleClose}
                    title={t('modals.requestTeam.title')}
                    text={t('modals.requestTeam.text')}
                    action1={{
                        label: t('components.buttons.submitRequest'),
                        onClick: handleSubmit,
                        loading: isLoading,
                        disabled: isLoading
                    }}
                    cancel={{
                        label: t('components.buttons.cancel'),
                        onClick: handleClose,
                        disabled: isLoading
                    }}
                    sx={{'& .MuiPaper-root': {maxWidth: '480px', background: 'var(--background)'}}}
                    />
                </>}
        </Box>
    )
}

export default RequestTeam;