import { FC } from "react";

import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";

const LoadingSpinner: FC<CircularProgressProps> = (props) => (
    <>
    {/* <CircularProgress 
        variant='determinate'
        value={100}
        sx={{
            position: 'absolute',
            right: 24,
            color: 'var(--highlight)'
        }}
    /> */}
    <CircularProgress {...props} sx={{
        color: 'var(--interactive)',
        ...props.sx
    }} />
</>
)

export default LoadingSpinner;