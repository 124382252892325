import { FC } from "react";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";

// Components
import { Box, LinearProgress } from "@mui/material";


interface _ComplianceBarProps {
    actual: number;
    target?: number;
}

const ComplianceBar: FC<_ComplianceBarProps> = ({
    actual,
    target
}) => {

    if (!target) return '-';

    const percentage = roundNumber((actual / target) * 100, 0);
    const progressValue = percentage > 100 ? 100 : percentage;

    return (
        <Box display="flex" alignItems="center">
            <span className="label-text-01" style={{width: '50px'}}>{percentage}%</span>
            <LinearProgress
                variant="determinate" 
                value={progressValue} 
                sx={{
                    marginLeft: '16px',
                    width: '80px',
                    '&.MuiLinearProgress-root': {
                        backgroundColor: 'var(--border-subtle-01)',
                        '.MuiLinearProgress-bar': {
                            backgroundColor: 'var(--border-interactive)'
                        }
                    },
                }}/>
        </Box>
    )
}

export default ComplianceBar