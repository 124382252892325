import { FC } from "react";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";

// Interfaces
import { TotalValues } from "@/interfaces/components/totals_card";

// Components
import { Box, Skeleton } from "@mui/material";


interface _TotalsCardProps {
    metric: string;
    totals: TotalValues;
    isLoading?: boolean;
}

const TotalsCard: FC<_TotalsCardProps> = ({ 
    metric,
    totals,
    isLoading = false
}) => {

    const { t } = useTranslation();
    const isCalories = metric == 'calories';

    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start" height="114px" width="100%" padding="16px" boxSizing="border-box" borderRadius="6px" border="solid 1px var(--border-subtle-01)" marginBottom="16px" sx={{bgcolor: 'var(--layer-01)'}}>
                        
            {isLoading ?
                <Skeleton variant="rounded" width="100px" height="18px" sx={{marginBottom: '20px'}} /> :
                <span className="body-02-compact" style={{textAlign: 'left', marginBottom: '16px'}}>{t(`components.chartCard.nutrition.total${capitalize(metric)}`)}</span>}

            {isLoading ?
                <Skeleton variant="rounded" height="28px" width="100px" sx={{marginBottom: '4px'}} /> :
                <span className="heading-05" style={{marginBottom: '4px'}}>{`${totals.value} ${isCalories ? t('metrics.kcal') : t('metrics.gram')}`}</span>}

            {isLoading ?
                <Skeleton variant="rounded" width="100px" height="16px" /> :
                <span className="helper-text-02" style={{color: 'var(--text-secondary)'}}>{t('components.chartCard.nutrition.daysLogged', {count: totals.daysLogged})}</span>}

        </Box>
    )
}

export default TotalsCard;