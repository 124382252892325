import { FC } from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

// Styes
import { ArrowRight, CheckmarkFilled } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";


interface _RescueModalProps {
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    onReedem: () => void;
}

const RescueModal: FC<_RescueModalProps> = ({
    open,
    onClose,
    onCancel,
    onReedem
}) => {

    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t(`modals.rescueCancellation.title`)}
            text={t(`modals.rescueCancellation.text`)}
            children={<_RescueCard />}
            action1={{
                label: t('components.buttons.redeemCancellationOffer'),
                icon: <ArrowRight />,
                onClick: onReedem,
                loading: false,
            }}
            action2={{
                kind: 'danger-ghost',
                label: t('components.buttons.cancelSubscription'),
                onClick: onCancel,
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: onClose,
                disabled: false
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '670px', background: 'var(--background)'}}}
            />
    )
}

export default RescueModal;

const _RescueCard: FC = () => {


    return (
        <Box 
            display="flex" 
            flexDirection="column" 
            bgcolor="var(--layer-01)" 
            borderRadius="6px"
            border="solid 1px var(--border-subtle-01)"
            padding="16px" 
            rowGap="12px"
            boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
            >

            <Box display="flex" padding="8px 12px" alignItems="center">
                <CheckmarkFilled size="24" style={{color: 'var(--support-success)', marginRight: '16px'}} />
                <Box display="flex" flexDirection="column">
                    <span className="heading-07-compact">{t('modals.rescueCancellation.offer.discount.title')}</span>
                    <span className="body-02">{t('modals.rescueCancellation.offer.discount.text')}</span>
                </Box>
            </Box>
            <Box display="flex" padding="8px 12px" alignItems="center">
                <CheckmarkFilled size="24" style={{color: 'var(--support-success)', marginRight: '16px'}} />
                <Box display="flex" flexDirection="column">
                    <span className="heading-07-compact">{t('modals.rescueCancellation.offer.successCall.title')}</span>
                    <span className="body-02">{t('modals.rescueCancellation.offer.successCall.text')}</span>
                </Box>
            </Box>
            <Box display="flex" padding="8px 12px" alignItems="center">
                <CheckmarkFilled size="24" style={{color: 'var(--support-success)', marginRight: '16px'}} />
                <Box display="flex" flexDirection="column">
                    <span className="heading-07-compact">{t('modals.rescueCancellation.offer.accountManager.title')}</span>
                    <span className="body-02">{t('modals.rescueCancellation.offer.accountManager.text')}</span>
                </Box>
            </Box>
            
        </Box>
    )
}