import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FC, forwardRef, useRef, useState } from "react";

// Helpers
// import showToast from "@/_lib/toast";

// Services and interfaces
import { selectUser } from "@/store/auth";
import { selectCoach } from "@/store/coach";
import { useLogoutMutation } from "@/repositories/auth";

// Styles
import { ChevronDown } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import Avatar from "@/components/avatar";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography/";
import Menu, { Option } from "@/components/navigation/nav_menu";


const NavUser: FC = () => {
   
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const coach = useSelector(selectCoach);
    
    const [logout] = useLogoutMutation();
    const anchor  = useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const isSetup = location.pathname.includes('/setup');
    
    const options: Option[]  = [
        {
            name: 'Settings',
            disabled: isSetup,
            action: () => navigate('/settings/profile')
        },
        {
            name: 'Log Out',
            action: () => logout()
        },
    ];

    return (
        <>
            <UserSection 
                ref={anchor} 
                loading={!coach || !user}
                avatar={coach.avatar ?? ''}
                name={`${user?.first_name} ${user?.last_name}`}
                isOpen={open}
                onClick={() => setOpen(true)} 
            />
            <Menu
                anchorEl={anchor?.current}
                open={open}
                onClose={() => setOpen(false)}
                divider 
                options={options}
            />
        </>

    );
}

interface UserSectionProps {
    name: string;
    avatar: string;
    loading: boolean;
    isOpen: boolean;
    onClick?: () => void;
}

const UserSection = forwardRef<HTMLButtonElement, UserSectionProps>(({ 
    name, 
    isOpen, 
    loading, 
    onClick,
    avatar 
}, ref) => {
    return (
        <>
            <Box ref={ref} className="NavHeaderDropdown" sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={onClick}>
                <Avatar 
                    index={0}
                    isLoading={loading}
                    size='small' 
                    // sx={{ width: 24, height: 24, bgcolor: 'tertiary.main'}}
                    alt={name}
                    name={name}
                    src={avatar}
                    // src={`https://api.dicebear.com/8.x/pixel-art/svg?seed=${name}`}
                />
                <Typography mx={1.5} sx={{color: 'var(--nav-text-primary)'}}>
                    {loading ? (<Skeleton />) : (
                        <span className="body-02-compact">{name}</span>
                    )}
                </Typography>
                <Box sx={{
                    height: 16,
                    '& svg': {
                        transform: isOpen ? 'rotate(180deg)' : undefined,
                        transition: 'transform 0.1s linear'
                    }
                }}>
                    <ChevronDown />
                </Box>
            </Box>
        </>
    )
});

export default NavUser;