import { t } from "i18next";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { ILeadForm } from "@/interfaces/lead_form/lead_form";
import { useAddLeadFormMutation } from "@/repositories/lead_form";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import FormModal from "./form_modal";
import Button from "@/components/button";


interface _NoFormsProps {
    message?: string;
    disabled?: boolean;
}

const NoForms: FC<_NoFormsProps> = ({
    message = t('components.dataTable.noData.noLeadformsFound'),
    disabled = false
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null);
    const [addLeadForm, { isLoading: isAdding }] = useAddLeadFormMutation();

    const handleAdd = (data: ILeadForm) => {
        addLeadForm(data).unwrap().then((form) => {
            showToast({
                type: 'success',
                title: t('notifications.form.created.title'), 
                message: t('notifications.form.created.message', {name: form.name})
            });
            setOpen(null);
            navigate(`/prospects/lead-forms/${form.id}`);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" paddingBottom="24px">

            <Box height="32px" />
            <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{message}</span>
            <Box height="12px" />

            <Box display="flex"justifyContent="center" alignItems="center" columnGap="8px">
                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addForm')}
                    endIcon={<Add />}
                    minWidth={false}
                    disabled={disabled}
                    onClick={() => setOpen('add')}
                    />
                    {open === 'add' && <FormModal 
                        open={open === 'add'} 
                        onClose={() => setOpen(null)}
                        isLoading={isAdding}
                        onSave={handleAdd} 
                        />}
            </Box>

        </Box>
    )
}

export default NoForms;