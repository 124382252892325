import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

// Constants
import { prospectCloseOptions, prospectStageOptions } from "@/_constants/prospect";

// Services and interfaces
import { IProspectForm } from "@/interfaces/prospect/prospect_form";

// Components
import { Box } from "@mui/material";
import TextInput from "@/components/text_input";
import EmailInput from "@/components/email_input";
import PhoneInput from "@/components/phone_input";
import NumberInput from "@/components/number_input";
import SelectInput from "@/components/select_input";


interface _ProspectFormProps {
    readonly?: boolean;
}

const ProspectForm: FC<_ProspectFormProps> = ({
    readonly = false
}) => {

    const { t } = useTranslation();
    const { control } = useFormContext<IProspectForm>();

    return (
        <Box display="flex" flexDirection="column" rowGap="12px" width="670px">

            {/* Name */}
            <Box display="flex" columnGap="16px">

                <TextInput 
                    name="first_name"
                    control={control}
                    label={t('inputs.titles.firstName')}
                    placeHolder="John"
                    maxLength={40}
                    disabled={readonly}
                    />

                <TextInput 
                    name="last_name"
                    control={control}
                    label={t('inputs.titles.lastName')}
                    placeHolder="Smith"
                    maxLength={40}
                    disabled={readonly}
                    />
            </Box>

            {/* Email and Phone */}
            <Box display="flex" columnGap="16px">

                <EmailInput
                    name="email"
                    control={control}
                    placeHolder="john@1fit.com"
                    disabled={readonly}
                    />
                
                <PhoneInput
                    name="mobile"
                    control={control}
                    label={t('inputs.titles.phoneNumber')}
                    disabled={readonly}
                    />

            </Box>

            {/* CRM details */}
            <Box display="flex" columnGap="16px">

                <NumberInput
                    name="value"
                    control={control}
                    label={t('inputs.titles.dealValue')}
                    placeHolder="0"
                    disabled={readonly}
                    />

                <SelectInput
                    name="close_percentage"
                    control={control}
                    label={t('inputs.titles.closePercentage')}
                    items={prospectCloseOptions}
                    itemKey="id"
                    itemValue="value"
                    disabled={readonly}
                    />

                <SelectInput
                    name="stage"
                    control={control}
                    label={t('inputs.titles.dealStage')}
                    items={prospectStageOptions}
                    disabled={readonly}
                    />

            </Box>

            <TextInput
                name="notes"
                control={control}
                size="textarea"
                label={t('inputs.titles.notes')}
                placeHolder={t('inputs.placeholders.addProspectNotes')}
                minRows={4}
                maxLength={2000}
                disabled={readonly}
                />
            
        </Box>
    )
}

export default ProspectForm;