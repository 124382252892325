import { z } from "zod";
import { t } from "i18next";


export interface ITransferForm {
    id: string;
    coach: number | null;
}

export interface ITransferManyForm {
    ids: string[];
    coach: number | null;
}

export const clientTransferSchema = z.object({
    // An array of client IDs that must contain at least one element
    id: z.preprocess((val) => String(val), z.string().min(1, { message: t('inputs.errors.selectClients') })),
    coach: z.number({message: t('inputs.errors.selectTeamMember')})
})

export const prospectTransferSchema = z.object({
    // An array of prospect IDs that must contain at least one element
    id: z.preprocess((val) => String(val), z.string().min(1, { message: t('inputs.errors.selectProspects') })),
    coach: z.number({message: t('inputs.errors.selectTeamMember')})
})


export const clientTransferManySchema = z.object({
    // An array of client IDs that must contain at least one element
    ids: z.array(z.preprocess((val) => String(val), z.string())).nonempty({ message: t('inputs.errors.selectClients') }),
    coach: z.number({message: t('inputs.errors.selectTeamMember')})
})

export const prospectTransferManySchema = z.object({
    // An array of prospect IDs that must contain at least one element
    ids: z.array(z.preprocess((val) => String(val), z.string())).nonempty({ message: t('inputs.errors.selectProspects') }),
    coach: z.number({message: t('inputs.errors.selectTeamMember')})
})