import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import IPaymentMethod from "@/interfaces/payment_methods/payment_method";
import { useGetPaymentMethodsQuery } from "@/repositories/payment_methods";
import { useGetLatestInvoiceQuery, usePayInvoiceMutation } from "@/repositories/invoice";

// Styes
import { Add, ArrowRight } from "@carbon/icons-react";

// Components
import CardIcon, { CardKey } from "@/components/card_icon";
import Modal from "@/components/modal/modal";
import { Box, Card, Radio, RadioGroup } from "@mui/material";
import dayjs from "dayjs";
import Button from "@/components/button";
import AddPaymentModal from "./add_payment_modal";


interface _PayNowModalProps {
    open: boolean;
    onClose: () => void;
}

const PayNowModal: FC<_PayNowModalProps> = ({
    open,
    onClose
}) => {

    const { t } = useTranslation();

    const { data: invoice } = useGetLatestInvoiceQuery(undefined);
    const { data: paymentMethods } = useGetPaymentMethodsQuery(undefined);
    const [payInvoice, { isLoading }] = usePayInvoiceMutation();
    const [openAddPaymentMethod, setOpenAddPaymentMethod] = useState<boolean>(false);
    const [selectedCard, setSelectedCard] = useState<IPaymentMethod|undefined>(undefined);

    const submitDisabled = () => {
        if (!selectedCard) return false;
        return dayjs().isAfter(dayjs(`${selectedCard.exp_year}-${selectedCard.exp_month}-31`), 'month');
    
    }

    useEffect(() => {
        if (paymentMethods && !selectedCard) {
            setSelectedCard(paymentMethods.find((item) => item.default == true));
        }
    }, [paymentMethods, selectedCard])

    const handleClose = () => {
        setSelectedCard(undefined);
        onClose();
    }
    const submit = () => {
        if (!invoice || !selectedCard) return;

        const payload = {
            invoice_id: invoice.id,
            payment_method_id: selectedCard.id
        }

        payInvoice(payload).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.billing.paymentSubscriptionSuccessful.title'), message: t('notifications.billing.paymentSubscriptionSuccessful.message')})
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Modal  
            open={open}
            title={t('modals.payNow')}
            children={
                <Box display="flex" flexDirection="column" alignItems="center">
                    <RadioGroup
                        defaultValue="female"
                        name="radio-buttons-group"
                        >
                        {paymentMethods?.map((item: IPaymentMethod) => (
                            <_PaymentMethod 
                                key={item.id} 
                                paymentMethod={item} 
                                isSelected={selectedCard?.id === item.id}
                                onSelect={() => setSelectedCard(item)}
                                />
                        ))}
                    </RadioGroup>
                    <Button
                        kind="ghost"
                        size="small"
                        label={t('components.buttons.addPaymentMethod')}
                        endIcon={<Add />}
                        onClick={() => setOpenAddPaymentMethod(true)}
                        minWidth={false}
                        />
                    <AddPaymentModal open={openAddPaymentMethod} onClose={() => setOpenAddPaymentMethod(false)} />
                </Box>
            }
            action1={{
                label: t('components.buttons.continue'),
                icon: <ArrowRight />,
                onClick: submit,
                loading: isLoading,
                disabled: submitDisabled()
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: handleClose,
                disabled: isLoading
            }}
            />
    )
}

export default PayNowModal;

interface _PaymentMethodProps {
    paymentMethod: IPaymentMethod;
    isSelected: boolean;
    onSelect: () => void;
}

const _PaymentMethod: FC<_PaymentMethodProps> = ({
    paymentMethod,
    isSelected,
    onSelect
}) => {

    const { t } = useTranslation();
    const isExpired = dayjs().isAfter(dayjs(`${paymentMethod.exp_year}-${paymentMethod.exp_month}-31`), 'month');

    const cardStyles = {
        display: 'flex',
        alignItems: 'center',
        width: '384px',
        backgroundColor: 'var(--layer-01) !important',
        padding: '16px 32px', 
        border: isSelected && !isExpired ? 'solid 1px var(--border-inverse)' : 'solid 1px var(--border-subtle-01)', 
        borderRadius: '6px', marginBottom: '12px',
        color: isExpired ? 'var(--text-disabled)' : 'var(--text-primary)'
    }

    return (
        <Card 
            key={paymentMethod.id} 
            elevation={0}
            sx={cardStyles}
            >
            <Radio size="small" checked={isSelected} onChange={onSelect} disabled={isExpired} sx={{padding: 0}} />
            <Box width={32} />
            <CardIcon type={paymentMethod.brand as CardKey} size="medium" />
            <Box width={32} />
            <Box display="flex" flexDirection="column" rowGap="8px" width="100%">
                <span className="label-text-01">{t('billing.endsWith', {last4: paymentMethod.last_four})}</span>
                <span className="label-text-01">{t('billing.expirey', {expirey: `${paymentMethod.exp_month}/${paymentMethod.exp_year}`})}</span>
            </Box>
            {paymentMethod.default && <Box display="flex" flexGrow={1}>
                <span className="label-text-01" style={{color: 'var(--focus)'}}>{t('components.buttons.default')}</span>
            </Box>}
        </Card>
    )

}