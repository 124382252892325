import { FC } from "react"
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

// Services and interfaces
import IPhase from "@/interfaces/phase/phase";
import { IPhaseForm } from "@/interfaces/phase/phase_form";

// Components
import { Box } from "@mui/material";
import TextInput from "@/components/text_input";
import DateRange from "@/components/date_range";
import { AvailableDates } from "@/components/calendar";


interface FormProps {
    phase?: IPhase;
    availableDates?: AvailableDates[];
}

const PhaseForm: FC<FormProps> = ({
    phase,
    availableDates
}) => {

    const { t } = useTranslation()
    const { control } = useFormContext<IPhaseForm>();
    const projected = `(${t('components.phases.projected')})`

    return (
        <Box display="flex" flexDirection="column">

            <TextInput 
                name='name' 
                maxLength={40}
                control={control} 
                label={t('inputs.titles.phaseName')}
            />

            <DateRange
                name={[
                    phase?.start ? 'start' : 'expected_start', 
                    phase?.end ? 'end' : 'expected_end'
                ]} 
                label={[
                    `${t('inputs.titles.startDate')} ${phase?.start ? '' : projected}`, 
                    `${t('inputs.titles.endDate')} ${phase?.end ? '' : projected}`
                ]}
                control={control}
                availableDates={availableDates}
                startDisabled={!!phase?.start}
                disabled={!!phase?.end}
            />
            
            <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>
                {t('inputs.info.indicativeDates')}
            </span>

            <Box height="16px" />
            
            <TextInput 
                name='description' 
                control={control} 
                size='textarea'
                label={t('inputs.titles.description')}
                minRows={8}
            />

        </Box>
    )
}

export default PhaseForm;