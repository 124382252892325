import { BaseSyntheticEvent, forwardRef, ReactNode, useState } from 'react';
import { Button as MuiButton, CircularProgress, ButtonProps, SxProps } from '@mui/material';

interface _ButtonProps {
    kind?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'danger' | 'danger-secondary' | 'danger-ghost';
    size?: 'small' | 'medium' | 'large';
    type?: 'button' | 'submit' | 'reset';
    label?: string;
    tabIndex?: number;
    children?: ReactNode;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    onClick?: ((e?: BaseSyntheticEvent) => (void | Promise<void>));
    disabled?: boolean;
    loading?: boolean|null;
    loadingText?: string;
    fullWidth?: boolean;
    minWidth?: boolean;
    sx?: ButtonProps['sx'];
}


const Button = forwardRef<HTMLButtonElement, _ButtonProps>(({ 
    kind = 'primary',
    size = 'large',
    type,
    label,
    tabIndex = -1, 
    children,
    startIcon,
    endIcon,
    onClick,
    disabled,
    loading: externalLoading,
    loadingText,
    fullWidth,
    minWidth = true,
    sx
}, ref) => {

    const classes = {
        'Button__primary': kind === 'primary',
        'Button__secondary-sub': kind === 'secondary',
        'Button__tertiary': kind === 'tertiary',
        'Button__ghost': kind === 'ghost',
        'Button__danger': kind === 'danger',
        'Button__danger-secondary': kind === 'danger-secondary',
        'Button__danger-ghost': kind === 'danger-ghost',
    }

    const validClasses = Object.entries(classes)
        .filter(([,v]) => !!v)
        .map(([k,]) => k)
        .join(' ')

    const buttonStyles: SxProps = {
        textTransform: 'none',
        width: fullWidth ? '100%' : 'auto',
        minWidth: minWidth ?  '160px' : 'auto',
        borderRadius: '4px',
        boxShadow: 'none',
    }

    const [isLoading, setIsLoading] = useState(false);
    // Effective loading state considering external and internal
    const loading = externalLoading !== undefined ? externalLoading : isLoading;

    const handleClick = async (e: BaseSyntheticEvent) => {
        e.stopPropagation();
        if (!onClick) return;
        setIsLoading(true);
        try {
            await onClick(e);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <MuiButton
            ref={ref}
            variant="contained"
            type={type}
            tabIndex={tabIndex}
            disableRipple
            onClick={handleClick}
            disabled={loading || disabled}  // Disable button during loading
            startIcon={startIcon}
            endIcon={endIcon}
            size={size}
            className={validClasses}
            sx={{...buttonStyles, ...sx}}
            >
            {loading ? 
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <span className="body-02-compact" style={{ marginRight: '1em' }}>{loadingText}</span>
                    <CircularProgress size="1.5em" style={{ justifyContent: 'center', margin: '0' }}/>
                </div> : 
                (label ? <span className="body-02-compact" style={{whiteSpace: 'nowrap'}}>{label}</span> : children)
            }
        </MuiButton>
    );
});

export default Button;