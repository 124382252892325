import baseApi from '@/repositories/base_api';

import { clientApi } from './client';
import { RootState } from '@/store/store';
import IClient from '@/interfaces/client/client';
import { IAPIResponse } from '@/interfaces/api/response';
import { IProspect } from '@/interfaces/prospect/prospect';
import { IProspectForm } from '@/interfaces/prospect/prospect_form';
import { IProspectConvertForm } from '@/interfaces/prospect/prospect_convert_form';
import { ITransferForm, ITransferManyForm } from '@/interfaces/transfer/transfer_form';


export const prospectApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getProspects: build.query<IProspect[], string|undefined>({
            query: (memberId) => ({ url: memberId ? `/prospects?member=${memberId}` : '/prospects', method: 'get' }),
            transformResponse: (response: IAPIResponse<IProspect[]>) => response.data.sort((a, b) => b.created_at.localeCompare(a.created_at)),
            providesTags: ['Prospects.list'],
        }),
        getProspect: build.query<IProspect, string>({
            query: (id) => ({ url: `/prospects/${id}`, method: 'get' }),
            transformResponse: (response: IAPIResponse<IProspect>) => response.data,
        }),
        addProspect: build.mutation<IProspect, IProspectForm>({
            query: (data) => ({ url: 'prospects', method: 'post', data }),
            transformResponse: (response: IAPIResponse<IProspect>) => response.data,
            async onQueryStarted(_, { dispatch, getState, queryFulfilled }) {
                const hasTeam = (getState() as RootState).team.id !== undefined;
                const memberId = (getState() as RootState).coach.id?.toString();
                queryFulfilled.then((response) =>
                dispatch(prospectApi.util.updateQueryData('getProspects', hasTeam ? memberId : undefined, prospects => {
                    prospects.push(response.data)
                }))).catch(() => {});
            },
        }),
        updateProspect: build.mutation<IProspect, {id: string, data: IProspectForm}>({
            query: ({id, data}) => ({ url: `/prospects/${id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<IProspect>) => response.data,
            async onQueryStarted(_, { dispatch, getState, queryFulfilled }) {
                const memberId = (getState() as RootState).team.selectedMember?.id.toString();
                queryFulfilled.then((response) =>
                dispatch(prospectApi.util.updateQueryData('getProspects', memberId, prospects => {
                    const index = prospects.findIndex((item) => item.id === response.data.id);
                    prospects.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteProspect: build.mutation<void, string>({
            query: (id) => ({ url: `/prospects/${id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(id, { dispatch, getState, queryFulfilled }) {
                const memberId = (getState() as RootState).team.selectedMember?.id.toString();
                queryFulfilled.then(() =>
                dispatch(prospectApi.util.updateQueryData('getProspects', memberId, prospects => {
                    return prospects.filter((item) => item.id !== id)
                }))).catch(() => {});
            },
        }),
        deleteProspects: build.mutation<void, string[]>({
            query: (ids) => ({ url: `/prospects/bulk-delete`, method: 'post', data: {ids: ids} }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(ids, { dispatch, getState, queryFulfilled }) {
                const memberId = (getState() as RootState).team.selectedMember?.id.toString();
                queryFulfilled.then(() =>
                dispatch(prospectApi.util.updateQueryData('getProspects', memberId, prospects => {
                    return prospects.filter((item) => !ids.includes(item.id))
                }))).catch(() => {});
            },
        }),
        transferProspect: build.mutation<void, ITransferForm>({
            query: (data) => ({ url: `/prospects/${data.id}/transfer`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            invalidatesTags: ['Prospects.list', 'LeadForm.response.list'],
        }),
        transferProspects: build.mutation<void, ITransferManyForm>({
            query: (data) => ({ url: `/prospects/bulk-transfer`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            invalidatesTags: ['Prospects.list', 'LeadForm.response.list'],
        }),
        convertProspect: build.mutation<IClient, {id: string, data: IProspectConvertForm}>({
            query: ({id, data}) => ({ url: `/prospects/${id}/convert`, method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<IClient>) => response.data,
            async onQueryStarted({ id }, { dispatch, getState, queryFulfilled }) {
                const memberId = (getState() as RootState).team.selectedMember?.id.toString();
                queryFulfilled.then((response) => {
                    dispatch(prospectApi.util.updateQueryData('getProspects', memberId, prospects => {
                        return prospects.filter((item) => item.id !== id);
                    }));
                    dispatch(clientApi.util.updateQueryData('getClients', memberId, clients => {
                        clients.push(response.data);
                        clients.sort((a, b) => a.full_name.localeCompare(b.full_name));
                    }));
                }).catch(() => {});
            },
        }),
    }),
})

export const { 
    useGetProspectsQuery,
    useAddProspectMutation,
    useUpdateProspectMutation,
    useDeleteProspectMutation,
    useDeleteProspectsMutation,
    useTransferProspectMutation,
    useTransferProspectsMutation,
    useConvertProspectMutation,
} = prospectApi;

