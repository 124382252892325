import { FC } from "react";
import { useTranslation } from "react-i18next";

// Interfaces
import IInvoice from "@/interfaces/invoices/invoice";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";


interface _InvoiceBreakdownModalProps {
    open: boolean;
    onClose: () => void;
    invoice: IInvoice;
}


const InvoiceBreakdownModal: FC<_InvoiceBreakdownModalProps> = ({
    open,
    onClose,
    invoice
}) => {

    const { t } = useTranslation();
    
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t('modals.invoiceBreakdown.title')}
            showClose
            children={
                <Box display="flex" flexDirection="column" width="448px">
                    <span className="body-02-compact">{t('modals.invoiceBreakdown.currentClients', {total: invoice.quantity})}</span>
                    <Box height="8px" />
                    <Box display="flex" justifyContent="space-between">
                        <span className="body-02-compact">{t('modals.invoiceBreakdown.lineItem', {cost: '£3', total: invoice.quantity})}</span>
                        <span className="body-02-compact">{`£${invoice.total / 100}`}</span>
                    </Box>
                    <Box height="32px" />
                    <Box display="flex" justifyContent="space-between">
                        <span className="heading-07">{t('modals.invoiceBreakdown.total')}</span>
                        <span className="heading-07">{`£${invoice.total / 100}`}</span>
                    </Box>
                </Box>
            }
        />
    )
}

export default InvoiceBreakdownModal;