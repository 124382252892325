import { FC, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Calendar, { HighlightRange } from '.';
import IconButton from '../icon_button';
import { ChevronLeft, ChevronRight } from '@carbon/icons-react';

interface YearlyProps {
    range?: HighlightRange[]
}

const YearlyCalendar: FC<YearlyProps> = ({ 
    range 
}) => {

    const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('year'))
    
    const months: Dayjs[] = useMemo(() => {
        return new Array(12)
            .fill({})
            .map((_,i) => startDate.clone().set('month', i));
    }, [startDate])

    return (
        <Box>
            <Box display='flex' sx={{float: 'right'}}>
                <IconButton 
                    kind='ghost'
                    size='small'
                    icon={<ChevronLeft />}
                    onClick={() => setStartDate(startDate.subtract(1, 'year'))}
                />
                <Box sx={{
                    width: 120,
                    mx: 0.5,
                    display: 'grid',
                    bgcolor: 'white',
                    borderRadius: '3px',
                    border: '1px solid var(--border-subtle-01)'
                }}>
                    <span style={{alignSelf: 'center'}}>
                        {startDate.format('YYYY')}
                    </span> 
                </Box>
                <IconButton 
                    kind='ghost'
                    size='small'
                    icon={<ChevronRight />}
                    onClick={() => setStartDate(startDate.add(1, 'year'))}
                />
            </Box>

            <Grid container spacing={4}>
            {months.map(x => (
                <Grid key={x.format('MMMM YYYY')} item xs={3} sx={{textAlign: 'left'}}>
                    <Box p={1} className='body-02-compact text-primary'>{x.format('MMMM')}</Box>
                    <Calendar 
                        value={x}
                        range={range}
                        hideMonthSelect
                        hideToday
                        overrideDisabledTile
                        />
                </Grid>
            ))}
            </Grid>
        </Box>
    );
}

export default YearlyCalendar;