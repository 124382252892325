import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";

// Services and interfaces
import { INutritionBuilder } from "@/interfaces/nutrition/nutrition_builder";

// Styles
import { WarningFilled } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import NumberInput from "@/components/number_input";


interface _MacrosHeaderProps {
    selectedDay: number;
    hasMeals: boolean;
}
const MacrosHeader: FC<_MacrosHeaderProps> = ({
    selectedDay,
    hasMeals
}) => {

    const { t } = useTranslation();
    const { control, setValue, formState: { errors } } = useFormContext<INutritionBuilder>();

    const calories = useWatch({ control, name: `plan.${selectedDay}.calories` });
    const protein = useWatch({ control, name: `plan.${selectedDay}.protein` });
    const carbs = useWatch({ control, name: `plan.${selectedDay}.carbs` });
    const fat = useWatch({ control, name: `plan.${selectedDay}.fat` });

    useEffect(() => {
        const c = roundNumber((Number(protein ?? 0) * 4) + (Number(carbs ?? 0) * 4) + (Number(fat ?? 0) * 9), 0);
        setValue(`plan.${selectedDay}.calories`, c, { shouldDirty: true });
    }, [protein, carbs, fat, selectedDay, setValue]);

    return (
        <Box 
            display="grid"
            gridTemplateColumns={`repeat(5, 1fr)`}
            columnGap="48px"
            justifyContent="center" 
            alignItems="center" 
            padding="16px 24px"
            marginBottom="24px"
            borderRadius="6px"
            border="solid 1px var(--border-subtle-01)"
            sx={{bgcolor: 'var(--layer-01)'}}
            >
            <NumberInput
                name={`plan.${selectedDay}.protein`}
                control={control}
                label={t('components.nutritionBuilder.macros.protein')}
                placeHolder="0"
                gutter="0"
                hideErrorMessage
                disabled={hasMeals}
                />
            <NumberInput
                name={`plan.${selectedDay}.carbs`}
                control={control}
                label={t('components.nutritionBuilder.macros.carbs')}
                placeHolder="0"
                gutter="0"
                hideErrorMessage
                disabled={hasMeals}
                />
            <NumberInput
                name={`plan.${selectedDay}.fat`}
                control={control}
                label={t('components.nutritionBuilder.macros.fat')}
                placeHolder="0"
                gutter="0"
                hideErrorMessage
                disabled={hasMeals}
                />
            <NumberInput
                name={`plan.${selectedDay}.fibre`}
                control={control}
                label={t('components.nutritionBuilder.macros.fibre')}
                placeHolder="0"
                gutter="0"
                hideErrorMessage
                disabled={hasMeals}
                />
            <Box display="flex" flexDirection="column" alignItems="flex-start">
                <span className="label-text-02">{t('components.nutritionBuilder.macros.calories')}</span>
                <Box height="8px" />
                <Box display="flex" alignItems="center">
                    {errors?.plan?.[selectedDay]?.calories && <WarningFilled style={{color: 'var(--support-error)', marginRight: '8px'}} />}
                    <span className="heading-05">{`${calories.toLocaleString(undefined, {maximumFractionDigits: 0})} ${t('components.nutritionBuilder.macros.kcal')}`}</span>
                </Box>
            </Box>
            
        </Box>
    )
}

export default MacrosHeader;