import { useDispatch, useSelector } from 'react-redux';
import { selectClientId, selectMinimized, selectOpen, selectSubmissionId, setMinimized, setOpen, setCheckin } from '@/store/checkin_popup';


const useCheckinPopup = () => {

  const dispatch = useDispatch();

  const open = useSelector(selectOpen);
  const minimized = useSelector(selectMinimized);
  const client_id = useSelector(selectClientId);
  const submission_id = useSelector(selectSubmissionId);

  const handleSetOpen = (open: boolean) => {
    dispatch(setOpen(open));
    if (!open) {
      dispatch(setCheckin({ client_id: undefined, submission_id: undefined }));
      dispatch(setMinimized(false));
    }
  };

  const handleSetMinimized = (minimize: boolean) => {
    dispatch(setMinimized(minimize));
  };

  const handleSetCheckin = (client_id: number, submission_id: string) => {
    dispatch(setCheckin({ client_id: client_id, submission_id }));
  }

  return {
    handleSetOpen,
    handleSetMinimized,
    handleSetCheckin,
    open,
    minimized,
    client_id,
    submission_id
  };

};

export default useCheckinPopup;