import showToast from "@/_lib/toast";
import { t } from "i18next";


export const downloadFile = async (url: string, fileName: string) => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/octet-stream',
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const urlBlob = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = urlBlob;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();

        window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
        showToast({ type: 'error', title: t('notifications.file.downloadError.title'), message: t('notifications.file.downloadError.message')});
    }
};