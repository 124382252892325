import { ThemeOptions } from "@mui/material";
import typography from "./typography";

const theme: ThemeOptions = {
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        }
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: 'xl'
        }
      },
      MuiToolbar: {
        styleOverrides: {
          dense: {
            height: 40,
            minHeight: 40,
            paddingLeft: 16,
            paddingRight: 16,
            "@media (min-width: 0px)": { 
              paddingRight: 16, 
              paddingLeft: 16
            },
          }
        }
      }
    },
    // To override the default typography for each component
    typography: typography,
    // palette: colors
  };
  
export default theme;