import baseApi from '@/repositories/base_api';
import { IFood } from '@/interfaces/food/food';
import { IAPIResponse } from '@/interfaces/api/response';

export const edamamApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        searchFoods: build.query<IFood[], {query: string, loadMore?: boolean}>({
            query: ({query, loadMore = false}) => ({ url: `/nutrition/search?query=${query}${loadMore ? "&loadMore=true" : ""}`, method: 'get'}),
            transformResponse: (response: IAPIResponse<IFood[]>) => response.data,
            serializeQueryArgs: ({ queryArgs }) => queryArgs.query,
            merge: (cache, newItems) => { cache.push(...newItems) },
            forceRefetch: ({ currentArg, previousArg }) =>  currentArg?.query !== previousArg?.query,
        }),
        
    }),
})

export const { 
    useLazySearchFoodsQuery,
} = edamamApi;

