import { FC } from "react";

// Interface
import ButtonAction from "@/interfaces/components/button_action";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import IconButton from "@/components/icon_button";


export interface IDataTableHeaderProps {
    // Main title, show top left
    title?: string;
    // Optional subtitle shown below title
    subtitle?: string;
    // Optional Button component
    button?: ButtonAction;
    // Optional actions, justified end in order
    action1?: ButtonAction;
    action2?: ButtonAction;
    action3?: ButtonAction;
    // Loader used to skeletonize the header
    // isLoading?: boolean;
}

const DataTableHeader: FC<IDataTableHeaderProps> = ({
    title,
    subtitle,
    action1,
    action2,
    action3,
    button,
    // isLoading
}) => {

    return (
        <Box className="DataTable__header" display="flex" padding="0 16px 16px 16px">
            <Box display="flex" flexDirection="column" justifyContent="start" alignItems="flex-start">
                {title && <span className="heading-05">{title}</span>}
                {subtitle && <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{subtitle}</span>}
            </Box>
            <Box display="flex" flexGrow={1} justifyContent="flex-end" alignItems="flex-end" minHeight={40}>
                {action3 && <IconButton kind="ghost" icon={action3.icon} onClick={action3.onClick} />}
                {action2 && <IconButton kind="ghost" icon={action2.icon} onClick={action2.onClick} />}
                {action1 && <IconButton kind="ghost" icon={action1.icon} onClick={action1.onClick} />}
            </Box>

            {button && <Button 
                kind="primary"
                size="small"
                label={button.label}
                endIcon={button.icon}
                disabled={button.disabled}
                onClick={button.onClick}
                sx={{marginLeft: '16px'}}
                />}

        </Box>
    )
}

export default DataTableHeader;