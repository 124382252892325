import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";

// Services and interfaces
import { selectDataMetricsTraining } from "@/store/settings";
import { programmeWorkoutForm } from "@/interfaces/programme_workout/programme_workout_form";
import { IProgrammeWorkoutBuilder } from "@/interfaces/programme_workout/programme_workout_builder";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";
import Checkbox from "@/components/checkbox";
import TextInput from "@/components/text_input";


interface _WorkoutModalProps {
    open: boolean;
    onClose: () => void;
    workout?: IProgrammeWorkoutBuilder;
    isLoading?: boolean;
    onSave: (data: IProgrammeWorkoutBuilder) => void;
}

const WorkoutModal: FC<_WorkoutModalProps> = ({
    open,
    onClose,
    workout,
    isLoading,
    onSave,
}) => {

    const { t } = useTranslation();
    const coachSettings = useSelector(selectDataMetricsTraining);
    
    const formMethods = useForm<IProgrammeWorkoutBuilder>({
        resolver: zodResolver(programmeWorkoutForm),
        mode: 'onBlur',
        defaultValues: {
            name: workout?.name ?? '',
            settings: workout?.settings ?? {
                rir: coachSettings?.rir ?? false,
                rpe: coachSettings?.rpe ?? false,
                tempo: coachSettings?.tempo ?? false,
                set_type: coachSettings?.set_type ?? false,
                rest_time: coachSettings?.rest_time ?? false
            }
        }
    });

    const handleSave = (data: IProgrammeWorkoutBuilder) => {
        onSave(data);
    }
    
    return (
        <Modal
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : onClose}
            title={t(workout ? 'modals.programmeWorkout.title_edit' : 'modals.programmeWorkout.title_add')}
            children={
                <FormProvider {...formMethods}>
                    <WorkoutForm />
                </FormProvider>}
            action1={{
                label: t('components.buttons.save'),
                loading: isLoading,
                disabled: !formMethods.formState.isDirty,
                onClick: formMethods.handleSubmit(handleSave)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            />
    )
}

export default WorkoutModal


const WorkoutForm: FC = () => {

    const { t } = useTranslation();
    const { control, setValue } = useFormContext<IProgrammeWorkoutBuilder>();
    const settings = useWatch({ control, name: 'settings' });

    useEffect(() => {
        if (settings.rpe) return setValue('settings.rir', false, { shouldDirty: true });
    }, [settings.rpe])

    useEffect(() => {
        if (settings.rir) return setValue('settings.rpe', false, { shouldDirty: true });
    }, [settings.rir])

    return (
        <Box display="flex" flexDirection="column" rowGap="16px" minWidth="640px">
            
            <TextInput
                control={control}
                name="name"
                label={t('inputs.titles.workoutName')}
                maxLength={75}
                placeHolder={t('inputs.placeholders.workoutName')}
                />

            {/* Settings */}
            <Box>
                <span className="label-text-02">{t('inputs.titles.trainingData')}</span>
                <Box 
                    display="grid" 
                    gridTemplateColumns="1fr 1fr 1fr" 
                    columnGap="52px" padding="12px 16px"
                    marginTop="6px"
                    borderRadius="6px" 
                    border="solid 1px var(--border-subtle-01)"
                    sx={{bgcolor: 'var(--layer-01)'}}
                    >
                    <Checkbox
                        control={control}
                        name="settings.rpe"
                        label={t('components.checkbox.rpe')}
                        />
                    <Checkbox
                        control={control}
                        name="settings.rir"
                        label={t('components.checkbox.rir')}
                        />
                    <Checkbox
                        control={control}
                        name="settings.tempo"
                        label={t('components.checkbox.tempo')}
                        />
                    <Checkbox
                        control={control}
                        name="settings.set_type"
                        label={t('components.checkbox.setType')}
                        />
                    <Checkbox
                        control={control}
                        name="settings.rest_time"
                        label={t('components.checkbox.restTime')}
                        />
                </Box>
            </Box>

        </Box>
    )
}