import { Navigate } from "react-router-dom";

// Pages
import ErrorPage from "@/pages/error";
import AuthLayout from "@/layouts/auth";
import RegistrationDetailsPage from "@/pages/register/details";
import RegistrationPasswordPage from "@/pages/register/password";
import RegistrationBusinessPage from "@/pages/register/business";
import RegistrationSuccessPage from "@/pages/register/success";


export const registrationRoutes = {
  path: "register",
  element: <AuthLayout />,
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Navigate to="details" replace />,
    },
    {
      path: "details",
      element: <RegistrationDetailsPage />,
    },
    {
      path: "password",
      element: <RegistrationPasswordPage />,
    },
    {
      path: "business",
      element: <RegistrationBusinessPage />,
    },
    {
      path: "success",
      element: <RegistrationSuccessPage />
    },
  ],
}
