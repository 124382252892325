import { FC, ReactNode } from "react";

// Interface
import ButtonAction from "@/interfaces/components/button_action";

// Components
import Button from "@/components/button";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from "@mui/material";
import IconButton from "../icon_button";
import { Close } from "@carbon/icons-react";


interface _ModalProps {
    // Open and close logic
    open: boolean;
    onClose?: () => void;
    // Text items
    title: string|ReactNode;
    label?: string;
    text?: string|ReactNode;
    // Optional close icon in top right
    floatCancelLeft?: boolean;
    showClose?: boolean;
    // Children
    children?: ReactNode|ReactNode[];
    // Actions
    action1?: ButtonAction;
    action2?: ButtonAction
    action3?: ButtonAction
    cancel?: ButtonAction;
    // Optional SX prop to override styles
    sx?: DialogProps['sx'];
}

const Modal: FC<_ModalProps> = ({
    open = false,
    onClose,
    title,
    label,
    floatCancelLeft = false,
    showClose = false,
    text,
    children,
    action1,
    action2,
    action3,
    cancel,
    sx
}) => {

    const dialogStyles = {
        backgroundColor: 'var(--overlay)',
        '& .MuiPaper-root': {
            background: 'var(--background)',
            maxWidth: 'unset'
        },
    }

    const hasActions = action1 || action2 || action3 || cancel;

    return (
        <Dialog open={open} onClose={onClose} sx={{...dialogStyles, ...sx}}>

                <DialogTitle display="flex" justifyContent="space-between" alignItems="flex-start" sx={{padding: '16px'}}>
                    <Box display="flex" flexDirection="column">
                        {/* Optional label */}
                        {label && <span className="label-text-02">{label}</span>}

                        {/* Title */}
                        <span className="heading-05">{title}</span>
                    </Box>
                    {showClose && <IconButton
                        kind="ghost"
                        size="small"
                        icon={<Close />}
                        onClick={onClose}
                        sx={{height: '20px !important', width: '20px !important', padding: 0}}
                        />}
                </DialogTitle>

                {text && <DialogContentText sx={{whiteSpace: 'pre-wrap', padding: '0 16px 24px 16px'}}>
                    <span className="body-02-compact">{text}</span> 
                </DialogContentText>}

                {children && <DialogContent sx={{padding: '16px', overflowX: 'hidden'}}>
                    {children}
                </DialogContent>}

                {/* {actions} */}
                {hasActions && <DialogActions sx={{minHeight: '64px', justifyContent: 'flex-end', gap: '16px', padding: '0 24px 8px 24px'}}>
                    {cancel && 
                        <Button 
                            kind="tertiary" size="medium" 
                            label={cancel.label}
                            disabled={cancel.disabled}
                            loading={cancel.loading}
                            onClick={cancel.onClick}
                            sx={cancel.sx}
                            />}
                    {floatCancelLeft && <Box flexGrow={1} />}
                    {action3 && 
                        <Button 
                            kind="primary" size="medium"
                            label={action3.label}
                            endIcon={action3.icon}
                            disabled={action3.disabled}
                            loading={action3.loading}
                            onClick={action3.onClick}
                            sx={action3.sx}
                            />}
                    {action2 && 
                        <Button
                            kind={action2.kind ?? 'primary'} size="medium"
                            label={action2.label}
                            endIcon={action2.icon}
                            disabled={action2.disabled}
                            loading={action2.loading}
                            onClick={action2.onClick}
                            sx={action2.sx}
                            />}
                    {action1 && 
                        <Button 
                            kind={action1.kind ?? 'primary'} size="medium"
                            label={action1.label} 
                            endIcon={action1.icon}
                            disabled={action1.disabled}
                            loading={action1.loading}
                            onClick={action1.onClick}
                            sx={action1.sx}
                            />}
                </DialogActions>}

        </Dialog>
    )

}

export default Modal;