import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from 'react-hook-form';
import { FC, useContext, useEffect, useState } from 'react';

// Helpers
import showToast from '@/_lib/toast';

// Services and interfaces
import { selectSettings } from '@/store/settings';
import { ApiError } from '@/interfaces/api/error';
import { useUpdateSettingsMutation } from '@/repositories/coach';
import { ISettingsForm, settingsSchema } from "@/interfaces/coach/settings_form";

// Context
import SetupContext from '@/contexts/setup_context';

// Components
import SettingsForm from '@/pages/common/settings_form';
import CompleteSetupOverlay from '@/components/overlays/complete_setup';


const SettingsPage: FC = () => {

    const navigate = useNavigate();
    const settings = useSelector(selectSettings);
    const [open, setOpen] = useState<string|null>(null);
    const { profile, setSettings } = useContext(SetupContext);
    const [updateSettings, { isLoading }] = useUpdateSettingsMutation();

    useEffect(() => {
        if (!profile) navigate('/setup/profile');
    })

    const formMethods = useForm<ISettingsForm>({
        resolver: zodResolver(settingsSchema),
        mode: 'onBlur',
        defaultValues: {
            physique: settings.physique,
            training: settings.training,
            supplementation: settings.supplementation,
            activity: settings.activity,
            recovery: settings.recovery,
            health: settings.health,
            units: settings.units
        }
    });

    const submitForm = async (data: ISettingsForm) => {
        const payload = {
            site_settings: data.units,
            data_metrics: {
                physique: data.physique,
                training: data.training,
                supplementation: data.supplementation,
                activity: data.activity,
                recovery: data.recovery,
                health: data.health
            }
        }
        updateSettings(payload).unwrap().then(() => {
            setSettings(true);
            setOpen('complete');
            // navigate('/setup/checkin');
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        })
    }

    return (
        <>
            <FormProvider {...formMethods}>
                <SettingsForm 
                    isSetup
                    isLoading={isLoading} 
                    submitForm={formMethods.handleSubmit(submitForm)}
                    />
            </FormProvider>
            {open === 'complete' && <CompleteSetupOverlay
                    open={true}
                    />}
        </>
    )
}

export default SettingsPage;
