
import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";

// Helpers
import showToast from "@/_lib/toast";

// Services
import { useCreateTeamMutation } from "@/repositories/team";

// Interfaces
import { ApiError } from "@/interfaces/api/error";
import { ITeamForm, teamSchema } from "@/interfaces/team/team_form";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import Modal from "@/components/modal/modal";
import TeamForm from "@/pages/settings/team/team_form";


const NoTeam: FC = () => {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [createTeam, { isLoading }] = useCreateTeamMutation();

    const formMethods = useForm<ITeamForm>({
        resolver: zodResolver(teamSchema),
        mode: 'onBlur',
        defaultValues: {
            team_name: ''
        }
    });

    const handleClose = () => {
        setOpen(false);
        formMethods.reset();
    }

    const submit = (data: ITeamForm) => {
        createTeam(data).unwrap().then(() => {
            setOpen(false);
            showToast({type: 'success', title: t('notifications.team.created.title'), message: t('notifications.team.created.message')});
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        });
    }

    return (
        <Box 
            display="flex" flexDirection="column" flexGrow={1} alignItems="center" justifyContent="center" padding="24px 32px"
            border="1px solid var(--border-subtle-01)" borderRadius="6px"
            sx={{backgroundColor: "var(--layer-01)"}}>

            <span className="label-text-01">{t('pages.settings.team.noTeam')}</span>

            {/* Spacer */}
            <Box height="16px" />

            <Button 
                kind="primary"
                size="large" 
                label={t('components.buttons.createTeam')} 
                endIcon={<Add />} 
                onClick={() => setOpen(true)} 
                />
            <Modal
                open={open}
                onClose={formMethods.formState.isDirty ? undefined : handleClose}
                title={t('modals.createTeam.title')}
                text={t('modals.createTeam.text')}
                children={
                    <FormProvider {...formMethods}>
                        <TeamForm />
                    </FormProvider>}
                action1={{
                    label: t('components.buttons.createTeam'),
                    onClick: formMethods.handleSubmit(submit),
                    loading: isLoading,
                    disabled: !formMethods.formState.isValid || !formMethods.formState.isDirty
                }}
                cancel={{
                    label: t('components.buttons.cancel'),
                    onClick: handleClose,
                    disabled: isLoading
                }}
                sx={{'& .MuiPaper-root': {maxWidth: '480px', background: 'var(--background)'}}}
                />
        </Box>
    )
}

export default NoTeam;