import { t } from "i18next";
import { FC, useEffect } from "react";

// Constants
import { filterColumns } from "@/_constants/supplement";

// Services and interfaces
import { useGetSupplementsQuery } from "@/repositories/supplement";

// Components
import NoSupplements from "./no_supplements";
import DataTable from "@/components/datatable";
import useSupplementTableHeaders from "./headers";
import useBulkActions from "@/components/datatable/use_bulk_actions";
import useSupplementFilterItems from "@/pages/library/supplements/filter_items";


const SupplementTable: FC = () => {

    const headers = useSupplementTableHeaders();
    const { handleSetShowBulkActions } = useBulkActions();
    const { data: list, isLoading } = useGetSupplementsQuery();
    const filters = useSupplementFilterItems(list ?? []);

    useEffect(() => {
        handleSetShowBulkActions(true)
    }, []);

    return (
        <>
            <DataTable
                data={list}
                dataKey="id"
                columns={headers} 
                hideHeader
                localPagination
                bulkActions={{
                    hideCancel: true
                }}
                minRows={10}
                filterKeys={["name"]}
                filter={{
                    filterBar: {
                        filterItems: filters,
                        columns: filterColumns,
                    }
                }}
                noDataMessage={<NoSupplements message={t('components.supplementBuilder.table.noSupplements')} />}
                noDataFoundMessage={<NoSupplements />}
                disablePageLimit={true}
                isLoading={isLoading}
                />
        </>
    )
}

export default SupplementTable;