import { FC } from "react";
import { t } from "i18next";

// Services and interfaces
import { IPhaseMilestone } from "@/interfaces/phase_milestone/phase_milestone";

// Styles
import { Milestone as MilestoneIcon } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import PhaseMilestone from "./milestone";
import Typography from "@mui/material/Typography";
import PopoverTooltip from "@/components/phases/popover_tooltip";


interface TooltipProps {
    totalCount: number;
    phase_id: string;
    milestones: IPhaseMilestone[];
    onShowAll: () => void
}

const MilestoneTooltip: FC<TooltipProps> = ({
    totalCount,
    phase_id,
    milestones,
    onShowAll
}) => (
    <PopoverTooltip 
        icon={<MilestoneIcon />}
        label={t('components.buttons.showAll', {count: totalCount})}
        onClick={onShowAll}
    >
        <Box p={1} display='flex' flexDirection='column' gap={2} >
            <Typography>
                <span className="heading-07-compact">
                    {t('components.phases.milestones')}
                </span>
            </Typography>
            {milestones.map(n => (
                <PhaseMilestone key={n.id} phase_id={phase_id} milestone={n} />
            ))}
        </Box>
    </PopoverTooltip>
)

export default MilestoneTooltip;