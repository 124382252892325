import dayjs from 'dayjs';
import { FC, useState } from 'react';
import { useTranslation } from "react-i18next";

// Services and interfaces
import IPhase from '@/interfaces/phase/phase';

// Components
import PhasePanel from './phase/phase_panel';
import EndPhaseModal from "./phase/end_modal";
import ResetPhaseModal from './phase/reset_modal';
import DeletePhaseModal from "./phase/delete_modal";
import OverflowMenu from "@/components/overflow_menu";


interface _MenuProps {
    phase: IPhase;
}

const PhaseMenu: FC<_MenuProps> = ({ phase }) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<'edit'|'delete'|'reset'|'end'|null>(null)

    const startedTodayOrFuture = dayjs.utc(phase.start).isSame(dayjs.utc(), 'day') || dayjs.utc(phase.start).isAfter(dayjs.utc(), 'day')

    const isActive = !!phase.start && !phase.end 
    const menuOptions = [
        { name: t('components.menuItems.edit'), action: () => setOpen('edit') },
        ...isActive ? [{ name: t(`components.menuItems.${startedTodayOrFuture ? 'resetPhase' : 'endPhase'}`), action: () => setOpen(startedTodayOrFuture ? 'reset' : 'end') }] : []
    ];

    const closeForm = () => setOpen(null)

    return (
        <>
            <OverflowMenu
                options={menuOptions}
                deleteLabel={t('components.menuItems.delete')}
                onDelete={isActive ? undefined : () => setOpen('delete')}
                disabled={phase.readonly}
            />

            {open === 'edit' && <PhasePanel
                open={open === 'edit'}
                onClose={closeForm}
                phase={phase}
                />}
            {open === 'reset' && <ResetPhaseModal 
                open={open === 'reset'} 
                onClose={closeForm} 
                phase={phase} 
                />}
            {open === 'end' && <EndPhaseModal 
                open={open === 'end'} 
                onClose={closeForm} 
                phase={phase} 
                />}
            {open === 'delete' && <DeletePhaseModal 
                open={open === 'delete'} 
                onClose={closeForm} 
                phase={phase} 
                />}
        </>
    );
}

export default PhaseMenu;