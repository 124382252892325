import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { ICheckinTemplate } from "@/interfaces/checkin_template/checkin_template";
import { useDeleteCheckinTemplatesMutation, useGetCheckinTemplatesQuery } from "@/repositories/checkin_template";

// Compnents
import NoTemplates from "./no_templates";
import { Container, Box } from "@mui/material";
import DataTable from "@/components/datatable";
import useCheckinTemplateHeaders from "./table_headers";
import useBulkActions from "@/components/datatable/use_bulk_actions";
import DeleteTemplateModal from "@/pages/templates/components/delete_modal";


const PersonalTab: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null);
    const headers = useCheckinTemplateHeaders();

    const { data, isLoading } = useGetCheckinTemplatesQuery();
    const [deleteTemplates, { isLoading: isDeletingMany }] = useDeleteCheckinTemplatesMutation();
    const { selected, showBulkActions, handleSetSelected, handleSetShowBulkActions } = useBulkActions();
    const list = data?.filter((t) => t.type === 'coach')
        .sort((a, b) => {
            if (a.default && !b.default) return -1;
            if (!a.default && b.default) return 1;
            return a.name.localeCompare(b.name)
        });

    const handleDeleteMany = () => {
        deleteTemplates(selected).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.template.deleted.title', {count: selected.length})});
            setOpen(null);
            handleSetShowBulkActions(false);
            handleSetSelected([]);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleOpenTemplate = (template: ICheckinTemplate) => {
        if (showBulkActions) return;
        navigate(`/templates/checkins/${template.id}`);
    }

    return (
        <Box height='100%' overflow='auto'>
        <Container sx={{padding: '24px 0'}}>

            <DataTable
                data={list}
                dataKey="id"
                columns={headers} 
                hideHeader
                localPagination
                filterKeys={["name"]}
                filter={{showSelect: true}}
                bulkActions={{
                    disableSelect: (template: ICheckinTemplate) => template.default || template.shared || template.assigned_count > 0,
                    action1: {
                        label: t('components.buttons.delete'), 
                        onClick: () => setOpen('deleteMany'),
                    },
                }}
                noDataMessage={<NoTemplates message={t('components.dataTable.noData.noTemplates')} />}
                noDataFoundMessage={<NoTemplates />}
                isLoading={isLoading}
                rowClick={handleOpenTemplate}
                />
                {open === 'deleteMany' && <DeleteTemplateModal
                    open={open == 'deleteMany'}
                    onClose={()=> setOpen(null)}
                    name=''
                    count={selected.length}
                    isLoading={isDeletingMany}
                    onDelete={handleDeleteMany}
                />}

        </Container>
        </Box>
    );
};

export default PersonalTab;