import { ReactNode, forwardRef } from 'react';
import { IconButtonProps, IconButton as MuiIconButton } from '@mui/material';

interface _NavHeaderActionProps extends Omit<IconButtonProps, 'ref'> {
    kind?: 'nav' | 'primary';
    tabIndex?: number;
    icon?: ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    isActive?: boolean;
}

const NavHeaderAction = forwardRef<HTMLButtonElement, _NavHeaderActionProps>(({ 
    kind = 'nav',
    tabIndex = -1, 
    icon,
    onClick,
    disabled,
    isActive,
    className,
    ...rest
}, ref) => {

    const buttonStyles = {
        height: 'auto',
        width: '48px',
        borderRadius: '4px',
        boxShadow: 'none',
    }

    const combinedClassName = [
        className,
        kind == 'nav' ? 'NavHeaderAction' : 'HeaderAction',
        isActive ? `${kind == 'nav' ? 'Nav' : ''}HeaderAction--selected` : ''
    ].filter(Boolean).join(' ');

    return (
        <MuiIconButton
            ref={ref}
            tabIndex={tabIndex}
            onClick={onClick}
            disabled={disabled}
            className={combinedClassName}
            sx={buttonStyles}
            {...rest}
            >
            {icon}
        </MuiIconButton>
    );
});

export default NavHeaderAction;