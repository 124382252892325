import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FC, useEffect, useState } from "react";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectHasInvitation, selectHasTeam, selectInvitation } from "@/store/team";
import { useAcceptInvitationMutation, useDeclineInvitationMutation } from "@/repositories/team_invitation";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Button from "@/components/button";
import { Box, Container } from "@mui/material";
import { InlineNotification } from "@/components/notification/notification";
import FullScreenLoader from "@/components/overlays/fullscreen_loader";


const TeamInvitePage: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const hasTeam = useSelector(selectHasTeam);
    const hasInvitation = useSelector(selectHasInvitation);
    const invitation = useSelector(selectInvitation);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const [acceptInvitation, { isLoading: acceptLoading }] = useAcceptInvitationMutation();
    const [declineInvitation, { isLoading: declineLoading }] = useDeclineInvitationMutation();

    // Will be team but no sub, need to get or return sub
    useEffect(() => {
        if (hasTeam) {
            navigate('/setup/profile');
        } else if (!hasInvitation) {
            navigate('/setup/subscription');
        }
    }, [hasTeam, hasInvitation])

    const declineInvite = async () => {
        await declineInvitation().unwrap().then(() => {
            showToast({type: 'info', title: t('notifications.teamInvitation.declineSuccessTitle'), message: t('notifications.teamInvitation.declineSuccessMessage')});
            navigate('/setup/subscription');
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        });
    }

    const submitForm = async () => {
        await acceptInvitation().unwrap().then(() => {
            showToast({type: 'info', title: t('notifications.teamInvitation.acceptSuccessTitle'), message: t('notifications.teamInvitation.acceptSuccessMessage')});
            navigate('/setup/profile');
        }).catch((error: ApiError) => {
            console.log('error', error);
            showToast({type: 'error', apiError: error.type});
        });
    }
    
    return (
        <>
            <FullScreenLoader show={acceptLoading} loadingText={t('pages.settings.team.invitation.acceptingInvitation')} />
            <Container>
                <Box maxWidth="752px" display="flex" flexDirection="column">

                    {/* Notification card */}
                    <InlineNotification 
                        type={termsAccepted ? 'success' : 'info'}
                        title={t('pages.setup.teamInvitation.notificationTitle')}
                        message={t('pages.setup.teamInvitation.notificationSubtitle')}
                        action={termsAccepted ? undefined : {label: t('components.buttons.confirm'), onClick: () => setTermsAccepted(true)}}
                        spacingBottom="16px"
                        />

                    {/* Spacer */}
                    <Box height="16px"></Box>

                    <span className="heading-05" style={{textAlign: 'start'}}>{t('pages.setup.teamInvitation.congratulations', {team: invitation?.team.name, inviter: invitation?.invited_by})}</span>

                    {/* Spacer */}
                    <Box height="16px"></Box>

                    <span className="body-01" style={{textAlign: 'start', whiteSpace: 'pre-line'}}>{t('pages.setup.teamInvitation.invitationTerms')}</span>

                    {/* Spacer */}
                    <Box height="32px" />

                    {/* Actions */}
                    <Box display="flex" flexGrow={1} height="24px" width="100%" justifyContent="end">

                        <Button
                            kind="danger-secondary"
                            disabled={acceptLoading}
                            loading={declineLoading}
                            endIcon={<ArrowRight />}
                            onClick={declineInvite}
                            >
                            {t('components.buttons.decline')}
                        </Button>

                        {/* Spacer */}
                        <Box width="16px" />

                        <Button
                            disabled={!termsAccepted || declineLoading}
                            loading={acceptLoading}
                            endIcon={<ArrowRight />}
                            onClick={submitForm}
                            >
                            {t('components.buttons.continue')}
                        </Button>

                    </Box>

                </Box>
            </Container>
        </>
    )
}

export default TeamInvitePage;