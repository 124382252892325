import { z } from "zod";
import { t } from "i18next";
import ruleset, { addIssue, decimalRule } from "@/_helpers/ruleset";

export type MacroKeys = 'protein' | 'carbs' | 'fat' | 'fibre' | 'calories';
export interface INutritionBuilder {
    id: string;
    team_id: number;
    type: string;
    name: string;
    duration: number;
    notes?: string;
    tags: string[];
    plan: IDay[];
    draft: boolean;
    status: string;
    shared: boolean;
    created_at: string;
}

export interface IDay {
    uuid: string;
    name: string;
    calories: number;
    protein: number|string;
    carbs: number|string;
    fat: number|string;
    fibre: number|string;
    meals: IMeal[];
}

export interface IMeal {
    // [key: string]: string|number|IMealFood[];
    uuid: string;
    name: string;
    calories: number;
    protein: number|string;
    carbs: number|string;
    fat: number|string;
    fibre: number|string;
    options: IMealOption[];
    foods: IMealFood[];
}

export interface IMealOption {
    uuid: string;  
    calories: number;
    protein: number|string;
    carbs: number|string;
    fat: number|string;
    fibre: number|string;
    foods: IMealFood[];
}

export interface IMealFood {
    _id: string;
    name: string;
    calories: number;
    protein: number;
    carbs: number;
    fat: number;
    fibre: number;
    portion: number;
    macros: IMacros;
    unit: IUnit;
    units: IUnit[];
}

export interface IMacros {
    calories: number;
    protein: number;
    carbs: number;
    fat: number;
    fibre: number;
}

export interface IUnit {
    label: string;
    weight: number;
}

const unitSchema = z.object({
    label: ruleset.required,
    weight: z.number()
});

const macrosSchema = z.object({
    calories: z.number(),
    protein: z.number(),
    carbs: z.number(),
    fat: z.number(),
    fibre: z.number(),
});

// TODO add in lenth check on all fields
const foodSchema = z.array(z.object({
    _id: ruleset.required,
    name: ruleset.required,
    calories: z.number(),
    protein: z.number(),
    carbs: z.number(),
    fat: z.number(),
    fibre: z.number(),
    portion: z.number(),
    macros: macrosSchema,
    unit: unitSchema,
    units: z.array(unitSchema)
}));

const optionSchema = z.array(z.object({
    uuid: ruleset.uuid,
    calories: ruleset.requiredNumber,
    protein: decimalRule(1),
    carbs: decimalRule(1),
    fat: decimalRule(1),
    fibre: decimalRule(1),
    foods: foodSchema,
}));

const mealsSchema = z.array(z.object({
    uuid: ruleset.uuid,
    name: ruleset.mealName,
    calories: ruleset.requiredNumber,
    protein: decimalRule(1),
    carbs: decimalRule(1),
    fat: decimalRule(1),
    fibre: decimalRule(1),
    foods: foodSchema,
    options: optionSchema.optional(),
}));

export const daySchema = z.array(z.object({
    uuid: ruleset.uuid,
    name: ruleset.stringOptional,
    meals: mealsSchema,
    calories: ruleset.requiredNumber,
    protein: decimalRule(1),
    carbs: decimalRule(1),
    fat: decimalRule(1),
    fibre: decimalRule(1),
    updated_at: ruleset.stringOptional
}).superRefine((data, ctx) => {
    if (!data.name) addIssue(ctx, ['name'], t('components.nutritionBuilder.errors.noDayTitle'));
    if (data.name && data.name.length > 40) addIssue(ctx, ['name'], t('inputs.errors.titleTooLong', { length: 40 }));
    data.meals.forEach((meal, mealIndex) => {
        const path = ['meals', mealIndex];
        if (meal.options && meal.options.length > 0) {
            meal.options.forEach((option, optionIndex) => {
                if (!option.foods || option.foods.length == 0) addIssue(ctx, [...path, 'options', optionIndex, 'foods'], t('components.nutritionBuilder.errors.noFoods'));
            });
        }
        if (meal.options && meal.options.length == 1) addIssue(ctx, [...path], t('components.nutritionBuilder.errors.insufficientOptions'));
    });
})).min(1, t('components.nutritionBuilder.errors.noDays'))


export const nutritionFormSchema = z.object({
    name: ruleset.required,
    notes: ruleset.stringOptional,
})