import { z } from "zod";
import ruleset from "@/_helpers/ruleset";
import { ISection, ISupplementBuilder, supplementSectionsSchema } from "@/interfaces/supplement_plan/supplement_builder";


export interface ISupplementTemplateForm extends ISupplementBuilder {
    id: string;
    name: string;
    focus: string;
    notes: string;
    draft: boolean;
    sections: ISection[];
    created_at: string;
    updated_at: string;
}

export const supplementTemplateFormSchema = z.object({
    name: ruleset.required,
    focus: ruleset.stringOptional,
    notes: ruleset.stringOptional,
    draft: ruleset.boolean,
    sections: supplementSectionsSchema,
});