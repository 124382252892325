import { useDispatch, useSelector } from 'react-redux';
import { selectFilters, selectHiddenColumns, setFilters, setHiddenColumns } from '@/store/datatable';


const useFilterActions = () => {

  const dispatch = useDispatch();

  const filters = useSelector(selectFilters);
  const hiddenColumns = useSelector(selectHiddenColumns);

  const handleSetFilters = (filters: Record<string, string[]>) => {
    dispatch(setFilters(filters));
  }

  const handleSetHiddenColumns = (columns: string[]) => {
    dispatch(setHiddenColumns(columns));
  }

  return {
    filters,
    handleSetFilters,
    hiddenColumns,
    handleSetHiddenColumns
  };

};

export default useFilterActions;