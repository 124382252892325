import { FC, useRef, useState } from "react";

// Styles
import { View } from "@carbon/icons-react";

// Components
import Menu, { Options } from "@/components/menu";
import IconButton from "@/components/icon_button";


interface ColumnVisibilityProps {
    size?: 'small'|'medium'|'large';
    position?: { 
        horizontal: 'center'|'left'|'right'|number, 
        vertical: 'bottom'|'center'|'top'|number 
    }
    options: Options[];
}

const ColumnVisibility: FC<ColumnVisibilityProps> = ({
    size = 'small',
    position = { horizontal: 'right', vertical: 'bottom' },
    options,
}) => {

    const [open, setOpen] = useState<boolean>(false);
    const anchor = useRef<HTMLButtonElement>(null);

    return (
        <>
            <IconButton 
                ref={anchor} 
                kind="ghost" 
                size={size} 
                icon={<View />}
                onClick={() => setOpen(true)}
                sx={{boxShadow: open ? '0px 2px 6px 0px rgba(0, 0, 0, 0.30)' : null}}
            />
            <Menu
                size={size}
                open={open}
                onClose={() => setOpen(false)}
                anchorEl={anchor?.current}
                position={position}
                options={options}
                closeOnClick={false}
                disableDelete={true}
                sx={{'& .MuiMenu-list': {
                    borderRadius: '6px',
                    border: 'solid 1px var(--focus)',
                }}}
                />
        </>
    )

}

export default ColumnVisibility;