import dayjs from "dayjs";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";

// Services and interfaces
import { TotalValues } from "@/interfaces/components/totals_card";
import { useGetClientMealsQuery } from "@/repositories/client_data";
import { MacroKeys } from "@/interfaces/nutrition/nutrition_builder";
import { IClientNutrition } from "@/interfaces/client/client_nutrition";

// Components
import { Box } from "@mui/material";
import TotalsCard from "./totals_card";
import DataTable from "@/components/datatable";
import RightPanel from "@/components/right_panel";
import NutritionBreakdown from "./nutrition_breakdown";
import useNutritionDataTableHeaders from "./data_headers";


const NutritionDataTab: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const [open, setOpen] = useState<string|null>(null);
    const [selectedNutrition, setSelectedNutrition] = useState<IClientNutrition|null>(null);
    const headers = useNutritionDataTableHeaders();

    const { data, isLoading } = useGetClientMealsQuery(id!);

    const totalMacro = (macro: MacroKeys): TotalValues => {
        if (!data) return {value: 0, change: 0, daysLogged: 0};
        const now = dayjs();
        const sevenDaysAgo = now.subtract(7, 'day');
        const thisWeek = data.filter((item) => item.meals.length > 0 && dayjs(item.date).isAfter(sevenDaysAgo));

        const value = roundNumber(thisWeek.reduce((acc, item) => {
            return acc + roundNumber(item.meals.reduce((mealAcc, meal) => mealAcc + Number(meal[macro]), 0), 0);
        }, 0), 0);

        return {value: value, change: 0, daysLogged: thisWeek.length};
    }

    const handleRowClick = (nutrition: IClientNutrition) => {
        setSelectedNutrition(nutrition);
        setOpen('meals');
    }

    return (
        <>

            <Box display="flex" columnGap="16px">

                <TotalsCard metric="calories" totals={totalMacro('calories')} isLoading={isLoading} />
                <TotalsCard metric="protein" totals={totalMacro('protein')} isLoading={isLoading} />
                <TotalsCard metric="carbs" totals={totalMacro('carbs')} isLoading={isLoading} />
                <TotalsCard metric="fat" totals={totalMacro('fat')} isLoading={isLoading} />

            </Box>

            <DataTable
                data={data}
                dataKey="id"
                columns={headers} 
                hideHeader
                hideFilter
                localPagination
                noDataMessage={t('components.dataTable.noData.noLoggedData')}
                isLoading={isLoading}
                rowClick={handleRowClick}
                />
                {selectedNutrition && <RightPanel
                    open={open === 'meals'}
                    onClose={() => setOpen(null)}
                    title={t('rightPanel.nutrition.title')}
                    children={<NutritionBreakdown nutrition={selectedNutrition} />}
                    />}

        </>
    )
}

export default NutritionDataTab;