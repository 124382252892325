import baseApi from '@/repositories/base_api';
import { IUser } from '@/interfaces/user/user';
import { IAPIResponse } from '@/interfaces/api/response';
import { IUserResponse } from '@/interfaces/user/user_response';
import { IChangePasswordForm } from '@/interfaces/auth/change_password';

export const userApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getUser: build.query<IUserResponse, void>({
            query: () => ({ url: '/user', method: 'GET' }),
            transformResponse: (response: IAPIResponse<IUserResponse>) => response.data,
          }),
        // TODO: replace bject with actual model
        updateUser: build.mutation<IUser, object>({
            query: (data: object) => ({ url: '/user', method: 'POST', data: data}),
            transformResponse: (response: IAPIResponse<IUser>) => response.data,
        }),
        changePassword: build.mutation<void, IChangePasswordForm>({
            query: (data: IChangePasswordForm) => ({ url: '/user/change-password', method: 'POST', data: data}),
        })
    }),
})

export const { useUpdateUserMutation, useChangePasswordMutation } = userApi;