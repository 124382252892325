import { t } from "i18next";
import { FC, useState } from "react";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import SupplementModal from "@/pages/library/supplements/supplement_modal";


interface _NoSupplementsProps {
    message?: string;
}

const NoSupplements: FC<_NoSupplementsProps> = ({
    message = t('components.supplementBuilder.table.noSupplementsFound')
}) => {

    const [open, setOpen] = useState<boolean>(false);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" paddingBottom="12px">

            <Box height="32px" />
            <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{message}</span>
            <Box height="12px" />
            <Button
                kind="ghost"
                size="small"
                label={t('components.buttons.addSupplement')}
                endIcon={<Add />}
                onClick={() => setOpen(true)}
                />
            {open && <SupplementModal 
                open={open}
                onClose={() => setOpen(false)}
                />}

        </Box>
    )
}

export default NoSupplements;