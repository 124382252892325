import { FC } from "react";
import { Control } from "react-hook-form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import { AvailableDates } from "./calendar";
import DateInput, { DateInputProps } from "./date_input";


interface _DateRangeProps {
  name: string[];
  control: Control<any>;
  size?: 'small' | 'medium' | 'large';
  label?: string | string[];
  tabIndex?: number;
  availableDates?: AvailableDates[];
  startDisabled?: boolean;
  disabled?: boolean;
}

const DateRange: FC<_DateRangeProps> = (props) => {

  const disableStart = props.startDisabled || props.disabled
  const startProps: DateInputProps = {...props, name: props.name[0], label: props.label?.[0], availableDates: props.availableDates?.[0], disabled: disableStart}
  const endProps: DateInputProps = {...props, name: props.name[1], label: props.label?.[1], availableDates: props.availableDates?.[1]}

  return (
    <Box display='flex' alignItems='start'>
      <DateInput {...startProps} />
      <ArrowRight size={32} style={{margin: '22px 4px 0px'}} className='text-secondary' />
      <DateInput {...endProps} />
    </Box>
  );
}

export default DateRange;