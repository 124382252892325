import { t } from "i18next";
import { ISelectOption } from "@/interfaces/components/select_option";


export const supplementUnitOptions: ISelectOption<string>[] = [
    { label: 'g', value: 'g' },
    { label: 'mg', value: 'mg' },
    { label: 'mcg', value: 'mcg' },
    { label: 'iu', value: 'iu' },
    { label: 'ml', value: 'ml' },
    { label: 'Capsule', value: 'capsule' },
    { label: 'Tablet', value: 'tablet' },
    { label: 'Softgel', value: 'softgel' },
    { label: 'Scoop', value: 'scoop' },
    { label: 'Serving', value: 'serving' },
];

export const supplementPriorityOptions: ISelectOption<string>[] = [
    { label: 'Recommended', value: 'recommended' },
    { label: 'Optional', value: 'optional' },
];

export const supplementCategoryOptions: ISelectOption<string>[] = [
    { label: 'Performance', value: 'performance' },
    { label: 'Health', value: 'health' },
    { label: 'Recovery', value: 'recovery' },
    { label: 'Cognition', value: 'cognition' }
];

export const supplementLibraryTypeOptions: ISelectOption<string>[] = [
    { label: 'Custom', value: 'custom' },
    { label: 'Default', value: 'default' },
    { label: 'Team', value: 'team' },
];

export const filterColumns = [
    { label: t('components.dataTable.headers.brand'), field: 'brand' },
    { label: t('components.dataTable.headers.category'), field: 'category' },
    { label: t('components.dataTable.headers.priority'), field: 'priority' },
    { label: t('components.dataTable.headers.unit'), field: 'unit' },
    { label: t('components.dataTable.headers.library'), field: 'library' }
]