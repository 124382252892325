import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";
import appearance from "@/_constants/stripe_appearance";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useAddPaymentMethodMutation } from "@/repositories/payment_methods";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import { Box } from '@mui/material';
import Button from "@/components/button";
import Toggle from "@/components/toggle";
import { AddressMode, StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { AddressElement, Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"


interface PaymentMethodFormProps {
    onClose: () => void;
}

const PaymentMethodForm: FC<PaymentMethodFormProps> = ({onClose}) => {

    const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);
    const options: StripeElementsOptions = {
        mode: 'setup',
        currency: 'gbp',
        paymentMethodCreation: 'manual',
        appearance: appearance,
    }

    return (
        <Elements stripe={stripePromise} options={options}>
            <_Form onClose={onClose} />
        </Elements>
    )
}

const _Form: FC<PaymentMethodFormProps> = ({onClose}) => {

    const { t } = useTranslation();
    const elements = useElements();
    const stripe = useStripe();

    const [addPaymentMethod, { isLoading }] = useAddPaymentMethodMutation();
    const [loading, setLoading] = useState(false);
    const [addressComplete, setAddressComplete] = useState(false);
    const [paymentComplete, setPaymentComplete] = useState(false);

    const addressOptions = {
        mode: 'billing' as AddressMode
    }

    const { control, handleSubmit } = useForm<{default: boolean}>({
        mode: 'onBlur',
        defaultValues: {
            default: false
        }
    });

    const submit = async (data: {default: boolean}) => {
        setLoading(true);
        if (!elements || !stripe) return;

        const address = elements.getElement(AddressElement);
        const payment = elements.getElement(PaymentElement);

        if (!address || !payment) return;
        
        await elements.submit();

        const { paymentMethod, error } = await stripe.createPaymentMethod({elements: elements});
        if (error) {
            setLoading(false);
            return showToast({type: 'error', title: t('notifications.billing.paymentMethodCreationFailed.title'), message: t('notifications.billing.paymentMethodCreationFailed.message')});
        }

        addPaymentMethod({payment_method_id: paymentMethod.id, default: data.default}).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.billing.paymentMethodAdded.title'), message: t('notifications.billing.paymentMethodAdded.message')});
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        }).finally(() => {
            setLoading(false);
            onClose();
        });
            
    }

    return (
        <Box display="flex" flexDirection="column" flexGrow={1} minHeight="520px" justifyContent="space-between">
            <Box display="flex" flexGrow={1} width="100%" justifyContent="space-between" marginBottom="24px">

                {/* Address element */}
                <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start" maxWidth="284px">
                    <span className="heading-06" style={{color: 'var(--text-secondary)'}}>{t('pages.setup.subscription.form.billingAddress')}</span>
                    <Box height="24px" />
                    <AddressElement options={addressOptions} onChange={(e) => setAddressComplete(e.complete)} />
                </Box>

                {/* Spacer between elements */}
                <Box width="24px" />

                {/* Payment element */}
                <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-start" maxWidth="284px">

                    <span className="heading-06" style={{color: 'var(--text-secondary)'}}>{t('pages.setup.subscription.form.paymentDetails')}</span>
                    <Box height="24px" />

                    <PaymentElement onChange={(e) => setPaymentComplete(e.complete)} />

                    {/* Spacer - push to end */}
                    <Box display="flex" flexGrow={1} />

                    <Toggle
                        name="default" 
                        control={control} 
                        label={t('billing.setAsDefault')} 
                        sx={{marginBottom: '8px'}}
                        disabled={isLoading || loading}
                        />
                </Box>
                
            </Box>

            {/* Actions - hidden in modal and placed inside form due to stripe limitations */}
            <Box display="flex" width="100%" height="100%" sx={{justifyContent: 'flex-end', gap: '16px'}}>
  
                <Button 
                    kind="tertiary" size="medium" 
                    label={t('components.buttons.cancel')}
                    disabled={loading}
                    onClick={onClose} 
                    />

                <Button 
                    kind="primary" size="medium"
                    label={t('components.buttons.save')} 
                    endIcon={<ArrowRight />}
                    disabled={!addressComplete || !paymentComplete}
                    loading={loading || isLoading}
                    onClick={handleSubmit(submit)} 
                    />
            </Box>
        </Box> 
    )
}

export default PaymentMethodForm;