import { t } from "i18next";
import { FC, useEffect } from "react";

// Constants
import { filterColumns } from "@/_constants/exercise";

// Services and interfaces
import { useGetExercisesQuery } from "@/repositories/exercise";

// Components
import NoExercises from "./no_exercises";
import DataTable from "@/components/datatable";
import useExerciseTableHeaders from "./exercise_headers";
import useBulkActions from "@/components/datatable/use_bulk_actions";
import useExerciseFilterItems from "@/pages/library/exercises/filter_items";


interface _ExerciseTableProps {
    isReplace?: boolean;
}

const ExerciseTable: FC<_ExerciseTableProps> = ({isReplace = false}) => {

    const filters = useExerciseFilterItems();
    const headers = useExerciseTableHeaders();
    const { handleSetShowBulkActions } = useBulkActions();
    const { data: list, isLoading } = useGetExercisesQuery();

    useEffect(() => {
        if (!isReplace) handleSetShowBulkActions(true)
    }, []);

    return (
        <>
            <DataTable
                data={list}
                dataKey="id"
                columns={headers} 
                hideHeader
                localPagination
                bulkActions={{
                    hideCancel: true
                }}
                filter={{
                    filterBar: {
                        filterItems: filters,
                        columns: filterColumns,
                    }
                }}
                filterKeys={["name"]}
                minRows={10}
                singleSelect={isReplace}
                noDataMessage={<NoExercises message={t('components.programmeBuilder.table.noExercises')}/>}
                noDataFoundMessage={<NoExercises />}
                disablePageLimit={true}
                isLoading={isLoading}
            />
        </>
    )
}

export default ExerciseTable;