import { createSlice } from "@reduxjs/toolkit";

import { authApi } from "@/repositories/auth";
import { userApi } from "@/repositories/user";
import { IUser } from "@/interfaces/user/user";

interface AuthState {
    authenticated: boolean;
    user: IUser|null;
}

const initialState: AuthState = {
    authenticated: false,
    user: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state) => {
        state.authenticated = true;
    }).addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
        state.user = null;
        state.authenticated = false;
    }).addMatcher(authApi.endpoints.getUser.matchFulfilled, (state, { payload }) => {
        state.user = payload.user;
        state.authenticated = true;
    }).addMatcher(authApi.endpoints.getUser.matchRejected, (state) => {
        state.user = null;
        state.authenticated = false;
    }).addMatcher(userApi.endpoints.updateUser.matchFulfilled, (state, { payload }) => {
      state.user = payload;
  })
  },
  selectors: {
    selectIsAuthenticated: (state): boolean => state.authenticated,
    selectUser: (state): IUser | null => state.user,
    selectHasTeamOwnerRole: (state): boolean => state.user?.role.includes('team_owner') || false
  }
});

export const { 
    selectIsAuthenticated, 
    selectUser,
    selectHasTeamOwnerRole
} = authSlice.selectors;
export default authSlice.reducer;

//NOTES
/* 

App loads -> checks if the user is authed -> if not, redirect to login page
If the user is authed -> getUser from API -> if successful, set the user in the store
If the user is logging in -> set loader -> login -> get user -> set user in store -> set loader to false

*/