import { z } from 'zod';
import i18n from '@/i18n';
import { useState } from "react";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { zodResolver } from "@hookform/resolvers/zod";

// Helpers
import showToast from '@/_lib/toast';
import ruleset from "@/_helpers/ruleset";

// Styles
import { Refresh } from '@mui/icons-material';
import styles from '@/layouts/auth.module.scss';

// Services and interfaces
import { ApiError } from '@/interfaces/api/error';
import { useForgotPasswordMutation } from '@/repositories/auth';

// Components
import { Box } from '@mui/material';
import Button from '@/components/button';
import EmailInput from '@/components/email_input';

interface IForgotPassword {
    email: string;
}

const ForgotPasswordPage = () => {

    const { t } = useTranslation();

    const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

    // const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const { control, handleSubmit, setError, formState } = useForm<IForgotPassword>({
        resolver: zodResolver(z.object({
            email: ruleset.email,
        })),
        mode: 'onBlur',
        defaultValues: {
            email: '',
        }
    });

    const submitForm = async (form: IForgotPassword) => {
        await forgotPassword(form.email).unwrap().then(() => {
            // Route to clients page
            setDisabled(true);
            showToast({type: 'info', title: t('pages.forgotPassword.resetSentTitle'), message: t('pages.forgotPassword.resetSentSubtitle')});
        }).catch((error: ApiError) => {
            if (!i18n.exists(`api.errors.${error.type}`)) return showToast({type: 'error', apiError: error.type});
            setError('email', { type: 'api', message: t(`api.errors.${error.type}.message`)}, { shouldFocus: true });
        })
    }

    return (
        <div className={styles.form} style={{textAlign: 'left'}}>

            {/* title, subtitle and 40px spacer */}
            <span className="heading-05">{t('pages.forgotPassword.title')}</span>
            <span className="body-01" style={{color: 'var(--text-secondary)'}}>{t('pages.forgotPassword.subtitle')}</span>
            <Box height={40} />

            {/* Form */}
            <form style={{width: '100%'}}>
                <EmailInput
                    name='email'
                    control={control}
                    label={t('inputs.titles.emailAddress')} 
                    placeHolder={t('inputs.placeholders.emailAddress')} 
                    tabIndex={1}
                    />
                <Box height={40} />

                {/* Actions */}
                <div className={styles.actions}>
                    <div className={styles.actions__registration}>
                        <Link className="body-02" to={'/auth/login'}>{t('components.buttons.returnToLogin')}</Link>
                    </div>
                    <Box width={12} />
                    <Button 
                        type="submit"
                        label={t('components.buttons.requestReset')}
                        endIcon={<Refresh />}
                        loading={isLoading}
                        disabled={disabled || isLoading || !formState.isValid}
                        onClick={handleSubmit(submitForm)}
                        sx={{'@media (max-width: 768px)': {width: '100%'}}}
                    />
                </div>
            </form>
                
        </div>
    )
}

export default ForgotPasswordPage;

