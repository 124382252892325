import { FC, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { FieldErrors, useFieldArray, useFormContext, useWatch } from "react-hook-form";

// Interfaces
import { IExercise } from "@/interfaces/programme/programme_builder";
import { IProgrammeBuilder, ISet } from "@/interfaces/programme/programme_builder";
import { IProgrammeWorkoutBuilder } from "@/interfaces/programme_workout/programme_workout_builder";

// Styles
import { Add, DragVertical, Unlink, WarningFilled } from "@carbon/icons-react";

// Components
import AddModal from "./add_modal";
import { Box } from "@mui/material";
import Button from "@/components/button";
import useSetHeaders from "./set_headers";
import IconButton from "@/components/icon_button";
import OverflowMenu from "@/components/overflow_menu";
import BuilderTable from "@/components/builder/table";
import DragHandle from "@/components/sortable/drag_handle";
import DeleteModal from "@/components/builder/delete_modal";


interface _SupersetExerciseProps {
    id: string;
    basePath: `plan.${number}.exercises`|'exercises';
    exerciseIndex: number;
    supersetIndex: number;
    onReplace?: (exercise: IExercise, exerciseIndex: number) => void;
    onRemoveExercise: (index: number) => void;
    onUnlinkExercise: (index: number) => void;
}

const SupersetExercise: FC<_SupersetExerciseProps> = ({
    id,
    basePath,
    exerciseIndex,
    supersetIndex,
    onReplace,
    onRemoveExercise,
    onUnlinkExercise
}) => {

    const { t } = useTranslation();
    const { control, formState } = useFormContext();
    const [open, setOpen] = useState<string|null>(null);
    const exercise = useWatch({ control, name: `${basePath}.${exerciseIndex}` });
    const { fields: sets, append: appendSet, remove: removeSet } = useFieldArray({ control, name: `${basePath}.${exerciseIndex}.sets` });
    const headers = useSetHeaders({ basePath, exerciseIndex, supersetIndex, onRemoveSet: removeSet, onRemoveExercise });

    const programmeErrors = formState.errors as FieldErrors<IProgrammeBuilder>;
    const programmeWorkoutErrors = formState.errors as FieldErrors<IProgrammeWorkoutBuilder>;

    const hasErrors = () => {
        if (basePath === 'exercises') {
            return programmeWorkoutErrors.exercises?.[exerciseIndex];
        }
        // Get the workoutIndex from the basePath
        const workoutIndex = basePath.split('.')[1];
        return programmeErrors.plan?.[Number(workoutIndex)]?.exercises?.[exerciseIndex];
    }

    const handleAddSet = () => {
        if (!sets.length) {
            const newSet: ISet = {
                id: uuidv4(),
                rep_min: '',
                rep_max: '',
                distance_min: '',
                duration_min: '',
                calories_min: '',
                target_rpe: '',
                target_rir: '',
                target_rest_time: '',
                tempo_con: '',
                tempo_ecc: '',
                tempo_len: '',
                tempo_sho: ''
            }
            appendSet(newSet);
        } else {
            const lastSet = exercise.sets[exercise.sets.length - 1];
            lastSet.id = uuidv4();
            lastSet.notes = '';
            appendSet(lastSet);
        }
    }

    const handleReplace = (exercise: IExercise) => {
        if (!onReplace) return;
        onReplace(exercise, exerciseIndex);
        setOpen(null);
    }

    return (
        <>
            <Box display="flex" alignItems="center" padding="16px 16px 16px 56px" borderTop="solid 1px var(--border-subtle-01)">

                <span className="heading-06" style={{color: 'var(--text-placeholder)', marginRight: '12px'}}>{String.fromCharCode(65 + supersetIndex)}</span>
                <span className="body-01">{exercise.name}</span>
                {hasErrors() && <WarningFilled style={{color: 'var(--support-error)', marginLeft: '8px'}} />}

                <Box display="flex" flexGrow={1} />

                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addSet')}
                    endIcon={<Add />}
                    minWidth={false}
                    onClick={handleAddSet}
                    />

                <Box width="8px" />

                <IconButton
                    kind="ghost"
                    size="small"
                    icon={<Unlink />}
                    onClick={() => onUnlinkExercise(exerciseIndex)}
                    />

                <Box width="8px" />

                {/* Sort */}
                <DragHandle id={id}>
                    <Box display="flex" alignItems="center" width="32px" justifyContent="center" sx={{ cursor: 'grab' }}>
                        <DragVertical onClick={(e) => e.stopPropagation()} />
                    </Box>
                </DragHandle>

                <Box width="8px" />

                <OverflowMenu
                    options={[{name: t('components.menuItems.replace'), action: () => setOpen('replace')}]}
                    onDelete={() => setOpen('delete')}
                    />
                    {open === 'replace' && <AddModal
                        open={open === 'replace'}
                        onClose={() => setOpen(null)}
                        onReplace={handleReplace}
                        />}
                    {open === 'delete' && <DeleteModal
                        open={open === 'delete'}
                        onClose={() => setOpen(null)}
                        title={t('modals.deleteWorkoutExercise.title')}
                        text={t('modals.deleteWorkoutExercise.text')}
                        onDelete={() => onRemoveExercise(exerciseIndex)}
                        />}

            </Box>

            <Box padding="0px 24px 24px 48px">
                <BuilderTable
                    data={sets}
                    columns={headers}
                    noDataMessage={t('components.programmeBuilder.table.noSets')}
                    />
            </Box>
        
        </>

    )
}

export default SupersetExercise;