import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

// Constants
import { subscriptionCancellationReasonOptions } from "@/_constants/subscription";

// Components
import { Box } from "@mui/material";
import TextInput from "@/components/text_input";
import SelectInput from "@/components/select_input";


const CancelSubscriptionForm: FC = () => {

    const { t } = useTranslation();
    const { control } = useFormContext();

    return (
        <Box display="flex" flexDirection="column" rowGap="16px">

            <SelectInput
                name='reason'
                control={control}
                label={t('components.select.titles.primaryReason')}
                items={subscriptionCancellationReasonOptions}
                allowEmpty
            />
            <TextInput
                name='comments'
                control={control}
                size="textarea"
                label={t('inputs.titles.anyOtherComments')}
                minRows={5}
                />

        </Box>
    );
}

export default CancelSubscriptionForm;