import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectIsMember, selectTeam } from "@/store/team";
import { useDeleteTeamMutation } from "@/repositories/team";
import { ITeamMemberForm } from "@/interfaces/team/member_form";
import { useCreateTeamInvitationMutation } from "@/repositories/team_invitation";

// Styles
import { Add } from "@carbon/icons-react"

// Components
import TeamHeader from "./header";
import { Box } from "@mui/material";
import Button from "@/components/button";
import TeamMemberModal from "./member_modal";
import DataTable from "@/components/datatable";
import DeleteTeamModal from "./delete_team_modal";
import useTeamTableHeaders from "./table_headers";
import { ITeamForm } from "@/interfaces/team/team_form";


const ManageTeam: FC = () => {

    const { t } = useTranslation();
    const team = useSelector(selectTeam);
    const headers = useTeamTableHeaders();
    const isMember = useSelector(selectIsMember);
    const list = [...team.members ?? [], ...team.invitations ?? []];
    const [open, setOpen] = useState<string|null>(null);
    const [createInvite, { isLoading: inviteLoading}] = useCreateTeamInvitationMutation();
    const [deleteTeam, { isLoading: isDeleting }] = useDeleteTeamMutation();

    const handleInviteMember = (data: ITeamMemberForm) => {
        if (isMember) return;
        createInvite(data).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.teamInvitation.invite.title'), message: t('notifications.teamInvitation.invite.message')});
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        });
    }

    const handleDeleteTeam = (data: ITeamForm) => {
        deleteTeam().unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.team.deleted.title'), message: t('notifications.team.deleted.message')});
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type});
        });
    }

    return (
        <>
            <TeamHeader />

            {/* Spacer */}
            <Box height="40px" />

            {/* Table */}
            <DataTable 
                data={list} 
                noDataMessage={t('components.datatable.noData.teamMembers')}
                columns={headers} 
                header={{
                    title: t('pages.settings.team.teamMembers'),
                    button: {
                        label: t('components.buttons.addTeamMember'),
                        icon: <Add />,
                        disabled: isMember,
                        onClick: () => setOpen('add')
                    }
                }}
                hideFilter
                />
            {open == 'add' && <TeamMemberModal 
                open={open === 'add'} 
                onClose={() => setOpen(null)} 
                isLoading={inviteLoading} 
                onSubmit={handleInviteMember} 
                />}

            {/* Spacer */}
            <Box height="40px" />

            {/* Sharing options */}
            {/* <Resources /> */}

            <Box display="flex" justifyContent="center">
                <Button
                    kind="danger-ghost"
                    size="small"
                    label={t('components.buttons.deleteTeam')}
                    minWidth={false}
                    disabled={team?.members && team.members.length > 1}
                    onClick={() => setOpen('delete')}
                    />
                    {open == 'delete' && <DeleteTeamModal 
                        open={open === 'delete'} 
                        onClose={() => setOpen(null)}
                        team_name={team.name}
                        isLoading={isDeleting} 
                        onSubmit={handleDeleteTeam} 
                        />}

            </Box>
            

        </>
    )
}

export default ManageTeam