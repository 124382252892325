import { useTranslation } from "react-i18next";

// Helpers
import { capitalize } from "@/_helpers/text_functions";

// Interfaces
import { ISupplementTemplate } from "@/interfaces/supplement_template/supplement_template";

// Components
import TemplateMenu from "./supplement_menu";
import { ColDef } from "@/components/datatable";


const useTeamSupplementTemplateTableHeaders = (): ColDef<ISupplementTemplate>[] => {

    const { t } = useTranslation();

    const headers: ColDef<ISupplementTemplate>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '30%',
            sortable: true
        },
        {
            field: 'created_at',
            headerName: t('components.dataTable.headers.dateAdded'),
            width: '10%',
            render: (row: ISupplementTemplate) => new Date(row.created_at).toLocaleDateString()
        },
        {
            field: 'focus',
            headerName: t('components.dataTable.headers.focus'),
            width: '25%',
            render: (row: ISupplementTemplate) => row.focus ?? '-'
        },
        {
            field: 'shared_by',
            headerName: t('components.dataTable.headers.sharedBy'),
            width: '20%',
            render: (row: ISupplementTemplate) => capitalize(row.shared_by ?? '')
        },
        {
            field: 'actions',
            headerName: '',
            width: '10%',
            render: (row: ISupplementTemplate) => <TemplateMenu row={row} />
        }
    ];

    return headers;
}

export default useTeamSupplementTemplateTableHeaders;