import { FC, useEffect } from "react"

// Styles
import { View, ViewOff } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import FilterMenu from "./other_menu";
import Button from "@/components/button";
import ColumnVisibility from "./column_visibility";
import FilterSelect, { ISelectItem } from "./filter_select";
import useFilterActions from "@/components/datatable/use_filter_actions";


interface _FilterColumns {
    label: string;
    field: string;
    defaultHidden?: boolean;
}
export interface FilterItem {
    type: 'primary' | 'secondary';
    label: string;
    // Name of the field to filter (corresponds to headers)
    field: string;
    // List of items to filter by
    items: ISelectItem[];
    // Key of the item to filter by
    itemKey: string;
}

export interface FilterBarProps {
    filterItems: FilterItem[];
    columns: _FilterColumns[];
    showVisibility?: boolean;
}

const FilterBar: FC<FilterBarProps> = ({
    filterItems,
    columns,
    showVisibility = true
}) => {

    const primaryFilters = filterItems.filter((f) => f.type === 'primary');
    const secondaryFilters = filterItems.filter((f) => f.type === 'secondary');
    const { filters, handleSetFilters, hiddenColumns, handleSetHiddenColumns } = useFilterActions();

    const handleSetFilter = (field: string, value: any) => {
        const newFilters = { ...filters, [field]: value };
        handleSetFilters(newFilters);
    }

    const handleHideColumn = (column: string) => {
        let h = hiddenColumns;
        if (hiddenColumns.includes(column)) {
            h = hiddenColumns.filter((c) => c !== column);
            handleSetHiddenColumns(h);
        } else {
            h = [...hiddenColumns, column];
            handleSetHiddenColumns(h);
        }
    }

    const handleResetFilters = () => {
        const resetFilters = filterItems.reduce((acc, filter) => {
            acc[filter.field] = [];
            return acc;
          }, {} as Record<string, string[]>)
        handleSetFilters(resetFilters)
    }

    const handleFilterItemSelect = (filter: string, item: string) => {
        const currentSelections = filters[filter] || [];
        const updatedSelections = currentSelections.includes(item)
            ? currentSelections.filter((i: string) => i !== item)
            : [...currentSelections, item];
        
        handleSetFilters({
            ...filters,
            [filter]: updatedSelections
        });
    };

    useEffect(() => {
        handleSetFilters(filterItems.reduce((acc, filter) => {
            acc[filter.field] = [];
            return acc;
          }, {} as Record<string, string[]>));
        handleSetHiddenColumns(columns.filter((c) => c.defaultHidden).map((c) => c.field));
        return () => {
            handleSetFilters({});
            handleSetHiddenColumns([]);
        }
    }, [])

    return (
        <Box display="flex" alignItems="center">
            <Button
                kind="ghost"
                size="small"
                label="Reset Filters"
                minWidth={false}
                onClick={handleResetFilters}
                sx={{mr: '8px'}}
                />
            <span className="label-text-01" style={{color: 'var(--text-secondary)', marginRight: '8px'}}>Filter by:</span>

            {primaryFilters.map((filter) => 
                <FilterSelect
                    key={filter.label}
                    label={filter.label}
                    selectedItems={filters[filter.field]}
                    items={filter.items}
                    itemKey={filter.itemKey}
                    onChange={(v) => handleSetFilter(filter.field, v)}
                    />
            )}

            {/* Dropdown for additional filters */}
            {secondaryFilters.length > 0 &&
                <FilterMenu
                    closeOnClick={false}
                    options={secondaryFilters.map((filter) => ({
                        name: filter.label,
                        action: () => {},
                        options: filter.items.map((item) => ({
                            name: item.label,
                            action: () => handleFilterItemSelect(filter.field, item.value),
                            selected: filters[filter.field]?.includes(item.value)
                        }))
                    }))}
                    />}

            {/* Column visibility */}
            {showVisibility && <ColumnVisibility
                size="small"
                options={
                    columns.map((column) => ({
                        name: column.label,
                        action: () => handleHideColumn(column.field),
                        render: () => 
                            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                                <span className="body-02-compact">{column.label}</span>
                                {hiddenColumns.includes(column.field) ? <ViewOff /> : <View />}
                            </Box>
                    }))
                }
                />}

        </Box>
    )
}

export default FilterBar;