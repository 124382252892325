import { useTranslation } from "react-i18next";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";

// Interfaces
import { IMealFood } from "@/interfaces/nutrition/nutrition_builder";

// Components
import { ColDef } from "@/components/datatable";
import { Box } from "@mui/material";


const useFoodTableHeaders = (): ColDef<IMealFood>[] => {

    const { t } = useTranslation();
    const portionLabel = (label: string) => {
        switch (label) {
            case 'gram':
                return t('metrics.gram');
            case 'ounce':
                return t('metrics.oz');
            default:
                return `(${label})`;
        }
    }

    const headers: ColDef<IMealFood>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.food'),
            width: '45%',
            // TODO WTF is wit hthe width of this shit
            render: (row: IMealFood) => 
                <Box display="flex" flexDirection="column" justifyContent="center">
                    <span className="OneLine--ellipsis" style={{marginBottom: '2px'}}>{row.name}</span>
                    <Box display="flex">
                        <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{row.portion}</span>
                        <span className="label-text-02" style={{marginLeft: '4px', color: 'var(--text-placeholder)'}}>{portionLabel(row.unit.label.toLowerCase())}</span>
                    </Box>
                </Box>
        },
        {
            field: 'protein',
            headerName: t('components.dataTable.headers.protein'),
            width: '10%',
            render: (row: IMealFood) => 
                <Box display="flex">
                    <span>{roundNumber(row.protein, 1)}</span>
                    <span style={{color: 'var(--text-placeholder)', marginLeft: '4px'}}>{t('metrics.gram')}</span>
                </Box>
        },
        {
            field: 'carbs',
            headerName: t('components.dataTable.headers.carbs'),
            width: '10%',
            render: (row: IMealFood) => 
                <Box display="flex">
                    <span>{roundNumber(row.carbs, 1)}</span>
                    <span style={{color: 'var(--text-placeholder)', marginLeft: '4px'}}>{t('metrics.gram')}</span>
                </Box>
        },
        {
            field: 'fat',
            headerName: t('components.dataTable.headers.fat'),
            width: '10%',
            render: (row: IMealFood) => 
                <Box display="flex">
                    <span>{roundNumber(row.fat, 1)}</span>
                    <span style={{color: 'var(--text-placeholder)', marginLeft: '4px'}}>{t('metrics.gram')}</span>
                </Box>
        },
        {
            field: 'fibre',
            headerName: t('components.dataTable.headers.fibre'),
            width: '10%',
            render: (row: IMealFood) =>
                <Box display="flex">
                    <span>{roundNumber(row.fibre, 1)}</span>
                    <span style={{color: 'var(--text-placeholder)', marginLeft: '4px'}}>{t('metrics.gram')}</span>
                </Box>
        },
        {
            field: 'calories',
            headerName: t('components.dataTable.headers.kcal'),
            width: '15%',
            render: (row: IMealFood) => 
                <Box display="flex">
                    <span style={{whiteSpace: 'nowrap'}}>{roundNumber(row.calories, 0)}</span>
                    <span style={{color: 'var(--text-placeholder)', marginLeft: '4px'}}>{t('metrics.kcal')}</span>
                </Box>
        }
    ];

    return headers;
}

export default useFoodTableHeaders;