import FormsTab from "@/pages/prospects/form/forms_tab";
import ProspectsTab from "@/pages/prospects/prospect/prospects_tab";
import ProspectsPage from "@/pages/prospects/prospects_page";


export const prospectRoutes = {
  path: "prospects",
  element: <ProspectsPage />,
  children: [
    {
      path: "list",
      element: <ProspectsTab />
    },
    {
      path: "lead-forms",
      element: <FormsTab />
    },
  ]
}