import { t } from "i18next";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";

// Services and interfaces
import { INutritionBuilder } from "@/interfaces/nutrition/nutrition_builder";

// Styles
import { WarningFilled } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import OptionTabs from "./option_tabs";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import BuilderTable from "@/components/builder/table";
import useFoodHeaders, { FoodMacro } from "./food_headers";


interface _MealOptionProps {
    dayIndex: number;
    mealIndex: number;
}

const MealOptions: FC<_MealOptionProps> = ({
    dayIndex, 
    mealIndex 
}) => {

    const [optionIndex, setOptionIndex] = useState<number>(0);
    const { watch, setValue, formState: { errors } } = useFormContext<INutritionBuilder>();
    const hasErrors = errors?.plan?.[dayIndex]?.meals?.[mealIndex]?.options?.[optionIndex];
    const foods = watch(`plan.${dayIndex}.meals.${mealIndex}.options.${optionIndex}.foods`)

    const handleRemoveFoods = (idx: number) => {
        setValue(`plan.${dayIndex}.meals.${mealIndex}.options.${optionIndex}.foods`, foods.filter((_,i) => i != idx))
    }
    
    const headers = useFoodHeaders({ dayIndex, mealIndex, optionIndex: optionIndex, onRemoveFood: handleRemoveFoods });

    const mealsPrefix = ( 
        <TableRow sx={{'&:last-child td': {borderBottom: 'none'}}}>
            <TableCell colSpan={3}>
                {/* Empty cell for alignment */}
            </TableCell>
            <TableCell>
                <FoodMacro name={`plan.${dayIndex}.meals.${mealIndex}.options.${optionIndex}.protein`} />
            </TableCell>
            <TableCell>
                <FoodMacro name={`plan.${dayIndex}.meals.${mealIndex}.options.${optionIndex}.carbs`} />
            </TableCell>
            <TableCell>
                <FoodMacro name={`plan.${dayIndex}.meals.${mealIndex}.options.${optionIndex}.fat`} />
            </TableCell>
            <TableCell>
                <FoodMacro name={`plan.${dayIndex}.meals.${mealIndex}.options.${optionIndex}.fibre`} />
            </TableCell>
            <TableCell>
                <FoodMacro name={`plan.${dayIndex}.meals.${mealIndex}.options.${optionIndex}.calories`} />
            </TableCell>
            <TableCell>
                {/* Empty cell for alignment */}
            </TableCell>
        </TableRow>
    );

    return (
        <Box key={optionIndex}>
            <OptionTabs 
                current={optionIndex} 
                setCurrent={setOptionIndex} 
                dayIndex={dayIndex}
                mealIndex={mealIndex}
            />
            
            <Box m={2}>
                <BuilderTable
                    dataKey="_id"
                    key={foods.map(f => f._id).join("")}
                    suffixRow={foods.length > 0 ? mealsPrefix : null}
                    data={foods}
                    columns={headers}
                    noDataMessage={<_NoFoods hasErrors={!!hasErrors} />}
                />
            </Box>
        </Box>
    )
}

export default MealOptions;


interface _NoFoodsProps {
    hasErrors: boolean;
}
const _NoFoods: FC<_NoFoodsProps> = ({
    hasErrors
}) => {

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            {t(`components.nutritionBuilder.errors.${hasErrors ? 'noOptionFoodsError' : 'noOptionFoods'}`)}
            {hasErrors && <WarningFilled size="12px" style={{color: 'var(--support-error)', marginLeft: '8px'}} />}
        </Box>
    )
}