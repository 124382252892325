import { t } from "i18next";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useAddProgrammeWorkoutMutation } from "@/repositories/programme_workout";
import { IProgrammeWorkoutForm } from "@/interfaces/programme_workout/programme_workout_form";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";
import WorkoutModal from "./workout_modal";


interface _NoWorkoutsProps {
    message?: string;
    disabled?: boolean;
    hideAdd?: boolean;
}

const NoWorkouts: FC<_NoWorkoutsProps> = ({
    message = t('components.dataTable.noData.noWorkoutsFound'),
    disabled = false,
    hideAdd = false
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null);
    const [addWorkout, { isLoading: isSaving }] = useAddProgrammeWorkoutMutation();

    const handleAdd = (data: IProgrammeWorkoutForm) => {
        addWorkout(data).unwrap().then((workout) => {
            showToast({
                type: 'success', 
                title: t('notifications.catalogue.created.title', {type: t('components.catalogues.workoutCatalogue')}), 
                message: t('notifications.catalogue.created.message', {type: t('components.catalogues.workoutCatalogue'), name: workout.name})
            });
            setOpen(null);
            navigate(`/catalogues/workouts/${workout.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%" width="100%" paddingBottom="24px">

            <Box height="32px" />
            <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{message}</span>
            <Box height="12px" />

            <Box display="flex"justifyContent="center" alignItems="center" columnGap="8px">
                {!hideAdd && <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addWorkout')}
                    endIcon={<Add />}
                    minWidth={false}
                    disabled={disabled}
                    onClick={() => setOpen('add')}
                    />}
                    {open === 'add' && <WorkoutModal 
                        open={open === 'add'}
                        onClose={() => setOpen(null)}
                        isLoading={isSaving}
                        onSave={handleAdd}
                    />}
            </Box>

        </Box>
    )
}

export default NoWorkouts;