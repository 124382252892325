import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

// Interfaces
import { ISupplement } from "@/interfaces/supplement_plan/supplement_builder";
import { ISupplementTemplateForm } from "@/interfaces/supplement_template/supplement_template_form";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import AddModal from "./add_modal";
import Button from "@/components/button";
import { Box, Card, Skeleton } from "@mui/material";
import BuilderTable from "@/components/builder/table";
import useSupplementHeaders from "./supplement_headers";

interface _SectionProps {
    selectedSection: number;
    isLoading?: boolean;
}
const Section: FC<_SectionProps> = ({
    selectedSection,
    isLoading = false,
}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<string|null>(null);

    const { control } = useFormContext<ISupplementTemplateForm>();
    const section = useWatch({ control, name: `sections.${selectedSection}` });
    const { fields: supplements, append, remove } = useFieldArray({
        control,
        name: `sections.${selectedSection}.supplements`
    });

    const handleAddSupplements = (supplements: ISupplement[]) => {
        append(supplements);
        setOpen(null);
    }

    const handleRemoveSupplement = (index: number) => {
        remove(index);
    }

    const headers = useSupplementHeaders({ sectionIndex: selectedSection, onRemoveSupplement: handleRemoveSupplement });

    return (
        <Box overflow="hidden">

            <Box display="flex" width="100%" justifyContent="space-between" height="48px" alignItems="center">

                {isLoading ? <Skeleton variant="rounded" width={200} height={22} />:
                    <span className="heading-06-compact">{section.name ? section.name : t('components.supplementBuilder.menu.sectionPlaceholder')}</span>}

                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addSupplement')}
                    endIcon={<Add />}
                    onClick={() => setOpen('addSupplements')}
                    />
                {open === 'addSupplements' && <AddModal
                    open={open === 'addSupplements'}
                    onClose={() => setOpen(null)}
                    onAdd={handleAddSupplements}
                    />}
                    
            </Box>

            <Box height="calc(100% - 40px)" sx={{overflowY: 'auto', scrollbarWidth: 'none'}}>

                {supplements.length === 0 && <_NoSupplements />}

                {supplements.length > 0 && <Card elevation={0} sx={{borderRadius: '6px', border: 'solid 1px var(--border-subtle-01)', marginBottom: '24px'}}>

                    <Box padding="0px 24px 24px 24px">
                        <BuilderTable
                            data={supplements}
                            columns={headers}
                            />
                    </Box>

                </Card>}

            </Box>
        </Box>
    )
}

export default Section;

const _NoSupplements: FC = () => {

    const { t } = useTranslation();

    return (
        <Box 
            display="flex" 
            alignItems="center" 
            justifyContent="center" 
            flexGrow={1}
            padding="16px"
            minHeight="48px"
            borderRadius="6px" 
            border="solid 1px var(--border-subtle-01)"
            sx={{bgcolor: 'var(--layer-01)'}}
            >
            <span className="body-02">{t('components.supplementBuilder.section.noSupplements')}</span>
        </Box>
    )
}