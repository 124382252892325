import { t } from "i18next";
import { ISelectOption } from "@/interfaces/components/select_option";


export const prospectCloseOptions: ISelectOption<number>[] = [
    { id: 1, label: '20%', value: 20 },
    { id: 2, label: '40%', value: 40 },
    { id: 3, label: '60%', value: 60 },
    { id: 4, label: '80%', value: 80},
    { id: 5, label: '100%', value: 100},
];

export const prospectStageOptions: ISelectOption<string>[] = [
    { id: 1, label: t('components.select.options.prospectStage.added'), value: 'added' },
    { id: 2, label: t('components.select.options.prospectStage.callNotScheduled'), value: 'call_not_scheduled' },
    { id: 3, label: t('components.select.options.prospectStage.callScheduled'), value: 'call_scheduled' },
    { id: 4, label: t('components.select.options.prospectStage.callCompleted'), value: 'call_completed' },
    { id: 5, label: t('components.select.options.prospectStage.followup'), value: 'follow_up' },
    { id: 6, label: t('components.select.options.prospectStage.other'), value: 'other' },
];