import { t } from "i18next";
import { ISelectOption } from "@/interfaces/components/select_option";


export const subscriptionCancellationReasonOptions: ISelectOption<string>[] = [
    { id: 1, label: t('components.select.options.cancellationReason.budget'), value: 'budget' },
    { id: 2, label: t('components.select.options.cancellationReason.platform'), value: 'changed_platform' },
    { id: 3, label: t('components.select.options.cancellationReason.features'), value: 'features' },
    { id: 4, label: t('components.select.options.cancellationReason.complexity'), value: 'complexity' },
    { id: 5, label: t('components.select.options.cancellationReason.migration'), value: 'migration' },
    { id: 6, label: t('components.select.options.cancellationReason.stopped'), value: 'stopped_coaching' },
    { id: 7, label: t('components.select.options.cancellationReason.temporary'), value: 'temporary' },
    { id: 8, label: t('components.select.options.cancellationReason.other'), value: 'other' },
];