import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { ISupplement } from "@/interfaces/supplement/supplement";
import { ISupplementForm, supplementFormSchema } from "@/interfaces/supplement/supplement_form";

// Components
import SupplementForm from "./supplement_form";
import Modal from "@/components/modal/modal";
import { useAddSupplementMutation, useUpdateSupplementMutation } from "@/repositories/supplement";


interface SupplementModalProps {
    open: boolean;
    onClose: () => void;
    supplement?: ISupplement;
}

const SupplementModal: FC<SupplementModalProps> = ({
    open,
    onClose,
    supplement
}) => {

    const { t } = useTranslation();
    const [addSupplement, { isLoading: isAddingSupplement }] = useAddSupplementMutation();
    const [updateSupplement, { isLoading: isUpdatingSupplement }] = useUpdateSupplementMutation();

    const { control, reset, handleSubmit, formState: { isDirty } } = useForm<ISupplementForm>({
        resolver: zodResolver(supplementFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: supplement?.name ?? '',
            brand: supplement?.brand ?? '',
            category: supplement?.category ?? [],
            priority: supplement?.priority ?? '',
            unit: supplement?.unit ?? '',
            link: supplement?.link ?? '',
            notes: supplement?.notes ?? ''
        }
    });

    const handleClose = () => {
        reset();
        onClose();
    }

    const submitAddSupplement = (data: ISupplementForm) => {
        addSupplement(data).unwrap().then((exercise) => {
            showToast({type: 'success', title: t('notifications.supplement.created.title'), message: t('notifications.supplement.created.message', {name: exercise.name})});
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const submitUpdateSupplement = (data: ISupplementForm) => {
        if (!supplement) return;
        updateSupplement({id: supplement.id, data: data}).unwrap().then((exercise) => {
            showToast({type: 'success', title: t('notifications.supplement.updated.title'), message: t('notifications.supplement.updated.message', {name: exercise.name})});
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Modal
            open={open}
            onClose={isDirty ? undefined : onClose}
            title={supplement ? supplement.name : t('modals.addSupplement')}
            children={<SupplementForm control={control} type={supplement?.library} />}
            action1={{
                label: t('components.buttons.save'),
                disabled: !isDirty,
                loading: isAddingSupplement || isUpdatingSupplement,
                onClick: handleSubmit(supplement ? submitUpdateSupplement : submitAddSupplement),
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isAddingSupplement || isUpdatingSupplement,
                onClick: handleClose
            }}
            />
    )
}

export default SupplementModal;