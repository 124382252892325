import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Helpers
import { useNotifications } from "@/_lib/notification";

// Services and interfaces
import { useReadNotificationMutation } from '@/repositories/notifications';

// Styles
import { Restart } from '@carbon/icons-react';

// Components
import Modal from './modal/modal';


const AppUpdatedModal: FC = () => {

    const { t } = useTranslation();
    const path = useLocation();
    const [keepOpen, setKeepOpen] = useState<string>('');

    const { notifications, handleClickNotification } = useNotifications();
    const [read, { isLoading }] = useReadNotificationMutation();
    const notif = notifications.find(n => n.data.kind === 'service.updated');        

    const handleClick = () => {
        read(notif!.id).unwrap().then(() => {
            handleClickNotification(notif!)
            location.reload()
        })
    }

    return (
        <Modal
            showClose
            open={!!notif && keepOpen !== path.pathname}
            onClose={()=>setKeepOpen(path.pathname)}
            title={t('modals.applicationUpdated.title')}
            text={t('modals.applicationUpdated.text')}
            action1={{
                label: t('components.buttons.refresh'),
                icon: <Restart />,
                loading: isLoading,
                onClick: handleClick 
            }}
        />
    )
}

export default AppUpdatedModal;