import { z } from 'zod';
import ruleset from '@/_helpers/ruleset';

export interface IPhaseNoteForm {
    title: string,
    content: string,
    date: string
}


export const noteSchema = z.object({
    title: ruleset.required,
    content: ruleset.required,
    date: ruleset.date,
});