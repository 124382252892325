import { z } from "zod";
import { t } from "i18next";
import ruleset, { addIssue } from "@/_helpers/ruleset";
import { IProgrammeWorkoutBuilder } from "./programme_workout_builder";
import { exercisesSchema, programmeSettingsSchema } from "@/interfaces/programme/programme_builder";


export interface IProgrammeWorkout extends IProgrammeWorkoutBuilder {
    id: string;
    catalogue: string;
    readonly: boolean;
}

export const programmeWorkoutFormSchema = z.object({
    name: ruleset.required,
    draft: ruleset.boolean,
    exercises: exercisesSchema,
    settings: programmeSettingsSchema
}).superRefine((data, ctx) => {
    const settings = data.settings;
    if (!data.exercises || data.exercises.length == 0) addIssue(ctx, ['exercises'], t('components.programmeBuilder.errors.noExercises'));
    data.exercises.forEach((exercise, exerciseIndex) => {
        exercise.sets.forEach((set, setIndex) => {
            const metrics = exercise.metrics;
            const path = ['exercises', exerciseIndex, 'sets', setIndex];
            if (metrics.reps && set.rep_min === null) addIssue(ctx, [...path, 'rep_min']);
            if (metrics.duration && set.duration_min === null) addIssue(ctx, [...path, 'duration_min']);
            if (metrics.distance && set.distance_min === null) addIssue(ctx, [...path, 'distance_min']);
            if (metrics.calories && set.calories_min === null) addIssue(ctx, [...path, 'calories_min']);
            if (set.notes && set.notes.length > 1000) addIssue(ctx, [...path, 'notes']);
            if (!metrics.reps) return;
            if (settings.tempo && set.tempo_con === null) addIssue(ctx, [...path, 'tempo_con']);
            if (settings.tempo && set.tempo_ecc === null) addIssue(ctx, [...path, 'tempo_ecc']);
            if (settings.tempo && set.tempo_len === null) addIssue(ctx, [...path, 'tempo_len']);
            if (settings.tempo && set.tempo_sho === null) addIssue(ctx, [...path, 'tempo_sho']);
        });
    });
});