import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Helpers
import { formatDuration } from "@/_helpers/time_functions";
import { convertToPounds, formatNumber, roundNumber } from "@/_helpers/number_functions";

// Interfaces
import { selectSiteSettingsWeight } from "@/store/settings";
import { IExerciseMetrics, IExerciseSet } from "@/interfaces/workout/workout";

// Styles
import { CaretDown, CaretUp, Subtract } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import { ColDef } from "@/components/datatable";


interface _SetHeadersProps {
    metrics: IExerciseMetrics;
    historicSets?: IExerciseSet[];
}

const useSetHeaders = ({ 
    metrics,
    historicSets
}: _SetHeadersProps): ColDef<IExerciseSet>[] => {

    const { t } = useTranslation();
    const unit = useSelector(selectSiteSettingsWeight);
    const isKg = unit?.value === 'kg';

    const changeIcon = (current: number, previous: number) => {
        if (current === previous) return <Subtract size={20} />;
        if (current > previous) return <CaretUp size={20} color="var(--support-success)" />;
        return <CaretDown size={20} color="var(--support-error)" />;
    }

    const setChange = (current: number, previous: number) => {
        if (current === previous) return 0;
        if (current > previous) return current - previous;
        return current - previous;
    }

    const weightChange = (current: number, previous: number) => {
        const change = setChange(current, previous);
        return formatNumber(isKg ? roundNumber(change, 2) : convertToPounds(change));
    }

    const headers: ColDef<IExerciseSet>[] = [
        {
            field: 'set',
            headerName: t('components.dataTable.headers.set'),
            width: '10%',
            render: (_: IExerciseSet, i: number) => <span>{i+1}</span>
        },
        ...(metrics?.weight ? [{
            field: 'weight',
            headerName: t('components.dataTable.headers.weight'),
            width: '10%',
            render: (row: IExerciseSet, i: number) => 
                <Box display="flex" alignItems="center">
                    <span style={{marginRight: '8px'}}>{`${isKg ? roundNumber(row.weight ?? 0, 2) : convertToPounds(Number(row.weight))} ${unit?.value}`}</span>
                    {!!historicSets?.[i]?.weight && changeIcon(Number(row.weight), Number(historicSets[i].weight))}
                    {!!historicSets?.[i]?.weight && setChange(Number(row.weight), Number(historicSets[i].weight)) != 0 && (<span className="label-text-02">{`${weightChange(Number(row.weight), Number(historicSets[i].weight))} ${unit?.value}`}</span>)}
                </Box>
        }] : []),
        ...(metrics?.reps ? [{
            field: 'reps',
            headerName: t('components.dataTable.headers.reps'),
            width: '10%',
            render: (row: IExerciseSet, i: number) => 
                <Box display="flex" alignItems="center">
                    <span style={{marginRight: '8px'}}>{row.reps}</span>
                    {!!historicSets?.[i]?.reps && changeIcon(Number(row.reps), Number(historicSets[i].reps))}
                    {!!historicSets?.[i]?.reps && <span className="label-text-02">{formatNumber(setChange(Number(row.reps), Number(historicSets[i].reps)))}</span>}
                </Box>
        }] : []),
        ...(metrics?.distance ? [{
            field: 'distance',
            headerName: t('components.dataTable.headers.distance'),
            width: '10%',
            render: (row: IExerciseSet, i: number) => 
                <Box display="flex" alignItems="center">
                    <span style={{marginRight: '8px'}}>{`${row.distance} m`}</span>
                    {!!historicSets?.[i]?.distance && changeIcon(Number(row.distance), Number(historicSets[i].distance))}
                    {!!historicSets?.[i]?.distance && <span className="label-text-02">{formatNumber(setChange(Number(row.distance), Number(historicSets[i].distance)))}</span>}
                </Box>

        }] : []),
        ...(metrics?.duration ? [{
            field: 'duration',
            headerName: t('components.dataTable.headers.duration'),
            width: '10%',
            render: (row: IExerciseSet, i: number) =>
                <Box display="flex" alignItems="center">
                    <span style={{marginRight: '8px'}}>{row.duration ? formatDuration(row.duration) : ''}</span>
                    {!!historicSets?.[i]?.duration && changeIcon(Number(row.duration), Number(historicSets[i].duration))}
                    {!!historicSets?.[i]?.duration && <span className="label-text-02">{formatDuration(setChange(Number(row.duration), Number(historicSets[i].duration)))}</span>}
                </Box>

        }] : []),
        ...(metrics?.calories ? [{
            field: 'calories',
            headerName: t('components.dataTable.headers.calories'),
            width: '10%',
            render: (row: IExerciseSet, i: number) =>
                <Box display="flex" alignItems="center">
                    <span style={{marginRight: '8px'}}>{`${row.calories} ${t('metrics.kcal')}`}</span>
                    {!!historicSets?.[i]?.calories && changeIcon(Number(row.calories), Number(historicSets[i].calories))}
                    {!!historicSets?.[i]?.calories && <span className="label-text-02">{formatNumber(setChange(Number(row.calories), Number(historicSets[i].calories)))}</span>}
                </Box>

        }] : []),
        ...(metrics?.weight && metrics?.reps ? [{
            field: 'volume',
            headerName: t('components.dataTable.headers.volume'),
            width: '10%',
            render: (row: IExerciseSet) => <span>{`${roundNumber(isKg ? (Number(row.weight) * Number(row.reps)) : convertToPounds(Number(row.weight)) * Number(row.reps), 2)} ${unit?.value}`}</span>
        }] : []),
        {
            field: '',
            headerName: '',
            width: '1%',
            render: () => null
        }
    ];

    return headers;
}

export default useSetHeaders;