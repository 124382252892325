import { z } from "zod";
import { t } from "i18next";
import { IFoodUnit } from "./food";
import ruleset, { decimalRule } from "@/_helpers/ruleset";


export interface IFoodForm {
    name: string;
    macros: _IFoodMacros;
    unit: IFoodUnit;
    units: IFoodUnit[];
    custom_units: IFoodUnit[];
}

interface _IFoodMacros {
    protein: number|string;
    carbs: number|string;
    fat: number|string;
    fibre: number|string;
    calories: number;
}

const foodMacrosSchema = z.object({
    protein: decimalRule(2),
    carbs: decimalRule(2),
    fat: decimalRule(2),
    fibre: decimalRule(2),
    calories: decimalRule(2, false)
}, {message: t('inputs.errors.required')});

const foodUnitSchema = z.object({
    label: ruleset.required,
    weight: decimalRule(11, false),
    custom: ruleset.booleanOptional
});

const foodUnitsSchema = z.array(foodUnitSchema).nonempty({message: t('inputs.errors.defaultUnits')});

export const foodFormSchema = z.object({
    name: ruleset.required,
    macros: foodMacrosSchema,
    unit: ruleset.selectObject,
    units: foodUnitsSchema,
    custom_units: z.array(foodUnitSchema).nullable()
})

  


