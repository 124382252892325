import { folderApi } from './folder';
import baseApi from '@/repositories/base_api';
import { IFile } from '@/interfaces/file/file';
import { ILink } from '@/interfaces/link/link';
import { ILinkForm } from '@/interfaces/link/link_form';
import { IAPIResponse } from '@/interfaces/api/response';
import { MoveLinkForm } from '@/interfaces/link/move_link_form';


export const linkApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        addLink: build.mutation<ILink, {id: string, data: ILinkForm}>({
            query: ({id, data}) => ({ url: `folders/${id}/links`, method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<ILink>) => response.data,
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(folderApi.util.updateQueryData('getFolder', id, folder => {
                    folder.links?.push(response.data)
                    folder.links?.sort((a, b) => a.name.localeCompare(b.name));
                }))).catch(() => {});
            }
        }),
        updateLink: build.mutation<IFile, {id: string, link_id: string, data: ILinkForm}>({
            query: ({id, link_id, data}) => ({ url: `/folders/${id}/links/${link_id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<IFile>) => response.data,
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(folderApi.util.updateQueryData('getFolder', id, folder => {
                    const index = folder.links?.findIndex((item) => item.uuid === response.data.uuid);
                    if (index == -1 || index == undefined) return;
                    folder.links?.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        moveLink: build.mutation<IFile, {id: string, link_id: string, data: MoveLinkForm}>({
            query: ({id, link_id, data}) => ({ url: `folders/${id}/links/${link_id}/move`, method: 'post', data }),
            transformResponse: (response: IAPIResponse<IFile>) => response.data,
            async onQueryStarted({ id, link_id, data }, { dispatch, queryFulfilled }) {
                queryFulfilled.then((res) => {
                    dispatch(folderApi.util.updateQueryData('getFolder', id, folder => {
                        folder.links = folder.links?.filter((item) => item.uuid !== link_id)
                    }))
                    dispatch(folderApi.util.updateQueryData('getFolder', String(data.folder_id), folder => {
                        folder.links?.push(res.data)
                    }))
                })
                .catch(() => {});
            },
            invalidatesTags: ['Folder.list']
        }),
        deleteLink: build.mutation<void, {folder_id: string, link_id: string}>({
            query: ({folder_id, link_id}) => ({ url: `folders/${folder_id}/links/${link_id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted({ folder_id, link_id }, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(folderApi.util.updateQueryData('getFolder', folder_id, folder => {
                    folder.links = folder.links?.filter((item) => item.uuid !== link_id)
                }))).catch(() => {});
            },
        }),
        deleteLinks: build.mutation<void, {id: string, link_ids: string[]}>({
            query: ({id, link_ids}) => ({ url: `folders/${id}/links/bulk-delete`, method: 'post', data: {ids: link_ids} }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted({id, link_ids}, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(folderApi.util.updateQueryData('getFolder', id, folder => {
                    folder.links = folder.links?.filter((f) => !link_ids.includes(f.uuid))
                }))).catch(() => {});
            },
        }),
    }),
})

export const { 
    useAddLinkMutation,
    useUpdateLinkMutation,
    useDeleteLinkMutation,
    useMoveLinkMutation,
    useDeleteLinksMutation,
} = linkApi;

