import baseApi from '@/repositories/base_api';
import IInvoice from '@/interfaces/invoices/invoice';
import { IAPIResponse } from '@/interfaces/api/response';
import { IPayInvoice } from '@/interfaces/invoices/pay_invoice';


export const invoiceApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getInvoices: build.query<IInvoice[], void>({
            query: () => ({ url: '/invoices', method: 'get' }),
            transformResponse: (response: IAPIResponse<IInvoice[]>) => response.data,
            providesTags: ['Invoice.list']
        }),
        getLatestInvoice: build.query<IInvoice, void>({
            query: () => ({ url: '/invoices/latest', method: 'get' }),
            transformResponse: (response: IAPIResponse<IInvoice>) => response.data,
            providesTags: ['Invoice.latest']
        }),
        getUpcomingInvoice: build.query<IInvoice, void>({
            query: () => ({ url: '/invoices/upcoming', method: 'get' }),
            transformResponse: (response: IAPIResponse<IInvoice>) => response.data,
            providesTags: ['Invoice.upcoming'],
        }),
        payInvoice: build.mutation<IInvoice, IPayInvoice>({
            query: (data: IPayInvoice) => ({ url: `/invoices/${data.invoice_id}/pay`, method: 'post', data: data }),
            transformResponse: (response: IAPIResponse<IInvoice>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled.then(() => {
                    dispatch(baseApi.util.invalidateTags(['Subscription', 'Invoice.list', 'Invoice.latest', 'PaymentMethod.list']));
                }).catch(() => {});
            },
        })
    }),
})

export const { useGetInvoicesQuery, useGetLatestInvoiceQuery, useGetUpcomingInvoiceQuery, usePayInvoiceMutation } = invoiceApi;

