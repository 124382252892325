import { FC } from "react";

// Services and interfaces
import { IExercise } from "@/interfaces/programme/programme_builder";

// Styles
import { DragVertical, Link } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import useSort from "@/components/sortable/sort_hooks";
import DragHandle from "@/components/sortable/drag_handle";
import SortableItem from "@/components/sortable/sortable_item";
import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";


interface _ReorderFormProps {
    exercises: IExercise[];
    mappedExercises: IExercise[];
    onSetExercises: (exercises: IExercise[]) => void;
}

const ReorderForm: FC<_ReorderFormProps> = ({
    exercises,
    mappedExercises,
    onSetExercises
}) => {

    const { sensors, restrictToVerticalAxis } = useSort();

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (over !== null && active.id !== over.id) {
            const oldIndex = mappedExercises.findIndex(item => item.uuid === active.id);
            const newIndex = mappedExercises.findIndex(item => item.uuid === over.id);

            const updatedExercises = [...mappedExercises];
            const movedItem = updatedExercises[oldIndex];
            updatedExercises.splice(oldIndex, 1);
            updatedExercises.splice(newIndex, 0, movedItem);

            onSetExercises(updatedExercises);
        }
    }

    return (
        <Box>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToVerticalAxis]}>
                <SortableContext items={mappedExercises.map(exercise => exercise.uuid)} strategy={verticalListSortingStrategy}>

                <Box display="flex" flexDirection="column" rowGap="24px">
                {mappedExercises.map((exercise) => {

                    if (exercise.superset_id) {
                        const exerciseCount = exercises.filter(item => item.superset_id === exercise.superset_id).length;

                        return <SortableItem key={exercise.uuid} id={exercise.uuid}>
                            <Box display="flex" padding="16px" alignItems="center" border="solid 1px var(--border-subtle-01)" borderRadius="6px" sx={{backgroundColor: 'var(--layer-01)'}}>
                                
                                <DragHandle id={exercise.uuid}>
                                    <Box display="flex" alignItems="center" width="32px" justifyContent="center" marginRight="8px" sx={{ cursor: 'grab' }}>
                                        <DragVertical tabIndex={-1} onClick={(e) => e.stopPropagation()} />
                                    </Box>
                                </DragHandle>

                                <Box width="16px" />

                                <Box display="flex" minWidth="40px" minHeight="40px" borderRadius="4px" alignItems="center" justifyContent="center" sx={{backgroundColor: 'var(--button-secondary)', marginRight: '16px'}}>
                                    <Link style={{color: 'var(--icon-on-color)'}} />
                                </Box>

                                <Box display="flex" flexGrow={1} flexDirection="column">
                                    <span className="heading-06-compact">{exerciseCount > 2 ? 'Giant Set' : 'Superset'}</span>
                                    <span className="label-text-02">{exerciseCount} exercises</span>
                                </Box>

                            </Box>
                        </SortableItem>

                    }

                    return <SortableItem key={exercise.uuid} id={exercise.uuid}>
                        <Box display="flex" padding="16px" alignItems="center" border="solid 1px var(--border-subtle-01)" borderRadius="6px" sx={{backgroundColor: 'var(--layer-01)'}}>
                            
                            <DragHandle id={exercise.uuid}>
                                <Box display="flex" alignItems="center" width="32px" justifyContent="center" marginRight="8px" sx={{ cursor: 'grab' }}>
                                    <DragVertical tabIndex={-1} onClick={(e) => e.stopPropagation()} />
                                </Box>
                            </DragHandle>

                            <Box width="16px" />

                            <Box display="flex" flexGrow={1} flexDirection="column">
                                <span className="body-02">{exercise.name}</span>
                                <span className="label-text-02">{exercise.sets.length} sets</span>
                            </Box>

                        </Box>
                    </SortableItem>
                })}
                </Box>

                </SortableContext>
            </DndContext>

        </Box>
    )
}

export default ReorderForm;