import { z } from 'zod';
import { t } from 'i18next';
import ruleset, { addIssue, transformEmptyStringToNull } from '@/_helpers/ruleset';

export interface IClientForm {
    first_name: string,
    last_name: string,
    email: string,
    mobile: string,
    payment_amount: number|string,
    payment_schedule: string,
    checkin_template_id: string,
    checkin_days: number|number[],
    checkin_frequency: string,
    notes?: string,
}


export const clientSchema = z.object({
    first_name: ruleset.required,
    last_name: ruleset.required,
    email: ruleset.email,
    mobile: ruleset.phoneNumber,
    payment_amount: transformEmptyStringToNull(ruleset.numberOptional),
    payment_schedule: ruleset.selectOptional,
    checkin_template_id: ruleset.required,
    checkin_days: z.preprocess(
        (val) => (Array.isArray(val) ? val : [val]),
        ruleset.checkinDays
    ),
    checkin_frequency: ruleset.required,
    notes: ruleset.stringOptional,
}).superRefine((data, context) => {
    if (data.checkin_frequency == 'weekly') {
        if (!data.checkin_days || data.checkin_days.length === 0) {
            addIssue(context, ['checkin_days'], t('inputs.errors.checkinDays'));
        }
    } else if (data.checkin_frequency == 'biweekly') {
        if (!data.checkin_days || data.checkin_days.length === 0) {
            addIssue(context, ['checkin_days'], t('inputs.errors.checkinDay'));
        }
    }
});