import { FC } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

// Interfaces
import { ILeadFormForm, leadFormSchema } from "@/interfaces/lead_form/lead_form_form";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";
import TextInput from "@/components/text_input";


interface _FormModalProps {
    open: boolean;
    onClose: () => void;
    isLoading?: boolean;
    onSave: (data: ILeadFormForm) => void;
}

const FormModal: FC<_FormModalProps> = ({
    open,
    onClose,
    isLoading,
    onSave
}) => {

    const { t } = useTranslation();
    const formMethods = useForm<ILeadFormForm>({
        resolver: zodResolver(leadFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: '',
            published: false,
            form: [{
                uuid: uuidv4(),
                title: t('pages.prospects.leadForm.details'),
                required: true,
                questions: [{
                    uuid: uuidv4(),
                    title: t('pages.prospects.leadForm.firstName'),
                    type: 'text',
                    required: true,
                },
                {
                    uuid: uuidv4(),
                    title: t('pages.prospects.leadForm.lastName'),
                    type: 'text',
                    required: true,
                },
                {
                    uuid: uuidv4(),
                    title: t('pages.prospects.leadForm.email'),
                    type: 'email',
                    required: true,
                },
                {
                    uuid: uuidv4(),
                    title: t('pages.prospects.leadForm.phoneNumber'),
                    type: 'phone',
                    required: true,
                }]
            }]
        }
    });

    const handleSave = (data: ILeadFormForm) => {
        onSave(data);
    }
    
    return (
        <Modal
            open={open}
            onClose={formMethods.formState.isDirty ? undefined : onClose}
            title={t('modals.addForm')}
            children={
                <FormProvider {...formMethods}>
                    <Form />
                </FormProvider>}
            action1={{
                label: t('components.buttons.save'),
                loading: isLoading,
                disabled: !formMethods.formState.isDirty,
                onClick: formMethods.handleSubmit(handleSave)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            />
    )
}

export default FormModal


const Form: FC = () => {

    const { t } = useTranslation();
    const { control } = useFormContext<ILeadFormForm>();

    return (
        <Box display="flex" flexDirection="column" rowGap="16px" minWidth="640px">
            
            <TextInput
                control={control}
                name="name"
                label={t('inputs.titles.formName')}
                maxLength={75}
                placeHolder={t('inputs.placeholders.leadFormName')}
                />

        </Box>
    )
}