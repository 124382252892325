import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Constants
import { foodLibraryTypeOptions } from "@/_constants/food";

// Services and interfaces
import { selectHasTeam } from "@/store/team";

// Components
import { FilterItem } from "@/components/datatable/filter/filter_bar";


const useFoodFilterItems = (): FilterItem[] => {

    const { t } = useTranslation();
    const hasTeam = useSelector(selectHasTeam);

    const filters: FilterItem[] = [
        {
            type: 'primary',
            label: t('components.dataTable.headers.library'),
            field: 'library',
            items: hasTeam ? foodLibraryTypeOptions : foodLibraryTypeOptions.filter(item => item.value !== 'team'),
            itemKey: 'value'
        }
    ];

    return filters;
}

export default useFoodFilterItems;