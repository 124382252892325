import { useSensor, useSensors, PointerSensor, Modifier } from "@dnd-kit/core";

const useSort = () => {

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        })
    );

    const restrictToVerticalAxis: Modifier = ({ transform }) => {
        return {
            ...transform,
            x: 0,
        };
    };

    return { sensors, restrictToVerticalAxis };
};

export default useSort;
