import { FC, useState } from "react"
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { ApiError } from "@/interfaces/api/error";
import { INutritionBuilder } from "@/interfaces/nutrition/nutrition_builder";
import { useAddNutritionTemplateMutation } from "@/repositories/nutrition_template";
import { INutritionTemplateForm } from "@/interfaces/nutrition_template/nutrition_template_form";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import PersonalTab from "./nutrition/personal_tab";
import SecondaryToolbar from "@/components/secondary_toolbar";
import NutritionModal from "@/components/nutrition_builder/nutrition_modal";


const NutritionTemplateListPage: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null);
    const hasTeam = useSelector(selectHasTeam);
    const [addNutritionPlan, { isLoading }] = useAddNutritionTemplateMutation();

    const tabs = [
        {
            label: t('tabs.personal'),
            link: '/templates/nutrition/personal',
            sx: {width: '90px'}
        },
        {
            label: t('tabs.team'),
            link: '/templates/nutrition/team',
            sx: {width: '90px'}
        }
    ]

    const handleAdd = (data: INutritionBuilder) => {
        addNutritionPlan(data as INutritionTemplateForm).unwrap().then((template) => {
            showToast({
                type: 'success', 
                title: t('notifications.template.created.title'), 
                message: t('notifications.template.created.message', {name: template.name})
            });
            setOpen(null);
            navigate(`/templates/nutrition/${template.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>
            <SecondaryToolbar 
                title={t('pages.templates.nutrition.title')}
                tabs={hasTeam ? tabs : []}
                action1={{
                    label: t('components.buttons.addNutritionPlan'),
                    icon: <Add />,   
                    onClick: () => setOpen('add')      
                }}
                />
                {open === 'add' && <NutritionModal
                    open={open === 'add'}
                    onClose={() => setOpen(null)}
                    isLoading={isLoading}
                    onSave={handleAdd}
                    />}

            {hasTeam && <Outlet />}
            {!hasTeam && <PersonalTab />}
            
        </Box>
    )
}

export default NutritionTemplateListPage;