import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldArrayWithId, useFieldArray, useFormContext, useWatch } from "react-hook-form";

// Interfaces
import { IExercise } from "@/interfaces/programme/programme_builder";
import { IProgrammeBuilder, ISet } from "@/interfaces/programme/programme_builder";
import { IProgrammeWorkout } from '@/interfaces/programme_workout/programme_workout';
import { IProgrammeWorkoutBuilder } from '@/interfaces/programme_workout/programme_workout_builder';

// Styles
import { Add, DragVertical, Link } from "@carbon/icons-react";

// Components
import Exercise from "./exercise";
import Superset from "./superset";
import AddModal from "./add_modal";
import Button from "@/components/button";
import WorkoutModal from './workout_modal';
import ReorderPanel from './reorder_panel';
import { Box, Skeleton } from "@mui/material";
import IconButton from '@/components/icon_button';


interface _ExercisesProps {
    basePath: `plan.${number}.exercises`|'exercises';
    workoutName: string;
    isLoading?: boolean;
}

const Exercises: FC<_ExercisesProps> = ({
    basePath,
    workoutName,
    isLoading = false
}) => {

    const { t } = useTranslation();
    const processedSupersetIds = new Set();
    const [open, setOpen] = useState<string|null>(null);
    const [expanded, setExpanded] = useState<string[]>([]);

    const { control } = useFormContext<IProgrammeBuilder|IProgrammeWorkoutBuilder>();
    const exercises = useWatch({ control, name: basePath });
    const { fields, update, append, move, remove } = useFieldArray({
        control,
        name: basePath
    });

    const handleExpand = (uuid: string) => {
        if (expanded.includes(uuid)) {
            setExpanded(expanded.filter((id) => id !== uuid));
        } else {
            setExpanded([...expanded, uuid]);
        }
    }

    const handleAddExercises = (exercises: IExercise[]) => {
        append(exercises);
        setExpanded([...expanded, ...exercises.map((e) => e.uuid)]);
        setOpen(null);
    }

    const handleReplaceExercise = (exercise: IExercise, exerciseIndex: number) => {
        const ex = exercises[exerciseIndex];
        if (!ex) return;

        // If the exercises have the same metrics, update only the name and _id, otherwise update the entire exercise with a new single empty set
        if (_.isEqual(exercise.metrics, ex.metrics)) {
            update(exerciseIndex, { ...ex, _id: exercise._id, name: exercise.name });
        } else {
            const newSet: ISet = {
                id: uuidv4(),
                rep_min: '',
                rep_max: '',
                distance_min: '',
                duration_min: '',
                calories_min: '',
                target_rpe: '',
                target_rir: '',
                target_rest_time: '',
                tempo_con: '',
                tempo_ecc: '',
                tempo_len: '',
                tempo_sho: ''
            }
            if (ex.superset_id) exercise.superset_id = ex.superset_id;
            update(exerciseIndex, { ...exercise, uuid: ex.uuid, sets: [newSet] });
        }
    }

    const handleLinkToSuperset = (supersetExercises: FieldArrayWithId<IProgrammeBuilder, `plan.${number}.exercises`, "id">[]) => {
        const x = supersetExercises.at(-1);
        if (!x) return;
        const i = fields.findIndex((e) => e.id === x.id);
        handleLinkSuperset(i);
    }

    const handleLinkSuperset = (exerciseIndex: number) => {
        const exercise = exercises[exerciseIndex];
        const nextExercise = exercises[exerciseIndex + 1];

        if (exercise.superset_id) {

            update(exerciseIndex + 1, { ...nextExercise, superset_id: exercise.superset_id });

            if (nextExercise && nextExercise.superset_id && nextExercise.superset_id !== exercise.superset_id) {

                const oldSupersetExercises = fields.filter((ex, index) => 
                    ex.superset_id === nextExercise.superset_id && index !== exerciseIndex + 1);

                if (oldSupersetExercises.length === 1) {
                    update(fields.indexOf(oldSupersetExercises[0]), { ...oldSupersetExercises[0], superset_id: undefined });
                }
            }
        } else if (nextExercise && nextExercise.superset_id) {

            update(exerciseIndex, { ...exercise, superset_id: nextExercise.superset_id });

            const remainingSupersetExercises = fields.filter((ex, index) => 
                ex.superset_id === nextExercise.superset_id && index !== exerciseIndex);

            if (remainingSupersetExercises.length === 1) {
                update(fields.indexOf(remainingSupersetExercises[0]), { ...remainingSupersetExercises[0], superset_id: undefined });
            }
        } else {
            const newSupersetId = uuidv4();
            update(exerciseIndex, { ...exercise, superset_id: newSupersetId });
            update(exerciseIndex + 1, { ...nextExercise, superset_id: newSupersetId });
        }
    }

    const handleUnlinkExercise = (index: number) => {
        const superset_id = exercises[index].superset_id;
        const supersetExercises = exercises.filter((e) => e.superset_id === superset_id);

        if (supersetExercises.length == 2) {
            supersetExercises.forEach((e) => {
                update(fields.findIndex((ex) => ex.uuid === e.uuid), { ...e, superset_id: undefined });
            });
        } else if (supersetExercises.length > 2) {
            const i = fields.findIndex((e) => e.id === fields[index].id);
            if (i != -1) update(i, { ...fields[i], superset_id: undefined });
        }
    }

    const handleRemoveExercise = (index: number) => {
        remove(index);
        setExpanded(expanded.filter((id) => id !== fields[index].uuid));
    }

    return (
        <Box overflow="hidden">

            <Box display="flex" width="100%"  height="48px" alignItems="center">

            {isLoading ? <Skeleton variant="rounded" width={200} height={22} /> :
                <span className="heading-06-compact">{workoutName ? workoutName : t('components.programmeBuilder.menu.workoutPlaceholder')}</span>}

                <Box flexGrow={1} />

                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.reorder')}
                    endIcon={<DragVertical />}
                    minWidth={false}
                    disabled={fields.length === 0}
                    onClick={() => setOpen('reorder')}
                    />
                {open === 'reorder' && <ReorderPanel
                    open={open === 'reorder'}
                    onClose={() => setOpen(null)}
                    basePath={basePath}
                    exercises={exercises}
                    />}

                {/* Spacer */}
                <Box width="8px" />

                <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addExercise')}
                    endIcon={<Add />}
                    minWidth={false}
                    onClick={() => setOpen('addExercise')}
                    />
                {open === 'addExercise' && <AddModal
                    open={open === 'addExercise'}
                    onClose={() => setOpen(null)}
                    onAdd={handleAddExercises}
                    />}
                    
            </Box>

            <Box height="calc(100% - 40px)" sx={{overflowY: 'auto', scrollbarWidth: 'none'}}>

                {fields.length === 0 && <_NoExercises basePath={basePath} />}
                        
                {fields.map((exercise, exerciseIndex) => {
                    if (exercise.superset_id) {
                        if (processedSupersetIds.has(exercise.superset_id)) return null;
                        
                        processedSupersetIds.add(exercise.superset_id);
                        const supersetExercises = fields.filter(ex => ex.superset_id === exercise.superset_id);
                    
                        return (
                            <Box key={exercise.superset_id}>
                                <Superset
                                    key={exercise.superset_id}
                                    basePath={basePath}
                                    exercises={fields} 
                                    supersetExercises={supersetExercises} 
                                    onReplace={handleReplaceExercise}
                                    onUnlinkExercise={handleUnlinkExercise}
                                    onMove={move}
                                    onRemove={remove}
                                    />

                                <Box height="32px">
                                    {supersetExercises.at(-1) !== fields.at(-1) && 
                                        <IconButton
                                            kind="ghost"
                                            size="small"
                                            icon={<Link />}
                                            onClick={() => handleLinkToSuperset(supersetExercises)}
                                            />}
                                </Box>
                            </Box>
                        );
                    }

                    return (
                        <Box key={exercise.id}>
                            <Exercise
                                basePath={`${basePath}`}
                                exerciseIndex={exerciseIndex}
                                expanded={expanded.includes(exercise.uuid)}
                                onExpand={handleExpand}
                                onReplace={handleReplaceExercise}
                                onRemove={handleRemoveExercise}
                                />

                            <Box height="32px">
                                {exerciseIndex !== fields.length - 1 && 
                                    <IconButton
                                        kind="ghost"
                                        size="small"
                                        icon={<Link />}
                                        onClick={() => handleLinkSuperset(exerciseIndex)}
                                        />}
                            </Box>
                        </Box>
                    )
                })}

            </Box>

        </Box>
    )
}

export default Exercises;


interface _NoExercisesProps {
    basePath: `plan.${number}.exercises`|'exercises';
}

const _NoExercises: FC<_NoExercisesProps> = ({basePath}) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const { setValue } = useFormContext<IProgrammeBuilder>();

    const handleAddWorkout = (workout: IProgrammeWorkout) => {
        if (basePath === 'exercises') return;
        // Get the index from the basePath
        const workoutIndex = Number(basePath.split('.')[1])

        // Update the plan with the workout name and exercises
        setValue(`plan.${workoutIndex}.name`, workout.name, { shouldValidate: true });
        setValue(`plan.${workoutIndex}.exercises`, workout.exercises);

        setOpen(false);
    }

    return (
        <Box 
            display="flex" 
            alignItems="center" 
            justifyContent="center" 
            flexGrow={1}
            padding="16px"
            minHeight="48px"
            borderRadius="6px" 
            border="solid 1px var(--border-subtle-01)"
            sx={{bgcolor: 'var(--layer-01)'}}
            >
            <Box display="flex" flexDirection="column" alignItems="center">
                <span className="body-02">{t('components.programmeBuilder.exercise.noExercises')}</span>
                {basePath != 'exercises' && <Button
                    kind="ghost"
                    size="small"
                    label={t('components.buttons.addSavedWorkout')}
                    endIcon={<Add />}
                    onClick={() => setOpen(true)}
                    sx={{marginTop: '12px'}}
                    />}
                    {open && <WorkoutModal
                        open={open}
                        onClose={() => setOpen(false)}
                        onAdd={handleAddWorkout}
                        />}
            </Box>
        </Box>
    )
}
