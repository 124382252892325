import { FC } from "react";
import { useTranslation } from "react-i18next";

// Components
import Modal from "@/components/modal/modal";
import { TrashCan } from "@carbon/icons-react";


interface _DeleteModalProps {
    open: boolean;
    onClose: () => void;
    isLoading: boolean;
    onDelete: () => void;
}

const DeleteCheckinModal: FC<_DeleteModalProps> = ({
    open,
    onClose,
    isLoading,
    onDelete
}) => {

    const { t } = useTranslation();

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.deleteCheckin.title')}
            text={t('modals.deleteCheckin.text')}
            action1={{
                kind: 'danger',
                label: t('components.buttons.delete'),
                icon: <TrashCan />,
                loading: isLoading,
                onClick: onDelete
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px'}}}
            />
    )
}

export default DeleteCheckinModal;