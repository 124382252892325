import { FC } from "react";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Styles
import { CircleFill, Copy } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import Button from "@/components/button";


interface _FormLinkProps {
    id: string;
    baseUrl: string;
    suffixUrl: string;
    published: boolean;
}

const FormLink: FC<_FormLinkProps> = ({
    id,
    baseUrl,
    suffixUrl,
    published
}) => {

    const { t } = useTranslation();
    const link = `${baseUrl}${suffixUrl}${id}`;

    const handleCopyLink = () => {
        navigator.clipboard.writeText(link);
        showToast({
            type: 'info',
            title: t('notifications.form.linkCopied.title'),
        });
    }

    if (!published) return (
        <Box display="flex" alignItems="center">
            <CircleFill size={8} style={{color: 'var(--text-placeholder)'}} />
            <span className="label-text-02" style={{padding: '0 16px 0 8px'}}>{t('general.unpublished')}</span>
        </Box>
    )

    return (
        <Box display="flex" alignItems="center">

            <CircleFill size={8} style={{color: 'var(--background-brand)'}} />
            <span className="label-text-02" style={{padding: '0 4px 0 8px'}}>{t('general.published')}</span>

            {/* <span className="body-02-compact" style={{color: 'var(--text-secondary)', marginRight: '8px', whiteSpace: 'pre-wrap'}}>{t('components.formBuilder.link')}</span>
            <span className="body-02-compact" style={{maxWidth: '300px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{link}</span> */}
            
            {/* <Box width="12px" /> */}
            
            <Button
                kind="ghost"
                size="small"
                label={t('components.buttons.copyLink')}
                endIcon={<Copy />}
                minWidth={false}
                onClick={handleCopyLink}
                />
        </Box>
    )
}

export default FormLink;