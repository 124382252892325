import { FC } from "react";
import { t } from "i18next";

// Components
import { Options } from "@/components/menu";
import MenuButton from "@/components/menu_button";


interface _PlanMenuProps {
    label: string;
    disabled?: boolean;
    setOpen: (value: string) => void;
}

const PlanMenu: FC<_PlanMenuProps> = ({
    label,
    disabled = false,
    setOpen
}) => {

    const buttonOptions: Options[] = [
        {
            name: t('components.buttons.addNewPlan'),
            action: () => setOpen('add')
        },
        {
            name: t('components.buttons.assignTemplate'),
            action: () => setOpen('assign')
        }
    ];

    return (
        <MenuButton
            size='small'
            label={label}
            options={buttonOptions}
            disabled={disabled}
            />
    )
}

export default PlanMenu;