import { FC, useState } from "react"
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { ApiError } from "@/interfaces/api/error";
import { useAddSupplementTemplateMutation } from "@/repositories/supplement_template";
import { ISupplementTemplateForm } from "@/interfaces/supplement_template/supplement_template_form"

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box } from "@mui/material";
import PersonalTab from "./supplements/personal_tab";
import SecondaryToolbar from "@/components/secondary_toolbar";
import SupplementModal from "@/components/supplement_builder/supplement_modal";
import { ISupplementBuilder } from "@/interfaces/supplement_plan/supplement_builder";


const SupplementTemplateListPage: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const hasTeam = useSelector(selectHasTeam);
    const [open, setOpen] = useState<string|null>(null);
    const [addSupplementPlan, { isLoading }] = useAddSupplementTemplateMutation();

    const tabs = [
        {
            label: t('tabs.personal'),
            link: '/templates/supplements/personal',
            sx: {width: '90px'}
        },
        {
            label: t('tabs.team'),
            link: '/templates/supplements/team',
            sx: {width: '90px'}
        }
    ]

    const handleAdd = (data: ISupplementBuilder) => {
        addSupplementPlan(data as ISupplementTemplateForm).unwrap().then((template) => {
            showToast({
                type: 'success', 
                title: t('notifications.template.created.title'), 
                message: t('notifications.template.created.message', {name: template.name})
            });
            setOpen(null);
            navigate(`/templates/supplements/${template.id}`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar 
                title={t('pages.templates.supplement.title')}
                tabs={hasTeam ? tabs : []}
                action1={{
                    label: t('components.buttons.addSupplementPlan'),
                    icon: <Add />,   
                    onClick: () => setOpen('add')         
                }}
                />
                {open === 'add' && <SupplementModal 
                    open={open === 'add'} 
                    onClose={() => setOpen(null)}
                    isLoading={isLoading}
                    onSave={handleAdd} 
                    />}

            {hasTeam && <Outlet />}
            {!hasTeam && <PersonalTab />}
            
        </Box>
    )
}

export default SupplementTemplateListPage