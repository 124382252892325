import { FC } from "react";
import { t } from "i18next";

// Styles
import { Close } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import IconButton from "@/components/icon_button";


interface _FileItemProps {
    file: File;
    uploadProgress: number;
    error?: string;
    isLoading?: boolean;
    onRemove: () => void;
}

const FileItem: FC<_FileItemProps> = ({
    file,
    uploadProgress,
    error,
    isLoading,
    onRemove
}) => {

    return (
        <Box sx={{
            p: 1, pl: 2, 
            bgcolor: 'var(--layer-01)',
            maxWidth: '100%',
            borderRadius: '6px',
            border: error ? '1px solid red' : undefined
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <span className="body-02-compact text-primary">
                    {file.name}
                </span>
                <Box flexGrow={1} />

            {isLoading && (
                <>
                    <Box className='body-02-compact' component='span' mx={2}>
                        {uploadProgress}%
                    </Box>
                    <CircularProgress 
                        variant='determinate'
                        value={100}
                        size={16}
                        thickness={8}
                        sx={{
                            position: 'absolute',
                            right: 24,
                            color: 'var(--highlight)'
                        }}
                    />
                    <CircularProgress 
                        variant='determinate'
                        value={uploadProgress}
                        size={16}
                        thickness={8}
                        sx={{ color: uploadProgress == 100 ? 'var(--support-success)' : 'var(--interactive)' }}
                    />
                </>
            )}
            {!isLoading && (
                <IconButton 
                    kind='ghost'
                    size='small'
                    icon={<Close />}
                    onClick={onRemove}
                />
            )}
            </Box>
            {!!error && (
                <Box mt={1} color='red' className='helper-text-02'>
                    {t(`api.errors.${error}.message`)}
                </Box>
            )}
        </Box>
    )
}

export default FileItem