import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { ApiError } from "@/interfaces/api/error";
import { IProgrammeBuilder } from "@/interfaces/programme/programme_builder";
import { IProgrammePlan, programmePlanFormSchema } from "@/interfaces/programme/programme_plan";
import { useLazyGetProgrammePlanQuery, useUpdateProgrammePlanMutation } from "@/repositories/programme_plan";

// Styles
import { Save, Settings } from "@carbon/icons-react";

// Components
import { Box, Container } from "@mui/material";
import SecondaryToolbar from "@/components/secondary_toolbar";
import Exercises from '@/components/programme_builder/exercises';
import WorkoutMenu from '@/components/programme_builder/workout_menu';
import UnsavedChangesModal from "@/pages/common/unsaved_changes_modal";
import ProgrammeModal from "@/components/programme_builder/programme_modal";


const EditProgrammePlanPage: FC = () => {

    const { id, planId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const hasTeam = useSelector(selectHasTeam)
    const [open, setOpen] = useState<string|null>(null);
    const [selectedWorkout, setSelectedWorkout] = useState<number>(0);
    
    const [getPlan, { data: plan }] = useLazyGetProgrammePlanQuery();
    const [savePlan, { isLoading: isSaving }] = useUpdateProgrammePlanMutation();
    const [saveDraftPlan, { isLoading: isSavingDraft }] = useUpdateProgrammePlanMutation();

    const formMethods = useForm<IProgrammePlan>({
        resolver: zodResolver(programmePlanFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: '',
            focus: '',
            notes: '',
            draft: false,
            plan: [{
                uuid: uuidv4(),
                name: '',
                exercises: []
            }],
            settings: {
                rir: false,
                rpe: false,
                tempo: false,
                set_type: false,
                rest_time: false,
            }
        }
    });

    const name = useWatch({ control: formMethods.control, name: 'name' });
    const workoutName = formMethods.watch(`plan.${selectedWorkout}.name`);

    useEffect(() => {
        if (id && planId) {
            getPlan({client_id: id!, plan_id: planId}).unwrap().then((p) => {
                formMethods.reset({
                    name: p.name,
                    focus: p.focus,
                    notes: p.notes,
                    draft: p.draft,
                    plan: p.plan,
                    settings: p.settings
                })
                setLoading(false);
            }).catch((error: ApiError) => {
                navigate(`/clients/${id}/training/plans`);
                showToast({type: 'error', apiError: error.type})
            });
        }
    }, [id, formMethods, getPlan, navigate, hasTeam]);

    useEffect(() => {
        if (!loading && plan?.readonly) showToast({
            type: 'info',
            title: t('notifications.plan.readonly.title'),
            message: t('notifications.plan.readonly.message')
        });
    }, [loading]);

    const handleSelectWorkout = (index: number) => {
        setSelectedWorkout(index);
    }

    const handleUpdateSettings = (data: IProgrammeBuilder) => {
        formMethods.setValue('name', data.name, { shouldDirty: true });
        formMethods.setValue('focus', data.focus, { shouldDirty: true });
        formMethods.setValue('notes', data.notes, { shouldDirty: true });
        formMethods.setValue('settings', data.settings, { shouldDirty: true });
        setOpen(null);
    }

    const handleBack = () => {
        if (formMethods.formState.isDirty) return setOpen('unsaved');
        navigate(`/clients/${id}/training/plans`);
    }

    const handleSave = (data: IProgrammePlan) => {
        if (!id || !planId) return;
        data.draft = false;
        savePlan({p: {client_id: id!, plan_id: planId}, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.plan.updated.title'), 
                message: t('notifications.plan.updated.message', {name: name})
            });
            navigate(`/clients/${id}/training/plans`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleSaveDraft = () => {
        if (!id || !planId) return;
        const data = formMethods.getValues();
        data.draft = true;
        saveDraftPlan({p: {client_id: id!, plan_id: planId}, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.plan.updated.title'), 
                message: t('notifications.plan.updated.message', {name: name})
            });
            navigate(`/clients/${id}/training/plans`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar
                title={name}
                isLoading={loading}
                onBack={handleBack}
                iconActions={([{kind: "ghost", icon: <Settings />, onClick: () => setOpen('settings')}])}
                action1={{
                    label: t('components.buttons.save'),
                    icon: <Save />,
                    loading: isSaving,
                    disabled: isSavingDraft || plan?.readonly,
                    onClick: formMethods.handleSubmit(handleSave),
                    sx: {minWidth: '115px'}
                }}
                action2={{
                    label: t('components.buttons.saveDraft'),
                    loading: isSavingDraft,
                    disabled: isSaving || plan?.active || plan?.readonly,
                    onClick: () => handleSaveDraft(),
                }}
                sx={{borderBottom: 'none'}}
                />
            {open === 'settings' && <ProgrammeModal
                open={open === 'settings'}
                onClose={() => setOpen(null)}
                programme={formMethods.getValues()}
                onSave={handleUpdateSettings}
                />}
            {open === 'unsaved' && 
                <UnsavedChangesModal 
                    open={open === 'unsaved'} 
                    onClose={() => setOpen(null)}
                    onSubmit={() => navigate(`/clients/${id}/training/plans`)}
                    />}

            {!loading && <FormProvider {...formMethods}>
                <Container sx={{padding: '16px 24px 0 24px', overflow: 'hidden'}}>
                    <Box className="Builder">

                        <WorkoutMenu 
                            selectedIndex={selectedWorkout}
                            onSelectWorkout={handleSelectWorkout}
                            />

                        <Exercises 
                            key={selectedWorkout}
                            basePath={`plan.${selectedWorkout}.exercises`}
                            workoutName={workoutName}
                            />

                    </Box>
                </Container>
            </FormProvider>}
        </Box>
    )
}

export default EditProgrammePlanPage;