import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useRemovePaymentMethodMutation } from "@/repositories/payment_methods";

// Styes
import { ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import IPaymentMethod from "@/interfaces/payment_methods/payment_method";


interface _DeletePaymentModalProps {
    open: boolean;
    onClose: () => void;
    paymentMethod: IPaymentMethod;
}

const DeletePaymentModal: FC<_DeletePaymentModalProps> = ({
    open,
    onClose,
    paymentMethod
}) => {

    const { t } = useTranslation();
    const [removePaymentMethod, { isLoading }] = useRemovePaymentMethodMutation();

    const submit = () => {
        removePaymentMethod(paymentMethod).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.billing.paymentMethodRemoved')})
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t('modals.deletePaymentMethod.title')}
            text={<Trans
                i18nKey="modals.deletePaymentMethod.text"
                components={{ strong: <strong /> }}
                values={{ last_four: paymentMethod.last_four }}
            />}
            action1={{
                kind: "danger-ghost",
                label: t('components.buttons.confirmDeletePaymentMethod'),
                icon: <ArrowRight />,
                onClick: submit,
                loading: isLoading,
                sx: {minWidth: '240px'}
            }}

            cancel={{
                label: t('components.buttons.cancel'),
                onClick: onClose,
                disabled: isLoading
            }}
            />
    )
}

export default DeletePaymentModal;