import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

// Components
import { Box } from "@mui/material";
import SecondaryToolbar from "@/components/secondary_toolbar";


const PhysiquePage: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const tabs = [
        {
            label: t('tabs.bodyweight'),
            link: `/clients/${id}/physique/bodyweight`,
            sx: {width: '120px'}
        },
        {
            label: t('tabs.measurements'),
            link: `/clients/${id}/physique/measurements`,
            sx: {width: '120px'}
        }
    ];

    useEffect(() => {
        const routes = [
            `/clients/${id}/physique`,
            `/clients/${id}/physique/`
        ]
        if (routes.includes(location.pathname)) {
            navigate(`/clients/${id}/physique/bodyweight`)
        }
    }, [location.pathname, id, navigate])

    return (
        <Box width='100%' height='100%'>

            <SecondaryToolbar
                kind="primary"
                title={t('pages.client.physique')}
                tabs={tabs}
            />

            <Outlet />
            
        </Box>
    )
}

export default PhysiquePage