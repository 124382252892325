import { t } from "i18next";
import { useParams } from "react-router-dom";
import showToast from "@/_lib/toast";

// Services and interfaces
import { useGetClientQuery, useOnboardClientMutation } from "@/repositories/client";
import { ApiError } from "@/interfaces/api/error";

// Components
import Box from "@mui/material/Box";
import { InlineNotification } from '@/components/notification/notification';


export default function OnboardingNotice() {
    const { id } = useParams()
    const { data: client, isLoading } = useGetClientQuery(id!)
    const [ onboardClient, { isLoading: onboardInProgress } ] = useOnboardClientMutation()
    const isActive = client?.active;

    // onboarding action
    const onboard = () => onboardClient(id!).unwrap()
        .then((d) => showToast({
            type: 'success',
            title: t('notifications.client.onboarded.title'),
            message: t('notifications.client.onboarded.message', {name: `${d.first_name} ${d.last_name}`})
        }))
        .catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })

    // dont render banner if onboarded or data not yet loaded
    if (isLoading || !!(client?.onboarded_at || !isActive)) return <></>;

    return (
        <>   
            {/* banner */}
            <Box>
                <InlineNotification 
                    type='warning'
                    title={t('components.banners.clientNotOnboarded.title')} 
                    message={t('components.banners.clientNotOnboarded.message')}
                    // message={width}
                    action={{
                        label: t('components.buttons.onboardClient'),
                        loading: onboardInProgress,
                        onClick: onboard
                    }}
                />  
            </Box>   
        </>
    )
}