import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';

// Helpers
import showToast from '@/_lib/toast';

// Services and interfaces
import { ApiError } from '@/interfaces/api/error';
import { useGetClientQuery } from '@/repositories/client';
import { feedbackFormSchema, IFeedback } from '@/interfaces/checkin_submission/checkin_submission';
import { useLazyGetCheckinSubmissionQuery, useUpdateCheckinSubmissionMutation } from '@/repositories/checkin_submission';

// Styles
import { Close, LicenseDraft, Maximize, Minimize, Send } from '@carbon/icons-react';

// Components
import Box from '@mui/material/Box';
import Avatar from '@/components/avatar';
import Button from '@/components/button';
import SubmissionForm from './submission_form';
import IconButton from '@/components/icon_button';
import useCheckinPopup from './use_checkin_popup';
import NavHeaderAction from '@/components/navigation/nav_header_action';


const CheckinPopup: FC = () => {

    const { t } = useTranslation();
    const [draft, setDraft] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const { open, minimized, client_id, submission_id, handleSetOpen, handleSetMinimized } = useCheckinPopup();
    const { data: client } = useGetClientQuery(client_id!);
    const [getSubmission, { data: submission }] = useLazyGetCheckinSubmissionQuery();
    const [saveSubmission, { isLoading: isSaving }] = useUpdateCheckinSubmissionMutation();

    const formMethods = useForm<IFeedback>({
        resolver: zodResolver(feedbackFormSchema),
        mode: 'onBlur',
        defaultValues: {
            comments: '',
            video_link: ''
        }
    });

    useEffect(() => {
        if (!client_id || !submission_id) return;
        getSubmission({client_id: client_id, submission_id: submission_id}).unwrap().then((s) => {
            formMethods.reset({
                comments: s.coach_feedback?.comments ?? '',
                video_link: s.coach_feedback?.video_link ?? ''
            })
            setLoading(false)
        }).catch((error: ApiError) => {
            handleSetOpen(false)
            showToast({type: 'error', apiError: error.type})
        });
    }, [client_id, submission_id, formMethods, getSubmission])

    const toggleMinimize = () => {
        handleSetMinimized(!minimized);
    };

    const handleSave = (data: IFeedback) => {
        if (!client_id || !submission_id) return;
        setDraft(false);
        saveSubmission({p: {client_id: client_id, submission_id: submission_id}, draft: false, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.checkins.submitted.title'), 
                message: t('notifications.checkins.submitted.message')
            });
            handleSetOpen(false)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleSaveDraft = () => {
        if (!client_id || !submission_id) return;
        const data = formMethods.getValues();
        setDraft(true);
        saveSubmission({p: {client_id: client_id, submission_id: submission_id}, draft: true, data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.checkins.draft.title'), 
                message: t('notifications.checkins.draft.message')
            });
            handleSetOpen(false)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <>
          {open && (
            <Box className={`CheckinPopup ${minimized ? 'CheckinPopup__minimized' : 'CheckinPopup__expanded'}`}>

                {/* Maximized header */}
                {!minimized && <Box className="CheckinPopup__expanded-header">
                    <span className="heading-05">
                        {t('components.checkinPopup.title', { date: new Date(submission?.created_at ?? new Date()).toLocaleDateString() })}
                    </span>
                    <Box height="100%">
                        <Button
                            kind="primary"
                            size="small"
                            label={t('components.buttons.saveDraft')}
                            endIcon={<LicenseDraft />}
                            loading={isSaving && draft}
                            disabled={submission?.reviewed_at != null || submission?.readonly}
                            onClick={handleSaveDraft}
                            sx={{marginRight: '8px'}}
                            />
                        <Button
                            kind="tertiary"
                            size="small"
                            label={t('components.buttons.send')}
                            endIcon={<Send />}
                            loading={isSaving && !draft}
                            disabled={submission?.reviewed_at != null || submission?.readonly}
                            onClick={formMethods.handleSubmit(handleSave)}
                            sx={{marginRight: '8px'}}
                            />
                        <NavHeaderAction
                            kind="primary"
                            icon={<Minimize />}
                            onClick={toggleMinimize}
                            sx={{marginRight: '8px'}}
                        />
                        <NavHeaderAction
                            kind="primary"
                            icon={<Close />}
                            onClick={() => handleSetOpen(false)}
                        />
                    </Box>
                </Box>}
                    
                {/* Minimized header */}
                {minimized && <Box className="CheckinPopup__minimized-header">
                    <Avatar
                        name={client?.full_name ?? ''}
                        src={client?.avatar ?? ''}
                        sx={{marginRight: '12px'}}
                        />
                    <span className="heading-05">
                        {t('components.checkinPopup.title_minimized', { name: client?.full_name })}
                    </span>
                    <Box display="flex" flexGrow={1} />
                    <IconButton
                        kind="ghost"
                        size="small"
                        icon={<Maximize />}
                        onClick={() => handleSetMinimized(false)}
                        />
                </Box>}
    
                {/* Content */}
                {!minimized && (
                <Box height="100%" padding="16px" bgcolor="var(--background)">
                    <FormProvider {...formMethods}>
                        <SubmissionForm 
                            sections={submission?.client_questionnaire} 
                            isLoading={loading} 
                            isDisabled={submission?.reviewed_at != null || submission?.readonly} 
                            />
                    </FormProvider>
                </Box>
                )}
            </Box>
          )}
        </>
      );
}

export default CheckinPopup;