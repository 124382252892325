import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Helper
import showToast from "@/_lib/toast";

// Services and interfaces
import { selectUser } from "@/store/auth";
import { selectCoach } from "@/store/coach";
import { ApiError } from "@/interfaces/api/error";
import { useUpdateUserMutation } from "@/repositories/user";
import { useUpdateCoachMutation } from "@/repositories/coach";
import { IProfileForm, profileSchema } from "@/interfaces/coach/profile_form";

// Components
import { Box, Container } from "@mui/material";
import ProfileForm from "@/pages/common/profile_form";


const ProfilePage = () => {

    const { t } = useTranslation();
    const [updateUser, { isLoading: isUserLoading }] = useUpdateUserMutation();
    const [updateCoach, { isLoading: isCoachLoading }] = useUpdateCoachMutation();

    const user = useSelector(selectUser);
    const coach = useSelector(selectCoach);

    const formMethods = useForm<IProfileForm>({
        resolver: zodResolver(profileSchema),
        mode: 'onBlur',
        defaultValues: {
            first_name: user?.first_name,
            last_name: user?.last_name,
            phone_number: user?.phone_number,
            email: user?.email,
            business_name: coach.business_name,
            avatar: coach.avatar,
            business_logo: coach.business_logo,
        }
    });

    const submitForm = async (data: IProfileForm) => {
        if (!formMethods.formState.isDirty) return;

        const userPayload = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone_number: data.phone_number,
        };

        const formData = new FormData();
        formData.append('business_name', data.business_name);

        if (formMethods.formState.dirtyFields.avatar && data.avatar) {
            const blob = await fetch(data.avatar as URL).then(r => r.blob());
            formData.append('avatar', blob, 'avatar.jpg');
        }
        if (formMethods.formState.dirtyFields.business_logo) {
            const blob = await fetch(data.business_logo as URL).then(r => r.blob());
            formData.append('business_logo', blob, 'business_logo.jpg');
        }
        await updateUser(userPayload).unwrap().catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
            return;
        });
        await updateCoach(formData).unwrap().catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
            return;
        });
        showToast({type: 'success', title: t('notifications.settings.profile.title'), message: t('notifications.settings.profile.message')});
        // Reset the form state to use the newly updated data
        formMethods.reset(data);
    }

    return (
        <Container>
            <Box display="flex" flexDirection="column" justifyContent="flex-start">

                {/* Flexible space */}
                <Box sx={{height: 'clamp(30px, 10vh, 70px)'}} />

                {/* Title */}
                <span className="heading-07-compact" style={{textAlign: 'left', paddingLeft: '24px'}}>{t('pages.settings.profile.title')}</span>

                {/* Spacer */}
                <Box height="24px" />

                <FormProvider {...formMethods}>
                    <ProfileForm 
                        disableOnNotDirty 
                        isLoading={isUserLoading || isCoachLoading} 
                        submitForm={formMethods.handleSubmit(submitForm)}
                        />

                </FormProvider>
            
            </Box>
        </Container>
    )
}

export default ProfilePage;


