import { FC } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";
import { usePhaseContext } from "@/contexts/phase_context";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IPhaseMilestone } from "@/interfaces/phase_milestone/phase_milestone";
import { IPhaseMilestoneForm, milestoneSchema } from "@/interfaces/phase_milestone/phase_milestone_form";
import { useAddPhaseMilestoneMutation, useUpdatePhaseMilestoneMutation } from "@/repositories/phase_milestone";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";
import TextInput from "@/components/text_input";
import DateInput from "@/components/date_input";


interface FormProps {
    open: boolean;
    onClose: () => void;
    phase_id: string;
    milestone?: IPhaseMilestone;
}

const MilestoneModal: FC<FormProps> = ({
    open,
    onClose,
    phase_id,
    milestone
}) => {

    const { id } = useParams();
    const { t } = useTranslation();
    const { phase } = usePhaseContext();
    const [addMilestone, { isLoading: isAdding }] = useAddPhaseMilestoneMutation()
    const [updateMilestone, { isLoading: isEditing }] = useUpdatePhaseMilestoneMutation()

    const date = (): string => {
        const start = dayjs(dayjs.utc(phase?.start ?? phase?.expected_start).format('YYYY-MM-DDTHH:mm:ss'));
        const end = dayjs(dayjs.utc(phase?.end ?? phase?.expected_end).format('YYYY-MM-DDTHH:mm:ss'));
        if (dayjs.utc().isBetween(start, end)) return dayjs.utc().startOf('day').toISOString();
        if (dayjs.utc().isBefore(start)) return start.toISOString();
        return end.toISOString();
    }

    const formMethods = useForm<IPhaseMilestoneForm>({
        resolver: zodResolver(milestoneSchema),
        mode: 'onBlur',
        defaultValues: {
            title: milestone?.title ?? '',
            date: milestone?.date ?? date(),
            completed_at: milestone?.completed_at ?? undefined,
        }
    });

    const handleAddMilestone = (data: IPhaseMilestoneForm) => {
        addMilestone({client_id: id!, phase_id, data}).unwrap().then((n) => {
            showToast({
                type: 'success',
                title: t('notifications.phaseMilestone.created.title'),
                message: t('notifications.phaseMilestone.created.message', {name: n.title})
            });
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleUpdateMilestone = (data: IPhaseMilestoneForm) => {
        if (!milestone) return;
        const req = {client_id: id!, phase_id, id: milestone.id, data: data}
        updateMilestone(req).unwrap().then((n) => {
            showToast({
                type: 'success',
                title: t('notifications.phaseMilestone.updated.title'),
                message: t('notifications.phaseMilestone.updated.message', {name: n.title})
            });
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleClose = () => { 
        formMethods.reset(); 
        onClose(); 
    }

    return (
        <Modal 
            open={open} 
            onClose={formMethods.formState.isDirty ? undefined : handleClose}
            title={milestone ? t('modals.editMilestone') : t('modals.addMilestone')}
            action1={{
                onClick: formMethods.handleSubmit(milestone ? handleUpdateMilestone : handleAddMilestone),
                label: t('components.buttons.save'), 
                disabled: !formMethods.formState.isValid, 
                loading: isAdding || isEditing
            }}
            cancel={{label: t('components.buttons.cancel'), onClick: handleClose}}
            children={
                <FormProvider {...formMethods}>
                    <_MilestoneForm />
                </FormProvider>
            }
        />        
    )
}

export default MilestoneModal;

const _MilestoneForm: FC = () => {

    const { t } = useTranslation();
    const { phase } = usePhaseContext();
    const { control } = useFormContext<IPhaseMilestoneForm>();

    const availableDates = {
        start: dayjs(dayjs.utc(phase?.start ?? phase?.expected_start).format('YYYY-MM-DDTHH:mm:ss')), 
        end: dayjs(dayjs.utc(phase?.end ?? phase?.expected_end).format('YYYY-MM-DDTHH:mm:ss'))
    }

    return (
        <Box display="flex" flexDirection="column" minWidth="448px">

            <TextInput 
                name='title' 
                maxLength={40}
                control={control} 
                label={t('inputs.titles.milestoneTitle')}
            />

            <DateInput
                name='date'
                control={control} 
                label={t('inputs.titles.date')}
                availableDates={availableDates}
            />
            
        </Box>
    )
}