import { FC } from "react"
import { useTranslation } from "react-i18next";

// Services and interfaces
import { useGetLeadFormResponseQuery } from "@/repositories/lead_form_response";

// Components
import { Box, Skeleton } from "@mui/material"


interface _FormResponseProps {
    id: string;
}

const FormResponse: FC<_FormResponseProps> = ({
    id
}) => {

    const { t } = useTranslation();
    const { data: response, isLoading } = useGetLeadFormResponseQuery(id, {skip: !id});

    return (
        <Box display="flex" flexDirection="column">

            {/* Date */}
            <Box display="flex" justifyContent="space-between" alignItems="center" padding="8px 0" sx={{borderBottom: 'solid 1px var(--border-subtle-01)'}}>
                {isLoading ? <Skeleton variant="text" width="100px" /> : <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{t('rightPanel.formResponse.dateAdded')}</span>}
                {isLoading ? <Skeleton variant="text" width="100px" /> : <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{new Date(response?.created_at ?? '').toLocaleDateString()}</span>}
            </Box>

            {/* Form title */}
            <Box display="flex" justifyContent="space-between" alignItems="center" padding="8px 0">
                {isLoading ? <Skeleton variant="text" width="100px" /> : <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{t('rightPanel.formResponse.leadGenerationForm')}</span>}
                {isLoading ? <Skeleton variant="text" width="100px" /> : <span className="body-02-compact" style={{color: 'var(--text-secondary)'}}>{response?.name}</span>}
            </Box>

            {/* Form sections */}
            {isLoading && 
                <Box rowGap="24px" padding="24px 0">
                    <Skeleton variant="text" width="100%" height="24px" />
                    {/* <span className="heading-07-compact" style={{color: 'var(--text-secondary)'}}>{section.title}</span> */}
                    <Box height="24px" />
                    <Box display="flex" flexDirection="column" rowGap="8px">
                        {new Array(4).fill({}).map((item, i) => (
                            <Box key={i} display="flex" flexDirection="column" rowGap="8px" alignItems="flex-start" padding="4px 0">
                                <Skeleton variant="text" width="100px" height="24px" />
                                <Skeleton variant="text" width="200px" height="24px" />
                            </Box>
                        ))}
                    </Box>
                </Box>
            }
            {!isLoading && response?.form.map(section => (
                <Box key={section.uuid} rowGap="24px" padding="24px 0">
                    <span className="heading-07-compact" style={{color: 'var(--text-secondary)'}}>{section.title}</span>
                    <Box height="24px" />
                    <Box display="flex" flexDirection="column" rowGap="8px">
                        {section.questions.map(question => (
                            <Box key={question.uuid} display="flex" flexDirection="column" rowGap="8px" alignItems="flex-start" padding="4px 0">
                                <span className="label-text-02" style={{color: 'var(--text-secondary)'}}>{question.title}</span>
                                <span className="body-02-compact">{question.answer}</span>
                            </Box>
                        ))}
                    </Box>
                </Box>
            ))}


        </Box>
    )
}

export default FormResponse;