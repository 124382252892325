import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ISupplement } from "@/interfaces/supplement/supplement";
import { useDeleteSupplementMutation } from "@/repositories/supplement";

// Components
import Modal from "@/components/modal/modal";
import { TrashCan } from "@carbon/icons-react";
import IconButton from "@/components/icon_button";
import { ApiError } from "@/interfaces/api/error";


interface DeleteSupplementProps {
    row: ISupplement;
}

const DeleteSupplement: FC<DeleteSupplementProps> = ({
    row
}) => {

    const [open, setOpen] = useState<boolean>(false);
    const disableMenu: boolean = ['default', 'team'].includes(row.library);

    return (
        <>
            <IconButton
                kind="ghost"
                size="small"
                icon={<TrashCan style={{color: !disableMenu ? 'var(--support-error)' : 'var(--icon-disabled)'}} />}
                disabled={disableMenu}
                onClick={() => setOpen(true)}
                />
            {open && <DeleteSupplementModal
                open={open}
                onClose={() => setOpen(false)}
                supplement={row}
                />}
            
        </>
    )
}

export default DeleteSupplement;

interface DeleteSupplementModalProps {
    open: boolean;
    onClose: () => void;
    supplement: ISupplement;
}

const DeleteSupplementModal: FC<DeleteSupplementModalProps> = ({
    open,
    onClose,
    supplement
}) => {

    const { t } = useTranslation();
    const [deleteSupplement, { isLoading }] = useDeleteSupplementMutation();

    const handleDelete = () => {
        deleteSupplement(supplement.id).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.supplement.deleted.title'), message: t('notifications.supplement.deleted.message', {name: supplement.name})});
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal 
            open={open}
            onClose={onClose}
            title={t('modals.deleteSupplement.title', {name: supplement?.name})}
            text={<Trans i18nKey="modals.deleteSupplement.text" values={{ name: supplement.name }} />}
            action1={{
                kind: 'danger',
                label: t('components.buttons.delete'),
                loading: isLoading,
                onClick: handleDelete,
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: onClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '480px'}}}
            />
    )
}