import { FC } from "react"

// Components
import Modal from "@/components/modal/modal";
import LineChart from "@/components/charts/line_chart";


interface _ChartProps {
    open: boolean;
    onClose: () => void;
    kind?: 'line' | 'bar';
    title: string;
    data: any[];
    metric: string;
    isRating?: boolean;
    yLabel?: string;
    xLabel?: string;
}

const Chart: FC<_ChartProps> = ({
    open,
    onClose,
    kind = 'line',
    title,
    data = [],
    metric,
    isRating = false,
    yLabel,
    xLabel
}) => {

    return (
        <Modal 
            open={open}
            onClose={() => onClose()}
            title={title}
            showClose
            children={
            <LineChart
                kind={kind}
                data={data}
                metric={metric}
                isRating={isRating}
                yLabel={yLabel}
                xLabel={xLabel}
                />}
            />
    )
}

export default Chart;