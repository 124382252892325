import baseApi from '@/repositories/base_api';
import { IPlanForm } from '@/interfaces/plan/plan';
import { IAPIResponse } from '@/interfaces/api/response';
import { ICopyPlanForm } from '@/interfaces/plan/copy_plan';
import { IProgrammePlan } from '@/interfaces/programme/programme_plan';


export const programmePlanApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getProgrammePlans: build.query<IProgrammePlan[], string>({
            query: (id) => ({ url: `clients/${id}/programmes`, method: 'get' }),
            transformResponse: (response: IAPIResponse<IProgrammePlan[]>) => response.data.sort((a, b) => a.name.localeCompare(b.name)).sort((a,b) => Number(b.active)-Number(a.active)),
            providesTags: (result, error, id) => [
                { type: 'Plans.programme.list', id }, 
                { type: 'Plans.programme.list', id: 'ALL' }
            ],
            keepUnusedDataFor: 900
        }),
        getProgrammePlan: build.query<IProgrammePlan, IPlanForm>({
            query: (p) => ({ url: `/clients/${p.client_id}/programmes/${p.plan_id}`, method: 'get' }),
            transformResponse: (response: IAPIResponse<IProgrammePlan>) => response.data,
        }),
        addProgrammePlan: build.mutation<IProgrammePlan, {id: string, data: IProgrammePlan}>({
            query: ({id, data}) => ({ url: `clients/${id}/programmes`, method: 'post', data }),
            transformResponse: (response: IAPIResponse<IProgrammePlan>) => response.data,
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(programmePlanApi.util.updateQueryData('getProgrammePlans', _.id, plans => {
                    plans.push(response.data);
                    plans.sort((a, b) => a.name.localeCompare(b.name)).sort((a,b) => Number(b.active)-Number(a.active));
                }))).catch(() => {});
            },
        }),
        updateProgrammePlan: build.mutation<IProgrammePlan, {p: IPlanForm, data: IProgrammePlan}>({
            query: ({p, data}) => ({ url: `clients/${p.client_id}/programmes/${p.plan_id}`, method: 'patch', data: data }),
            transformResponse: (response: IAPIResponse<IProgrammePlan>) => response.data,
            async onQueryStarted({p}, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(programmePlanApi.util.updateQueryData('getProgrammePlans', p.client_id, plans => {
                    const index = plans.findIndex((item) => item.id === response.data.id);
                    plans.splice(index, 1, response.data);
                }))).catch(() => {});
            },
        }),
        deleteProgrammePlan: build.mutation<void, IPlanForm>({
            query: (p) => ({ url: `/clients/${p.client_id}/programmes/${p.plan_id}`, method: 'delete' }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(p, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(programmePlanApi.util.updateQueryData('getProgrammePlans', p.client_id, plans => {
                    return plans.filter((item) => item.id !== p.plan_id)
                }))).catch(() => {});
            },
        }),
        deleteProgrammePlans: build.mutation<void, {id: string, plans: string[]}>({
            query: ({id, plans}) => ({ url: `/clients/${id}/programmes/bulk-delete`, method: 'post', data: {ids: plans} }),
            transformResponse: (response: IAPIResponse<void>) => response.data,
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                queryFulfilled.then(() =>
                dispatch(programmePlanApi.util.updateQueryData('getProgrammePlans', params.id, plans => {
                    return plans.filter((item) => !params.plans.includes(item.id))
                }))).catch(() => {});
            },
        }),
        copyProgrammePlan: build.mutation<IProgrammePlan, ICopyPlanForm>({
            query: (p) => ({ url: `/clients/${p.client_id}/programmes/${p.plan_id}/copy?template=${p.copy_to_template}`, method: 'post' }),
            transformResponse: (response: IAPIResponse<IProgrammePlan>) => response.data,
            invalidatesTags: (result, _, arg) => {
                if (arg.copy_to_template) return ['Templates.programme.list'];
                return [];
            },
            async onQueryStarted({ client_id }, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(programmePlanApi.util.updateQueryData('getProgrammePlans', client_id, plans => {
                    if (!response.data) return;
                    plans.push(response.data);
                    plans.sort((a, b) => a.name.localeCompare(b.name)).sort((a,b) => Number(b.active)-Number(a.active));
                }))).catch(() => {});
            },
        }),
        setActiveProgrammePlan: build.mutation<IProgrammePlan, {client_id: string, plan_id: string}>({
            query: ({client_id, plan_id}) => ({ url: `/clients/${client_id}/programmes/${plan_id}/active`, method: 'patch' }),
            transformResponse: (response: IAPIResponse<IProgrammePlan>) => response.data,
            async onQueryStarted({ client_id }, { dispatch, queryFulfilled }) {
                queryFulfilled.then((response) =>
                dispatch(programmePlanApi.util.updateQueryData('getProgrammePlans', client_id, plans => {
                    const index = plans.findIndex((item) => item.id === response.data.id);
                    if (index == -1) return plans;
                    plans.splice(index, 1, response.data);
                    plans.map((item) => {
                        if (item.id !== response.data.id) return item.active = false;
                        return item;
                    });
                }))).catch(() => {});
            },
        }),
    }),
})

export const { 
    useGetProgrammePlansQuery,
    useGetProgrammePlanQuery,
    useLazyGetProgrammePlanQuery,
    useAddProgrammePlanMutation,
    useUpdateProgrammePlanMutation,
    useDeleteProgrammePlanMutation,
    useDeleteProgrammePlansMutation,
    useCopyProgrammePlanMutation,
    useSetActiveProgrammePlanMutation,
} = programmePlanApi;

