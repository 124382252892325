import { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Styles
import { ChevronDown } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";


interface NavButtonProps {
    kind?: 'primary' | 'secondary' | 'submenu' | 'sidemenu' | 'ghost'
    label: string;
    // Optional string to indicate the path for the button
    path?: string;
    // Optional boolean to indicate if the button has a submenu
    hasSubMenu?: boolean;
    // Optional string to indicate the root path for the tabs
    tabsRoot?: string;
    startIcon?: ReactNode;
    isOpen? : boolean;
    onClick?: (()=>void);
    onHover?: ((entered: boolean)=>void);
    marginTop?: string;
    marginBottom?: string;
    children?: ReactNode[] | ReactNode;
}

const NavButton: React.FC<NavButtonProps> = ({
    kind = 'primary', 
    label,
    path,
    startIcon,
    hasSubMenu = false,
    tabsRoot,
    isOpen = false,
    onClick,
    onHover,
    marginTop,
    marginBottom,
    children,
}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const isAtPath = !!path && location.pathname.includes(path) || !!tabsRoot && location.pathname.includes(tabsRoot)
    const isActive = hasSubMenu || tabsRoot ? isAtPath : location.pathname == path;
    const handleClick = () => (!hasSubMenu && !!path && navigate(path)) || onClick?.()
    const handleEnter = () => onHover?.(true)
    const handleExit = () => onHover?.(false)

    const classes = {
        'NavButton__primary': kind === 'primary',
        'NavButton__secondary': kind === 'secondary',
        'NavButton__submenu': kind === 'submenu',
        'NavButton__sidemenu': kind === 'sidemenu',
        'NavButton__ghost': kind === 'ghost',
        'NavButton__primary--selected': kind === 'primary' && isActive,
        'NavButton__secondary--selected': kind === 'secondary' && isActive,
        'NavButton__submenu--selected': kind === 'submenu' && isActive,
        'NavButton__sidemenu--selected': kind === 'sidemenu' && isActive,
        'NavButton__ghost--selected': kind === 'ghost' && isActive,
    }

    const validClasses = Object.entries(classes)
        .filter(([,v]) => !!v)
        .map(([k,]) => k)
        .join(' ');

    return (
        <ListItemButton 
            className={validClasses} 
            sx={{width: '100%', borderRadius: '4px', height: '48px', maxHeight: '48px', padding: '0 16px', marginTop: marginTop, marginBottom: marginBottom}}
            selected={true} 
            onMouseEnter={handleEnter}
            onMouseLeave={handleExit}
            onClick={handleClick}>
             {startIcon && <Box sx={{display: 'flex', alignItems: 'center', marginRight: '24px'}}>{startIcon}</Box>}
             <span className="body-02" style={{textWrap: 'nowrap', flexGrow: 1}}>{label}</span>
             {!!children && children}
             {hasSubMenu && 
                <Box sx={{
                    height: 16,
                    '& svg': {
                        transform: isOpen ? 'rotate(180deg)' : undefined,
                        transition: 'transform 0.3s linear'
                    }
                }}>
                    <ChevronDown />
                </Box>
            }
        </ListItemButton>
    )
}

export default NavButton;