import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { ApiError } from "@/interfaces/api/error";
import { ILeadFormForm } from '@/interfaces/lead_form/lead_form_form';
import { leadFormSchema } from '@/interfaces/lead_form/lead_form_form';
import { useLazyGetLeadFormQuery, useUpdateLeadFormMutation } from '@/repositories/lead_form';

// Styles
import { Edit, Save } from "@carbon/icons-react";

// Components
import { Box, Container } from "@mui/material";
import Form from "@/components/form_builder/form";
import FormLink from '@/components/form_builder/form_link';
import SecondaryToolbar from "@/components/secondary_toolbar";
import RenameFormModal from "@/components/builder/rename_modal";
import UnsavedChangesModal from "@/pages/common/unsaved_changes_modal";


const EditLeadFormPage: FC = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const publicUrl = import.meta.env.VITE_PUBLIC_URL;

    const hasTeam = useSelector(selectHasTeam)
    const [open, setOpen] = useState<string|null>(null);
    
    const [loading, setLoading] = useState<boolean>(true);
    const [getForm, { data: form }] = useLazyGetLeadFormQuery();
    const [updateLeadForm, { isLoading: isUpdating }] = useUpdateLeadFormMutation();

    const formMethods = useForm<ILeadFormForm>({
        resolver: zodResolver(leadFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: t('pages.prospects.leadForm.newForm'),
            published: false,
            form: [{
                uuid: uuidv4(),
                title: t('pages.prospects.leadForm.details'),
                required: true,
                questions: [{
                    uuid: uuidv4(),
                    title: t('pages.prospects.leadForm.firstName'),
                    type: 'text',
                    required: true,
                },
                {
                    uuid: uuidv4(),
                    title: t('pages.prospects.leadForm.lastName'),
                    type: 'text',
                    required: true,
                },
                {
                    uuid: uuidv4(),
                    title: t('pages.prospects.leadForm.email'),
                    type: 'email',
                    required: true,
                },
                {
                    uuid: uuidv4(),
                    title: t('pages.prospects.leadForm.phoneNumber'),
                    type: 'phone',
                    required: true,
                }]
            },
            {
                uuid: uuidv4(),
                title: '',
                questions: [{
                        uuid: uuidv4(),
                        title: '',
                        type: 'text',
                        required: true,
                    }]
            }]
        }
    });

    const name = useWatch({ control: formMethods.control, name: 'name' });

    useEffect(() => {
        if (id) {
            getForm(id).unwrap().then((f) => {
                formMethods.reset({
                    name: f.name,
                    published: f.published,
                    form: f.form
                })
                setLoading(false)
            }).catch((error: ApiError) => {
                navigate(`/prospects/lead-forms${hasTeam ? '/personal' : '/'}`);
                showToast({type: 'error', apiError: error.type})
            });
        }
    }, [id, formMethods, getForm, navigate, hasTeam])

    useEffect(() => {
        // Used to ensure the user is prompted when navigating away with unsaved changes
    }, [formMethods.formState.isDirty]);

    const handleBack = () => {
        if (formMethods.formState.isDirty) return setOpen('unsaved');
        navigate(`/prospects/lead-forms`);
    }

    const handleSave = (data: ILeadFormForm) => {
        updateLeadForm({id: String(id), data: data}).unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.form.updated.title'), 
                message: t('notifications.form.updated.message', {name: name})
            });
            navigate(`/prospects/lead-forms`)
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }
    
    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar
                title={name}
                titleAction={{  
                    icon: <Edit />,
                    onClick: () => setOpen('edit')
                }}
                isLoading={loading}
                onBack={handleBack}
                slot={<FormLink id={id ?? ''} baseUrl={publicUrl} suffixUrl={'leadforms/'} published={form?.published ?? false} />}
                action1={{
                    label: t('components.buttons.save'),
                    icon: <Save />,
                    loading: isUpdating,
                    onClick: formMethods.handleSubmit(handleSave),
                    sx: {minWidth: '115px'}
                }}
                />
            {open === 'edit' && 
                <RenameFormModal 
                    open={open === 'edit'} 
                    onClose={() => setOpen(null)}
                    onSubmit={(d) => formMethods.setValue('name', d.name, { shouldDirty: true })}
                    title={t('modals.renameForm')}
                    name={name}
                    />}
            {open === 'unsaved' && 
                <UnsavedChangesModal 
                    open={open === 'unsaved'} 
                    onClose={() => setOpen(null)}
                    onSubmit={() => navigate(`/prospects/lead-forms`)}
                    />}

            <Container sx={{padding: '16px 24px', overflow: 'hidden'}}>

                <FormProvider {...formMethods}>
                    <Form isLoading={loading} />
                </FormProvider>

            </Container>

        </Box>
    )
}

export default EditLeadFormPage;