import { BaseSyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { Control, useController } from "react-hook-form";

// Constants
import { emojiRegex } from "@/_constants/text";

// Components
import { Error } from '@mui/icons-material';
import { TextField, InputAdornment, Box } from "@mui/material";

interface EmailInputProps {
  name: string;
  control: Control<any>;
  size?: 'small' | 'medium' | 'large';
  label?: string;
  placeHolder?: string;
  tabIndex?: number;
  disabled?: boolean;
}

const EmailInput: React.FC<EmailInputProps>  = ({
  name,
  control,
  size = 'small',
  label,
  placeHolder,
  tabIndex = -1,
  disabled = false
}) => {

    const { t } = useTranslation();
    const { field, fieldState } = useController({name, control});

    const classes = {
      'TextInput': true,
      'TextInput-small': size == 'small',
      'TextInput-medium': size == 'medium',
      'TextInput-large': size == 'large',
    }
  
    const validClasses = Object.entries(classes)
        .filter(([,v]) => !!v)
        .map(([k,]) => k)
        .join(' ')

    const handleBeforeInput = (event: BaseSyntheticEvent) => {
      const nativeEvent = event.nativeEvent as InputEvent;
      const inputValue = nativeEvent.data;

      if (inputValue && emojiRegex.test(inputValue)) {
        event.preventDefault();
      }
    };

    return (
      // Width set to 100% to always fill what ever container it is in
      <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: '12px', width: '100%'}}>

        <span className="label-text-02 text-secondary" style={{marginBottom: '0.5em'}}>{label ?? t('inputs.titles.emailAddress')}</span>

        <TextField
          ref={field.ref}
          className={validClasses}
          value={field.value || ''}
          onChange={(e) => field.onChange(e.target.value)}
          onBlur={field.onBlur}
          onBeforeInput={handleBeforeInput}
          inputProps={{maxLength: 320}}
          placeholder={placeHolder ?? t('inputs.placeholders.emailAddress')}
          tabIndex={tabIndex}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={ fieldState.invalid ? {marginLeft: '8px'} : {marginLeft: 0}}>
                {fieldState.invalid  && (<Error color="error" sx={{fontSize: '16px', marginRight: '8px'}} />)}
              </InputAdornment>
            )
          }}
        />
        
      </Box>
    );
};

export default EmailInput;