import { z } from "zod";
import ruleset from "@/_helpers/ruleset";
import { ILeadFormSection } from "./lead_form";
import { formSectionSchema } from "@/interfaces/form_builder/form_builder";

export interface ILeadFormForm {
    name: string;
    form: ILeadFormSection[];
    published: boolean;
}

export const leadFormSchema = z.object({
    name: ruleset.required,
    published: ruleset.boolean,
    form: formSectionSchema
});