import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Helpers
import { capitalizeWords } from "@/_helpers/text_functions";

// Services and interfaces
import { selectHasTeam } from "@/store/team";
import { ICheckinTemplate } from "@/interfaces/checkin_template/checkin_template";

// Components
import CheckinMenu from "./checkin_menu";
import TableToggle from "./toggle_shared";
import { ColDef } from "@/components/datatable";


const useCheckinTemplateTableHeaders = (): ColDef<ICheckinTemplate>[] => {

    const { t } = useTranslation();
    const hasTeam = useSelector(selectHasTeam);

    const headers: ColDef<ICheckinTemplate>[] = [
        {
            field: 'name',
            headerName: t('components.dataTable.headers.name'),
            width: '30%',
            sortable: true,
            render: (row: ICheckinTemplate) => capitalizeWords(row.name)
        },
        {
            field: 'created_at',
            headerName: t('components.dataTable.headers.dateAdded'),
            width: '15%',
            render: (row: ICheckinTemplate) => new Date(row.created_at).toLocaleDateString()
        },
        {
            field: 'assigned_count',
            headerName: t('components.dataTable.headers.assignedTo'),
            width: '15%',
        },
        {
            field: 'status',
            headerName: t('components.dataTable.headers.status'),
            width: '20%',
            render: (row: ICheckinTemplate) => t(`enums.checkinTemplateStatus.${row.status}`)
        },
        {
            field: 'shared',
            headerName: t('components.dataTable.headers.shared'),
            width: '10%',
            render: (row: ICheckinTemplate) => <TableToggle row={row} />
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (row: ICheckinTemplate) => <CheckinMenu row={row} />
        }
    ];

    if (!hasTeam) {
        return headers.filter((h) => h.field !== 'shared');
    }

    return headers;
}

export default useCheckinTemplateTableHeaders;