import { z } from 'zod';
import i18next from 'i18next';
import ruleset from '@/_helpers/ruleset';

export interface IChangePasswordForm {
    password: string;
    password_confirmation: string;
}

export const passwordSchema = z.object({
    password: ruleset.passwordCriteria,
    password_confirmation: ruleset.passwordConfirmation,
}).superRefine(({ password, password_confirmation }, ctx) => {
    if (password !== password_confirmation) {
        ctx.addIssue({
        code: "custom",
        message: i18next.t('inputs.errors.passwordMismatch'),
        path: ['password_confirmation']
        });
    }
});